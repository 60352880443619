import React, { useState } from "react";
import { Button, Flex, Input, Text, VStack } from "@chakra-ui/react";
import { AiOutlinePlusCircle } from "react-icons/ai";
import DummyQuestionnaire from "./dummyQuestionnaire";
import QuestionTemplate from "./QuestionTemplate";
import { v4 as uuid } from "uuid";
function SectionQuestions({
  sectionData,
  sectionHeading,
  setSectionHeading,
  questionText,
  setQuestionText,
  activeButton,
}) {
  const [choices, setChoices] = useState([""]);
  const [questionState, setQuestionState] = useState("Multiple Choice");

  const addQuestion = (e) => {
    e.preventDefault();
    sectionData.sectionHeading = sectionHeading;
    sectionData?.sectionQuestions?.push({
      question_text: questionText,
      question_type: questionState,
      choices: [...choices],
      questionId: uuid(),
    });
    const options = document.getElementsByName("sectionQuestionData")[0];
    options.reset();
    setQuestionText("");
    setChoices([""]);
  };
  return (
    <VStack alignItems="flex-start" bg="light.100" p="0.8333vw">
      <VStack w="100%" justifyContent="flex-start" alignItems="flex-start">
        <Text fontWeight="600">Section</Text>
        <Input
          value={sectionHeading}
          fontFamily="inter"
          required
          placeholder="Heading"
          bgColor="#F8F8F5"
          size="sm"
          borderRadius="0px"
          borderRight="none"
          borderTop="none"
          onInput={(e) => setSectionHeading(e.target.value)}
          borderLeft="none"
          borderBottom="1px solid #3B5D7C"
          _focus={{ outline: "none" }}
        />
      </VStack>
      <DummyQuestionnaire
        direction="row"
        sectionQuestions={true}
        questions={sectionData?.sectionQuestions}
        activeButton={activeButton}
      />
      <form name="sectionQuestionData" onSubmit={addQuestion}>
        <QuestionTemplate
          setQuestionState={setQuestionState}
          questionState={questionState}
          questionText={questionText}
          setQuestionText={setQuestionText}
          choices={choices}
          setChoices={setChoices}
        />
        <Flex w="100%" justify="flex-end" my="0.3vh">
          <Button
            background="none"
            type="submit"
            size="sm"
            _focus={{ outline: "none" }}
          >
            <AiOutlinePlusCircle size={17} />
          </Button>
        </Flex>
      </form>
    </VStack>
  );
}

export default SectionQuestions;

import { Box, Button, Flex, Text, VStack } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import {
  useAddQuestionsMutation,
  useGetQuestionsQuery,
} from "../../state/api/medicalApi";
import CreateQuestionnaire from "../Qna/createQuestionnaire";
import LibraryBtn from "../Qna/LibraryBtn";
import { useDispatch, useSelector } from "react-redux";
import { resetQuestions } from "../../state/reducers/newStudyReducer";
import Toastify from "toastify-js";
import "toastify-js/src/toastify.css";

function Questionnaire({ caseId }) {
  const dispatch = useDispatch();
  const [slideType, setSlideType] = useState("HAndE");
  const [newQuestionState, setNewQuestionState] = useState(false);
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [addQuestions] = useAddQuestionsMutation();
  const { questions } = useSelector((state) => state.newStudyState);

  const { data: studyQuestions } = useGetQuestionsQuery({ studyId: caseId });

  const uploadQuestions = async () => {
    const resp = await addQuestions({
      studyId: caseId,
      questions: { questions },
    });
    if (resp?.data?.status >= 200) {
      Toastify({
        text: "Questions uploaded",
        duration: 3000,
        gravity: "bottom",
        position: "center",
        style: {
          background: "green",
        },
        onClick: function () {},
      }).showToast();
      dispatch(resetQuestions());
      setNewQuestionState(false);
    } else {
      Toastify({
        text: "Questions upload failed",
        duration: 3000,
        gravity: "bottom",
        position: "center",
        style: {
          background: "red",
        },
        onClick: function () {},
      }).showToast();
    }
  };
  const buttonStyle = {
    fontSize: "0.9rem",
    fontFamily: "inter",
    fontWeight: "400",
    color: "light.700",
  };
  useEffect(() => {
    setSelectedQuestions([]);
  }, [slideType]);

  return (
    <Flex
      direction="column"
      justifyContent="center"
      alignItems="center"
      overflow="hidden"
    >
      <Flex
        w="100%"
        p="2.407vh 11.1458vw 2.8703vh 2.6041vw"
        justifyContent="space-between"
      >
        <Flex
          border="1px solid rgba(64, 123, 255, 1)"
          borderRadius="5px"
          width="23.333vw"
          h="5.1851vh"
          justifyContent="space-between"
          alignItems="center"
          px="0.4166vw"
          minW="300px"
          flexWrap="wrap"
          bgColor="rgba(255, 255, 255, 1)"
        >
          <Button
            onClick={() => setSlideType("HAndE")}
            borderColor={slideType === "HAndE" ? "blue" : "grey"}
            color={
              slideType === "HAndE"
                ? "rgba(27, 117, 188, 1)"
                : "rgba(0, 0, 0, 1)"
            }
            backgroundColor={
              slideType === "HAndE"
                ? "rgba(198, 221, 238, 1)"
                : "rgba(255, 255, 255, 1)"
            }
            _hover={{ bgColor: "none" }}
            h="3.7037vh"
            _focus={{ outline: "none" }}
            fontWeight="400"
            fontFamily="inter"
            minW="40%"
          >
            H&E Slides
          </Button>
          <Button
            onClick={() => setSlideType("Trichrome")}
            borderColor={slideType === "Trichrome" ? "blue" : "grey"}
            color={
              slideType === "Trichrome"
                ? "rgba(27, 117, 188, 1)"
                : "rgba(0, 0, 0, 1)"
            }
            backgroundColor={
              slideType === "Trichrome"
                ? "rgba(198, 221, 238, 1)"
                : "rgba(255, 255, 255, 1)"
            }
            marginLeft="2"
            h="3.7037vh"
            _focus={{ outline: "none" }}
            _hover={{ bgColor: "none" }}
            fontWeight="400"
            fontFamily="inter"
            minW="40%"
          >
            Trichrome Slides
          </Button>
        </Flex>
        <Flex>
          {newQuestionState ? (
            <Flex>
              <Button
                onClick={() => {
                  setNewQuestionState(false);
                  dispatch(resetQuestions());
                }}
                bg="light.100"
                style={buttonStyle}
                size="sm"
              >
                Cancel
              </Button>
              <Button
                onClick={uploadQuestions}
                bg="rgba(27, 117, 188, 0.25)"
                style={buttonStyle}
                size="sm"
                ml="1rem"
                disabled={
                  !questions?.HAndE?.length && !questions?.Trichrome?.length
                }
              >
                Save Changes
              </Button>
            </Flex>
          ) : (
            <Button
              onClick={() => setNewQuestionState(true)}
              bg="#FCFCFC"
              style={buttonStyle}
              size="sm"
            >
              Add Questions
            </Button>
          )}
        </Flex>
      </Flex>

      <Flex
        w="76.979vw"
        bg="#FCFCFC"
        minW="76.979vw"
        maxH="66vh"
        minH="66vh"
        flex="1"
        direction="column"
        p={newQuestionState ? "2vw 4.4791vw" : "2vw 6.8229vw"}
        overflow="auto"
        justifyContent="flex-start"
      >
        {!newQuestionState ? (
          <Box>
            {studyQuestions?.data?.[slideType]?.map((question, index) => {
              return (
                <VStack
                  key={question?._id ? question?._id : index}
                  spacing={4}
                  mt="1.388vh"
                  w="100%"
                  maxW="100%"
                  alignItems="flex-start"
                  p="0.8333vw"
                  justifyContent="flex-start"
                >
                  {question?.is_section && (
                    <Text fontWeight="600">Section</Text>
                  )}
                  {question?.is_section && (
                    <>
                      <Text>{`Q${index + 1}. ${
                        question?.section_heading
                      }`}</Text>
                    </>
                  )}
                  {!question?.is_section ? (
                    <Flex direction="column" justifyContent="flex-start">
                      <Text w="100%" maxW="100%" pb="1vh">{`Q${index + 1}. ${
                        question?.question_text
                      } `}</Text>

                      <Box w="100%" justifyContent="flex-start">
                        {question?.choices?.map((choice, i) => {
                          return (
                            <Text key={i} textAlign="start">
                              {choice}
                            </Text>
                          );
                        })}
                      </Box>
                    </Flex>
                  ) : (
                    question?.section_questions?.map((sectionQuestion, i) => {
                      return (
                        <VStack
                          key={sectionQuestion?._id ? sectionQuestion?._id : i}
                          spacing={4}
                          mt="1.388vh"
                          w="100%"
                          maxW="100%"
                          alignItems="flex-start"
                          p="0.8333vw"
                        >
                          <Text
                            w="100%"
                            maxW="100%"
                            pb="1vh"
                          >{` ${sectionQuestion?.question_text}`}</Text>
                          <Box w="100%" justifyContent="flex-start">
                            {sectionQuestion?.choices?.map((choice, i) => {
                              return <Text key={i}>{choice}</Text>;
                            })}
                          </Box>
                        </VStack>
                      );
                    })
                  )}
                </VStack>
              );
            })}
          </Box>
        ) : (
          <Flex direction="column">
            <Flex justifyContent="flex-end">
              <LibraryBtn
                activeButton={slideType}
                selectedQuestions={selectedQuestions}
                setSelectedQuestions={setSelectedQuestions}
              />
            </Flex>
            <CreateQuestionnaire activeButton={slideType} />
          </Flex>
        )}
      </Flex>
    </Flex>
  );
}

export default Questionnaire;

import React from "react";
import {
  Box,
  Text,
  HStack,
  Flex,
  VStack,
  useMediaQuery,
  Image,
} from "@chakra-ui/react";
import moment from "moment";
import { IoRadioButtonOnSharp } from "react-icons/io5";
import Chart from "./Chart";
import { getSlideUrl } from "../../hooks/utility";
import Loading from "../Loading/loading";

const Timeline = ({ slides, studyName, isLoading, studyInfo, caseId }) => {
  const [ifWidthLessthan1920] = useMediaQuery("(max-width:1920px)");
  return isLoading ? (
    <Loading />
  ) : (
    <Flex
      direction="column"
      bgColor="#FCFCFC"
      px="1vw"
      maxW="600px"
      mt="2px"
      mr="1.4vw"
      h="80vh"
      maxH="80vh"
    >
      <Flex
        fontSize={ifWidthLessthan1920 ? "14px" : " 0.72916vw"}
        overflow="hidden"
        bg="light.100"
        my="1.6vh"
        pl="0.625vw"
        ml="0.5vw"
        pb="2vh"
        py="1.11vh"
        h="60vh"
        maxH="60vh"
        boxShadow="1px 1px 2px rgba(176, 200, 214, 0.1)"
        css={{
          "&::-webkit-scrollbar": {
            width: "4px",
          },
          "&::-webkit-scrollbar-track": {
            width: "6px",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "#C4C4C4",
          },
        }}
      >
        {!slides?.length > 0 ? (
          <Flex alignItems="center" justifyContent="center" w="100%">
            <Text>No Slides Available</Text>
          </Flex>
        ) : (
          <>
            <VStack spacing={0} pb="1vh">
              <IoRadioButtonOnSharp
                color="#434343"
                size="0.9vw"
                opacity={0.6}
              />
              <Box h="100%" w="1.5px" bgColor="#434343" opacity={0.6} />
            </VStack>
            <Box
              w="100%"
              h="100%"
              maxH="100%"
              overflow="auto"
              css={{
                "&::-webkit-scrollbar": {
                  width: "4px",
                },
                "&::-webkit-scrollbar-track": {
                  width: "6px",
                },
                "&::-webkit-scrollbar-thumb": {
                  background: "#C4C4C4",
                },
              }}
            >
              <Text ml="2vw">{`${slides?.length} slides`}</Text>

              {slides?.map((slide, index) => {
                return (
                  <Flex mt="1vh" pb="2vh" key={index}>
                    <Image
                      src={getSlideUrl(slide?.awsImageBucketUrl)}
                      w="1.458vw"
                      h="2.592vh"
                      mr="0.6vw"
                      mt="0.7vh"
                    />
                    <Text>
                      {`${studyName}-${index + 1} Uploaded at ${moment(
                        slide?.created_at
                      ).format(" DD.MM.YYYY-h:mm:ss ")}by ${slide?.firstName} ${
                        slide?.lastName
                      }`}
                    </Text>
                  </Flex>
                );
              })}
            </Box>
          </>
        )}
      </Flex>
      <Chart studyInfo={studyInfo} caseId={caseId} />
    </Flex>
  );
};

export default Timeline;

import React, { useState, useEffect } from "react";
import {
  Button,
  Flex,
  HStack,
  VStack,
  Text,
  useMediaQuery,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Box,
  useToast,
  Link,
  Image,
  Badge,
} from "@chakra-ui/react";
import { AiOutlineCaretDown } from "react-icons/ai";
import { IoGrid } from "react-icons/io5";
import { BiLinkExternal } from "react-icons/bi";
import { Link as RouteLink } from "react-router-dom";
import { getSlideUrl } from "../../../../hooks/utility";
import { CheckCircleIcon } from "@chakra-ui/icons";
import { MdOutlineTableRows } from "react-icons/md";
import ProgressBar from "./SlideTabScreenSubComponent/ProgressBar";
import SlideTableView from "./SlideTabScreenSubComponent/SlideTableView";
import SlideGridView from "./SlideTabScreenSubComponent/SlideGridView";

const SlideTabScreen = ({
  readerSlideInfo,
  userInfo,
  setSlideType,
  caseId,
  permission,
}) => {
  const [ifWidthLessthan1920] = useMediaQuery("(max-width:1920px)");
  const [activeButton, setActiveButton] = useState(
    // Retrieve activeButton from local storage or default to 1
    parseInt(localStorage.getItem("activeButton"), 10) || 1
  );

  const [layoutSelect, setLayoutSelect] = useState("Table");
  const [selectedStatus, setSelectedStatus] = useState("All");

  const filteredSlides = readerSlideInfo?.data?.filter((item) => {
    if (selectedStatus === "Saved" && item.status === true) {
      return true;
    }
    if (selectedStatus === "New" && item.status === false) {
      return true;
    }
    if (selectedStatus === "All") {
      return true;
    }
  });

  useEffect(() => {
    setSlideType(activeButton === 1 ? "HAndE" : "Trichrome");
    // Save activeButton to local storage whenever it changes
    localStorage.setItem("activeButton", activeButton);
  }, [activeButton, setSlideType]);

  const handleButtonClick = (buttonId) => {
    setActiveButton(buttonId);
  };
  return (
    <Flex w="100%" h="100%" bgColor="rgb(244,244,244)" flexDirection="column">
      <HStack
        justifyContent="space-between"
        w="100%"
        alignItems="flex-start"
        pt="15px"
      >
        <Flex
          border="1px solid rgba(64, 123, 255, 1)"
          borderRadius="8px"
          p="5px"
          width="300px"
          justifyContent="space-between"
          ml="12px"
          bgColor="rgba(255, 255, 255, 1)"
        >
          <Button
            onClick={() => handleButtonClick(1)}
            borderColor={activeButton === 1 ? "blue" : "grey"}
            color={
              activeButton === 1 ? "rgba(27, 117, 188, 1)" : "rgba(0, 0, 0, 1)"
            }
            backgroundColor={
              activeButton === 1
                ? "rgba(198, 221, 238, 1)"
                : "rgba(255, 255, 255, 1)"
            }
            width="150px"
            fontSize="14px"
          >
            H&E Slides
          </Button>
          <Button
            onClick={() => handleButtonClick(2)}
            borderColor={activeButton === 2 ? "blue" : "grey"}
            color={
              activeButton === 2 ? "rgba(27, 117, 188, 1)" : "rgba(0, 0, 0, 1)"
            }
            backgroundColor={
              activeButton === 2
                ? "rgba(198, 221, 238, 1)"
                : "rgba(255, 255, 255, 1)"
            }
            marginLeft="2" // Adding margin between the buttons
            width="150px"
            fontSize="14px"
          >
            Trichrome Slides
          </Button>
        </Flex>
        <HStack pt="15px">
          <Box pr="15px">
            <Text fontSize="14px" fontWeight="bold">
              {`Total ${
                readerSlideInfo?.metadata?.totalSlideCount !== undefined
                  ? readerSlideInfo?.metadata?.totalSlideCount
                  : 0
              } Slides`}
            </Text>
          </Box>
          <Box width="300px">
            <ProgressBar
              percentage={
                readerSlideInfo?.metadata?.percentageCompleted !== undefined
                  ? readerSlideInfo?.metadata?.percentageCompleted
                  : 0
              }
            />
          </Box>
          <Box pl="15px">
            <Text fontSize="14px" fontWeight="bold">
              {`${
                readerSlideInfo?.metadata?.percentageCompleted !== undefined
                  ? (readerSlideInfo?.metadata?.percentageCompleted).toFixed(2)
                  : 0
              }% Completed`}
            </Text>
          </Box>
        </HStack>
        <Flex>
          <Flex>
            <Menu>
              <MenuButton
                as={Button}
                fontSize={ifWidthLessthan1920 ? "13px" : "0.52916vw"}
                fontWeight="500"
                border="1px solid #E8E8EB"
                borderRadius="5px"
                bgColor="rgba(255, 255, 255, 1)"
                mr="12px"
                w="150px"
                mt="3px"
                mb="3px"
              >
                <Box>
                  <Flex justifyContent="space-between">
                    <Flex>
                      <Text fontWeight="bold">Status: {selectedStatus} </Text>
                    </Flex>
                    <Flex ml="32px">
                      <AiOutlineCaretDown size="14px" />
                    </Flex>
                  </Flex>
                </Box>
              </MenuButton>
              <MenuList p="0" fontSize="14px">
                <MenuItem
                  default
                  onClick={() => {
                    setSelectedStatus("All");
                  }}
                >
                  All
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setSelectedStatus("New");
                  }}
                >
                  New
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setSelectedStatus("Saved");
                  }}
                >
                  Saved
                </MenuItem>
              </MenuList>
            </Menu>
          </Flex>
          <Flex>
            <Menu>
              <MenuButton
                as={Button}
                fontSize={ifWidthLessthan1920 ? "13px" : "0.52916vw"}
                fontWeight="500"
                border="1px solid #E8E8EB"
                borderRadius="5px"
                bgColor="rgba(255, 255, 255, 1)"
                mr="12px"
                w="175px"
                mt="3px"
                mb="3px"
              >
                <Box>
                  <Flex justifyContent="space-between">
                    <Flex mr="5px">
                      {layoutSelect === "Grid" ? (
                        <IoGrid size="14px" />
                      ) : (
                        <MdOutlineTableRows size="16px" bgColor="black" />
                      )}
                    </Flex>
                    <Flex>
                      <Text fontWeight="bold">{`Layout: ${layoutSelect}`}</Text>
                    </Flex>
                    <Flex ml="18px">
                      <AiOutlineCaretDown size="14px" />
                    </Flex>
                  </Flex>
                </Box>
              </MenuButton>
              <MenuList p="0" fontSize="14px">
                <MenuItem
                  default
                  onClick={() => {
                    setLayoutSelect("Grid");
                  }}
                >
                  Grid
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setLayoutSelect("Table");
                  }}
                >
                  Table
                </MenuItem>
              </MenuList>
            </Menu>
          </Flex>
        </Flex>
      </HStack>
      {layoutSelect === "Table" ? (
        <Flex
          maxH="63vh"
          overflowY="auto"
          justifyContent="center"
          w="100%"
          mt="20px"
          zIndex="0"
        >
          <SlideTableView
            slides={filteredSlides}
            userInfo={userInfo}
            caseId={caseId}
            permission={permission}
          />
        </Flex>
      ) : (
        <SlideGridView
          slides={filteredSlides}
          userInfo={userInfo}
          caseId={caseId}
        />
      )}
    </Flex>
  );
};

export default SlideTabScreen;

import React from "react";
import { Bar } from "react-chartjs-2";
import datalabels from "chartjs-plugin-datalabels";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Text, HStack, useMediaQuery } from "@chakra-ui/react";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  datalabels
);

const ReaderSlideChartScreen = ({ chartInfo, readerDetailsInfo }) => {
  // const uploadSlidedata = [
  //   chartInfo?.metadata?.[0]?.numberofslides -
  //     chartInfo?.data?.[0]?.slidesReported,
  //   chartInfo?.metadata?.[1]?.numberofslides -
  //     chartInfo?.data?.[1]?.slidesReported,
  // ];
  // const reportedData = [
  //   chartInfo?.data?.[0]?.slidesReported,
  //   chartInfo?.data?.[1]?.slidesReported,
  // ];
  // const labels = ["H &E Slides", "Trichrome Slides"];
  // const tobeRecruited = Array.from([0, 1]).fill(20);

  // const optionForReader = {
  //   indexAxis: "x",
  //   scales: {
  //     x: {
  //       // title: {
  //       //   display: true,
  //       //   text: "No. of Slides",
  //       //   font: {
  //       //     size: 12,
  //       //   },
  //       // },
  //       grid: {
  //         display: false,
  //       },
  //     },
  //     y: {
  //       title: {
  //         display: true,
  //         text: "No of cases  ",
  //         font: {
  //           size: 12,
  //         },
  //         beginAtZero: true,
  //       },
  //       grid: {
  //         display: false,
  //       },
  //       ticks: {
  //         font: {
  //           size: 12, // Adjust the size of the y-axis ticks
  //         },
  //       },
  //     },
  //   },
  //   responsive: true,
  //   maintainAspectRatio: false,
  //   plugins: {
  // tooltip: {
  //   displayColors: false,
  //   callbacks: {
  //     title: function (context) {
  //       return ``;
  //     },
  //     labelTextColor: () => {
  //       return "#000";
  //     },
  //     label: function (context) {
  //       let datasetLabel1 = context.dataset.label;
  //       const index = context.dataIndex;
  //       let datasetValue1 = dataForReader.datasets.find(
  //         (d) => d.label === datasetLabel1
  //       )?.data[index];
  //       let reportedCasesValue = dataForReader.datasets.find(
  //         (d) => d.label === "REPORTED CASES"
  //       )?.data[index];
  //       return datasetLabel1 === "UPLOADED CASES"
  //         ? (datasetLabel1 += `: ${(
  //             chartInfo?.metadata?.[index]?.numberofslides * 1
  //           ).toFixed(0)} `)
  //         : datasetLabel1 === "REPORTED CASES"
  //         ? (datasetLabel1 += `: ${(
  //             chartInfo?.data?.[index]?.slidesReported * 1
  //           ).toFixed(0)}`)
  //         : "";
  //     },
  //   },
  //   backgroundColor: ["#D9D9D9"],
  //   borderRadius: 0,
  // },
  //     legend: {
  //       position: "right",
  //       align: "start",
  //       labels: {
  //         usePointStyle: true,
  //         pointStyle: "rect",
  //         filter: function (legendItem, chartData) {
  //           return (
  //             legendItem.text !== "H & E" &&
  //             legendItem.text !== "Buffer" &&
  //             legendItem.text !== "UPLOADED CASES" &&
  //             legendItem.text !== "REPORTED CASES" &&
  //             legendItem.text !== "To be recruited"
  //           );
  //         },
  //       },
  //     },
  //     subtitle: {
  //       display: true,
  //       text: "",
  //     },
  //     datalabels: {
  //       color: "#333",
  //       font: {
  //         weight: "bold",
  //         size: 10,
  //       },
  //       formatter: function (value, context) {
  //         let datasetLabel1 = context.dataset.label;
  //         const index = context.dataIndex;
  //         let datasetValue1 = dataForReader.datasets.find(
  //           (d) => d.label === datasetLabel1
  //         )?.data[index];
  //         let reportedCasesValue = dataForReader.datasets.find(
  //           (d) => d.label === "REPORTED CASES"
  //         )?.data[index];
  //         return datasetLabel1 === "UPLOADED CASES"
  //           ? `${(
  //               (chartInfo?.metadata?.[index]?.numberofslides /
  //                 readerDetailsInfo?.particpantsTobeRecruited) *
  //               100
  //             ).toFixed(0)}% `
  //           : datasetLabel1 === "REPORTED CASES"
  //           ? `${(
  //               (chartInfo?.data?.[index]?.slidesReported /
  //                 readerDetailsInfo?.particpantsTobeRecruited) *
  //               100
  //             ).toFixed(0)}%`
  //           : "";
  //       },
  //       offset: 9,
  //       anchor: "end",
  //       align: "right",
  //     },
  //   },
  // };

  // const dataForReader = {
  //   labels: labels,
  //   datasets: [
  // {
  //   label: "To be recruited",
  //   type: "line",
  //   data: [
  //     readerDetailsInfo?.particpantsTobeRecruited,
  //     readerDetailsInfo?.particpantsTobeRecruited,
  //   ],
  //   backgroundColor: ["rgba(0,0,0,1)"],
  //   barThickness: 0.1,
  //   borderColor: "rgba(0,0,0,1)",
  //   borderDash: [5, 5],
  //   pointRadius: 0,
  //   borderWidth: 1,
  // },
  //     {
  //       label: "REPORTED CASES",
  //       data: reportedData ? reportedData : [0],
  //       backgroundColor: ["rgba(80, 170, 80, 0.69)"],
  //       barThickness: 20,
  //       stack: "background",
  //     },
  //     {
  //       label: "UPLOADED CASES",
  //       data: uploadSlidedata,
  //       backgroundColor: ["#DEDEDE"],
  //       barThickness: 20,
  //       stack: "background",
  //     },
  //   ],
  // };
  // chartInfo && chartInfo?.metadata[0]?.slide_type === "HAndE"
  //   ? [chartInfo?.metadata[0]?.numberofslides]
  //   : chartInfo?.metadata[1]?.slide_type === "Trichrome"
  //   ? [chartInfo?.metadata[1]?.numberofslides]
  //   : [0];
  const [ifWidthLessthan1920] = useMediaQuery("(max-width:1920px)");
  let labelNo = "";
  const options = {
    scaleShowLabels: false,
    indexAxis: "y",
    scales: {
      x: {
        title: {
          display: true,
          text: "No. of Slides",
          font: {
            size: 12,
          },
        },
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          display: false,
        },
      },
    },
    responsive: true,

    maintainAspectRatio: false,
    plugins: {
      // tooltip: {
      //   enabled: false,
      // },
      tooltip: {
        displayColors: false,
        callbacks: {
          title: function (context) {
            return ``;
          },
          labelTextColor: () => {
            return "#000";
          },
          label: function (context) {
            let datasetLabel = context.dataset.label;
            const index = context.dataIndex;
            const datasetValue = data.datasets.find(
              (d) => d.label === datasetLabel
            )?.data[index];
            if (
              datasetLabel !== "Pending Slide" &&
              datasetLabel !== "Trichrome Reported Slides" &&
              datasetLabel !== "no. of slides alotted"
            ) {
              datasetLabel = `${(
                (datasetValue / chartInfo?.metadata?.[index]?.numberofslides) *
                100
              ).toFixed()}%`;
              labelNo = "";
            } else {
              datasetLabel = "";
              labelNo = "notAlloted";
            }
            return datasetLabel;
            // return `${`${
            //   datasetLabel !== "Pending Slide" &&
            //   datasetLabel !== "Trichrome Reported Slides"
            //     ? `${(
            //         (datasetValue /
            //           chartInfo?.metadata?.[index]?.numberofslides) *
            //         100
            //       ).toFixed()}%`
            //     : (labelNo = "newValue")
            // } `}`;
          },
        },
        backgroundColor: function (tooltipItem) {
          return labelNo === "notAlloted"
            ? "rgba(0, 0, 0, 0)"
            : ["rgba(196, 230, 196, 1)"];
        },
        borderRadius: 0,
      },
      legend: {
        position: "right",
        align: "start",
        reverse: "true",
        labels: {
          usePointStyle: true,
          pointStyle: "rect",
          filter: function (legendItem, chartData) {
            return (
              legendItem.text !== "no. of slides alotted"
              // legendItem.text !== "% of slides reported "
            );
          },
        },
      },

      datalabels: {
        anchor: "end",
        align: "end",
        color: function (context) {
          let label = context.dataset.label || "";
          const index = context.dataIndex;
          return label === "no. of slides alotted"
            ? "rgba(242, 145, 17, 1)"
            : "#333";
        },
        font: {
          weight: "bold",
          size: 10,
        },
        backgroundColor: function (context) {
          let label = context.dataset.label || "";
          const index = context.dataIndex;
          return label === "no. of slides alotted"
            ? "rgba(242, 145, 17, 0.12)"
            : "rgba(0,0,0,0)";
        },
        borderRadius: function (context) {
          let label = context.dataset.label || "";
          const index = context.dataIndex;
          return label === "no. of slides alotted" ? 0 : "";
        },
        borderWidth: function (context) {
          let label = context.dataset.label || "";
          const index = context.dataIndex;
          return label === "no. of slides alotted" ? 2.3 : "";
        },
        formatter: function (value, context) {
          const datasetLabel = context.dataset.label;
          const index = context.dataIndex;
          const datasetValue = data.datasets.find(
            (d) => d.label === datasetLabel
          )?.data[index];
          return `${`${
            datasetLabel === "no. of slides alotted"
              ? `${chartInfo?.metadata[index]?.numberofslides}`
              : datasetLabel === "No. of slides alotted "
              ? ""
              : datasetLabel !== "Trichrome Reported Slides"
              ? `${datasetValue}`
              : ""
          } `}`;
        },
        offset: -16.0,
      },
    },
  };

  const labels = ["H &E Slides", "Trichrome Slides"];
  const data = {
    labels,
    datasets: [
      {
        label: "Trichrome Reported Slides",
        type: "line",
        data: [
          readerDetailsInfo?.particpantsTobeRecruited,
          readerDetailsInfo?.particpantsTobeRecruited,
        ],
        backgroundColor: ["rgba(67, 124, 175, 1)"],
        barThickness: 0.1,
        borderColor: "rgba(0,0,0,0)",
        // borderDash: [5, 5],
        pointRadius: 0,
        borderWidth: 1,
      },
      {
        label: "H&E Reported Slides",
        data: [
          chartInfo?.data[0]?.slide_type === "HAndE"
            ? Number(chartInfo?.data[0]?.slidesreported)
            : chartInfo?.data[1]?.slide_type === "HAndE"
            ? Number(chartInfo?.data[1]?.slidesreported)
            : 0,
          chartInfo?.data[0]?.slide_type === "Trichrome"
            ? Number(chartInfo?.data[0]?.slidesreported)
            : chartInfo?.data[1]?.slide_type === "Trichrome"
            ? Number(chartInfo?.data[1]?.slidesreported)
            : 0,
        ],
        backgroundColor: ["rgba(80, 170, 80, 0.69)", "rgba(67, 124, 175, 1)"],
        barThickness: 20,
        stack: "background",
      },
      {
        label: "Pending Slide",
        data: [
          parseInt(chartInfo?.metadata[0]?.numberofslides) -
            parseInt(chartInfo?.data[0]?.slidesreported) ===
          0
            ? ""
            : parseInt(chartInfo?.metadata[0]?.numberofslides) -
              parseInt(chartInfo?.data[0]?.slidesreported),
          parseInt(chartInfo?.metadata[1]?.numberofslides) -
            parseInt(chartInfo?.data[1]?.slidesreported) ===
          0
            ? ""
            : parseInt(chartInfo?.metadata[1]?.numberofslides) -
              parseInt(chartInfo?.data[1]?.slidesreported),
        ],
        backgroundColor: ["rgba(232, 232, 235, 1)"],
        barThickness: 20,
        stack: "background",
      },
      {
        label: "no. of slides alotted",
        data: [1, 1],
        backgroundColor: ["rgba(0, 0, 0, 0)"],
        barThickness: 20,
        stack: "background",
      },
      {
        label: "No. of slides alotted ",
        type: "line",
        data: [
          parseInt(chartInfo?.metadata[0]?.numberofslides) + 6,
          parseInt(chartInfo?.metadata[1]?.numberofslides) + 6,
        ],
        backgroundColor: ["rgba(242, 145, 17, 1)"],
        barThickness: 0.1,
        borderColor: "rgba(0,0,0,0)",
        borderDash: [0, 0],
        pointRadius: 0,
        borderWidth: 1,
      },
    ],
  };

  // const optionForHandE = {
  //   indexAxis: "x",
  //   scales: {
  //     x: {
  //       // title: {
  //       //   display: true,
  //       //   text: "No. of Slides",
  //       //   font: {
  //       //     size: 12,
  //       //   },
  //       // },
  //       grid: {
  //         display: false,
  //       },
  //     },
  //     y: {
  //       title: {
  //         display: true,
  //         text: "No of cases  ",
  //         font: {
  //           size: 12,
  //         },
  //         beginAtZero: true,
  //       },
  //       grid: {
  //         display: false,
  //       },
  //       ticks: {
  //         font: {
  //           size: 12, // Adjust the size of the y-axis ticks
  //         },
  //       },
  //     },
  //   },
  //   responsive: true,
  //   maintainAspectRatio: false,
  //   plugins: {
  //     tooltip: {
  //       displayColors: false,
  //       callbacks: {
  //         title: function (context) {
  //           return ``;
  //         },
  //         labelTextColor: () => {
  //           return "#000";
  //         },
  //         label: function (context) {
  //           let label = context.dataset.label || "";
  //           if (label === "UPLOADED CASES") {
  //             label += `: ${studyProgress?.metadata?.[0]?.NumberOfSlides}`;
  //           } else {
  //             label += `: ${context.parsed.y}`;
  //           }
  //           return label;
  //         },
  //       },
  //       backgroundColor: ["#D9D9D9"],
  //       borderRadius: 0,
  //     },
  //     legend: {
  //       position: "right",
  //       align: "start",
  //       labels: {
  //         usePointStyle: true,
  //         pointStyle: "rect",
  //         filter: function (legendItem, chartData) {
  //           return (
  //             legendItem.text !== "H & E" &&
  //             legendItem.text !== "Buffer" &&
  //             legendItem.text !== "UPLOADED CASES" &&
  //             legendItem.text !== "REPORTED CASES" &&
  //             legendItem.text !== "To be recruited"
  //           );
  //         },
  //       },
  //     },
  //     title: {
  //       display: true,
  //       text: `H & E Slides - ${
  //         studyProgress?.metadata?.[0]?.NumberOfSlides
  //           ? studyProgress?.metadata?.[0]?.NumberOfSlides
  //           : ""
  //       } Slides`,
  //       align: "center",
  //       color: "rgba(80, 170, 80, 0.69)",
  //       padding: {
  //         bottom: 15,
  //       },
  //       font: {
  //         weight: "bold",
  //         family: "Inter",
  //         size: 16,
  //       },
  //     },
  //     subtitle: {
  //       display: true,
  //       text: "TO BE RECRUITED",
  //     },
  //     datalabels: {
  //       color: "#333",
  //       font: {
  //         weight: "bold",
  //         size: 10,
  //       },
  //       formatter: function (value, context) {
  //         const datasetLabel = context.dataset.label;
  //         const index = context.dataIndex;
  //         const datasetValue = dataForHandE.datasets.find(
  //           (d) => d.label === datasetLabel
  //         )?.data[index];
  //         return datasetLabel === "UPLOADED CASES"
  //           ? `${(
  //               (studyProgress?.metadata?.[0]?.NumberOfSlides /
  //                 particpantsTobeRecruited) *
  //               100
  //             ).toFixed(0)}% `
  //           : datasetLabel === "REPORTED CASES"
  //           ? `${((datasetValue / particpantsTobeRecruited) * 100).toFixed(0)}%`
  //           : "";
  //       },
  //       offset: 9,
  //       anchor: "end",
  //       align: "right",
  //     },
  //   },
  // };
  // const dataForHandE = {
  //   labels: HAndELabels,
  //   datasets: [
  // {
  //   label: "To be recruited",
  //   type: "line",
  //   data: tobeRecruited,
  //   backgroundColor: ["rgba(0,0,0,1)"],
  //   barThickness: 0.1,
  //   borderColor: "rgba(0,0,0,1)",
  //   borderDash: [5, 5],
  //   pointRadius: 0,
  //   borderWidth: 1,
  // },
  //     {
  //       label: "REPORTED CASES",
  //       data: chartInfo?.data[1]?.slidesReported,
  //       backgroundColor: ["rgba(80, 170, 80, 0.69)"],
  //       barThickness: 20,
  //       stack: "background",
  //     },

  //     {
  //       label: "UPLOADED CASES",
  //       data: HAndEUploadedSlides,
  //       backgroundColor: ["#DEDEDE"],
  //       barThickness: 20,
  //       stack: "background",
  //     },
  //   ],
  // };

  return (
    <div
      style={{
        height: "85%", // Adjust the height as needed
        width: "100%",
        position: "relative",
        marginBottom: "1%",
        paddingBottom: "10px",
        background: "#fff",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <HStack
        w="100%"
        justifyContent="space-between"
        ml="10px"
        mb="5px"
        pt="5px"
      >
        <Text
          color="#1B75BC"
          fontSize={ifWidthLessthan1920 ? "15px" : "18px"}
          fontWeight="bold"
        >
          Study Progress
        </Text>
      </HStack>
      <Text fontFamily="inter" fontSize="13px" fontWeight="600" ml="10px">
        Slides created and Reported per phase by you.
      </Text>
      <Bar data={data} options={options} />
    </div>
  );
};

export default ReaderSlideChartScreen;

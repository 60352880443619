import React, { useState, useEffect } from "react";
import {
  Flex,
  HStack,
  VStack,
  Box,
  Text,
  useMediaQuery,
  Button,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Avatar,
} from "@chakra-ui/react";
import { BiLinkExternal } from "react-icons/bi";
import { Link, useNavigate } from "react-router-dom";
import { getSlideUrl } from "../../../../../hooks/utility";
const ScrollBar = ({ children }) => {
  return (
    <Flex
      w="100%"
      h="100%"
      flexDir="column"
      overflowY="auto"
      overflowX="hidden"
      css={{
        "&::-webkit-scrollbar": {
          width: "4px",
        },
        "&::-webkit-scrollbar-track": {
          width: "6px",
        },
        "&::-webkit-scrollbar-thumb": {
          background: "#C4C4C4",
        },
      }}
    >
      {children}
    </Flex>
  );
};
const SlideComponent = ({
  readerSlideInfo,
  userInfo,
  setSlideType,
  setTabIndex,
  caseId,
}) => {
  console.log(readerSlideInfo);
  const [activeButton, setActiveButton] = useState(1);
  const [selectedStatus, setSelectedStatus] = useState("All");
  const navigate = useNavigate();
  const [ifWidthLessthan1920] = useMediaQuery("(max-width:1920px)");
  const handleButtonClick = (buttonId) => {
    setActiveButton(buttonId);
    setSlideType(buttonId === 1 ? "HAndE" : "Trichrome");
  };
  useEffect(() => {
    setSlideType("HAndE");
  }, []);

  return (
    <Flex w="100%" pl="10px" h="100%">
      <VStack w="100%" pt="8px" h="100%">
        <HStack w="100%" justifyContent="space-between">
          <Text
            color="#1B75BC"
            fontSize={ifWidthLessthan1920 ? "15px" : "18px"}
            fontWeight="bold"
          >
            Slides
          </Text>
          <Button
            bgColor="inherit"
            _hover={{ color: "#1B75BC", cursor: "pointer" }}
            _active={{ color: "#1B75BC", cursor: "pointer" }}
          >
            <BiLinkExternal
              size={18}
              style={{ color: "#1B75BC", cursor: "pointer" }}
              onClick={() => setTabIndex(1)}
            />
          </Button>
        </HStack>
        <HStack
          justifyContent="space-between"
          w="100%"
          alignItems="flex-start"
          pt="15px"
        >
          <Flex
            border="1px solid rgba(64, 123, 255, 1)"
            borderRadius="8px"
            p="5px"
            width="100%"
            mr="18px"
            justifyContent="space-between"
            bgColor="rgba(255, 255, 255, 1)"
          >
            <Button
              onClick={() => handleButtonClick(1)}
              borderColor={activeButton === 1 ? "blue" : "grey"}
              color={
                activeButton === 1
                  ? "rgba(27, 117, 188, 1)"
                  : "rgba(0, 0, 0, 1)"
              }
              backgroundColor={
                activeButton === 1
                  ? "rgba(198, 221, 238, 1)"
                  : "rgba(255, 255, 255, 1)"
              }
              width="50%"
              fontSize="14px"
            >
              H&E Slides
            </Button>
            <Button
              onClick={() => handleButtonClick(2)}
              borderColor={activeButton === 2 ? "blue" : "grey"}
              color={
                activeButton === 2
                  ? "rgba(27, 117, 188, 1)"
                  : "rgba(0, 0, 0, 1)"
              }
              backgroundColor={
                activeButton === 2
                  ? "rgba(198, 221, 238, 1)"
                  : "rgba(255, 255, 255, 1)"
              }
              marginLeft="2" // Adding margin between the buttons
              width="50%"
              fontSize="14px"
            >
              Trichrome Slides
            </Button>
          </Flex>
        </HStack>
        <HStack justifyContent="space-between" w="100%" alignItems="flex-start">
          <Flex
            border="1px solid rgba(64, 123, 255, 1)"
            borderRadius="8px"
            py="12px"
            px="15px"
            width="100%"
            mr="18px"
            alignItems="center"
            justifyContent="space-around"
            bgColor="rgba(255, 255, 255, 1)"
          >
            <Text fontSize="15px" fontWeight="bold">
              Images
            </Text>
            <Text fontSize="15px" fontWeight="bold">
              Accession Id
            </Text>
            <Text fontSize="15px" fontWeight="bold">
              Status
            </Text>
          </Flex>
        </HStack>
        <ScrollBar>
          {readerSlideInfo?.data?.map((item, index) => (
            <HStack
              key={index} // Adding a unique key for each item is recommended
              w="100%"
              alignItems="flex-start"
              mb="8px"
              onClick={() => {
                navigate("/viewer", {
                  state: {
                    caseId: caseId,
                    slide: item,
                    userInfo,
                  },
                });
              }}
            >
              <Flex
                border="1px solid rgba(64, 123, 255, 1)"
                borderRadius="8px"
                py="12px"
                px="15px"
                width="100%"
                mr="18px"
                alignItems="center"
                justifyContent="space-around"
                bgColor="rgba(255, 255, 255, 1)"
              >
                <Avatar
                  size="sm"
                  name={item.accessionId}
                  src={getSlideUrl(item?.awsImageBucketUrl)}
                  color="#000"
                  bg="rgba(27, 117, 188, 0.25)"
                />
                <Text fontSize="14px" fontWeight="bold">
                  {item.accessionId}
                </Text>
                <Text fontSize="15px" fontWeight="bold">
                  {item?.status ? (
                    <Text
                      color="rgba(50, 89, 38, 1)"
                      fontSize="14px"
                      fontWeight="bold"
                    >
                      SAVED
                    </Text>
                  ) : (
                    <Text
                      color="rgba(27, 117, 188, 1)"
                      fontSize="14px"
                      fontWeight="bold"
                    >
                      NEW
                    </Text>
                  )}
                </Text>
              </Flex>
            </HStack>
          ))}
        </ScrollBar>
      </VStack>
    </Flex>
  );
};

export default SlideComponent;

/* eslint-disable react/no-children-prop */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  useMediaQuery,
  Image,
  Button,
  Flex,
  useDisclosure,
  VStack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  useToast,
  Box,
} from "@chakra-ui/react";
import moment from "moment";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { usePagination, useSortBy, useTable, useFilters } from "react-table";
import { v4 as uuidv4 } from "uuid";
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";
import { BiFilterAlt } from "react-icons/bi";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import Environment from "../../environment";
import SearchFilterMenu from "./Filter/SearchFilterMenu";
import DateFilterMenu from "./Filter/DateFilterMenu";
import FilterMenu from "./Filter/FilterMenu";
import { Context } from "../../state/Context";

const FilterButton = ({
  handleFilterIconClick,
  showFilterMenu,
  children,
  column,
  setHideScrollBar,
  selected,
}) => {
  const filterButtonRef = useRef();
  const handleFilterButtonClick = () => {
    handleFilterIconClick(column.Header?.toLowerCase(), "");
    setHideScrollBar(true);
  };

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <div style={{ position: "relative", marginLeft: "auto" }}>
        <button
          type="button"
          ref={filterButtonRef}
          onClick={(e) => {
            e.stopPropagation();
            handleFilterButtonClick();
          }}
          style={{
            maximumWidth: "10px",
            height: "20px",
            paddingLeft: "3px",
            width: "8px",
          }}
        >
          <BiFilterAlt size="16" color={selected ? "#1B75BC" : "gray"} />
        </button>
        {showFilterMenu && (
          <div
            style={{
              position: "absolute",
              zIndex: 1000,
              right: "-35px",
            }}
          >
            {children}
          </div>
        )}
      </div>
    </div>
  );
};

const Table = ({
  columns,
  data,
  isFilterChecked,
  setFilterChecked,
  filterOptions,
  setFilterOptions,
  selectedRole,
  setSelectedRole,
  selectedInstitute,
  setSelectedInstitute,
  showRoleFilterMenu,
  setShowRoleFilterMenu,
  showInstituteFilterMenu,
  setShowInstituteFilterMenu,
  showEmailAddressFilterMenu,
  setShowEmailAddressFilterMenu,
  showContactFilterMenu,
  setShowContactFilterMenu,
  showNameFilterMenu,
  setShowNameFilterMenu,
  searchTerm,
  setSearchTerm,
  showDateFilterMenu,
  setShowDateFilterMenu,
  selectedDateFilter,
  setSelectedDateFilter,
  searchDate,
  setSearchDate,
  searchEmailAddress,
  setSearchEmailAddress,
  searchContact,
  setSearchContact,
  searchName,
  setSearchName,
  filterData,
}) => {
  const navigate = useNavigate();
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
    setFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },

    useFilters,
    useSortBy,
    usePagination
  );

  const [hideScrollbar, setHideScrollBar] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState([]);

  const handleFilterClick = (filter) => {
    setSelectedFilter([...selectedFilter, filter]);
  };

  const handleClearFilter = (filter) => {
    setSelectedFilter(selectedFilter.filter((f) => f !== filter));
  };

  const handleDateFilterChange = (value) => {
    setSelectedDateFilter(value);
  };

  const handleSearchChange = (event) => {
    const { value } = event.target;
    setSearchTerm(value);
  };

  const handleFilterChange = (column, event) => {
    const { name, checked } = event.target;

    if (name === "All") {
      setFilterOptions((prevOptions) => ({
        ...prevOptions,
        [column]: checked ? ["All"] : [],
      }));
    } else {
      setFilterOptions((prevOptions) => ({
        ...prevOptions,
        [column]: checked
          ? prevOptions[column].includes("All")
            ? [name]
            : [...prevOptions[column], name]
          : prevOptions[column].filter((option) => option !== name),
      }));
    }
  };
  const handleFilterIconClick = (column) => {
    if (column === "Role") {
      setSelectedRole("");
      setShowRoleFilterMenu(!showRoleFilterMenu);
      setShowInstituteFilterMenu(false);
      setShowDateFilterMenu(false);
      setShowEmailAddressFilterMenu(false);
      setShowInstituteFilterMenu(false);
      setShowContactFilterMenu(false);
      setShowNameFilterMenu(false);
    }

    if (column === "Institute") {
      setSelectedInstitute("");
      setShowInstituteFilterMenu(!showInstituteFilterMenu);
      setShowRoleFilterMenu(false);
      setShowDateFilterMenu(false); // Add this line
      setShowEmailAddressFilterMenu(false);
      setShowContactFilterMenu(false);
      setShowNameFilterMenu(false);
    }
    if (column === "Enroll Date") {
      setShowDateFilterMenu(!showDateFilterMenu);
      setShowRoleFilterMenu(false);
      setShowInstituteFilterMenu(false);
      setShowEmailAddressFilterMenu(false);
      setShowInstituteFilterMenu(false);
      setShowContactFilterMenu(false);
      setShowNameFilterMenu(false);
    }
    if (column === "Name") {
      setShowDateFilterMenu(false);
      setShowRoleFilterMenu(false);
      setShowInstituteFilterMenu(false);
      setShowEmailAddressFilterMenu(false);
      setShowInstituteFilterMenu(false);
      setShowContactFilterMenu(false);
      setShowNameFilterMenu(!showNameFilterMenu);
    }
    if (column === "Contact") {
      setShowDateFilterMenu(false);
      setShowRoleFilterMenu(false);
      setShowInstituteFilterMenu(false);
      setShowEmailAddressFilterMenu(false);
      setShowInstituteFilterMenu(false);
      setShowContactFilterMenu(!showContactFilterMenu);
      setShowNameFilterMenu(false);
    }
    if (column === "Email") {
      setShowDateFilterMenu(false);
      setShowRoleFilterMenu(false);
      setShowInstituteFilterMenu(false);
      setShowEmailAddressFilterMenu(!showEmailAddressFilterMenu);
      setShowInstituteFilterMenu(false);
      setShowContactFilterMenu(false);
      setShowNameFilterMenu(false);
    }
  };

  const uniqueRoleNames = Array.from(
    new Set(filterData.map((row) => row.role?.toLowerCase()))
  );

  const uniqueInstituteNames = Array.from(
    new Set(filterData.map((row) => row.institute?.toLowerCase()))
  );

  const [isLargerThanSm] = useMediaQuery("(min-width: 1280px)");
  const [isLargerThanMd] = useMediaQuery("(min-width: 1290px)");
  const [isLargerThanLg] = useMediaQuery("(min-width: 1880px)");
  const [isLargerThanXl] = useMediaQuery("(min-height: 1980px)");
  // default value
  const getComponentSize = () => {
    let size = "340px";
    switch (true) {
      case isLargerThanXl:
        size = "65vh";
        break;
      case isLargerThanLg:
        size = "64vh";
        break;
      case isLargerThanMd:
        size = "60vh";
        break;
      case isLargerThanSm:
        size = "340px";
        break;
      default:
        size = "340px";
        break;
    }
    return size;
  };

  const getPaginationSize = () => {
    let size = "125px";
    switch (true) {
      case isLargerThanXl:
        size = "500px";
        break;
      case isLargerThanLg:
        size = "480px";
        break;
      case isLargerThanMd:
        size = "186px";
        break;
      case isLargerThanSm:
        size = "125px";
        break;
      default:
        size = "125px";
        break;
    }
    return size;
  };

  const getPaginationSingleElementHandleSize = () => {
    let size = "184px";
    switch (true) {
      case isLargerThanXl:
        size = "500px";
        break;
      case isLargerThanLg:
        size = "455px";
        break;
      case isLargerThanMd:
        size = "246px";
        break;
      case isLargerThanSm:
        size = "184px";
        break;
      default:
        size = "184px";
        break;
    }
    return size;
  };
  const componentSize = getComponentSize();
  const componentPaginationSize = getPaginationSize();
  const componentSibgleElementPaginationSize =
    getPaginationSingleElementHandleSize();

  // Render the UI for your table
  return (
    <Flex
      direction="column"
      w="100%"
      maxHeight="340px"
      minHeight={componentSize}
      overflow={hideScrollbar ? "hidden" : ""}
    >
      <div
        style={{
          position: "relative",
          maxHeight: { componentSize },
          flex: "1",
        }}
      >
        <table
          {...getTableProps()}
          style={{
            width: "100%",
            borderCollapse: "collapse",
          }}
        >
          <thead
            style={{
              backgroundColor: "#f6f6f6",
              position: "sticky", // Make the table header sticky
              top: "0", // Stick the table header at the top
              zIndex: "20",
            }}
          >
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={uuidv4()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    key={uuidv4()}
                    onClick={(e) => e.stopPropagation()}
                    style={{
                      padding: "10px 30px 10px 0px",
                      textAlign: "left",
                      borderBottom: "1px solid #E8E8E8",
                      fontWeight: "bold",
                      fontSize: "13px",
                    }}
                  >
                    <Flex
                      justifyContent="flex-start"
                      alignItems="center"
                      paddingLeft="10px"
                    >
                      {column.id !== "SrNo" ? (
                        column.isSorted ? (
                          column.isSortedDesc ? (
                            <Box paddingRight="8px">
                              <AiFillCaretDown
                                size="14"
                                onClick={() =>
                                  column.toggleSortBy(!column.isSorted)
                                }
                                color="gray"
                              />
                            </Box>
                          ) : (
                            <Box paddingRight="8px">
                              <AiFillCaretUp
                                size="14"
                                onClick={() => {
                                  column.toggleSortBy(!column.isSortedDesc);
                                }}
                                color="gray"
                              />
                            </Box>
                          )
                        ) : (
                          <VStack
                            paddingRight="8px"
                            spacing={-1}
                            onClick={() => column.toggleSortBy(column.isSorted)}
                          >
                            <AiFillCaretUp size="11.8" color="gray" />
                            <AiFillCaretDown size="11.8" color="gray" />
                          </VStack>
                        )
                      ) : null}
                      <Flex alignItems="center">
                        {column.render("Header")}
                        {column.Header === "Role" && (
                          <FilterButton
                            handleFilterIconClick={() =>
                              handleFilterIconClick("Role")
                            }
                            showFilterMenu={showRoleFilterMenu}
                            column={column}
                            setPageSize={setPageSize}
                            data={data}
                            setFilterChecked={setFilterChecked}
                            hideScrollbar={hideScrollbar}
                            setHideScrollBar={setHideScrollBar}
                            selected={selectedFilter.includes("Role")}
                          >
                            <FilterMenu
                              uniqueNames={uniqueRoleNames}
                              filterOptions={filterOptions.Role}
                              selectedValue={selectedRole}
                              searchTerm={searchTerm}
                              handleSearchChange={handleSearchChange}
                              hideMenu={() => setShowRoleFilterMenu(false)}
                              setPageSize={setPageSize}
                              data={data}
                              setFilterChecked={setFilterChecked}
                              isFilterChecked={isFilterChecked}
                              hideScrollbar={hideScrollbar}
                              setHideScrollBar={setHideScrollBar}
                              handleFilterClick={handleFilterClick}
                              organ="Role"
                              handleFilterChange={handleFilterChange}
                              handleClearFilter={handleClearFilter}
                            />
                          </FilterButton>
                        )}

                        {column.Header === "Institute" && (
                          <FilterButton
                            handleFilterIconClick={() =>
                              handleFilterIconClick("Institute")
                            }
                            showFilterMenu={showInstituteFilterMenu}
                            column={column}
                            setPageSize={setPageSize}
                            data={data}
                            setFilterChecked={setFilterChecked}
                            isFilterChecked={isFilterChecked}
                            hideScrollbar={hideScrollbar}
                            setHideScrollBar={setHideScrollBar}
                            selected={selectedFilter.includes("Institute")}
                          >
                            <FilterMenu
                              uniqueNames={uniqueInstituteNames}
                              filterOptions={filterOptions.Institute}
                              selectedValue={selectedInstitute}
                              searchTerm={searchTerm}
                              handleSearchChange={handleSearchChange}
                              hideMenu={() => setShowInstituteFilterMenu(false)}
                              setPageSize={setPageSize}
                              data={data}
                              setFilterChecked={setFilterChecked}
                              hideScrollbar={hideScrollbar}
                              setHideScrollBar={setHideScrollBar}
                              handleFilterClick={handleFilterClick}
                              organ="Institute"
                              handleFilterChange={handleFilterChange}
                              handleClearFilter={handleClearFilter}
                            />
                          </FilterButton>
                        )}
                        {column.Header === "Enroll Date" && (
                          <FilterButton
                            handleFilterIconClick={() =>
                              handleFilterIconClick("Enroll Date")
                            }
                            showFilterMenu={showDateFilterMenu}
                            column={column}
                            setPageSize={setPageSize}
                            data={data}
                            setFilterChecked={setFilterChecked}
                            isFilterChecked={isFilterChecked}
                            hideScrollbar={hideScrollbar}
                            setHideScrollBar={setHideScrollBar}
                            selected={selectedFilter.includes("Enroll Date")}
                          >
                            <DateFilterMenu
                              dateFilterOptions={[
                                { label: "1 day", value: 1 },
                                { label: "3 days", value: 3 },
                                { label: "1 week", value: 7 },
                                { label: "2 weeks", value: 14 },
                                { label: "1 month", value: 30 },
                                { label: "2 months", value: 60 },
                                { label: "6 months", value: 180 },
                              ]}
                              // dateFilterOptions={dateFilterOptiondata}
                              selectedDateFilter={selectedDateFilter}
                              hideMenu={() => setShowDateFilterMenu(false)}
                              // setSearchDate={handleDateSearchChange}
                              setSearchDate={setSearchDate}
                              // setSearchDate={setSearchDate}
                              searchDate={searchDate}
                              setFilterChecked={setFilterChecked}
                              setPageSize={setPageSize}
                              data={data}
                              hideScrollbar={hideScrollbar}
                              setHideScrollBar={setHideScrollBar}
                              handleFilterClick={handleFilterClick}
                              organ="Enroll Date"
                              handleDateFilterChange={handleDateFilterChange}
                              handleClearFilter={handleClearFilter}
                            />
                          </FilterButton>
                        )}
                        {column.Header === "Name" && (
                          <FilterButton
                            handleFilterIconClick={() =>
                              handleFilterIconClick("Name")
                            }
                            showFilterMenu={showNameFilterMenu}
                            column={column}
                            setPageSize={setPageSize}
                            data={data}
                            setFilterChecked={setFilterChecked}
                            isFilterChecked={isFilterChecked}
                            hideScrollbar={hideScrollbar}
                            setHideScrollBar={setHideScrollBar}
                            selected={selectedFilter.includes("Name")}
                          >
                            <SearchFilterMenu
                              searchValue={searchName}
                              setSearchValue={setSearchName}
                              hideMenu={() => setShowNameFilterMenu(false)}
                              setFilterChecked={setFilterChecked}
                              setPageSize={setPageSize}
                              data={data}
                              hideScrollbar={hideScrollbar}
                              setHideScrollBar={setHideScrollBar}
                              handleFilterClick={handleFilterClick}
                              organ="Name"
                              handleClearFilter={handleClearFilter}
                            />
                          </FilterButton>
                        )}
                        {column.Header === "Email" && (
                          <FilterButton
                            handleFilterIconClick={() =>
                              handleFilterIconClick("Email")
                            }
                            showFilterMenu={showEmailAddressFilterMenu}
                            column={column}
                            setPageSize={setPageSize}
                            data={data}
                            setFilterChecked={setFilterChecked}
                            isFilterChecked={isFilterChecked}
                            hideScrollbar={hideScrollbar}
                            setHideScrollBar={setHideScrollBar}
                            selected={selectedFilter.includes("Email")}
                          >
                            <SearchFilterMenu
                              searchValue={searchEmailAddress}
                              setSearchValue={setSearchEmailAddress}
                              hideMenu={() =>
                                setShowEmailAddressFilterMenu(false)
                              }
                              setFilterChecked={setFilterChecked}
                              setPageSize={setPageSize}
                              data={data}
                              hideScrollbar={hideScrollbar}
                              setHideScrollBar={setHideScrollBar}
                              handleFilterClick={handleFilterClick}
                              organ="Email"
                              handleClearFilter={handleClearFilter}
                            />
                          </FilterButton>
                        )}

                        {column.Header === "Contact" && (
                          <FilterButton
                            handleFilterIconClick={() =>
                              handleFilterIconClick("Contact")
                            }
                            showFilterMenu={showContactFilterMenu}
                            column={column}
                            setPageSize={setPageSize}
                            data={data}
                            setFilterChecked={setFilterChecked}
                            isFilterChecked={isFilterChecked}
                            hideScrollbar={hideScrollbar}
                            setHideScrollBar={setHideScrollBar}
                            selected={selectedFilter.includes("Contact")}
                          >
                            <SearchFilterMenu
                              searchValue={searchContact}
                              setSearchValue={setSearchContact}
                              hideMenu={() => setShowContactFilterMenu(false)}
                              setFilterChecked={setFilterChecked}
                              setPageSize={setPageSize}
                              data={data}
                              hideScrollbar={hideScrollbar}
                              setHideScrollBar={setHideScrollBar}
                              handleFilterClick={handleFilterClick}
                              organ="Contact"
                              handleClearFilter={handleClearFilter}
                            />
                          </FilterButton>
                        )}
                      </Flex>
                    </Flex>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          {page.length > 0 ? (
            <tbody {...getTableBodyProps()}>
              {page.map((row, i) => {
                prepareRow(row);
                return (
                  <tr
                    {...row.getRowProps()}
                    key={uuidv4()}
                    style={{
                      borderBottom: "1px solid #E8E8E8",
                      cursor: "pointer",
                      height: "60px",
                    }}
                  >
                    {row.cells.map((cell) => {
                      return (
                        <td
                          {...cell.getCellProps()}
                          key={uuidv4()}
                          style={{
                            padding: "10px 6px",
                            textAlign: "left",
                            fontSize: "14px",
                            paddingLeft: "14px",
                          }}
                        >
                          {cell.column.Header === "Institute" ? (
                            <Flex
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <span style={{ marginRight: "8px" }}>
                                {cell.value}
                              </span>
                            </Flex>
                          ) : cell.column.Header === "Sr No" ? (
                            <Flex>
                              <span>{i + 1}</span>
                            </Flex>
                          ) : (
                            cell.render("Cell")
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          ) : (
            <tbody>
              <tr
                style={{
                  height: "100%",
                }}
              >
                <td
                  colSpan={headerGroups[0].headers.length}
                  style={{
                    textAlign: "center",
                    padding: "20px",
                    fontSize: "16px",
                    fontWeight: "bold",
                    height: "240px",
                  }}
                >
                  No data found
                </td>
              </tr>
            </tbody>
          )}
        </table>
        <div
          className="Pagination"
          style={{
            marginTop: `${
              page.length === 10
                ? "0px"
                : page.length === 1
                ? componentSibgleElementPaginationSize
                : componentPaginationSize
            }`,
            position: "sticky",
            top: "0",
            bottom: "0", // Stick the pagination bar to the bottom
            zIndex: "1", // Make sure the pagination is above the table content
            backgroundColor: "white",
            padding: "5px",
            boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.2)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "14px",
            maxHeight: "300px", // Adjust the maximum height as needed
          }}
        >
          <Button
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
            type="button"
            title="Go to first page"
            style={{
              backgroundColor: "#E8E8E8",
              color: "#333",
              border: "none",
              padding: "5px 10px",
              margin: "0 2px",
              cursor: "pointer",
            }}
          >
            <FaChevronLeft />
          </Button>

          <span>
            Page{" "}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>
          </span>
          <Button
            onClick={() => nextPage()}
            disabled={!canNextPage}
            type="button"
            title="Go to next page"
            style={{
              backgroundColor: "#E8E8E8",
              color: "#333",
              border: "none",
              padding: "5px 10px",
              margin: "0 5px",
              cursor: "pointer",
            }}
          >
            <FaChevronRight />
          </Button>
          <span>
            | Page size:{" "}
            <select
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
              }}
              style={{ padding: "3px" }}
            >
              {[10, 20, 30, 40, 50].map((size) => (
                <option key={size} value={size}>
                  {size}
                </option>
              ))}
            </select>
          </span>
        </div>
      </div>
    </Flex>
  );
};

const MemberTable = ({ filterData }) => {
  const [isFilterChecked, setFilterChecked] = useState(false);
  const [filterOptions, setFilterOptions] = useState({
    Role: ["All"],
    Institute: ["All"],
  });
  const [selectedRole, setSelectedRole] = useState("");
  const [selectedInstitute, setSelectedInstitute] = useState("");
  const [showRoleFilterMenu, setShowRoleFilterMenu] = useState(false);

  const [showInstituteFilterMenu, setShowInstituteFilterMenu] = useState(false);
  const [showEmailAddressFilterMenu, setShowEmailAddressFilterMenu] =
    useState(false);
  const [showContactFilterMenu, setShowContactFilterMenu] = useState(false);
  const [showNameFilterMenu, setShowNameFilterMenu] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [showDateFilterMenu, setShowDateFilterMenu] = useState(false); // Add this line
  const [selectedDateFilter, setSelectedDateFilter] = useState(null);
  const [searchDate, setSearchDate] = useState("");
  const [searchEmailAddress, setSearchEmailAddress] = useState("");
  const [searchInstitute, setSearchInstitute] = useState("");
  const [searchContact, setSearchContact] = useState("");
  const [searchName, setSearchName] = useState("");
  const [data, setData] = useState([]);

  const columns = React.useMemo(() => [
    {
      Header: "Sr No",
      accessor: "SrNo",
    },
    {
      Header: "Name", // Name
      accessor: "firstName",
      Filter: FilterButton,
    },

    {
      Header: "Role", // Role
      accessor: "role",
      Filter: FilterButton,
    },

    {
      Header: "Enroll Date",
      accessor: "date",
      Filter: FilterButton,
    },
    {
      Header: "Email", //patinent name
      accessor: "emailAddress",
      Filter: FilterButton,
    },
    {
      Header: "Contact", //patinent phoneNumber
      accessor: "phoneNumber",
      Filter: FilterButton,
    },
    {
      Header: "Institute",
      accessor: "institute",
      Filter: FilterButton,
    },
  ]);
  const applyDateFilter = (rowDate) => {
    if (!selectedDateFilter && !searchDate) return true;
    localStorage.setItem("dateSelect", selectedDateFilter);
    const parseDate = (dateString) => {
      const dateParts = dateString.split("/");
      return new Date(`${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`);
    };

    const currentDate = new Date();
    const rowDateObj = parseDate(rowDate);

    if (selectedDateFilter && searchDate) {
      const startDate = new Date(
        parseDate(searchDate) - selectedDateFilter.value * 24 * 60 * 60 * 1000
      );
      return rowDateObj >= startDate && rowDateObj <= parseDate(searchDate);
    }

    if (selectedDateFilter) {
      const startDate = new Date(
        currentDate - selectedDateFilter.value * 24 * 60 * 60 * 1000
      );
      return rowDateObj >= startDate && rowDateObj <= currentDate;
    }

    if (searchDate) {
      const formattedSearchDate = parseDate(searchDate);
      return rowDate === searchDate;
    }

    return true;
  };
  useEffect(() => {
    setData(
      filterData !== undefined
        ? filterData
            .filter((row) =>
              filterOptions.Role.includes("All")
                ? true
                : filterOptions.Role.includes(row?.role?.toLowerCase())
            )
            .filter((row) =>
              filterOptions.Institute.includes("All")
                ? true
                : filterOptions.Institute.includes(row.institute?.toLowerCase())
            )
            .filter((row) => applyDateFilter(row.date))
            .filter((row) =>
              row.emailAddress
                .toLowerCase()
                .includes(searchEmailAddress.toLowerCase())
            )
            .filter((row) =>
              row.phoneNumber
                .toLowerCase()
                .includes(searchContact.toLowerCase())
            )
            .filter((row) =>
              row.firstName.toLowerCase().includes(searchName.toLowerCase())
            )
        : []
    );
  }, [
    searchEmailAddress,
    searchInstitute,
    searchName,
    searchContact,
    filterOptions,
    filterData,
    selectedDateFilter,
    searchDate,
  ]);

  return filterData ? (
    <Flex maxH="65vh" overflowY="scroll" minH="80px">
      <Table
        columns={columns}
        data={data}
        filterData={filterData}
        isFilterChecked={isFilterChecked}
        setFilterChecked={setFilterChecked}
        filterOptions={filterOptions}
        setFilterOptions={setFilterOptions}
        selectedRole={selectedRole}
        setSelectedRole={setSelectedRole}
        selectedInstitute={selectedInstitute}
        setSelectedInstitute={setSelectedInstitute}
        showRoleFilterMenu={showRoleFilterMenu}
        setShowRoleFilterMenu={setShowRoleFilterMenu}
        showInstituteFilterMenu={showInstituteFilterMenu}
        setShowInstituteFilterMenu={setShowInstituteFilterMenu}
        showEmailAddressFilterMenu={showEmailAddressFilterMenu}
        setShowEmailAddressFilterMenu={setShowEmailAddressFilterMenu}
        showContactFilterMenu={showContactFilterMenu}
        setShowContactFilterMenu={setShowContactFilterMenu}
        showNameFilterMenu={showNameFilterMenu}
        setShowNameFilterMenu={setShowNameFilterMenu}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        showDateFilterMenu={showDateFilterMenu}
        setShowDateFilterMenu={setShowDateFilterMenu}
        selectedDateFilter={selectedDateFilter}
        setSelectedDateFilter={setSelectedDateFilter}
        searchDate={searchDate}
        setSearchDate={setSearchDate}
        searchEmailAddress={searchEmailAddress}
        searchInstitute={searchInstitute}
        setSearchEmailAddress={setSearchEmailAddress}
        setSearchInstitute={setSearchInstitute}
        searchContact={searchContact}
        setSearchContact={setSearchContact}
        searchName={searchName}
        setSearchName={setSearchName}
      />
    </Flex>
  ) : (
    <Flex w="100%" alignItems="center" justifyContent="center">
      Loading data
    </Flex>
  );
};

export default MemberTable;

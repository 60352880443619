import React from "react";
import {
  Divider,
  Flex,
  Text,
  useMediaQuery,
  VStack,
  Spinner,
  styled,
  HStack,
  Button,
} from "@chakra-ui/react";
import {
  Chart as ChartJS,
  PointElement,
  LineElement,
  ChartConfiguration,
  LineController,
  LinearScale,
  Title,
  CategoryScale,
  Tooltip,
  Legend,
  TimeScale,
  ChartOptions,
  registerables as registerablesJS,
} from "chart.js";
import { BiLinkExternal } from "react-icons/bi";
import { Chart } from "react-chartjs-2";
ChartJS.register(...registerablesJS);
ChartJS.register(
  PointElement,
  LineElement,
  LineController,
  PointElement,
  LinearScale,
  CategoryScale,
  PointElement,
  LineElement,
  TimeScale,
  Title,
  Tooltip,
  Legend
);

const RecruitmentStatusComponent = () => {
  const options = {
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          display: false,
        },
        title: {
          display: true,
          text: "(Phases)",
        },
      },

      y: {
        grid: {
          display: false,
        },
        title: {
          display: true,
          text: "(Patients)",
        },
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: "top",
        align: "end",
        labels: {
          font: {
            size: 10,
          },
        },
      },
    },
  };

  const graphData = {
    labels: ["phase1", "phase2", "phase3", "phase4"],
    datasets: [
      {
        label: "Reported",
        data: "1",
        backgroundColor: "#3B5D7C",
        barThickness: 23,
      },
      {
        label: "Recruited",
        data: "2",
        backgroundColor: "#D9D9D9",
        barThickness: 16,
      },
      {
        label: "To be recruited",
        data: "3",
        borderColor: "rgb(0, 0, 0)",
        type: "line",
        borderDash: [2, 2],
      },
    ],
  };

  const [ifWidthLessthan1920] = useMediaQuery("(max-width:1920px)");
  return (
    <Flex w="100%" pl="10px">
      <VStack
        bg="light.100"
        maxH="25vh"
        w="100%"
        fontSize={ifWidthLessthan1920 ? "14px" : "0.729vw"}
        minH="150px"
        minW="260px"
      >
        <HStack w="100%" justifyContent="space-between">
          <Text
            color="#1B75BC"
            fontSize={ifWidthLessthan1920 ? "15px" : "18px"}
            fontWeight="bold"
          >
            Recruitment Status
          </Text>
          <Button
            bgColor="inherit"
            _hover={{ color: "#1B75BC", cursor: "pointer" }}
            _active={{ color: "#1B75BC", cursor: "pointer" }}
          >
            <BiLinkExternal
              size={18}
              style={{ color: "#1B75BC", cursor: "pointer" }}
            />
          </Button>
        </HStack>
        <HStack w="100%">
          <Text
            fontSize={ifWidthLessthan1920 ? "12px" : "15px"}
            fontWeight="bold"
          >
            Patient recruited and Reported per phase
          </Text>
        </HStack>

        <Chart type="bar" options={options} data={graphData} />
      </VStack>
    </Flex>
  );
};

export default RecruitmentStatusComponent;

import React from "react";
import moment from "moment";
import {
  Flex,
  useMediaQuery,
  Text,
  HStack,
  Button,
  Image,
  VStack,
  Link,
} from "@chakra-ui/react";
import { MdOutlineRadioButtonChecked } from "react-icons/md";
import { v4 as uuidv4 } from "uuid";
import { HiOutlineClipboardList } from "react-icons/hi";
import { BiLinkExternal } from "react-icons/bi";
import studyAssignTimeline from "../../../../../static/Images/studyAssignTimeline.svg";
import slideUploadTimeline from "../../../../../static/Images/slideUploadTimeline.svg";
import studyCreatedTimeline from "../../../../../static/Images/studyCreatedTimeline.svg";

const ScrollBar = ({ children }) => {
  return (
    <Flex
      w="100%"
      h="100%"
      flexDir="column"
      overflowY="auto"
      overflowX="hidden"
      css={{
        "&::-webkit-scrollbar": {
          width: "4px",
        },
        "&::-webkit-scrollbar-track": {
          width: "6px",
        },
        "&::-webkit-scrollbar-thumb": {
          background: "#C4C4C4",
        },
      }}
    >
      {children}
    </Flex>
  );
};
const StudyTimelineComponent = ({ timeLineData }) => {
  const capitalizeFirstWord = (sentence) => {
    const words = sentence.split(" ");
    if (words.length === 0) {
      return sentence;
    }

    const firstWord = words[0].charAt(0).toUpperCase() + words[0].slice(1);
    const restOfSentence = words.slice(1).join(" ");

    return `${firstWord} ${restOfSentence.toLowerCase()}`;
  };
  const studyData = [
    {
      description: "A slide was uploaded by COM",
      created_by: "233333747-87353542-09898829",
      created_at: "2023-10-20T07:09:42.367Z",
      case_id: "uuid",
    },
  ];
  const [ifWidthLessthan1920] = useMediaQuery("(max-width:1920px)");
  return (
    <Flex
      h="100%"
      w="100%"
      bgColor="#fff"
      borderRadius="10px"
      pl="10px"
      direction="column"
    >
      <HStack w="100%" justifyContent="space-between">
        <Text
          color="#1B75BC"
          fontSize={ifWidthLessthan1920 ? "15px" : "18px"}
          fontWeight="bold"
          pt="10px"
          pb="10px"
        >
          Study Timeline
        </Text>
      </HStack>

      <ScrollBar>
        <Flex w="100%" h="100%" direction="column">
          {timeLineData?.data?.timeline.length > 0 ? (
            timeLineData?.data?.timeline.map((data, index) => {
              return (
                <HStack
                  w="96%"
                  minH="95px"
                  spacing="3"
                  alignItems="flex-start"
                  key={uuidv4()}
                >
                  <Flex
                    w="7%"
                    h="100%"
                    alignItems="center"
                    direction="column"
                    justifyContent="center"
                    mt="25px"
                  >
                    <MdOutlineRadioButtonChecked color="#1B75BC" size={27} />
                    <Flex
                      w="2px"
                      h="100%"
                      bgColor={
                        index < timeLineData?.data?.timeline?.length - 1
                          ? "#1B75BC"
                          : "#fff"
                      }
                    />
                  </Flex>
                  <Flex w="90%" h="100%">
                    <Flex
                      w="100%"
                      textOverflow="ellipsis"
                      css={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "-webkit-box",
                        boxOrient: "vertical",
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: 2,
                      }}
                      minH="88px"
                      h="fit-content"
                      background="#F9F9F9"
                      boxShadow="0px 1px 2px rgba(176, 200, 214, 0.08)"
                      borderRadius="10px"
                      alignItems="center"
                      justifyContent="center"
                      pt="8px"
                      pl="10px"
                    >
                      <HStack w="100%" pt="15px">
                        {data.description.includes("created") ? (
                          <Image
                            src={studyCreatedTimeline}
                            transform="scale(1.5)"
                          />
                        ) : data.description.includes("added") ? (
                          <Image
                            src={studyAssignTimeline}
                            transform="scale(1.5)"
                          />
                        ) : data.description.includes("saved") ? (
                          <HiOutlineClipboardList
                            color="#1B75BC"
                            size="20px"
                            style={{ transform: "scale(1.5)" }}
                          />
                        ) : data.description.includes("uploaded") ? (
                          <Image
                            src={slideUploadTimeline}
                            transform="scale(1.5)"
                          />
                        ) : null}
                        {/* <HiOutlineClipboardList color="#1B75BC" size="40px" /> */}
                        <Flex direction="column" w="90%">
                          <HStack w="100%" justifyContent="space-between">
                            <Text
                              fontSize={ifWidthLessthan1920 ? "13px" : "15px"}
                              whiteSpace="nowrap"
                              overflow="hidden"
                              textOverflow="ellipsis"
                              w="70%"
                            >
                              <Flex
                                alignItems="flex-start"
                                direction="column"
                                gap={0}
                              >
                                <Flex pl="5px" whiteSpace="wrap">
                                  {data?.description}
                                </Flex>
                                <Flex pl="5px">
                                  {data.description.includes("reported by") ? (
                                    <Button
                                      h="20px"
                                      w={
                                        ifWidthLessthan1920 ? "70px" : "80.5px"
                                      }
                                      backgroundColor="#F9F9F9"
                                      _hover={{ background: "#F9F9F9" }}
                                      // onClick={() => setTabIndex(4)}
                                    >
                                      <Text
                                        fontSize={
                                          ifWidthLessthan1920 ? "12px" : "14px"
                                        }
                                        color="#1B75BC"
                                        textDecoration="underline"
                                        textUnderlineOffset="4px"
                                      >
                                        View Report
                                      </Text>
                                    </Button>
                                  ) : data.description.includes(
                                      "uploaded by"
                                    ) ? (
                                    <Button
                                      h="20px"
                                      w={ifWidthLessthan1920 ? "68px" : "79px"}
                                      backgroundColor="#F9F9F9"
                                      _hover={{ background: "#F9F9F9" }}
                                      // onClick={() => setTabIndex(1)}
                                    >
                                      <Text
                                        fontSize={
                                          ifWidthLessthan1920 ? "12px" : "14px"
                                        }
                                        color="#1B75BC"
                                        textDecoration="underline"
                                        textUnderlineOffset="4px"
                                      >
                                        View Slides
                                      </Text>
                                    </Button>
                                  ) : null}
                                </Flex>
                              </Flex>
                            </Text>

                            <Flex alignItems="flex-end" w="30%" pt="13px">
                              <Text
                                color="#000"
                                opacity={0.7}
                                fontSize={ifWidthLessthan1920 ? "12px" : "14px"}
                                whiteSpace="nowrap"
                                overflow="hidden"
                                textOverflow="ellipsis"
                              >
                                {moment(data.created_at).fromNow()}
                              </Text>
                            </Flex>
                          </HStack>
                        </Flex>
                      </HStack>
                    </Flex>
                  </Flex>
                </HStack>
              );
            })
          ) : (
            <Flex w="100%" h="100%" alignItems="center" justifyContent="center">
              <Text opacity="0.3" textAlign="center">
                No timeline exists for this case
              </Text>
            </Flex>
          )}
        </Flex>
      </ScrollBar>
    </Flex>
  );
};

export default StudyTimelineComponent;

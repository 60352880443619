import React from "react";
import { Routes, Route } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import Login from "../components/Authenticate/login";
import UserDetails from "../components/Authenticate/userDetails";
// import ViewerRedirect from "../components/Viewer/ViewerRedirect";
// import OSDViewer from "../components/Viewer/OSDViewer";
// import CreateCase from "../NewCase/CreateCase";
// import AppContainer from "../AppContainer";
import PublicRoute from "./PublicRoute";
import roles from "../configs/role.config";
import { PageNotFound } from "../components/ErrorPages";
import AdminDashboard from "../components/NewAdmin/AdminDashboard";
import AddMember from "../components/Admin/AddMember";
import RestrictedRoute from "./RestrictedRoute";
import { DashboardLayout, DashboardRedirect } from "../components/Dashboard";
// import Dashboard from "../components/NewDashBoard/DashBoard";
import Dashboard from "../components/Dashboard/dashboard";
// import AddMemberDashboard from "../components/Admin/AddmemberDashboard";
import AddMemberDashboard from "../components/NewAdmin/AddMember/AddmemberDashboard";
import Studies from "../components/Studies/Studies";
import NewStudy from "../components/NewStudy/NewStudy";
import ViewerRedirect from "../components/Viewer/ViewerRedirect";
import OSDViewer from "../components/Viewer/OSDViewer";
import AddRoles from "../components/NewAdmin/AddMember/AddRoles";
import StudyDetailScreen from "../components/NewDashBoard/StudyDetailScreen/StudyDetailScreen";
import ReaderStudyDetailScreen from "../components/NewReader/StudyDetailScreen/ReaderStudyDetailScreen";
import ClinicalOperationManagerStudyDetailScreen from "../components/NewClinicalOperationManager/StudyDetailScreens/ClinicalOperationManagerStudyDetailScreen";
import StudiesList from "../components/Admin/StudiesList";
import ReportDetails from "../components/Admin/ReportDetails";
const RouterConfig = () => {
  return (
    <Routes>
      <Route
        path="/login"
        element={
          <PublicRoute>
            <Login />
          </PublicRoute>
        }
      />

      <Route
        path="/registration"
        element={
          <RestrictedRoute>
            <UserDetails />
          </RestrictedRoute>
        }
      />
      <Route
        path="/studies"
        element={
          <ProtectedRoute allowedRoles={[roles.PI, roles.Reader]}>
            <Studies />
          </ProtectedRoute>
        }
      />
      <Route
        path="/dashboard/Pathologist"
        element={
          <ProtectedRoute allowedRoles={[roles.Reader]}>
            {/* <Studies /> */}
            <ReaderStudyDetailScreen />
          </ProtectedRoute>
        }
      />
      <Route
        element={
          <ProtectedRoute allowedRoles={[roles.Admin, roles.PI, roles.Reader]}>
            <DashboardLayout />
          </ProtectedRoute>
        }
      >
        <Route
          path="/admin/add-user"
          element={
            <ProtectedRoute allowedRoles={[roles.Admin]}>
              <AddMemberDashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/add-role"
          element={
            <ProtectedRoute allowedRoles={[roles.Admin]}>
              <AddRoles />
            </ProtectedRoute>
          }
        />{" "}
        <Route
          path="/admin/add-member"
          element={
            <ProtectedRoute allowedRoles={[roles.Admin]}>
              <AddMemberDashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard/Admin"
          element={
            <ProtectedRoute allowedRoles={[roles.Admin]}>
              <AdminDashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard/PI"
          element={
            <ProtectedRoute allowedRoles={[roles.Admin, roles.PI]}>
              {/* <Dashboard /> */}
              <ClinicalOperationManagerStudyDetailScreen />
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard/study"
          element={
            <ProtectedRoute allowedRoles={[roles.Admin, roles.PI]}>
              <StudyDetailScreen />
            </ProtectedRoute>
          }
        />
        <Route
          path="/create-study"
          element={
            <ProtectedRoute
              allowedRoles={[roles.Admin, roles.PI, roles.Reader]}
            >
              <NewStudy />
            </ProtectedRoute>
          }
        />
        <Route
          path="/viewer"
          element={
            <ProtectedRoute allowedRoles={[roles.PI, roles.Reader]}>
              <OSDViewer />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/studies"
          element={
            <ProtectedRoute allowedRoles={[roles.Admin]}>
              <StudiesList />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/study-report"
          element={
            <ProtectedRoute allowedRoles={[roles.Admin]}>
              <ReportDetails />
            </ProtectedRoute>
          }
        />
        <Route index element={<DashboardRedirect />} />
        <Route path="*" element={<PageNotFound />} />
      </Route>
      {/* 
      <Route
        path="/dashboard/pathologist"
        element={
          <ProtectedRoute allowedRoles={[roles.Pathologist]}>
            <AppContainer />
          </ProtectedRoute>
        }
      />

      <Route
        path="/dashboard/technologist"
        element={
          <ProtectedRoute allowedRoles={[roles.Admin, roles.Technologist]}>
            <AppContainer />
          </ProtectedRoute>
        }
      />

      <Route
        path="/create-case"
        element={
          <ProtectedRoute allowedRoles={[roles.Technologist, roles.Admin]}>
            <CreateCase />
          </ProtectedRoute>
        }
      />

      <Route
        path="/viewer"
        element={
          <ProtectedRoute
            allowedRoles={[roles.Pathologist, roles.Technologist, roles.Admin]}
          >
            <ViewerRedirect />
          </ProtectedRoute>
        }
      />

      <Route
        path="/viewer/:caseName"
        element={
          <ProtectedRoute
            allowedRoles={[roles.Pathologist, roles.Technologist, roles.Admin]}
          >
            <OSDViewer />
          </ProtectedRoute>
        }
      /> */}

      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};

export default RouterConfig;

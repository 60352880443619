import React from "react";
import {
  Flex,
  Text,
  useMediaQuery,
  Image,
  HStack,
  VStack,
  Link,
  Button,
} from "@chakra-ui/react";
import { BiLinkExternal } from "react-icons/bi";
import { Link as RouteLink } from "react-router-dom";
import { getSlideUrl } from "../../../../../hooks/utility";

const RecentSlidesComponent = () => {
  const [ifWidthLessthan1920] = useMediaQuery("(max-width:1920px)");
  const slides = [
    {
      _id: "3185d2db-dabc-4d7f-ab37-e9d79f3c0ee1",
      awsImageBucketUrl:
        "https://d3fvaqnlz9wyiv.cloudfront.net/clinical/development/outputs/cfdbf3ac-f614-48da-9f49-2d1e1de1b1c2/output.dzi",
      uploadedBy: "1b6107e1-2bea-4b4b-bab9-0988e7d6567d",
      created_at: "2023-10-11T08:54:04.436Z",
      updated_at: "2023-10-11T08:54:04.436Z",
      userId: "1b6107e1-2bea-4b4b-bab9-0988e7d6567d",
      firstName: "Siddhesh",
      lastName: "Kale",
      originalFileUrl:
        "https://d3fvaqnlz9wyiv.cloudfront.net/unprocessed_images/clinical/development/source/cfdbf3ac-f614-48da-9f49-2d1e1de1b1c2.svs",
      slideName: "asasa",
    },
    {
      _id: "3185d2db-dabc-4d7f-ab37-e9d79f3c0ee1",
      awsImageBucketUrl:
        "https://d3fvaqnlz9wyiv.cloudfront.net/clinical/development/outputs/cfdbf3ac-f614-48da-9f49-2d1e1de1b1c2/output.dzi",
      uploadedBy: "1b6107e1-2bea-4b4b-bab9-0988e7d6567d",
      created_at: "2023-10-11T08:54:04.436Z",
      updated_at: "2023-10-11T08:54:04.436Z",
      userId: "1b6107e1-2bea-4b4b-bab9-0988e7d6567d",
      firstName: "Siddhesh",
      lastName: "Kale",
      originalFileUrl:
        "https://d3fvaqnlz9wyiv.cloudfront.net/unprocessed_images/clinical/development/source/cfdbf3ac-f614-48da-9f49-2d1e1de1b1c2.svs",
      slideName: "asasa",
    },
    {
      _id: "3185d2db-dabc-4d7f-ab37-e9d79f3c0ee1",
      awsImageBucketUrl:
        "https://d3fvaqnlz9wyiv.cloudfront.net/clinical/development/outputs/cfdbf3ac-f614-48da-9f49-2d1e1de1b1c2/output.dzi",
      uploadedBy: "1b6107e1-2bea-4b4b-bab9-0988e7d6567d",
      created_at: "2023-10-11T08:54:04.436Z",
      updated_at: "2023-10-11T08:54:04.436Z",
      userId: "1b6107e1-2bea-4b4b-bab9-0988e7d6567d",
      firstName: "Siddhesh",
      lastName: "Kale",
      originalFileUrl:
        "https://d3fvaqnlz9wyiv.cloudfront.net/unprocessed_images/clinical/development/source/cfdbf3ac-f614-48da-9f49-2d1e1de1b1c2.svs",
      slideName: "asasa",
    },
    {
      _id: "3185d2db-dabc-4d7f-ab37-e9d79f3c0ee1",
      awsImageBucketUrl:
        "https://d3fvaqnlz9wyiv.cloudfront.net/clinical/development/outputs/cfdbf3ac-f614-48da-9f49-2d1e1de1b1c2/output.dzi",
      uploadedBy: "1b6107e1-2bea-4b4b-bab9-0988e7d6567d",
      created_at: "2023-10-11T08:54:04.436Z",
      updated_at: "2023-10-11T08:54:04.436Z",
      userId: "1b6107e1-2bea-4b4b-bab9-0988e7d6567d",
      firstName: "Siddhesh",
      lastName: "Kale",
      originalFileUrl:
        "https://d3fvaqnlz9wyiv.cloudfront.net/unprocessed_images/clinical/development/source/cfdbf3ac-f614-48da-9f49-2d1e1de1b1c2.svs",
      slideName: "asasa",
    },
  ];
  return (
    <Flex w="100%" pl="10px">
      <VStack
        bg="light.100"
        maxH="25vh"
        w="100%"
        fontSize={ifWidthLessthan1920 ? "14px" : "0.729vw"}
        minH="210px"
        minW="260px"
      >
        <HStack w="100%" justifyContent="space-between">
          <Text
            color="#1B75BC"
            fontSize={ifWidthLessthan1920 ? "15px" : "18px"}
            fontWeight="bold"
          >
            Recruitment Status
          </Text>
          <Button
            bgColor="inherit"
            _hover={{ color: "#1B75BC", cursor: "pointer" }}
            _active={{ color: "#1B75BC", cursor: "pointer" }}
          >
            <BiLinkExternal
              size={18}
              style={{ color: "#1B75BC", cursor: "pointer" }}
            />
          </Button>
        </HStack>
        <Flex
          flexDirection="column"
          h="100%"
          flex="1"
          // fontSize={ifWidthLessthan1920 ? "14px" : "0.729vw"}
          maxH="80vh"
          overflow="auto"
          css={{
            "&::-webkit-scrollbar": {
              width: "4px",
            },
            "&::-webkit-scrollbar-track": {
              width: "6px",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#C4C4C4",
            },
          }}
        >
          <Flex
            flexWrap="wrap"
            alignItems="flex-start"
            justifyContent="flex-start"
            flex="1"
            h="100%"
            mt="1.11vh"
            ml="2.5vw"
          >
            {slides?.map((slide, index) => {
              return slide?.awsImageBucketUrl !== "" ? (
                <Link
                  key={index}
                  as={RouteLink}
                  to={`/viewer`}
                  // state={{ caseId, slide, userInfo }}
                  style={{ textDecoration: "none" }}
                  _hover={{ bgColor: "light.900" }}
                  _focus={{ outline: "none" }}
                  bgColor="light.100"
                  w="12.9vw"
                  m="1.1vh 1.1vw 1.1vw 0vw"
                  pb="0.833vw"
                >
                  <VStack pt="1.1vh" key={index}>
                    <Image
                      src={getSlideUrl(slide?.awsImageBucketUrl)}
                      w="12vw"
                      h="20.571vh"
                      alt={slide.accessionId}
                      pt="1vh"
                    />
                    <HStack w="100%" justifyContent="space-between" px="1.1vw">
                      <Text isTruncated>{slide?.slideName}</Text>
                      {/* <IconButton
                      bgColor="#fff"
                      _focus={{ outline: "none" }}
                      w="2vw"
                      h="3vh"
                      as={IoIosInformationCircleOutline}
                    /> */}
                    </HStack>
                  </VStack>
                </Link>
              ) : (
                ""
              );
            })}
          </Flex>
        </Flex>
      </VStack>
    </Flex>
  );
};

export default RecentSlidesComponent;

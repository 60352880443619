import {
  Flex,
  Text,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItemOption,
  MenuOptionGroup,
} from "@chakra-ui/react";
import React from "react";
import { QuestionsLibrary } from "../Qna/QuestionsLibrary";
import { addLibraryQuestions } from "../../state/reducers/newStudyReducer";
import { useDispatch } from "react-redux";

function LibraryBtn({ activeButton, selectedQuestions, setSelectedQuestions }) {
  const dispatch = useDispatch();
  return (
    <Menu closeOnSelect={false} placement="top">
      <MenuButton
        as={Button}
        px={4}
        py={1}
        size="sm"
        transition="all 0.2s"
        borderRadius="md"
        // borderWidth="1px"
        // border="1px solid #DEDEDE"
        fontWeight="400"
        // leftIcon={<AiOutlineFile color="rgba(153, 153, 153, 1)" size={17} />}
        _focus={{ outline: "none", bg: "light.100" }}
        _active={{ outline: "none", bg: "light.100" }}
        bg="#fcfcfc"
        color="light.700"
      >
        Question library
      </MenuButton>
      <MenuList w="27.4479vw" maxH="50vh" overflow="auto" bg="light.1000">
        <Flex justify="space-between" p="1.3888vh 1.1458vw" align="center">
          <Text>{selectedQuestions?.length} Questions Selected</Text>
          <Button
            size="sm"
            bg="rgba(27, 117, 188, 0.25)"
            color="light.700"
            _focus={{ outline: "none" }}
            fontWeight="400"
            _hover={{ bg: "rgba(27, 117, 188, 0.25)" }}
            onClick={() => {
              dispatch(
                addLibraryQuestions({
                  data: selectedQuestions,
                  type: activeButton,
                })
              );
            }}
          >
            Add
          </Button>
        </Flex>
        <MenuOptionGroup
          type="checkbox"
          value={selectedQuestions}
          onChange={(e) => setSelectedQuestions(e)}
        >
          {QuestionsLibrary[activeButton]?.map((question, i) => {
            return (
              <MenuItemOption
                px="0.5vw"
                key={i}
                value={question}
                bg={selectedQuestions?.includes(question) ? "#F3F0FF" : "#fff"}
                border=" 1px solid #D9D9D9"
                m=" 0.6481vh 0.5208vw"
                borderRadius="5px"
                w="95%"
              >
                {question?.isSection ? (
                  <Flex direction="column">
                    <Text>{`${question?.sectionHeading}`}</Text>
                    {question?.sectionQuestions?.map((question, i) => {
                      return (
                        <Flex direction="column" key={i}>
                          <Text>{`${question?.question_text}`}</Text>
                          {question?.choices?.map((choice, i) => {
                            return (
                              <Text px="2vw">{`(${i + 1}) ${choice}`}</Text>
                            );
                          })}
                        </Flex>
                      );
                    })}
                  </Flex>
                ) : (
                  <Flex direction="column">
                    <Text>{`${question?.question_text}`}</Text>
                    {question?.choices?.map((choice, i) => {
                      return (
                        <Text px="2vw" key={i}>
                          {`(${i + 1}) ${choice}`}
                        </Text>
                      );
                    })}
                    {/* } */}
                  </Flex>
                )}
              </MenuItemOption>
            );
          })}
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  );
}

export default LibraryBtn;

import React, { useState } from "react";
import { Flex } from "@chakra-ui/react";
import { useAuth0 } from "@auth0/auth0-react";
import {
  useGetUserInfoQuery,
  useGetAllClinicalUserInformationQuery,
  useGetAllClinicalRoleInformationQuery,
} from "../../state/api/medicalApi";
import Loading from "../Loading/loading";
import InfoCardComponent from "./InfoCardComponent";
import TotalMember from "./TotalMember";

const AdminDashboard = () => {
  const [searchUser, setSearchUser] = useState("");
  const [caseStatus, setCaseStatus] = useState("");
  const { user } = useAuth0();
  const { data: userInfo, isLoading } = useGetUserInfoQuery(
    {
      subClaim: user?.sub,
    },
    { skip: !user }
  );
  // console.log(userInfo);
  const { data: allClinicalDetails } = useGetAllClinicalUserInformationQuery(
    {
      desiredRole: caseStatus ? caseStatus : null,
    },
    { skip: !userInfo }
  );
  console.log(
    allClinicalDetails !== undefined ? allClinicalDetails.data : null
  );
  const { data: allClinicalRoleDetails } =
    useGetAllClinicalRoleInformationQuery(
      {
        organization_id: userInfo?.data[0].organizationId,
      },
      { skip: !userInfo }
    );

  return isLoading ? (
    <Loading />
  ) : (
    <Flex w="100%" h="100%" bg="light.100" flexDirection="column" overflow="hidden !important">
      <Flex>
        <InfoCardComponent
          data={
            allClinicalDetails?.metadata === undefined
              ? [
                  {
                    role_name: "pi",
                    userCount: "0",
                  },
                  {
                    role_name: "reader",
                    userCount: "0",
                  },
                ]
              : allClinicalDetails?.metadata
          }
          setCaseStatus={setCaseStatus}
        />
      </Flex>
      <Flex>
        {/* <TotalMember isLoading={isLoading} filterData={allClinicalDetails?.data} /> */}
        <TotalMember
          isLoading={isLoading}
          roleData={allClinicalRoleDetails?.data}
          filterData={allClinicalDetails?.data}
        />
      </Flex>
    </Flex>
  );
};

export default AdminDashboard;

import { v4 as uuid } from "uuid";
export const HEQuestions = [
  {
    question_text: "Biopsy Adequacy",
    question_type: "Multiple Choice",
    choices: ["Yes", "No"],
    isSection: false,
    sectionQuestions: [],
    sectionHeading: "",
    conditionQuestion: [],
    questionId: uuid(),
  },
  {
    question_text: "If not indicate why?",
    question_type: "Multiple Choice",
    choices: ["Faded/Poor stain", "Not in Focus", "Other "],
    isSection: false,
    sectionQuestions: [],
    sectionHeading: "",
    conditionQuestion: [],
    questionId: uuid(),
  },
  {
    question_text: "Other :",
    question_type: "text",
    choices: [],
    isSection: false,
    sectionQuestions: [],
    sectionHeading: "",
    conditionQuestion: [],
    questionId: uuid(),
  },
  {
    question_text: "",
    question_type: "text",
    choices: [],
    isSection: true,
    sectionQuestions: [
      {
        question_text: "(a) Steatosis",
        question_type: "Multiple Choice",
        choices: ["0:  (<5%)", "1:  (5-33%)", "2:  (34-66%)", "3:  (> 66%)"],
        isSection: false,
        sectionQuestions: [],
        sectionHeading: "",
        conditionQuestion: [],
        questionId: uuid(),
      },
      {
        question_text: "(b) Lobular inflammation",
        question_type: "Multiple Choice",
        choices: [
          "0:  (None)",
          "1:  (< 2 / 20x)",
          "2:  (2-4 / 20x)",
          "3:  (>4 / 20x)",
        ],
        isSection: false,
        sectionQuestions: [],
        sectionHeading: "",
        conditionQuestion: [],
        questionId: uuid(),
      },
      {
        question_text: "(c) Hepatocellular ballooning",
        question_type: "Multiple Choice",
        choices: ["0:  (None)", "1:  (Few)", "2:  (Many)"],
        isSection: false,
        sectionQuestions: [],
        sectionHeading: "",
        conditionQuestion: [],
        questionId: uuid(),
      },
    ],
    sectionHeading: "NAFLD Activity Score (NAS)",
    conditionQuestion: [],
    questionId: uuid(),
  },
  {
    question_text: "How many balloon cells did you see?",
    question_type: "text",
    choices: [],
    isSection: false,
    sectionQuestions: [],
    sectionHeading: "",
    conditionQuestion: [],
    questionId: uuid(),
  },
];

export const trichromeQuestions = [
  {
    question_text: "Biopsy Length:-",
    question_type: "text",
    choices: [],
    isSection: false,
    sectionQuestions: [],
    sectionHeading: "",
    conditionQuestion: [],
    questionId: uuid(),
  },
  {
    question_text: "No of portal tracts:-",
    question_type: "text",
    choices: [],
    isSection: false,
    sectionQuestions: [],
    sectionHeading: "",
    conditionQuestion: [],
    questionId: uuid(),
  },

  {
    question_text: "Biopsy Adequacy",
    question_type: "Multiple Choice",
    choices: ["Yes", "No"],
    isSection: false,
    sectionQuestions: [],
    sectionHeading: "",
    conditionQuestion: [],
    questionId: uuid(),
  },
  {
    question_text: "If not indicate why?",
    question_type: "Multiple Choice",
    choices: ["Faded/Poor stain", "Not in Focus", "Other "],
    isSection: false,
    sectionQuestions: [],
    sectionHeading: "",
    conditionQuestion: [],
    questionId: uuid(),
  },
  {
    question_text: "Other :",
    question_type: "text",
    choices: [],
    isSection: false,
    sectionQuestions: [],
    sectionHeading: "",
    conditionQuestion: [],
    questionId: uuid(),
  },
  {
    question_text: "Fibrosis Stage - NASH CRN",
    question_type: "Multiple Choice",
    choices: [
      "0:  None",
      "1a: Mild, Zone 3, perisinusoidal",
      "1b: Moderate, Zone 3, perisinusoidal",
      "1c: Portal/periportal only",
      "2:  Zone 3 and periportal",
      "3:  Bridging",
      "4:  Cirrhosis",
    ],
    isSection: false,
    sectionQuestions: [],
    sectionHeading: "",
    conditionQuestion: [],
    questionId: uuid(),
  },
  {
    question_text: "How many bridges did you observe?",
    question_type: "text",
    choices: [],
    isSection: false,
    sectionQuestions: [],
    sectionHeading: "",
    conditionQuestion: [],
    questionId: uuid(),
  },
];

export const QuestionsLibrary = {
  HAndE: HEQuestions,
  Trichrome: trichromeQuestions,
};

import { Box, Flex, Text, VStack } from "@chakra-ui/react";
import React from "react";
import Choice from "./Choice";

const DummyQuestionnaire = ({
  direction,
  questions,
  response,
  slideQna,
  setSlideQna,
  linkState,
  activeButton,
  sectionQuestions,
  ...restProps
}) => {
  const setQnaResponse = (qnaResponse) => {
    setSlideQna((state) => {
      const { id, value, choiceText } = qnaResponse;
      const { qna, response } = state;
      const newResponse = { ...response, [id]: value };
      let newQna = {};
      if (choiceText) newQna = { ...qna, [id]: choiceText };
      else newQna = { ...qna, [id]: value };
      return { qna: newQna, response: newResponse };
    });
  };
  const Questions = sectionQuestions ? questions : questions[activeButton];
  return (
    <Box w="100%" bgColor="#fcfcfc">
      <VStack spacing={6} align="flex-start" {...restProps} flexWrap="wrap">
        {Questions?.map((question, index) => (
          <VStack
            key={question?._id ? question?._id : index}
            direction={direction}
            spacing={4}
            mt="1.388vh"
            w="100%"
            maxW="100%"
            alignItems="flex-start"
            p="0.8333vw"
          >
            {question?.isSection && <Text fontWeight="600">Section</Text>}
            {question?.isSection && (
              <>
                <Text>{`Q${index + 1}. ${question?.sectionHeading}`}</Text>
                {!!question?.conditionQuestion?.length && (
                  <Flex
                    alignItems="center"
                    justifyContent="flex-start"
                    w="100%"
                    direction="column"
                  >
                    {question?.conditionQuestion?.map((e, i) => {
                      return (
                        <Text
                          key={i}
                          maxW="100%"
                          minW="100%"
                          color="light.700"
                        >{`Linked to ${e?.question_text} - ${e?.choice}`}</Text>
                      );
                    })}

                    {/* <Button
                    background="none"
                    onClick={() => dispatch(resetLink({ index }))}
                    size="sm"
                    _focus={{ outline: "none" }}
                  >
                    <RiDeleteBin6Line />
                  </Button> */}
                  </Flex>
                )}
              </>
            )}
            {!question?.isSection ? (
              <>
                <Text w="100%" maxW="100%" pb="1vh">{`Q${index + 1}. ${
                  question?.question_text
                } `}</Text>
                {!!question?.conditionQuestion?.length && (
                  <Flex
                    alignItems="center"
                    justifyContent="flex-start"
                    w="100%"
                    direction="column"
                  >
                    {question?.conditionQuestion?.map((e, i) => {
                      return (
                        <Text
                          key={i}
                          maxW="100%"
                          minW="100%"
                          color="light.700"
                        >{`Linked to ${e?.question_text} - ${e?.choice}`}</Text>
                      );
                    })}

                    {/* <Button
                      background="none"
                      onClick={() => dispatch(resetLink({ index }))}
                      size="sm"
                      _focus={{ outline: "none" }}
                    >
                      <RiDeleteBin6Line />
                    </Button> */}
                  </Flex>
                )}
                <Box w="100%">
                  <Choice
                    question={question}
                    direction={direction}
                    response={response}
                    setQnaResponse={setQnaResponse}
                    slideQna={slideQna}
                    questions={questions}
                    linkState={linkState}
                    activeButton={activeButton}
                    questionId={question?.questionId}
                  />
                </Box>
              </>
            ) : (
              question?.sectionQuestions?.map((sectionQuestion, i) => {
                return (
                  <VStack
                    key={sectionQuestion?._id ? sectionQuestion?._id : i}
                    direction={direction}
                    spacing={4}
                    mt="1.388vh"
                    w="100%"
                    maxW="100%"
                    alignItems="flex-start"
                    p="0.8333vw"
                  >
                    <Text
                      w="100%"
                      maxW="100%"
                      borderBottom="1px solid #3B5D7C"
                      pb="1vh"
                    >{`${sectionQuestion?.question_text}`}</Text>
                    <Box w="100%">
                      <Choice
                        question={sectionQuestion}
                        direction={direction}
                        response={response}
                        setQnaResponse={setQnaResponse}
                        slideQna={slideQna}
                        questions={questions}
                        linkState={linkState}
                        activeButton={activeButton}
                        questionId={question?.questionId}
                      />
                    </Box>
                  </VStack>
                );
              })
            )}
          </VStack>
        ))}
      </VStack>
    </Box>
  );
};

export default DummyQuestionnaire;

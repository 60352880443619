import { Image, Flex, Text, Link, VStack, Center } from "@chakra-ui/react";
import React from "react";
import { Link as RouteLink } from "react-router-dom";
import Error404 from "../../images/error-pages/error-404.svg";

const PageNotFound = () => {
  return (
    <Center w="100%" h="100%">
      <Flex
        justify="center"
        align="center"
        maxW={{
          base: "350px",
          md: "450px",
          lg: "500px",
          xl: "650px",
          "2xl": "750px",
        }}
        h="500px"
        flexDir="column"
        gap="50px"
      >
        <Image src={Error404} w="100%" h="auto" />
        <VStack
          spacing={3}
          fontSize={{
            base: "14px",
            md: "18px",
            lg: "22px",
            xl: "30px",
            "2xl": "36px",
          }}
          lineHeight={{
            base: "17px",
            md: "20px",
            lg: "26px",
            xl: "36px",
            "2xl": "42px",
          }}
        >
          <Text fontWeight="600">
            This is not the webpage you are looking for.
          </Text>
          <Link as={RouteLink} to="/">
            <Text decoration="underline" fontWeight="400" color="#3B5D7C">
              Click here to go back to dashboard
            </Text>
          </Link>
        </VStack>
      </Flex>
    </Center>
  );
};

export default PageNotFound;

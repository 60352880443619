import { configureStore } from "@reduxjs/toolkit";
import newStudyReducer from "./reducers/newStudyReducer";
import medicalApiSlice from "./api/medicalApi";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import authReducer from "./reducers/authReducer";

const store = configureStore({
  reducer: {
    newStudyState: newStudyReducer,
    authState: authReducer,
    [medicalApiSlice.reducerPath]: medicalApiSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(medicalApiSlice.middleware),
});

setupListeners(store.dispatch);

export default store;

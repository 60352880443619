import React, { useState } from "react";
import {
  Flex,
  VStack,
  useMediaQuery,
  Link,
  IconButton,
} from "@chakra-ui/react";
import { Link as RouteLink, NavLink, useLocation } from "react-router-dom";
import { AiFillHome, AiOutlineHome } from "react-icons/ai";
import { MdFileCopy, MdOutlineFileCopy } from "react-icons/md";
import { useGetUserInfoQuery } from "../state/api/medicalApi";
import useUserAuthentication from "../hooks/useUserAuthentication";

const Sidebar = ({ onOpen }) => {
  const isUserAuthenticated = useUserAuthentication();
  const [ifWidthLessthan1920] = useMediaQuery("(max-width:1920px)");
  const { data: userInfo } = useGetUserInfoQuery();
  const location = useLocation();
  const [hoverHome, setHoverHome] = useState(false);
  const [hoverStudies, setHoverStudies] = useState(false);

  return (
    <Flex
      background="light.800"
      w={ifWidthLessthan1920 ? "48px" : "2.5vw"}
      h={ifWidthLessthan1920 ? "calc(100vh - 44px)" : "95.1852vh"}
      boxShadow="0px 0px 2px 2px rgba(176, 200, 214, 0.1)"
      borderRight="1px solid rgba(176, 200, 214, 0.15)"
      justifyContent="center"
      minW={ifWidthLessthan1920 ? "48px" : "2.5vw"}
    >
      <VStack mt="2vh" spacing="2.5vh">
        <Link
          as={NavLink}
          end
          to="/"
          boxShadow={
            location.pathname === "/dashboard/PI" ||
            location.pathname === "/dashboard/Pathologist" ||
            location.pathname === "/dashboard/Admin" ||
            location.pathname === "/admin/add-user"
              ? "-2px 0px 0px 0px rgba(59, 93, 124, 1)"
              : ""
          }
          onMouseEnter={() => setHoverHome(true)}
          onMouseLeave={() => setHoverHome(false)}
        >
          {location.pathname === "/dashboard/PI" ||
          location.pathname === "/dashboard/Pathologist" ||
          location.pathname === "/dashboard/Admin" ||
          location.pathname === "/admin/add-user" ? (
            <IconButton
              icon={<AiFillHome size={20} color="#3B5D7C" />}
              _focus={{
                background: "none",
                color: "#3B5D7C",
              }}
              _hover={{
                background: "none",
                color: "#3B5D7C",
              }}
              borderRadius="0"
              background="none"
            />
          ) : (
            <IconButton
              icon={
                hoverHome ? (
                  <AiFillHome size={20} color="#3B5D7C" />
                ) : (
                  <AiOutlineHome size={20} />
                )
              }
              _focus={{
                background: "none",
                color: "#3B5D7C",
              }}
              _hover={{
                background: "none",
                color: "#3B5D7C",
              }}
              borderRadius="0"
              background="none"
            />
          )}
        </Link>
        {userInfo?.data[0]?.role === "PI" && (
          <Link
            as={NavLink}
            end
            to="/studies"
            onMouseEnter={() => setHoverStudies(true)}
            onMouseLeave={() => setHoverStudies(false)}
            boxShadow={
              location.pathname === "/studies" ||
              location.pathname === "/create-study"
                ? "-2px 0px 0px 0px rgba(59, 93, 124, 1)"
                : ""
            }
          >
            {location.pathname === "/studies" ||
            location.pathname === "/create-study" ? (
              <IconButton
                icon={<MdFileCopy size={20} color="#3B5D7C" />}
                _focus={{
                  background: "none",
                  color: "#3B5D7C",
                }}
                _hover={{
                  background: "none",
                  color: "#3B5D7C",
                }}
                borderRadius="0"
                background="none"
              />
            ) : (
              <IconButton
                icon={
                  hoverStudies ? (
                    <MdFileCopy size={20} color="#3B5D7C" />
                  ) : (
                    <MdOutlineFileCopy size={20} />
                  )
                }
                _focus={{
                  background: "none",
                  color: "#3B5D7C",
                }}
                _hover={{
                  background: "none",
                  color: "#3B5D7C",
                }}
                borderRadius="0"
                background="none"
              />
            )}
          </Link>
        )}
        {/* <Link
          as={(props) => (
            <NavLink
              {...props}
              style={({ isActive }) => {
                return {
                  boxShadow: isActive
                    ? "-2px 0px 0px 0px rgba(59, 93, 124, 1)"
                    : "",
                };
              }}
            />
          )}
          to="/database"
        > */}
        {/* <IconButton
          icon={
            <HiOutlineDatabase _hover={{ color: "rgba(59, 93, 124, 1)" }} />
          }
          _focus={{
            background: "none",
            color: "rgba(59, 93, 124, 1)",
          }}
          _hover={{
            background: "none",
            color: "rgba(59, 93, 124, 1)",
          }}
          borderRadius="0"
          background="none"
        /> */}
        {/* </Link> */}
      </VStack>
    </Flex>
  );
};

export default Sidebar;

import React from "react";
import { Flex, HStack, VStack, Box, useMediaQuery } from "@chakra-ui/react";
import RecruitmentStatusComponent from "./OverViewScreenSubComponent/RecruitmentStatusComponent";
import ReadersComponent from "./OverViewScreenSubComponent/ReadersComponent";
import RecentSlidesComponent from "./OverViewScreenSubComponent/RecentSlidesComponent";
import StudyDetailComponent from "./OverViewScreenSubComponent/StudyDetailComponent";
import StudyTimelineComponent from "./OverViewScreenSubComponent/StudyTimelineComponent";

const OverViewScreen = () => {
  const [ifWidthLargethanthan1800] = useMediaQuery("(min-width:1800px)");
  return (
    <Flex h="100%" w="100%" mt="10px">
      <HStack h="100%" w="100%" justifyContent="space-around">
        <VStack w="45%" h="100%">
          <Flex border="1px solid black" h="37vh" w="43vw">
            <StudyDetailComponent />
          </Flex>
          <Box border="1px solid black" h="37vh" w="43vw">
            <RecentSlidesComponent />
          </Box>
        </VStack>
        <VStack w="35%" h="100%">
          <Flex border="1px solid black" h="37vh" w="36.4vw">
            <RecruitmentStatusComponent />
          </Flex>
          <Box border="1px solid black" h="37vh" w="36.5vw">
            <StudyTimelineComponent />
          </Box>
        </VStack>
        <VStack w="20%" h="100%">
          <Box border="1px solid black" h="75vh" w="18.5vw">
            <ReadersComponent />
          </Box>
        </VStack>
      </HStack>
    </Flex>
  );
};

export default OverViewScreen;

import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  questions: { HAndE: [], Trichrome: [] },
};
const newStudySlice = createSlice({
  name: "newStudy",
  initialState: initialState,
  reducers: {
    addQuestions: (state, action) => {
      state.questions[action.payload.type].push(action.payload.data);
    },
    addLibraryQuestions: (state, action) => {
      state.questions[action.payload.type] = action.payload.data;
    },
    resetQuestions: (state) => {
      state.questions = { HAndE: [], Trichrome: [] };
    },
    linkQuestion: (state, action) => {
      state.questions[action.payload.type][
        action.payload.index
      ].conditionQuestion.push(action.payload.questionData);
      const conditionQuestions = state.questions[action.payload.type][
        action.payload.index
      ].conditionQuestion?.map(JSON.stringify);
      state.questions[action.payload.type][
        action.payload.index
      ].conditionQuestion = Array.from(new Set(conditionQuestions)).map(
        JSON.parse
      );
    },
    resetQuestionLink: (state, action) => {
      state.questions[action.payload.type][
        action.payload.index
      ].conditionQuestion = [];
    },
  },
});

export const {
  addQuestions,
  addLibraryQuestions,
  resetQuestions,
  linkQuestion,
  resetQuestionLink,
} = newStudySlice.actions;

export default newStudySlice.reducer;

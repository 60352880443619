import {
  Box,
  Flex,
  Text,
  useMediaQuery,
  HStack,
  Button,
} from "@chakra-ui/react";
import moment from "moment";
import React, { useState, useCallback, useEffect, useRef } from "react";
import { HiOutlinePlusCircle } from "react-icons/hi";
import Loading from "../Loading/loading";
import MemberTable from "./MemberTable";

function TotalMember({ isLoading, roleData, filterData }) {
  const [ifWidthLessthan1600] = useMediaQuery("(max-width:1600px)");
  const [ifWidthLessthan1920] = useMediaQuery("(max-width:1920px)");
  const [index, setIndex] = useState(0);

  return isLoading ? (
    <Loading />
  ) : (
    <Box
      border="1px solid #E8E8EB"
      borderRadius="10px"
      w="100%"
      mr="1%"
      ml="1%"
      mt="4vmin"
    >
      <HStack
        w="100%"
        justifyContent="space-between"
        p={ifWidthLessthan1600 ? "12px 12px 16px 0" : "16px 16px 16px 0"}
        h={ifWidthLessthan1600 ? "55px" : "70px"}
      >
        <HStack>
          <Flex w="80px" ml="8px">
            <Text
              fontSize={ifWidthLessthan1600 ? "14px" : "21px"}
              fontWeight={600}
              cursor="pointer"
              color={index === 0 ? "light.1006" : "#000"}
              pb="8px"
              pt="8px"
              borderBottom={index === 0 && "3px solid #1B75BC"}
              w="100%"
              textAlign="center"
              onClick={() => setIndex(0)}
            >
              All
            </Text>
          </Flex>
        </HStack>
        <HStack>
          <Button
            bgColor="light.100"
            border="1px solid #E8E8E8"
            borderRadius="5px"
            focusBorderColor="transparent"
            _focus={{ outline: "none" }}
            onClick={() => {
              roleData?.length > 0
                ? window.location.replace("/admin/add-member")
                : window.location.replace("/admin/add-role");
            }}
          >
            <HiOutlinePlusCircle />
            <Text
              fontSize={ifWidthLessthan1920 ? "14px" : "0.729vw"}
              fontWeight="500"
              ml="5px"
            >
              Add Member
            </Text>
          </Button>
        </HStack>
      </HStack>
      <MemberTable filterData={filterData} />
    </Box>
  );
}

export default TotalMember;

import React, { useState } from "react";
import {
  Flex,
  Text,
  VStack,
  Button,
  HStack,
  useMediaQuery,
  useToast,
  Spinner,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import generator from "generate-password";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import {
  useGetUserInfoQuery,
  useGetAllClinicalRoleInformationQuery,
  useLazyCreateClinicalUserQuery,
} from "../../../state/api/medicalApi";
import OrganisationDetails from "./OrganisationDetails";
import PersonalDetails from "./PersonalDetails";
import UserCreatedConfirmation from "./UserCreatedConfirmation";
import Loading from "../../Loading/loading";
import Environment from "../../../environment";

const AddMemberDashboard = () => {
  const [dob, setDob] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const user = useAuth0();
  const { data: userInfo, isLoading } = useGetUserInfoQuery(
    {
      subClaim: user?.sub,
    },
    { skip: !user }
  );
  const { data: rolesInfo } = useGetAllClinicalRoleInformationQuery(
    {
      organization_id: userInfo?.data[0].organizationId,
    },
    { skip: !userInfo }
  );
  const [selectedRole, setSelectedRole] = useState("");
  const toast = useToast();
  const navigate = useNavigate();
  const [index, setIndex] = useState(1);
  const [userData, setUserData] = useState(null);
  const [isBlurState, setIsBlurState] = useState(false);
  const [addUser] = useLazyCreateClinicalUserQuery();
  const generatePassword = () => {
    const randomPassword = generator.generate({
      length: 12,
      strict: true,
      symbols: true,
      numbers: true,
      exclude: '()+_-=}{[]|:;"/?.><,`~',
    });
    return randomPassword;
  };
  const [isUserCreated, setIsUserCreated] = useState(false);
  const [inputData, setInputData] = useState({
    firstName: "",
    lastName: "",
    emailAddress: "",
    role_id: "",
    yearOfExperience: "",
    highestDegree: "",
    Designation: "",
    Institute: "",
    medicalRegistrationNumber: "",
    gender: "",
    Department: "",
    password: generatePassword(),
    address: "",
    signature: "",
    profilePic: "",
    medicalRegistrationNumber: "",
    Department: "",
  });

  const handleSubmit = async () => {
    setIsBlurState(true);
    const currentDate = new Date();
    const formattedDate = currentDate.toISOString();

    try {
      const detailsForm = new FormData();
      detailsForm.append("firstName", inputData.firstName.trim());
      detailsForm.append("lastName", inputData.lastName.trim());
      detailsForm.append(
        "emailAddress",
        inputData.emailAddress.trim().toLowerCase()
      );
      detailsForm.append("phoneNumber", phoneNumber);
      detailsForm.append("role_id", inputData.role_id);
      detailsForm.append("yearOfExperience", inputData.yearOfExperience);
      detailsForm.append("dateOfBirth", dob);
      detailsForm.append("highestDegree", inputData.highestDegree);
      detailsForm.append("gender", inputData.gender);
      detailsForm.append("Designation", inputData.Designation);
      detailsForm.append("Institute", inputData.Institute);
      detailsForm.append(
        "medicalRegistrationNumber",
        inputData.medicalRegistrationNumber
      );
      detailsForm.append("Department", inputData.Department);
      detailsForm.append("password", inputData.password.trimEnd());
      detailsForm.append("address", inputData.address);
      detailsForm.append("yearOfRegistration", inputData.yearOfRegistration);
      detailsForm.append("signatureFile", inputData.signature);
      const resp = await addUser(detailsForm);
      setInputData({
        firstName: "",
        lastName: "",
        emailAddress: "",
        phoneNumber: "",
        role_id: "",
        yearOfExperience: "",
        highestDegree: "",
        Designation: "",
        Institute: "",
        medicalRegistrationNumber: "",
        gender: "",
        Department: "",
        password: generatePassword(),
        address: "",
        signature: "",
        profilePic: "",
      });
      setSelectedRole("");
      setDob("");
      setPhoneNumber("");
      setIsBlurState(false);
      if (resp?.error?.status === 400) {
        toast({
          title: "Error",
          description: `Failed to add ${inputData.firstName} ${inputData.lastName}, try again!`,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Status",
          description: `${inputData.firstName} ${inputData.lastName} has been added to your organisation`,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      }
      navigate("/dashboard/Admin");
    } catch (err) {
      console.error("Registration failed, Try again: ", err);
      toast({
        title: "Error",
        description: `Failed to add ${inputData.firstName} ${inputData.lastName}, try again!`,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };
  console.log("data", inputData);

  const [ifWidthLessthan1920] = useMediaQuery("(max-width:1920px)");
  return (
    <Flex w="100%" direction="column">
      <Flex w="100%">
        <Flex minWidth="768px">
          <Flex
            h="92vmin"
            w="100vw"
            background="light.800"
            zIndex="100"
            alignItems="flex-end"
            justifyContent="center"
          >
            <Flex
              h="90vmin"
              pos="absolute"
              top="8vmin"
              bottom={0}
              w="75%"
              bgColor="#fff"
              p="3% 6% 0 6%"
              direction="column"
              overflowY="scroll"
              css={{
                "&::-webkit-scrollbar": {
                  width: "5px",
                },
                "&::-webkit-scrollbar-track": {
                  width: "5px",
                  marginTop: "58px",
                },
                "&::-webkit-scrollbar-thumb": {
                  background: "#C4C4C4",
                },
              }}
            >
              {isBlurState ? <Loading /> : null}
              {isUserCreated ? (
                <UserCreatedConfirmation
                  userData={userData}
                  setIsUserCreated={setIsUserCreated}
                  setIndex={setIndex}
                  role={selectedRole}
                />
              ) : (
                <>
                  <Flex w="100%" justifyContent="space-between">
                    <VStack alignItems="flex-start">
                      <Text
                        fontSize={ifWidthLessthan1920 ? "16px" : "20px"}
                        color="light.1006"
                        fontWeight={600}
                      >
                        Add User
                      </Text>
                      <Text fontSize={ifWidthLessthan1920 ? "12px" : "14px"}>
                        Fill details below to create a new profile in your
                        organization
                      </Text>
                    </VStack>
                    <HStack color="light.1006">
                      <Button
                        w="150px"
                        bgColor="inherit"
                        border="1px solid rgba(27, 117, 188, 0.25)"
                        borderRadius="10px"
                        h="37px"
                        onClick={() => navigate("/dashboard/admin")}
                      >
                        Cancel
                      </Button>
                      {index === 2 ? (
                        <Button
                          w="150px"
                          borderRadius="10px"
                          h="37px"
                          bgColor="rgba(27, 117, 188, 0.25)"
                          disabled={
                            !inputData.firstName ||
                            !inputData.lastName ||
                            !inputData.emailAddress ||
                            !selectedRole.length === 0 ||
                            !inputData.gender ||
                            !inputData.Institute ||
                            !inputData.password ||
                            !inputData.medicalRegistrationNumber
                          }
                          onClick={handleSubmit}
                        >
                          Save
                        </Button>
                      ) : (
                        <Button
                          w="150px"
                          borderRadius="10px"
                          h="37px"
                          bgColor="rgba(27, 117, 188, 0.25)"
                          onClick={() => {
                            setIndex(index + 1);
                          }}
                        >
                          Next
                        </Button>
                      )}
                    </HStack>
                  </Flex>

                  {index === 1 ? (
                    <PersonalDetails
                      index={index}
                      setIndex={setIndex}
                      setInputData={setInputData}
                      inputData={inputData}
                      rolesInfo={rolesInfo?.data}
                      selectedRole={selectedRole}
                      setSelectedRole={setSelectedRole}
                      setDob={setDob}
                      dob={dob}
                      setPhoneNumber={setPhoneNumber}
                      phoneNumber={phoneNumber}
                    />
                  ) : (
                    <OrganisationDetails
                      index={index}
                      setIndex={setIndex}
                      setInputData={setInputData}
                      inputData={inputData}
                    />
                  )}
                </>
              )}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default AddMemberDashboard;

import React, { useState } from "react";
import { Flex, HStack, VStack, Box } from "@chakra-ui/react";
import StudyDetailComponent from "./OverViewScreenSubComponent/StudyDetailComponent";
import StudyTimelineComponent from "./OverViewScreenSubComponent/StudyTimelineComponent";
import ClinicalOperationManagerSlideChartScreenComponent from "./OverViewScreenSubComponent/ClinicalOperationManagerSlideChartScreenComponent";

const OverViewScreen = ({
  studyId,
  userInfo,
  socket,
  studyData,
  studyInfo,
  permission,
}) => {
  console.log("permission", permission);
  return (
    <Flex
      h="100%"
      w="100%"
      pt="10px"
      alignItems="center"
      bgColor="rgb(244,244,244)"
    >
      <HStack h="100%" w="100%" justifyContent="center">
        <VStack w="45%" h="100%">
          <Flex
            borderRadius="5px"
            h="40vh"
            w="43vw"
            bgColor="rgba(255, 255, 255, 1)"
          >
            <StudyDetailComponent
              studyId={studyId}
              userInfo={userInfo}
              studyInfo={studyInfo}
              permission={permission}
            />
          </Flex>
          <Box
            h="34vh"
            w="43vw"
            bgColor="rgba(255, 255, 255, 1)"
            borderRadius="5px"
          >
            <StudyTimelineComponent socket={socket} userInfo={userInfo} />
          </Box>
        </VStack>
        <VStack w="35%" h="100%">
          <Flex
            h="75vh"
            w="36.4vw"
            bgColor="rgba(255, 255, 255, 1)"
            borderRadius="5px"
          >
            <ClinicalOperationManagerSlideChartScreenComponent
              socket={socket}
              userInfo={userInfo}
              particpantsTobeRecruited={studyData?.particpantsTobeRecruited}
            />
          </Flex>
        </VStack>
      </HStack>
    </Flex>
  );
};

export default OverViewScreen;

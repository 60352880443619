import React from "react";
import { Flex, HStack, Text, VStack, Avatar } from "@chakra-ui/react";
import moment from "moment";
function ArchiveStudies({
  studies,
  setCaseId,
  caseId,
  setStudyName,
  searchStudy,
}) {
  const handleStudyData = (study) => {
    setCaseId(study?.caseId);
    setStudyName(study?.study_title);
  };
  return (
    <Flex
      direction="column"
      flex="1"
      h="82vh"
      overflowX="hidden"
      css={{
        "&::-webkit-scrollbar": {
          width: "4px",
        },
        "&::-webkit-scrollbar-track": {
          width: "6px",
        },
        "&::-webkit-scrollbar-thumb": {
          background: "#C4C4C4",
        },
      }}
      mt="1px"
    >
      <>
        {studies
          ?.filter((studiesInfo) => {
            if (searchStudy === "") {
              return studiesInfo;
            }
            if (
              studiesInfo?.study_title
                ?.toLowerCase()
                .includes(searchStudy.toLowerCase())
            ) {
              return studies;
            }
            return null;
          })
          .map((study) => {
            return (
              study?.isArchive && (
                <Flex
                  key={study?.caseId}
                  h="7.3vh"
                  minH="50px"
                  border="none"
                  w="100%"
                  _hover={{
                    bgColor: "light.900",
                    cursor: "pointer",
                    transition: "0.2s",
                  }}
                  bg={caseId === study?.caseId && "light.900"}
                  onClick={() => handleStudyData(study)}
                >
                  <HStack marginStart="0.8vw" border="none" w="100%">
                    <Avatar bg="light.700" w={10} h={10} color="#fff" />
                    <VStack alignItems="flex-start" spacing={3} w="75%">
                      <Flex w="100%" pt="0">
                        <Text fontSize="14px" fontWeight="500" isTruncated>
                          {study?.study_title}
                        </Text>
                      </Flex>
                      <Flex w="100%" pt="0" mt="-0rem !important">
                        <Text fontWeight="500" isTruncated fontSize="14px">
                          {moment(study?.created_at || study?.createdAt).format(
                            " DD/MM/YYYY"
                          )}
                        </Text>
                        <Text fontWeight="500" isTruncated></Text>
                      </Flex>
                    </VStack>
                  </HStack>
                </Flex>
              )
            );
          })}
      </>
    </Flex>
  );
}

export default ArchiveStudies;

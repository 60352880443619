import {
  Flex,
  HStack,
  Input,
  Select,
  Switch,
  Text,
  VStack,
  Radio,
  RadioGroup,
  Stack,
  Image,
  useMediaQuery,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { HiOutlineUser } from "react-icons/hi";
import icon1 from "../../../static/Images/icon1.svg";
import icon2 from "../../../static/Images/icon2.svg";
import icon3 from "../../../static/Images/icon3.svg";
import icon4 from "../../../static/Images/icon4.svg";

const CustomSwitch = ({ name, checked, onChange }) => {
  const switchStyle = {
    position: "relative",
    display: "inline-block",
    width: "40px",
    height: "16px",
    backgroundColor: checked ? "#3EBBFF" : "#ccc",
    borderRadius: "10px",
    cursor: "pointer",
    transition: "background-color 0.4s",
  };

  const thumbStyle = {
    position: "absolute",
    content: "",
    height: "10px",
    width: "10px",
    left: checked ? "25px" : "4px",
    top: "2.7px",
    backgroundColor: "white",
    transition: "left 0.4s",
    borderRadius: "50%",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
  };

  return (
    <label style={switchStyle}>
      <input
        type="checkbox"
        name={name}
        checked={checked}
        onChange={() => onChange(name, !checked)}
        style={{ display: "none" }}
      />
      <span style={thumbStyle}></span>
    </label>
  );
};

const RoleConfiguration = ({ rolesData, setRolesData }) => {
  const [ifWidthLessthan1500] = useMediaQuery("(max-width:1500px)");
  const [colorIconIndex, setColorIconIndex] = useState(1);
  const colorIcons = [icon1, icon2, icon3, icon4];
  const [report, setReport] = useState("submit");
  const [role, setRole] = useState("pi");
  const handleInput = (e) => {
    setRolesData({
      ...rolesData,
      [e.target.name]: e.target.value,
      role: role,
    });
  };
  const handleSwitch = (name, checked) => {
    setRolesData({
      ...rolesData,
      [name]: checked,
      role: role,
    });
  };

  const handleColorIconChange = (e) => {
    setColorIconIndex((colorIconIndex + 1) % colorIcons.length);
    setRolesData({
      ...rolesData,
      role: role,
      colorIconIndex: colorIconIndex,
    });
  };
  const handleChangeRole = (value) => {
    setRole(value);
    setRolesData({
      ...rolesData,
      role: role,
      colorIconIndex: colorIconIndex,
    });
  };

  useEffect(() => {
    setRolesData({
      roleTitle: "",
      studyCreate: false,
      studyEdit: false,
      studyDelete: false,
      slideUpload: false,
      slideEdit: false,
      slideDelete: false,
      slideAssign: false,
      viewReport: false,
      addQuestions: false,
      reportEdit: false,
      reportDownload: false,
      reportSubmit: false,
      downloadAbility: false,
      slideAnnotation: false,
      role: role,
      colorIconIndex: 0,
    });
  }, [role]);

  return (
    <Flex mt="2vh" fontSize="14px">
      <Flex w="50%" direction="column">
        <Text fontSize="18px" mb="2vh" mt="25px">
          Details
        </Text>
        <VStack alignItems="start" spacing={1} mb="2vh">
          <Text fontSize="14px" mb="1vh">
            Role Title*
          </Text>
          <Input
            w={ifWidthLessthan1500 ? "90%" : "80%"}
            placeholder="Enter role here"
            name="roleTitle"
            onChange={handleInput}
            value={rolesData?.roleTitle}
            mb="1vh"
          />
          <RadioGroup
            value={rolesData?.role}
            onChange={(value) => handleChangeRole(value)}
            mb="2vh"
          >
            <Stack direction="row" gridGap="25">
              <Radio value="pi">
                <Text fontSize="14px">COM</Text>
              </Radio>
              <Radio value="reader">
                <Text fontSize="14px">Reader</Text>
              </Radio>
            </Stack>
          </RadioGroup>
        </VStack>
        <Text fontSize="18px" mb="1vh">
          Permissions
        </Text>
        {role !== "reader" ? (
          <Flex
            borderRadius="5"
            border="1px solid #DEDEDE"
            minH="120px"
            w={ifWidthLessthan1500 ? "90%" : "80%"}
            p="10px 18px"
            direction="column"
            mb="2vh"
          >
            <Text mb="5px">Study</Text>
            <Flex ml="5%" direction="column" gridGap="1.5">
              <HStack justifyContent="space-between" w="100%">
                <Text>Create</Text>
                <CustomSwitch
                  name="studyCreate"
                  checked={rolesData?.studyCreate}
                  onChange={handleSwitch}
                />
              </HStack>
              <HStack justifyContent="space-between" w="100%">
                <Text>Edit</Text>
                <CustomSwitch
                  name="studyEdit"
                  checked={rolesData?.studyEdit}
                  onChange={handleSwitch}
                />
              </HStack>
              <HStack justifyContent="space-between" w="100%">
                <Text>Delete</Text>
                <CustomSwitch
                  name="studyDelete"
                  checked={rolesData?.studyDelete}
                  onChange={handleSwitch}
                />
              </HStack>
            </Flex>
          </Flex>
        ) : (
          ""
        )}
        {role !== "reader" ? (
          <Flex
            borderRadius="5"
            border="1px solid #DEDEDE"
            minH="130px"
            w={ifWidthLessthan1500 ? "90%" : "80%"}
            p="10px 18px"
            direction="column"
            mb="2vh"
          >
            <Text mb="5px">Slide</Text>
            <Flex ml="5%" direction="column" gridGap="1.5">
              <HStack justifyContent="space-between" w="100%" h="100%" pb="3px">
                <Text>Upload</Text>
                <CustomSwitch
                  name="slideUpload"
                  checked={rolesData?.slideUpload}
                  onChange={handleSwitch}
                />
              </HStack>
              <HStack justifyContent="space-between" w="100%" h="100%" pb="3px">
                <Text>Edit</Text>
                <CustomSwitch
                  name="slideEdit"
                  checked={rolesData?.slideEdit}
                  onChange={handleSwitch}
                />
              </HStack>
              <HStack justifyContent="space-between" w="100%" h="100%" pb="3px">
                <Text>Delete</Text>
                <CustomSwitch
                  name="slideDelete"
                  checked={rolesData?.slideDelete}
                  onChange={handleSwitch}
                />
              </HStack>
              <HStack justifyContent="space-between" w="100%" h="100%" pb="3px">
                <Text>Assign</Text>
                <CustomSwitch
                  name="slideAssign"
                  checked={rolesData?.slideAssign}
                  onChange={handleSwitch}
                />
              </HStack>
            </Flex>
          </Flex>
        ) : (
          ""
        )}
        {role !== "reader" ? (
          <Flex
            borderRadius="5"
            border="1px solid #DEDEDE"
            minH="40px"
            w={ifWidthLessthan1500 ? "90%" : "80%"}
            p="10px 18px"
            direction="column"
            mb="2vh"
          >
            <Flex ml="5%" direction="column" gridGap="1.5">
              <HStack justifyContent="space-between" w="100%" h="100%" pb="3px">
                <Text>View Report</Text>
                <CustomSwitch
                  name="viewReport"
                  checked={rolesData?.viewReport}
                  onChange={handleSwitch}
                />
              </HStack>
            </Flex>
          </Flex>
        ) : (
          ""
        )}
        {role !== "reader" ? (
          <Flex
            borderRadius="5"
            border="1px solid #DEDEDE"
            minH="40px"
            w={ifWidthLessthan1500 ? "90%" : "80%"}
            p="10px 18px"
            direction="column"
            mb="2vh"
          >
            <Flex ml="5%" direction="column" gridGap="1.5">
              <HStack justifyContent="space-between" w="100%" h="100%" pb="3px">
                <Text>Add Questions</Text>
                <CustomSwitch
                  name="addQuestions"
                  checked={rolesData?.addQuestions}
                  onChange={handleSwitch}
                />
              </HStack>
            </Flex>
          </Flex>
        ) : (
          ""
        )}
        {role !== "reader" ? (
          <Flex
            borderRadius="5"
            border="1px solid #DEDEDE"
            minH="40px"
            w={ifWidthLessthan1500 ? "90%" : "80%"}
            p="10px 18px"
            direction="column"
            mb="2vh"
          >
            <Flex ml="5%" direction="column" gridGap="1.5">
              <HStack justifyContent="space-between" w="100%" h="100%" pb="3px">
                <Text>Slide Annotation</Text>
                <CustomSwitch
                  name="slideAnnotation"
                  checked={rolesData?.slideAnnotation}
                  onChange={handleSwitch}
                />
              </HStack>
            </Flex>
          </Flex>
        ) : (
          ""
        )}
        {role === "reader" && (
          <Flex direction="column" h="48vh">
            <Flex
              borderRadius="5"
              border="1px solid #DEDEDE"
              minH="20px"
              w={ifWidthLessthan1500 ? "90%" : "80%"}
              pl="18px"
              pr="18px"
              direction="column"
              mb="2vh"
            >
              <Flex ml="5%" direction="column" gridGap="1.5">
                <HStack
                  justifyContent="space-between"
                  w="100%"
                  h="100%"
                  pb="3px"
                >
                  <Text>Report</Text>
                  <Select
                    variant="unstyled"
                    w="45%"
                    fontSize="14px"
                    onChange={(e) => setReport(e.target.value)}
                  >
                    <option value="edit">Can Edit</option>
                    <option value="view">Can View</option>
                    <option value="download">Can Download</option>
                    <option value="submit">Can Submit</option>
                  </Select>
                  {/* {report === "edit" ? (
                    <CustomSwitch
                      name="reportEdit"
                      checked={rolesData.reportEdit}
                      onChange={handleSwitch}
                    />
                  ) : report === "view" ? (
                    <CustomSwitch
                      name="viewReport"
                      checked={rolesData.viewReport}
                      onChange={handleSwitch}
                    />
                  ) : report === "download" ? (
                    <CustomSwitch
                      name="reportDownload"
                      checked={rolesData.reportDownload}
                      onChange={handleSwitch}
                    />
                  ) : report === "submit" ? (
                    <CustomSwitch
                      name="reportSubmit"
                      checked={rolesData.reportSubmit}
                      onChange={handleSwitch}
                    />
                  ) : null} */}
                  {report === "edit" && (
                    <CustomSwitch
                      name="reportEdit"
                      checked={rolesData?.reportEdit}
                      onChange={handleSwitch}
                    />
                  )}
                  {report === "view" && (
                    <CustomSwitch
                      name="viewReport"
                      checked={rolesData?.viewReport}
                      onChange={handleSwitch}
                    />
                  )}
                  {report === "download" && (
                    <CustomSwitch
                      name="reportDownload"
                      checked={rolesData?.reportDownload}
                      onChange={handleSwitch}
                    />
                  )}
                  {report === "submit" && (
                    <CustomSwitch
                      name="reportSubmit"
                      checked={rolesData?.reportSubmit}
                      onChange={handleSwitch}
                    />
                  )}
                </HStack>
              </Flex>
            </Flex>
            <Flex
              borderRadius="5"
              border="1px solid #DEDEDE"
              minH="40px"
              w={ifWidthLessthan1500 ? "90%" : "80%"}
              p="10px 18px"
              direction="column"
              mb="2vh"
            >
              <Flex ml="5%" direction="column" gridGap="1.5">
                <HStack
                  justifyContent="space-between"
                  w="100%"
                  h="100%"
                  pb="3px"
                >
                  <Text>Download Ability</Text>
                  <CustomSwitch
                    name="downloadAbility"
                    checked={rolesData?.downloadAbility}
                    onChange={handleSwitch}
                  />
                </HStack>
              </Flex>
            </Flex>
            <Flex
              borderRadius="5"
              border="1px solid #DEDEDE"
              minH="40px"
              w={ifWidthLessthan1500 ? "90%" : "80%"}
              p="10px 18px"
              direction="column"
              mb="2vh"
            >
              <Flex ml="5%" direction="column" gridGap="1.5">
                <HStack
                  justifyContent="space-between"
                  w="100%"
                  h="100%"
                  pb="3px"
                >
                  <Text>Add Questions</Text>
                  <CustomSwitch
                    name="addQuestions"
                    checked={rolesData?.addQuestions}
                    onChange={handleSwitch}
                  />
                </HStack>
              </Flex>
            </Flex>
            <Flex
              borderRadius="5"
              border="1px solid #DEDEDE"
              minH="40px"
              w={ifWidthLessthan1500 ? "90%" : "80%"}
              p="10px 18px"
              direction="column"
              mb="2vh"
            >
              <Flex ml="5%" direction="column" gridGap="1.5">
                <HStack
                  justifyContent="space-between"
                  w="100%"
                  h="100%"
                  pb="3px"
                >
                  <Text>Add Annotations</Text>
                  <CustomSwitch
                    name="slideAnnotation"
                    checked={rolesData?.slideAnnotation}
                    onChange={handleSwitch}
                  />
                </HStack>
              </Flex>
            </Flex>
          </Flex>
        )}
      </Flex>
      <Flex w="50%" pl="10%" direction="column" pt="5%">
        <Flex w="90%" h="150px" mb="2vh">
          <Flex
            w="160px"
            h="100%"
            border="1px solid #DEDEDE"
            borderRadius="5px"
            alignItems="center"
            justifyContent="center"
            onClick={handleColorIconChange}
          >
            <Image
              src={colorIcons[rolesData?.colorIconIndex]}
              w="120px"
              h="120px"
            />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default RoleConfiguration;

import React, { useState } from "react";
import { useSelector } from "react-redux";
import "../../styles/newproject.css";
import { Box, HStack, Flex } from "@chakra-ui/react";
import DummyQuestionnaire from "./dummyQuestionnaire";
import CustomQuestionnaire from "./customQuestionnaire";

const CreateQuestionnaire = ({ activeButton }) => {
  const [questionState, setQuestionState] = useState("Multiple Choice");
  const handleState = () => {
    setQuestionState(questionState);
  };
  const { questions } = useSelector((state) => state.newStudyState);
  const [linkState, setLinkState] = useState(false);
  return (
    <Flex flexDirection="column" bg="light.100" w="100%" pb="1.5vh">
      <HStack alignItems="center">
        <CustomQuestionnaire
          handleCustomQuestionnaire={handleState}
          setQuestionState={setQuestionState}
          questionState={questionState}
          linkState={linkState}
          setLinkState={setLinkState}
          activeButton={activeButton}
        />
      </HStack>
      <Box w="100%">
        <DummyQuestionnaire
          direction="row"
          questions={questions}
          linkState={linkState}
          activeButton={activeButton}
        />
      </Box>
    </Flex>
  );
};

export default CreateQuestionnaire;

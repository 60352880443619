import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Text,
  VStack,
  HStack,
  Icon,
  useMediaQuery,
  Button,
  Flex,
  Avatar,
} from "@chakra-ui/react";
import {
  useGetAllSlidesReaderQuery,
  useGetAllReaderInfoQuery,
  useLazyGetAllAssignSlidesReaderQuery,
  useGetAllMembersbasedonRolesQuery,
} from "../../../../../state/api/medicalApi";

function SlideReaderSelectionModal({
  isOpenReaderSelection,
  setOpenReaderSelection,
  selectedRows,
  select,
  userInfo,
  setIsSlideModalOpen,
  setConfirmMessage,
}) {
  const [ifSmallScreen] = useMediaQuery("(max-width:1920px)");
  const [selectedItems, setSelectedItems] = useState([]);
  const headerStyle = {
    fontSize: ifSmallScreen ? "14px" : "16px",
    opacity: "0.6",
    fontWeight: "600",
  };
  const data = [
    { name: "siddhesh", uid: "fdfdf23232" },
    { name: "dfgfgiddhesh", uid: "sdsdfd232" },
    { name: "sisdsdsesh", uid: "bvbvfdf2dfdfd232" },
  ];
  const { data: slideData } = useGetAllSlidesReaderQuery(
    {
      slideIds: selectedRows,
    },
    { skip: !isOpenReaderSelection }
  );

  const { data: membersInfo } = useGetAllMembersbasedonRolesQuery(
    {
      organizationId: userInfo?.data[0].organizationId,
      roleName: "Pathologist",
    },
    {
      skip: !isOpenReaderSelection,
    }
  );
  const [readerAssignInfo] = useLazyGetAllAssignSlidesReaderQuery();

  console.log(selectedItems);
  useEffect(() => {
    setSelectedItems(slideData ? slideData?.data.map((item) => item) : []);
  }, [slideData]);
  const handleItemClick = (item) => {
    const isSelected = selectedItems.includes(item.userId);
    if (isSelected) {
      // Item is already selected, so deselect it
      setSelectedItems((prevSelectedItems) =>
        prevSelectedItems.filter((selectedItem) => selectedItem !== item.userId)
      );
    } else {
      // Item is not selected, so select it
      setSelectedItems((prevSelectedItems) => [
        ...prevSelectedItems,
        item.userId,
      ]);
    }
  };
  const handleAssignsReaders = async () => {
    const message = await readerAssignInfo({
      userIds: selectedItems,
      slideIds: selectedRows,
    });
    setConfirmMessage(message?.data?.message);
    setOpenReaderSelection(false);
    setIsSlideModalOpen(true);
  };

  return (
    <Modal
      isOpen={isOpenReaderSelection}
      onClose={() => setOpenReaderSelection(false)}
      size="md"
      isCentered
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent background="light.200">
        <ModalHeader
          pl={ifSmallScreen ? "20px" : "27px"}
          pb={ifSmallScreen ? "10px" : "15px"}
          pt={ifSmallScreen ? "10px" : "19px"}
        >
          <HStack justifyContent="space-between">
            <Text
              color="rgba(27, 117, 188, 1)"
              fontSize={ifSmallScreen ? "16px" : "18.5px"}
              font-weight="500"
            >
              Choose Readers
            </Text>
            <Text color="rgba(1, 2, 2, 1)" fontSize="12px" font-weight="500">
              {selectedItems.length} Selected
            </Text>
          </HStack>
        </ModalHeader>
        <ModalBody>
          {membersInfo?.data?.desiredRoleInfo?.map((item, index) => (
            <HStack
              w="100%"
              key={item.user_id}
              alignItems="flex-start"
              mb="8px"
              onClick={() => handleItemClick(item)}
              cursor="pointer"
              bgColor={
                selectedItems.includes(item.userId)
                  ? "rgba(176, 200, 214, 0.1)"
                  : "none"
              }
            >
              <Flex
                border={
                  selectedItems.includes(item.userId)
                    ? "2px solid rgba(27, 117, 188, 0.25)"
                    : "none"
                }
                borderRadius={
                  selectedItems.includes(item.userId) ? "5px" : "none"
                }
                borderBottom={
                  selectedItems.includes(item.userId)
                    ? "2px solid rgba(27, 117, 188, 0.25)"
                    : "2px solid rgba(222, 222, 222, 1)"
                }
                py="12px"
                px="15px"
                width="100%"
                alignItems="center"
                gridColumnGap="10vmax"
                bgColor="rgba(255, 255, 255, 1)"
              >
                <Avatar
                  size="sm"
                  name={item.firstName}
                  color="#000"
                  bg="rgba(27, 117, 188, 0.25)"
                />
                <Text fontSize="14px" fontWeight="bold">
                  {item.firstName} {item.lastName}
                </Text>
              </Flex>
            </HStack>
          ))}
        </ModalBody>
        <ModalFooter>
          <HStack spacing="1vw" justifyContent="flex-end">
            <Button
              alignItems="center"
              bgColor="#fff"
              border="1px solid rgba(207, 226, 241, 1)"
              _focus={{ outline: "none" }}
              borderRadius="5px"
              fontWeight="400"
              fontSize="14px"
              px="30px"
              onClick={() => setOpenReaderSelection(false)}
            >
              Cancel
            </Button>
            <Button
              alignItems="center"
              bgColor="rgba(196, 218, 236, 1)"
              _focus={{ outline: "none" }}
              borderRadius="5px"
              fontWeight="400"
              fontSize="14px"
              color="rgba(33, 34, 36, 1)"
              px="30px"
              onClick={handleAssignsReaders}
            >
              Assign
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default SlideReaderSelectionModal;

import React, { useState } from "react";
import {
  Box,
  HStack,
  Image,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Text,
  useMediaQuery,
  Flex,
  IconButton,
} from "@chakra-ui/react";
import { FiFilter, FiMoreVertical } from "react-icons/fi";
import { useAuth0 } from "@auth0/auth0-react";
import { useGetPIStudiesQuery } from "../../state/api/medicalApi";
import AllStudy from "./AllStudy";
import OngoingStudies from "./OngoingStudies";
import CompletedStudies from "./CompletedStudies";
import ArchiveStudies from "./ArchiveStudies";

const Studybar = ({
  study,
  setStudyDetailsToggle,
  studies,
  setCaseId,
  caseId,
  setStudyName,
  searchStudy,
  setStudyArchive,
}) => {
  const [ifWidthLessthan1920] = useMediaQuery("(max-width:1920px)");
  const [ifWidthLessthan1536] = useMediaQuery("(max-width:1536px)");

  return (
    <Box
      w={ifWidthLessthan1536 ? "290.25px" : "20.46vw"}
      h={ifWidthLessthan1920 ? "calc(100vh - 84px)" : "90.926vh"}
      background="light.1000"
      boxShadow="0px 0px 20px rgba(176, 200, 214, 0.15)"
      overflow="hidden"
      minW="290.25px"
      css={{
        "&::-webkit-scrollbar": {
          width: "4px",
        },
        "&::-webkit-scrollbar-track": {
          width: "6px",
        },
        "&::-webkit-scrollbar-thumb": {
          background: "#C4C4C4",
        },
      }}
      display="flex"
      // flexDirection="column"
    >
      <Box w="1px" bg="#fff" h="100%"></Box>
      <Box
        w="100%"
        h="4.814vh"
        boxShadow="1px 1px 2px rgba(176, 200, 214, 0.1)"
        dropShadow="0px 1px 2px rgba(176, 200, 214, 0.15)"
        justifyContent="space-between"
        background="light.1000"
      >
        <HStack
          marginLeft="1vw"
          pt="0.8vh"
          pb="0.35vh"
          pr="5px"
          alignItems="center"
          justifyContent="flex-end"
        >
          {/* <Flex alignItems="center">
            <FiFilter />
            <Text ml="0.2vw">Filter</Text>
          </Flex> */}
          <Menu zIndex="1000">
            <MenuButton
              as={IconButton}
              transition="all 0.2s"
              bgColor="light.1000"
              _hover={{ bgColor: "light.900" }}
              _focus={{ outline: "none" }}
              icon={<FiMoreVertical />}
              w="1.5vw"
              h="3.5vh"
              borderRadius={0}
            ></MenuButton>
            <MenuList color="#000" padding={0} borderRadius={0}>
              <MenuItem
                onClick={() => {
                  setStudyDetailsToggle(true);
                }}
              >
                {/* <Image src={InfoIcon} /> */}
                <Text ml="0.5vw" fontFamily="inter">
                  Study Details
                </Text>
              </MenuItem>
            </MenuList>
          </Menu>
        </HStack>
        <Flex pb="2vh">
          {study === "allStudy" ? (
            <AllStudy
              studies={studies}
              setCaseId={setCaseId}
              caseId={caseId}
              setStudyName={setStudyName}
              searchStudy={searchStudy}
              setStudyArchive={setStudyArchive}
            />
          ) : study === "ongoingStudies" ? (
            <OngoingStudies
              studies={studies}
              setCaseId={setCaseId}
              caseId={caseId}
              setStudyName={setStudyName}
              searchStudy={searchStudy}
              setStudyArchive={setStudyArchive}
            />
          ) : study === "completedStudies" ? (
            <CompletedStudies
              studies={studies}
              setCaseId={setCaseId}
              caseId={caseId}
              setStudyName={setStudyName}
              searchStudy={searchStudy}
              setStudyArchive={setStudyArchive}
            />
          ) : study === "archiveStudies" ? (
            <ArchiveStudies
              studies={studies}
              setCaseId={setCaseId}
              caseId={caseId}
              setStudyName={setStudyName}
              searchStudy={searchStudy}
              setStudyArchive={setStudyArchive}
            />
          ) : (
            <Flex
              alignItems="center"
              justifyContent="center"
              direction="column"
              flex="1"
              h="84.386vh"
              overflowX="hidden"
              css={{
                "&::-webkit-scrollbar": {
                  width: "4px",
                },
                "&::-webkit-scrollbar-track": {
                  width: "6px",
                },
                "&::-webkit-scrollbar-thumb": {
                  background: "#C4C4C4",
                },
              }}
              mt="1px"
            >
              Select a category to view details
            </Flex>
          )}
        </Flex>
      </Box>
    </Box>
  );
};

export default Studybar;

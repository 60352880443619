import React, { useState, useEffect } from "react";
import moment from "moment";
import {
  Flex,
  useMediaQuery,
  Text,
  HStack,
  Button,
  Image,
  Box,
  VStack,
  Radio,
  RadioGroup,
  List,
  ListItem,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  Stack,
} from "@chakra-ui/react";
import { useAuth0 } from "@auth0/auth0-react";
import { MdOutlineRadioButtonChecked } from "react-icons/md";
import { v4 as uuidv4 } from "uuid";
import { BiFilterAlt } from "react-icons/bi";
import { HiOutlineClipboardList } from "react-icons/hi";
import studyAssignTimeline from "../../../../../static/Images/studyAssignTimeline.svg";
import slideUploadTimeline from "../../../../../static/Images/slideUploadTimeline.svg";
import studyCreatedTimeline from "../../../../../static/Images/studyCreatedTimeline.svg";
import {
  useGetStudyTimelineQuery,
  useGetAllReaderInfoQuery,
} from "../../../../../state/api/medicalApi";

const ScrollBar = ({ children }) => {
  return (
    <Flex
      w="100%"
      h="100%"
      flexDir="column"
      overflowY="auto"
      overflowX="hidden"
      css={{
        "&::-webkit-scrollbar": {
          width: "4px",
        },
        "&::-webkit-scrollbar-track": {
          width: "6px",
        },
        "&::-webkit-scrollbar-thumb": {
          background: "#C4C4C4",
        },
      }}
    >
      {children}
    </Flex>
  );
};
const StudyTimelineComponent = ({ socket, userInfo }) => {
  const { data: readerInfo } = useGetAllReaderInfoQuery();
  const selectedData = readerInfo?.data;
  const capitalizeFirstWord = (sentence) => {
    const words = sentence.split(" ");
    if (words.length === 0) {
      return sentence;
    }

    const firstWord = words[0].charAt(0).toUpperCase() + words[0].slice(1);
    const restOfSentence = words.slice(1).join(" ");

    return `${firstWord} ${restOfSentence.toLowerCase()}`;
  };
  const { data: studyTimeline, refetch } = useGetStudyTimelineQuery();
  console.log("studyTimeline", studyTimeline);
  const [ifWidthLessthan1920] = useMediaQuery("(max-width:1920px)");
  const headerStyle = {
    fontSize: ifWidthLessthan1920 ? "12px" : "15px",
    opacity: "0.7",
    fontFamily: "Inter",
  };
  const infoStyle = {
    fontSize: ifWidthLessthan1920 ? "13px" : "16px",
    fontFamily: "Inter",
    fontWeight: "bold",
  };

  //reader code
  const extractFirstName = (description) => {
    const firstName = description.split(" ")[0];
    return firstName;
  };
  const [filteredActivities, setFilteredActivities] = useState([]);
  const [selectedCreator, setSelectedCreator] = useState("All");
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const uniqueFirstNames = Array.from(
    new Set(
      selectedData?.map((user) => `${user.first_name}${" "}${user.last_name}`)
    )
  );

  const filterActivities = (creator) => {
    if (creator === selectedCreator) {
      setFilteredActivities(studyTimeline?.data?.timeline);
      setSelectedCreator("All");
    } else {
      if (creator === "All") {
        setFilteredActivities(studyTimeline?.data?.timeline);
        setSelectedCreator(creator);
      } else {
        const filtered = studyTimeline?.data?.timeline?.filter((activity) => {
          return activity.description.includes(creator);
        });
        setFilteredActivities(filtered);
        setSelectedCreator(creator);
      }
    }
    setIsPopoverOpen(false);
  };

  useEffect(() => {
    setFilteredActivities(studyTimeline?.data?.timeline);
  }, []);
  console.log("unique name", uniqueFirstNames);
  console.log(filteredActivities);

  return (
    <Flex
      h="100%"
      w="100%"
      bgColor="#fff"
      borderRadius="10px"
      pl="10px"
      pt="10px"
      direction="column"
    >
      {/* <HStack w="100%" justifyContent="space-between">
        <Text
          color="#1B75BC"
          fontSize={ifWidthLessthan1920 ? "15px" : "18px"}
          fontWeight="bold"
        >
          Study Timeline
        </Text>
      </HStack> */}
      <HStack w="100%" justifyContent="space-between">
        <Text
          color="#1B75BC"
          fontSize={ifWidthLessthan1920 ? "15.5px" : "18px"}
          fontWeight="bold"
        >
          Study Timeline
        </Text>
        <Popover
          isOpen={isPopoverOpen}
          onClose={() => setIsPopoverOpen(false)}
          placement="bottom-start"
          size="sm"
        >
          <PopoverTrigger>
            <Box mr="24px">
              <BiFilterAlt
                size={19}
                style={{
                  color:
                    selectedCreator === "All" || selectedCreator === ""
                      ? "gray"
                      : "#1B75BC",
                  cursor: "pointer",
                }}
                onClick={() => setIsPopoverOpen(!isPopoverOpen)}
              />
            </Box>
          </PopoverTrigger>
          <PopoverContent width="200px">
            <PopoverArrow />
            <PopoverCloseButton />
            <PopoverHeader fontSize="14px">Select a Filter</PopoverHeader>
            <PopoverBody maxH="125px" overflowY="auto">
              <ScrollBar>
                <RadioGroup
                  onChange={(value) => filterActivities(value)}
                  value={selectedCreator}
                  size="sm"
                >
                  <Stack>
                    <Radio key={1} value={"All"} defaultChecked>
                      All
                    </Radio>
                    {uniqueFirstNames.map((firstName) => (
                      <Radio key={firstName} value={firstName}>
                        {firstName}
                      </Radio>
                    ))}
                  </Stack>
                </RadioGroup>
              </ScrollBar>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </HStack>

      <ScrollBar>
        <Flex w="100%" h="100%" direction="column">
          {filteredActivities?.length > 0 ? (
            filteredActivities?.map((data, index) => {
              return (
                <HStack
                  w="96%"
                  minH="95px"
                  spacing="3"
                  alignItems="flex-start"
                  key={uuidv4()}
                >
                  <Flex
                    w="7%"
                    h="100%"
                    alignItems="center"
                    direction="column"
                    justifyContent="center"
                    mt="23.5px"
                  >
                    <MdOutlineRadioButtonChecked color="#1B75BC" size={27} />
                    <Flex
                      w="2px"
                      h="100%"
                      bgColor={
                        index < filteredActivities.length - 1
                          ? "#1B75BC"
                          : "#fff"
                      }
                    />
                  </Flex>
                  <Flex w="90%" h="100%">
                    <Flex
                      w="100%"
                      textOverflow="ellipsis"
                      css={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "-webkit-box",
                        boxOrient: "vertical",
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: 2,
                      }}
                      minH="70px"
                      h="fit-content"
                      background="#F9F9F9"
                      boxShadow="0px 1px 2px rgba(176, 200, 214, 0.08)"
                      borderRadius="10px"
                      alignItems="center"
                      justifyContent="center"
                      pt="8px"
                      pl="10px"
                    >
                      <HStack w="100%">
                        {data?.description
                          ?.toLowerCase()
                          .includes("created") ? (
                          <Image
                            src={studyCreatedTimeline}
                            transform="scale(1.5)"
                          />
                        ) : data?.description
                            ?.toLowerCase()
                            .includes("added") ? (
                          <Image
                            src={studyAssignTimeline}
                            transform="scale(1.5)"
                          />
                        ) : data?.description
                            ?.toLowerCase()
                            .includes("removed") ? (
                          <Image
                            src={studyAssignTimeline}
                            transform="scale(1.5)"
                          />
                        ) : data?.description
                            ?.toLowerCase()
                            .includes("saved") ? (
                          <HiOutlineClipboardList
                            color="#1B75BC"
                            size="20px"
                            style={{ transform: "scale(1.5)" }}
                          />
                        ) : data?.description
                            ?.toLowerCase()
                            .includes("uploaded") ? (
                          <Image
                            src={slideUploadTimeline}
                            transform="scale(1.5)"
                          />
                        ) : data?.description
                            ?.toLowerCase()
                            .includes("deleted") ? (
                          <Image
                            src={slideUploadTimeline}
                            transform="scale(1.5)"
                          />
                        ) : data?.description
                            ?.toLowerCase()
                            .includes("updated") ? (
                          <Image
                            src={slideUploadTimeline}
                            transform="scale(1.5)"
                          />
                        ) : null}
                        {/* <HiOutlineClipboardList color="#1B75BC" size="40px" /> */}
                        <Flex direction="column" w="90%">
                          <HStack w="100%" justifyContent="space-between">
                            <Text
                              fontSize={ifWidthLessthan1920 ? "13px" : "15px"}
                              whiteSpace="nowrap"
                              overflow="hidden"
                              textOverflow="ellipsis"
                              w="75%"
                              maxW="75%"
                              minW="75%"
                            >
                              <Flex
                                alignItems="flex-start"
                                direction="column"
                                gap={0}
                              >
                                <Flex pl="5px" whiteSpace="wrap">
                                  {data?.description}
                                </Flex>
                                {/* <Flex pl="5px">
                                  {data?.description?.includes(
                                    "reported by"
                                  ) ? (
                                    <Button
                                      h="20px"
                                      w={
                                        ifWidthLessthan1920 ? "70px" : "80.5px"
                                      }
                                      backgroundColor="#F9F9F9"
                                      _hover={{ background: "#F9F9F9" }}
                                      // onClick={() => setTabIndex(4)}
                                    >
                                      <Text
                                        fontSize={
                                          ifWidthLessthan1920 ? "12px" : "14px"
                                        }
                                        color="#1B75BC"
                                        textDecoration="underline"
                                        textUnderlineOffset="4px"
                                      >
                                        View Report
                                      </Text>
                                    </Button>
                                  ) : data?.description?.includes(
                                      "uploaded by"
                                    ) ? (
                                    <Button
                                      h="20px"
                                      w={ifWidthLessthan1920 ? "68px" : "79px"}
                                      backgroundColor="#F9F9F9"
                                      _hover={{ background: "#F9F9F9" }}
                                      // onClick={() => setTabIndex(1)}
                                    >
                                      <Text
                                        fontSize={
                                          ifWidthLessthan1920 ? "12px" : "14px"
                                        }
                                        color="#1B75BC"
                                        textDecoration="underline"
                                        textUnderlineOffset="4px"
                                      >
                                        View Slides
                                      </Text>
                                    </Button>
                                  ) : null}
                                </Flex> */}
                              </Flex>
                            </Text>

                            <Flex
                              alignItems="flex-end"
                              w="20%"
                              minW="20%"
                              pt="13px"
                            >
                              <Text
                                color="#000"
                                opacity={0.7}
                                fontSize={ifWidthLessthan1920 ? "12px" : "14px"}
                                whiteSpace="nowrap"
                                overflow="hidden"
                                textOverflow="ellipsis"
                              >
                                {moment(data?.created_at).fromNow()}
                              </Text>
                            </Flex>
                          </HStack>
                        </Flex>
                      </HStack>
                    </Flex>
                  </Flex>
                </HStack>
              );
            })
          ) : studyTimeline?.data?.timeline.length > 0 ? (
            studyTimeline?.data?.timeline.map((data, index) => {
              return (
                <HStack
                  w="96%"
                  minH="95px"
                  spacing="3"
                  alignItems="flex-start"
                  key={uuidv4()}
                >
                  <Flex
                    w="7%"
                    h="100%"
                    alignItems="center"
                    direction="column"
                    justifyContent="center"
                    mt="25px"
                  >
                    <MdOutlineRadioButtonChecked color="#1B75BC" size={27} />
                    <Flex
                      w="2px"
                      h="100%"
                      bgColor={
                        index < studyTimeline?.data?.timeline.length - 1
                          ? "#1B75BC"
                          : "#fff"
                      }
                    />
                  </Flex>
                  <Flex w="90%" h="100%">
                    <Flex
                      w="100%"
                      textOverflow="ellipsis"
                      css={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "-webkit-box",
                        boxOrient: "vertical",
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: 2,
                      }}
                      minH="70px"
                      h="fit-content"
                      background="#F9F9F9"
                      boxShadow="0px 1px 2px rgba(176, 200, 214, 0.08)"
                      borderRadius="10px"
                      alignItems="center"
                      justifyContent="center"
                      pt="8px"
                      pl="10px"
                    >
                      <HStack w="100%">
                        {data?.description
                          ?.toLowerCase()
                          .includes("created") ? (
                          <Image
                            src={studyCreatedTimeline}
                            transform="scale(1.5)"
                          />
                        ) : data?.description
                            ?.toLowerCase()
                            .includes("added") ? (
                          <Image
                            src={studyAssignTimeline}
                            transform="scale(1.5)"
                          />
                        ) : data?.description
                            ?.toLowerCase()
                            .includes("removed") ? (
                          <Image
                            src={studyAssignTimeline}
                            transform="scale(1.5)"
                          />
                        ) : data?.description
                            ?.toLowerCase()
                            .includes("saved") ? (
                          <HiOutlineClipboardList
                            color="#1B75BC"
                            size="20px"
                            style={{ transform: "scale(1.5)" }}
                          />
                        ) : data?.description
                            ?.toLowerCase()
                            .includes("uploaded") ? (
                          <Image
                            src={slideUploadTimeline}
                            transform="scale(1.5)"
                          />
                        ) : data?.description
                            ?.toLowerCase()
                            .includes("deleted") ? (
                          <Image
                            src={slideUploadTimeline}
                            transform="scale(1.5)"
                          />
                        ) : data?.description
                            ?.toLowerCase()
                            .includes("updated") ? (
                          <Image
                            src={slideUploadTimeline}
                            transform="scale(1.5)"
                          />
                        ) : null}
                        {/* <HiOutlineClipboardList color="#1B75BC" size="40px" /> */}
                        <Flex direction="column" w="90%">
                          <HStack w="100%" justifyContent="space-between">
                            <Text
                              fontSize={ifWidthLessthan1920 ? "13px" : "15px"}
                              whiteSpace="nowrap"
                              overflow="hidden"
                              textOverflow="ellipsis"
                              w="75%"
                              maxW="75%"
                              minW="75%"
                            >
                              <Flex
                                alignItems="flex-start"
                                direction="column"
                                gap={0}
                              >
                                <Flex pl="5px" whiteSpace="wrap">
                                  {data?.description}
                                </Flex>
                                {/* <Flex pl="5px">
                                  {data?.description?.includes(
                                    "reported by"
                                  ) ? (
                                    <Button
                                      h="20px"
                                      w={
                                        ifWidthLessthan1920 ? "70px" : "80.5px"
                                      }
                                      backgroundColor="#F9F9F9"
                                      _hover={{ background: "#F9F9F9" }}
                                      // onClick={() => setTabIndex(4)}
                                    >
                                      <Text
                                        fontSize={
                                          ifWidthLessthan1920 ? "12px" : "14px"
                                        }
                                        color="#1B75BC"
                                        textDecoration="underline"
                                        textUnderlineOffset="4px"
                                      >
                                        View Report
                                      </Text>
                                    </Button>
                                  ) : data?.description?.includes(
                                      ""
                                    ) ? (
                                    <Button
                                      h="20px"
                                      w={ifWidthLessthan1920 ? "68px" : "79px"}
                                      backgroundColor="#F9F9F9"
                                      _hover={{ background: "#F9F9F9" }}
                                      // onClick={() => setTabIndex(1)}
                                    >
                                      <Text
                                        fontSize={
                                          ifWidthLessthan1920 ? "12px" : "14px"
                                        }
                                        color="#1B75BC"
                                        textDecoration="underline"
                                        textUnderlineOffset="4px"
                                      >
                                        View Slides
                                      </Text>
                                    </Button>
                                  ) : null}
                                </Flex> */}
                              </Flex>
                            </Text>

                            <Flex
                              alignItems="flex-end"
                              w="20%"
                              minW="20%"
                              pt="13px"
                            >
                              <Text
                                color="#000"
                                opacity={0.7}
                                fontSize={ifWidthLessthan1920 ? "12px" : "14px"}
                                whiteSpace="nowrap"
                                overflow="hidden"
                                textOverflow="ellipsis"
                              >
                                {moment(data?.created_at).fromNow()}
                              </Text>
                            </Flex>
                          </HStack>
                        </Flex>
                      </HStack>
                    </Flex>
                  </Flex>
                </HStack>
              );
            })
          ) : (
            <Flex w="100%" h="100%" alignItems="center" justifyContent="center">
              <Text opacity="0.3" textAlign="center">
                No timeline exists for this case
              </Text>
            </Flex>
          )}
        </Flex>
      </ScrollBar>
    </Flex>
  );
};

export default StudyTimelineComponent;

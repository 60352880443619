/* eslint-disable react/no-children-prop */
import React, { useEffect, useState } from "react";
import {
  Text,
  Image,
  HStack,
  Avatar,
  Link,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Flex,
  useMediaQuery,
  Input,
  InputGroup,
  InputLeftElement,
  VStack,
  IconButton,
  Tooltip,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
} from "@chakra-ui/react";
import { useUpdateFCMTokenMutation } from "../state/api/medicalApi";
import { SearchIcon } from "@chakra-ui/icons";
import { Link as RouteLink, useLocation, useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { BiChevronDown } from "react-icons/bi";
import { MdOutlineNotifications } from "react-icons/md";
import { HiOutlinePlusCircle } from "react-icons/hi";
import MedAIIcon from "../static/medAIicon.svg";
import { isActionValid } from "../hooks/utility";
import { AiOutlineQuestion } from "react-icons/ai";
import { BsChatLeftText } from "react-icons/bs";
import { IoMdNotificationsOutline } from "react-icons/io";
import { requestFirebaseNotificationPermission } from "../Firebase/init";
import Loading from "./Loading/loading";

const CustomTooltip = ({ children, label, userName }) => {
  return (
    <Tooltip
      label={userName.length > 16 ? label : null}
      aria-label="A tooltip"
      zIndex={100}
    >
      {children}
    </Tooltip>
  );
};

const Header = ({ userInfo, setSearchStudy }) => {
  const [updateFCMToken] = useUpdateFCMTokenMutation();
  const location = useLocation();
  const navigate = useNavigate();
  // console.log("USERR", userInfo);
  const fcmToken = localStorage.getItem("fcm_token");
  useEffect(() => {
    if (fcmToken) return;
    requestFirebaseNotificationPermission({
      updateFCMToken,
    });
  }, [fcmToken, updateFCMToken]);

  const [ifWidthLessthan1920] = useMediaQuery("(max-width:1920px)");
  const [ifWidthLessthan768] = useMediaQuery("(max-width:768px)");
  const { logout } = useAuth0();
  const [activeLink, setActiveLink] = useState(location.pathname);

  const handleLinkClick = (path) => {
    setActiveLink(path);
  };
  return (
    <>
      {(userInfo?.role === "Admin" ||
        userInfo?.role === "PI" ||
        userInfo?.role === "Pathologist") && (
        <Flex
          h={ifWidthLessthan1920 ? "44px" : "6.11vh"}
          minH="46px"
          w="100%"
          background="light.100"
          justifyContent="space-between"
          fontSize={ifWidthLessthan1920 ? "14px" : " 0.72916vw"}
          alignItems="center"
          color="#000"
          boxShadow="0px 1px 2px 0px rgba(7, 7, 7, 0.10)"
          zIndex="2.5"
        >
          <Flex alignItems="center">
            <Link
              to={
                userInfo?.role ? `/dashboard/${userInfo?.role}` : "/dashboard"
              }
              _hover={{
                textDecoration: "none",
              }}
              _focus={{ outline: "none" }}
            >
              <HStack
                px={ifWidthLessthan1920 ? "16px" : "0.8333vw"}
                py={ifWidthLessthan1920 ? "15px" : "2.17vh"}
                h="100%"
              >
                <Link
                  href={`/dashboard/${userInfo?.role}`}
                  onClick={() => {
                    sessionStorage.clear();
                  }}
                  focusBorderColor="transparent"
                  _focus={{ outline: "none" }}
                >
                  <Image src={MedAIIcon} h="58px" w="58px" />
                </Link>
              </HStack>
            </Link>
            <HStack h="100%">
              {(userInfo?.role === "Admin" ||
                userInfo?.role === "PI" ||
                userInfo?.role === "Pathologist") && (
                <Button
                  pt="6px"
                  focusBorderColor="transparent"
                  _focus={{ outline: "none" }}
                  onClick={() => {
                    handleLinkClick(`/dashboard/${userInfo?.role}`);
                    navigate(`/dashboard/${userInfo?.role}`);
                  }}
                  fontSize={ifWidthLessthan1920 ? "12px" : "14px"}
                  fontWeight={
                    window.location.href.includes(
                      `/dashboard/${userInfo?.role}`
                    )
                      ? "600"
                      : "500"
                  }
                  bgColor="inherit"
                  _hover={{ bgColor: "inherit" }}
                  color={
                    window.location.href.includes(
                      `/dashboard/${userInfo?.role}`
                    )
                      ? "#1B75BC"
                      : "#000"
                  }
                  borderBottom={
                    window.location.href.includes(
                      `/dashboard/${userInfo?.role}`
                    )
                      ? "3px solid #1B75BC"
                      : ""
                  }
                  borderRadius={0}
                  fontFamily="Inter"
                >
                  {userInfo?.role === "Pathologist" ? "Studies" : "Home"}
                </Button>
              )}
            </HStack>
            <HStack h="100%" ml="1rem">
              {userInfo?.role === "Admin" && (
                <Button
                  pt="6px"
                  focusBorderColor="transparent"
                  _focus={{ outline: "none" }}
                  onClick={() => {
                    handleLinkClick(`/admin/studies`);
                    navigate(`/admin/studies`);
                  }}
                  // fontSize={ifWidthLessthan1920 ? "12px" : "14px"}
                  fontWeight={
                    window.location.href.includes(`/admin/studies`)
                      ? "600"
                      : "500"
                  }
                  bgColor="inherit"
                  _hover={{ bgColor: "inherit" }}
                  color={
                    window.location.href.includes(`/admin/studies`)
                      ? "#1B75BC"
                      : "#000"
                  }
                  borderBottom={
                    window.location.href.includes(`/admin/studies`)
                      ? "3px solid #1B75BC"
                      : ""
                  }
                  borderRadius={0}
                  fontFamily="Inter"
                  fontSize={ifWidthLessthan1920 ? "12px" : "14px"}
                >
                  Report
                </Button>
              )}
            </HStack>
          </Flex>
          <Flex alignItems="center">
            <HStack justifyContent="space-between" maxW="900px">
              {userInfo?.role === "Admin" && (
                <>
                  <InputGroup
                    borderRadius={2}
                    background="#fff"
                    color="#000"
                    w="24vw"
                  >
                    <InputLeftElement
                      pointerEvents="none"
                      children={<SearchIcon />}
                      opacity={0.3}
                    />
                    <Input
                      placeholder="Search"
                      maxLength={18}
                      // onChange={(e) => searchFunction(e)}
                      _focus={{ outline: "none" }}
                      // value={searchInput}
                    />
                  </InputGroup>
                  <Button
                    bgColor="light.100"
                    border="1px solid #E8E8E8"
                    borderRadius="5px"
                    focusBorderColor="transparent"
                    _focus={{ outline: "none" }}
                    onClick={() => {
                      userInfo?.role === "Admin"
                        ? window.location.replace("/admin/add-member")
                        : window.location.replace("/");
                    }}
                  >
                    <HiOutlinePlusCircle />
                    <Text
                      fontSize={ifWidthLessthan1920 ? "14px" : "0.729vw"}
                      fontWeight="500"
                      ml="5px"
                    >
                      {userInfo?.role === "Admin" ? "New" : "New Role"}
                    </Text>
                  </Button>
                </>
              )}
              {(userInfo?.role === "Admin" ||
                userInfo?.role === "PI" ||
                userInfo?.role === "Pathologist") && (
                <Popover>
                  <PopoverTrigger>
                    <IconButton
                      borderRadius="5px"
                      border="1px solid #E8E8E8"
                      bgColor="inherit"
                      focusBorderColor="transparent"
                      _focus={{ outline: "none" }}
                      icon={<MdOutlineNotifications />}
                    />
                  </PopoverTrigger>
                  <PopoverContent>
                    <PopoverArrow />
                    <PopoverCloseButton />
                    {/* <PopoverBody>
                    <Queries userInfo={userInfo} />
                  </PopoverBody> */}
                  </PopoverContent>
                </Popover>
              )}
              <CustomTooltip
                label={`${userInfo?.firstName} ${userInfo?.lastName}`}
                userName={`${userInfo?.firstName} ${userInfo?.lastName}`}
              >
                <HStack h="100%" spacing={0}>
                  <Avatar
                    size={ifWidthLessthan1920 ? "sm" : "md"}
                    name={`${userInfo?.firstName} ${userInfo?.lastName}`}
                  />
                  <Menu>
                    <MenuButton
                      as={Button}
                      rightIcon={<BiChevronDown size={20} />}
                      bgColor="inherit"
                      maxW="245px"
                      _focus={{ outline: "none" }}
                      _active={{ bgColor: "light.100" }}
                      _hover={{ bgColor: "light.100" }}
                    >
                      <VStack spacing={0} alignItems="flex-start" maxW="100%">
                        <Text
                          fontSize={ifWidthLessthan1920 ? "13px" : "16px"}
                          fontWeight="600"
                          overflow="hidden"
                          textOverflow="ellipsis"
                          wordBreak="break-word"
                          maxW="150px"
                        >
                          {userInfo?.role === "Admin"
                            ? `Dr. ${
                                userInfo?.firstName !== undefined
                                  ? userInfo.firstName.charAt(0).toUpperCase() +
                                    userInfo.firstName.slice(1)
                                  : ""
                              } ${
                                userInfo?.lastName !== undefined
                                  ? userInfo.lastName.charAt(0).toUpperCase() +
                                    userInfo.lastName.slice(1)
                                  : ""
                              }`
                            : `${
                                userInfo?.firstName !== undefined
                                  ? userInfo.firstName.charAt(0).toUpperCase() +
                                    userInfo.firstName.slice(1)
                                  : ""
                              } ${
                                userInfo?.lastName !== undefined
                                  ? userInfo.lastName.charAt(0).toUpperCase() +
                                    userInfo.lastName.slice(1)
                                  : ""
                              }`}
                        </Text>
                        <Text
                          fontSize={ifWidthLessthan1920 ? "11px" : "0.6vw"}
                          opacity="0.7"
                          overflow="hidden"
                          textOverflow="ellipsis"
                          maxW="150px"
                        >
                          {userInfo?.role === "Pathologist"
                            ? "READER"
                            : userInfo?.role === "Admin"
                            ? "Admin"
                            : "COM"}
                        </Text>
                      </VStack>
                    </MenuButton>

                    <MenuList p={0} zIndex="10000">
                      <MenuItem
                        onClick={() => {
                          localStorage.clear();
                          sessionStorage.clear();
                          logout({
                            returnTo: `${window.location.origin}/login`,
                          });
                        }}
                      >
                        Log out
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </HStack>
              </CustomTooltip>
            </HStack>
          </Flex>
        </Flex>
      )}

      {userInfo?.role !== "Admin" &&
        userInfo?.role !== "PI" &&
        userInfo?.role !== "Pathologist" && (
          <Flex
          // h={ifWidthLessthan1920 ? "44px" : "4.8148vh"}
          // w="100%"
          // pr="0.833vw"
          // background="light.700"
          // justifyContent="space-between"
          // fontSize={ifWidthLessthan1920 ? "14px" : " 0.72916vw"}
          // fontFamily="inter"
          // alignItems="center"
          // color="#fff"
          >
            {/* <Flex alignItems="center">
              <Link
                as={RouteLink}
                to="/"
                _hover={{
                  textDecoration: "none",
                }}
                _focus={{ outline: "none" }}
              >
                <HStack
                  px={ifWidthLessthan1920 ? "16px" : "0.8333vw"}
                  py={ifWidthLessthan1920 ? "15px" : "2.17vh"}
                  h="100%"
                  alignItems="center"
                >
                  <Image src={MedAIIcon} />
                  <Text>Med AI</Text>
                </HStack>
              </Link>
              {location.pathname === "/studies" ||
              location.pathname === "/dashboard/Pathologist" ? (
                <InputGroup
                  left="3.5vw"
                  borderRadius={2}
                  background="#fff"
                  color="#000"
                  w="16.66666vw"
                  minW="120px"
                >
                  <InputLeftElement
                    pointerEvents="none"
                    children={<SearchIcon />}
                    opacity={0.3}
                    h={ifWidthLessthan1920 ? "33px" : "3vh"}
                  />

                  <Input
                    placeholder="Search Study"
                    maxLength="35"
                    h={ifWidthLessthan1920 ? "33px" : "3vh"}
                    onChange={(e) => setSearchStudy(e.target.value)}
                    _focus={{ outline: "none" }}
                  />
                </InputGroup>
              ) : null}
            </Flex> */}
            {/* <Flex alignItems="center">
              <Flex
                minW="100px"
                w="6.25vw"
                alignItems="center"
                justifyContent="space-evenly"
              >
                <BsChatLeftText size={15} />
                <IoMdNotificationsOutline size={18} />
                <AiOutlineQuestion size={18} />
              </Flex>
              <Menu>
                <MenuButton
                  px={0}
                  as={Button}
                  transition="all 0.2s"
                  fontWeight={500}
                  fontSize={ifWidthLessthan1920 ? "14px" : "0.729vw"}
                  bgColor="light.700"
                  overflow="clip"
                  borderRadius="none"
                  _focus={{ outline: "none" }}
                  _active={{ bgColor: "light.700" }}
                  _hover={{ bgColor: "light.700" }}
                >
                  <HStack>
                    <Avatar size="sm" />
                    <Text
                      display={ifWidthLessthan768 ? "none" : "flex"}
                      fontFamily="inter"
                    >
                      {userInfo?.role === "Admin"
                        ? `${userInfo?.firstName} ${userInfo?.lastName}`
                        : `Dr. ${userInfo?.firstName} ${userInfo?.lastName}`}
                    </Text>
                    <Text fontFamily="inter">
                      ({userInfo?.role === "Pathologist" ? "READER" : "PI"})
                    </Text>
                  </HStack>
                </MenuButton>
                <MenuList color="#000" zIndex={1000}>
                  <MenuItem
                    onClick={() =>
                      logout({
                        returnTo: `${window.location.origin}/login`,
                      })
                    }
                  >
                    Log out
                  </MenuItem>
                </MenuList>
              </Menu>
            </Flex> */}
          </Flex>
        )}
    </>
  );
};

export default Header;

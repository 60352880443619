import React from "react";
import {
  Flex,
  useMediaQuery,
  Text,
  VStack,
  HStack,
  IconButton,
} from "@chakra-ui/react";
import { AiFillInfoCircle } from "react-icons/ai";
import StudyParticipants from "./StudyParticipants";
import { CloseIcon } from "@chakra-ui/icons";
import { useGetStudyInfoForPIQuery } from "../../state/api/medicalApi";
import Loading from "../Loading/loading";

const StudyInfo = ({
  userInfo,
  studyDetailsToggle,
  setStudyDetailsToggle,
  caseId,
}) => {
  const [ifWidthLessthan1920] = useMediaQuery("(max-width:1920px)");
  const addParticipants = true;
  const { data: studyInfo, isLoading } = useGetStudyInfoForPIQuery(
    {
      caseId: caseId,
    },
    { skip: !caseId }
  );
  return isLoading ? (
    <Flex
      w={ifWidthLessthan1920 ? "320px" : "24.6vw"}
      h={ifWidthLessthan1920 ? "calc(100vh - 84px)" : "90.926vh"}
      bg="light.1000"
      flexDirection="column"
      minW="320px"
      boxShadow="1px 1px 2px rgba(176, 200, 214, 0.15)"
      display={!caseId && "none"}
      ml="1px"
    >
      <Loading />
    </Flex>
  ) : (
    <Flex
      w={ifWidthLessthan1920 ? "320px" : "24.6vw"}
      h={ifWidthLessthan1920 ? "calc(100vh - 84px)" : "90.926vh"}
      bg="light.1000"
      flexDirection="column"
      minW="320px"
      display={!caseId ? "none" : "flex"}
      ml="1px"
    >
      <Flex
        w="100%"
        h="4.814vh"
        boxShadow="1px 1px 2px rgba(176, 200, 214, 0.1)"
        alignItems="center"
        justifyContent="space-between"
        background="light.1000"
        pl="0.7vw"
      >
        <Flex alignItems="center" h="4.814vh">
          <AiFillInfoCircle color="3B5D7C" />
          <Text ml="0.5vw">Clinical study details</Text>
        </Flex>
        <Flex alignItems="center" mr="0.6vw">
          <IconButton
            background="light.100"
            onClick={() => setStudyDetailsToggle(!studyDetailsToggle)}
            size="xs"
            icon={<CloseIcon />}
            _focus={{ outline: "none" }}
            _hover={{ outline: "none" }}
          ></IconButton>
        </Flex>
      </Flex>
      <Flex
        px="0.6vw"
        flexDirection="column"
        overflowY="auto"
        css={{
          "&::-webkit-scrollbar": {
            width: "4px",
          },
          "&::-webkit-scrollbar-track": {
            width: "6px",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "#C4C4C4",
          },
        }}
      >
        <Flex
          flexDirection="column"
          bg="light.100"
          w="100%"
          px="0.65vw"
          mt="1.7407vh"
          mb="0.7407vh"
          boxShadow="1px 1px 2px rgba(176, 200, 214, 0.1)"
        >
          <Text fontSize="12px">{`Last Update- ${studyInfo?.data?.caseInfo?.updated_at}`}</Text>
          <Text fontSize="16px">{`Study title- ${studyInfo?.data?.caseInfo?.name}`}</Text>
        </Flex>
        <VStack
          fontSize={ifWidthLessthan1920 ? "14px" : "0.729vw"}
          py="1.25vh"
          px="0.937vw"
          alignItems="flex-start"
          background="light.100"
          my="1vh"
          boxShadow="1px 1px 2px rgba(176, 200, 214, 0.1)"
        >
          <Text fontWeight={700}>Study details</Text>
          <HStack
            w="100%"
            borderBottom="1px solid rgba(0,0,0,0.2)"
            minH="2.0812vh"
            px="0.5208vw"
            pb="0.5.740vh"
            pt="1.11vh"
          >
            <Text minW="40%" maxW="40%">
              Title:
            </Text>
            <Text minW="60%" maxW="60%">
              {studyInfo?.data?.caseInfo?.name}
            </Text>
          </HStack>
          <HStack
            w="100%"
            borderBottom="1px solid rgba(0,0,0,0.2)"
            minH="2.0812vh"
            px="0.5208vw"
            pb="0.5.740vh"
            pt="1.11vh"
          >
            <Text minW="40%" maxW="40%">
              Aim:
            </Text>
            <Text minW="60%" maxW="60%">
              {studyInfo?.data?.caseInfo?.Aim}
            </Text>
          </HStack>
          <HStack
            w="100%"
            borderBottom="1px solid rgba(0,0,0,0.2)"
            minH="2.0812vh"
            px="0.5208vw"
            pb="0.5.740vh"
            pt="1.11vh"
          >
            <Text minW="40%" maxW="40%">
              Objective:
            </Text>
            <Text minW="60%" maxW="60%">
              {studyInfo?.data?.caseInfo?.Objectives}
            </Text>
          </HStack>
          <HStack
            w="100%"
            borderBottom="1px solid rgba(0,0,0,0.2)"
            minH="2.0812vh"
            px="0.5208vw"
            pb="0.5.740vh"
            pt="1.11vh"
          >
            <Text minW="40%" maxW="40%">
              Details:
            </Text>
            <Text minW="60%" maxW="60%">
              {studyInfo?.data?.caseInfo?.Studydetails}
            </Text>
          </HStack>
          {userInfo?.role === "PI" && (
            <HStack
              w="100%"
              borderBottom="1px solid rgba(0,0,0,0.2)"
              minH="2.0812vh"
              px="0.5208vw"
              pb="0.5.740vh"
              pt="1.11vh"
            >
              <Text minW="40%" maxW="40%">
                Duration:
              </Text>
              <Text minW="60%" maxW="60%">
                {`${studyInfo?.data?.caseInfo?.Duration} months`}
              </Text>
            </HStack>
          )}
          {userInfo?.role === "Pathologist" && (
            <HStack
              w="100%"
              borderBottom="1px solid rgba(0,0,0,0.2)"
              minH="2.0812vh"
              px="0.5208vw"
              pb="0.5.740vh"
              pt="1.11vh"
            >
              <Text minW="40%" maxW="40%">
                P.I. Name:
              </Text>
              <Text minW="60%" maxW="60%">
                {`${studyInfo?.data?.caseInfo?.firstName} ${studyInfo?.data?.caseInfo?.lastName}`}
              </Text>
            </HStack>
          )}
        </VStack>
        {userInfo?.role === "PI" && (
          <StudyParticipants
            addParticipants={addParticipants}
            userInfo={userInfo}
            caseId={caseId}
          />
        )}
      </Flex>
    </Flex>
  );
};

export default StudyInfo;

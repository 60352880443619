import React, { useEffect, useState } from "react";
import {
  Flex,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Tr,
  Box,
} from "@chakra-ui/react";
import moment from "moment";
import { useGetOverallReadersQuery } from "../../state/api/medicalApi";
function SlidesReport({ report, caseId, questions, activeButton }) {
  const { data: participants, isLoading } = useGetOverallReadersQuery({
    studyId: caseId,
  });
  const [formattedData, setFormattedData] = useState([]);
  let remarkQuestions;
  const tableCellStyle = {
    padding: "0.5vw",
    isTruncated: true,
    border: "1px solid #000",
    textTransform: "none",
  };
  useEffect(() => {
    const newData = [];
    report?.data?.finalResponseArray?.forEach((item) => {
      const slideData = {
        slide_id: item.slide_id,
        slide_name: item.slide_name,
        accessionId: item.accession_id,
        questions: [],
      };
      item.reportsResponses.forEach((report) => {
        report.piResponses.forEach((response) => {
          if (response.is_section) {
            const sectionHeading = response.section_heading;
            let section = slideData.questions.find(
              (item) => item.question_text === sectionHeading
            );

            if (!section) {
              section = {
                question_text: sectionHeading,
                is_section: true,
                section_questions: [],
              };
              slideData.questions.push(section);
            }

            response.section_questions.forEach((sectionQuestion) => {
              const existingSectionQuestion = section.section_questions.find(
                (item) => item.question_text === sectionQuestion.question_text
              );

              if (existingSectionQuestion) {
                const userId = report?.user_id;
                existingSectionQuestion.question_responses[userId] = {
                  user_id: report.user_id,
                  user_first_name: report.first_name,
                  user_last_name: report.last_name,
                  response: sectionQuestion.response,
                };
              } else {
                const userId = report?.user_id;

                section.section_questions.push({
                  question_text: sectionQuestion.question_text,
                  choices: sectionQuestion.choices,
                  question_id: sectionQuestion.question_id,
                  is_section: sectionQuestion.is_section,
                  question_responses: {
                    [userId]: {
                      user_id: report.user_id,
                      user_first_name: report.first_name,
                      user_last_name: report.last_name,
                      response: sectionQuestion.response,
                    },
                  },
                });
              }
            });
          } else {
            const question = response.question_text;
            const existingQuestion = slideData.questions.find(
              (item) => item.question_text === question
            );
            if (existingQuestion) {
              const userId = report?.user_id;
              existingQuestion.question_responses[userId] = {
                user_id: report.user_id,
                user_first_name: report.first_name,
                user_last_name: report.last_name,
                response: response.response,
              };
            } else {
              const userId = report?.user_id;
              slideData.questions.push({
                question_text: question,
                choices: response?.choices,
                question_id: response?.question_id,
                is_section: response?.is_section,
                section_questions: response?.section_questions,
                question_responses: {
                  [userId]: {
                    user_id: report.user_id,
                    user_first_name: report.first_name,
                    user_last_name: report.last_name,
                    response: response.response,
                  },
                },
              });
            }
          }
        });
      });
      newData.push(slideData);
    });

    setFormattedData(newData);
  }, [report]);
  return isLoading ? (
    <Flex flex="1" w="100%" align="center" justifyContent="center">
      <Spinner />
    </Flex>
  ) : (
    <>
      {report?.data?.finalResponseArray?.length ? (
        <Flex flexWrap="wrap" mt="1.5vh" maxH="58vh" overflow="auto">
          <Flex direction="column" maxW="73vw" overflow="auto">
            <Table id="report" border="1px solid #000" maxW="52vw" size="sm">
              <Tbody>
                <Tr bg="rgba(176, 200, 214, 0.10)">
                  <Td style={tableCellStyle}>S.No</Td>
                  <Td style={tableCellStyle} isTruncated>
                    AccessionID
                  </Td>
                  <Td style={tableCellStyle} isTruncated>
                    Reader Name
                  </Td>
                  <Td style={tableCellStyle} isTruncated>
                    Upload Date
                  </Td>
                  <Td style={tableCellStyle} isTruncated>
                    Report Date
                  </Td>
                  {questions?.[activeButton]?.map((question, index) => {
                    return (
                      <Td
                        p="0"
                        px="0.5vw"
                        w="100%"
                        key={index}
                        minW="100%"
                        isTruncated
                        border="1px solid #000"
                      >
                        <Text p="0.3vh 0.3vw" align="center">
                          {question?.question_text
                            ? question?.question_text
                            : question?.section_heading}
                        </Text>
                        {/* <Flex
                            w="100%"
                            flex="1"
                            borderTop="1px solid #000"
                            justifyContent="space-evenly"
                          >
                            {participants?.data?.desiredParticipantsInfo?.map(
                              (response) => {
                                return (
                                  <Flex
                                    borderRight="1px solid #000"
                                    _last={{ borderRight: "none" }}
                                    p="0.2vh 0vw"
                                    minW="33%"
                                    isTruncated
                                    flex={
                                      1 /
                                      participants?.data
                                        ?.desiredParticipantsInfo?.length
                                    }
                                  >
                                    <Td style={tableCellStyle}>
                                      {response?.firstName} {response?.lastName}
                                    </Td>
                                  </Flex>
                                );
                              }
                            )}
                          </Flex> */}
                      </Td>
                    );
                  })}
                  <Td
                    px="0"
                    pb="0"
                    align="center"
                    border="1px solid #000"
                    isTruncated
                  >
                    <Box px="0.45vw">Additional Questions</Box>

                    <Flex pb="0" minW="100%" borderTop="1px solid #000">
                      <Flex p="0.25vw" borderRight="1px solid #000" minW="50%">
                        Question
                      </Flex>
                      <Flex p="0.25vw">Answer</Flex>
                    </Flex>
                  </Td>
                </Tr>
                {report?.data?.finalResponseArray?.map((slide, slideIndex) => {
                  return (
                    <>
                      {slide?.reportsResponses?.map((response, index) => {
                        return (
                          <Tr key={index} border="1px solid #000">
                            <Td border="1px solid #000" px="0.5vw" py="0.5vh">
                              {slideIndex + 1}
                            </Td>
                            <Td style={tableCellStyle}>
                              {slide?.accession_id}
                            </Td>
                            <Td style={tableCellStyle} isTruncated>
                              {response?.first_name} {response?.last_name}
                            </Td>
                            <Td style={tableCellStyle}>
                              {moment(slide?.created_at).format("DD-MM-YY")}
                            </Td>
                            <Td style={tableCellStyle} isTruncated>
                              {moment(
                                response?.piResponses?.[0]?.created_at
                              ).format("DD-MM-YY")}
                            </Td>
                            {response?.piResponses?.map((questionElement) => {
                              remarkQuestions = response?.piResponses?.filter(
                                (e) => e?.question_type === "remark"
                              );
                              return questionElement?.question_type ===
                                "remark" ? null : questionElement
                                  ?.section_questions?.length ? (
                                <>
                                  <Flex p="0" direction="column">
                                    {questionElement?.section_questions?.map(
                                      (question, i) => {
                                        return (
                                          <Flex
                                            _first={{
                                              borderLeft: "none",
                                            }}
                                          >
                                            <Td
                                              p="0.2vw"
                                              isTruncated
                                              minW="220px"
                                              px="0.4vw"
                                            >
                                              {question?.question_text}
                                            </Td>
                                            <Td
                                              isTruncated
                                              p="0.2vw"
                                              minW="250px"
                                              borderLeft="1px solid #000"
                                              _first={{
                                                border: "none",
                                              }}
                                            >
                                              {question?.response
                                                ?.replace(/{"/g, "")
                                                .replace(/"}/g, "")
                                                .replace(/"/g, "") || "-"}
                                            </Td>
                                          </Flex>
                                        );
                                      }
                                    )}
                                  </Flex>
                                </>
                              ) : (
                                <Td
                                  border="1px solid #000"
                                  justifyContent="space-between"
                                  p="0"
                                  flex="1"
                                  minH="100% !important"
                                  h="100% !important"
                                >
                                  <Flex
                                    py="0.5vh"
                                    borderRight="1px solid #000"
                                    direction="row"
                                    _last={{ borderRight: "none" }}
                                    _first={{ borderLeft: "none" }}
                                    overflow="hidden"
                                  >
                                    <Text
                                      textAlign="center"
                                      flex="1"
                                      w="fit-content"
                                      isTruncated
                                    >
                                      {" "}
                                      {questionElement?.response
                                        ?.replace(/{"/g, "")
                                        .replace(/"}/g, "")
                                        .replace(/"/g, "") || "-"}
                                    </Text>
                                  </Flex>
                                </Td>
                              );
                            })}
                            {!!remarkQuestions?.length && (
                              <Td
                                p="0"
                                border="1px solid #000"
                                verticalAlign="top"
                                flex="1"
                              >
                                {remarkQuestions?.map((question, i) => {
                                  return (
                                    <Flex
                                      key={i}
                                      flex="1"
                                      borderBottom="1px solid #000"
                                    >
                                      <Flex flex="1" p="0.25vw" minW="50%">
                                        <Text isTruncated>
                                          {question?.question_text}
                                        </Text>
                                      </Flex>
                                      <Flex
                                        flex="0.5"
                                        borderLeft="1px solid #000"
                                        p="0.25vw"
                                        minW="50%"
                                      >
                                        <Text w="100%" minW="100%" isTruncated>
                                          {question?.response}
                                        </Text>
                                      </Flex>
                                    </Flex>
                                  );
                                })}
                              </Td>
                            )}
                          </Tr>
                        );
                      })}
                    </>
                  );
                })}
              </Tbody>
            </Table>
          </Flex>
        </Flex>
      ) : null}

      {/* <StudyConclusion
        studyConclusions={studyConclusions}
        isFetching={isConclusionFetching}
      /> */}
    </>
  );
}

export default SlidesReport;

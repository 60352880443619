import React, { useState } from "react";
import {
  Divider,
  Flex,
  HStack,
  Button,
  useMediaQuery,
  IconButton,
  Link,
  useToast,
} from "@chakra-ui/react";
import { AiOutlinePlus } from "react-icons/ai";
import { GiHamburgerMenu } from "react-icons/gi";
import { Link as RouteLink } from "react-router-dom";
import { BiBox } from "react-icons/bi";
import {
  useUpdateArchiveForStudyMutation,
  useLazyGetStudyInfoForPIQuery,
} from "../state/api/medicalApi";
const Toolbar = ({
  setNavbarToggle,
  navbarToggle,
  userInfo,
  caseId,
  studyName,
}) => {
  const [ifWidthLessthan1920] = useMediaQuery("(max-width:1920px)");
  const [hoverArchive, setHoverArchive] = useState(false);
  const [updateArchiveStatus] = useUpdateArchiveForStudyMutation();
  const toast = useToast();
  const [studyData] = useLazyGetStudyInfoForPIQuery();

  //toggle study archive status
  const setArchive = async () => {
    try {
      if (caseId) {
        const studyDetails = await studyData({
          caseId: caseId,
        });
        await updateArchiveStatus({
          case_id: caseId,
          isArchive:
            studyDetails?.data?.data?.caseInfo?.isArchive === true
              ? false
              : true,
        });
        toast({
          description: `Study ${studyName} has been ${
            studyDetails?.data?.data?.caseInfo?.isArchive === true
              ? "removed"
              : "added"
          } ${
            studyDetails?.data?.data?.caseInfo?.isArchive === true
              ? "from"
              : "to"
          } archive`,
          status: "success",
          duration: 2000,
        });
      } else {
        toast({
          description: `Select a study and try again!!`,
          status: "error",
          duration: 2000,
        });
      }
    } catch {
      toast({
        description: `Some error occurred while changing archive status of ${studyName}`,
        status: "error",
        duration: 2000,
      });
    }
  };
  return (
    <Flex
      h={ifWidthLessthan1920 ? "40px" : "4.2592vh"}
      background="light.800"
      w="100%"
      boxShadow=" 0px 1px 2px  rgba(176, 200, 214, 0.15)"
    >
      <HStack spacing="0.5vw">
        <IconButton
          _focus={{ outline: "none" }}
          borderRadius="0"
          mx={ifWidthLessthan1920 ? "12px" : "0.625vw"}
          icon={<GiHamburgerMenu color="#000" />}
          onClick={() => setNavbarToggle(!navbarToggle)}
          h={ifWidthLessthan1920 ? "30px" : "3.5vh"}
          background="none"
        />
        {userInfo?.role === "PI" && (
          <>
            <Divider orientation="vertical" h="3vh" borderWidth="1px" />
            <IconButton
              background="light.800"
              _focus={{ outline: "none" }}
              borderRadius="0"
              title="Archive this case"
              onClick={setArchive}
              h={ifWidthLessthan1920 ? "30px" : "3.5vh"}
              onMouseEnter={() => setHoverArchive(true)}
              onMouseLeave={() => setHoverArchive(false)}
              icon={<BiBox />}
            />
            <Divider orientation="vertical" h="3vh" borderWidth="1px" />
          </>
        )}
        {userInfo?.role === "PI" && (
          <Link
            as={RouteLink}
            to="/create-study"
            _hover={{ textDecor: "none" }}
          >
            <Button
              h={ifWidthLessthan1920 ? "30px" : "3.05vh"}
              background="light.700"
              borderRadius="0"
              leftIcon={<AiOutlinePlus />}
              _focus={{ outline: "none" }}
              ml={ifWidthLessthan1920 ? "18px" : "0.937vw"}
              color="light.100"
              fontWeight="400"
              size="xs"
              fontSize={ifWidthLessthan1920 ? "14px" : "0.729vw"}
              fontFamily="inter"
            >
              Create Study
            </Button>
          </Link>
        )}
      </HStack>
    </Flex>
  );
};

export default Toolbar;

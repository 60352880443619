import React, { useState, useEffect } from "react";
import {
  Button,
  Flex,
  HStack,
  Text,
  useMediaQuery,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Box,
  useToast,
  Image,
} from "@chakra-ui/react";
import { useNavigate, useLocation } from "react-router-dom";
import NoCaseIcon from "../../../static/Images/NoCaseIcon.svg";
import {
  useGetUserInfoQuery,
  useGetAllReaderDetailsQuery,
  useGetAllReaderProgressQuery,
  useGetAllReaderTimelineQuery,
  useGetAllReaderSlideInfoQuery,
  useLazyGetAllReaderTimelineQuery,
  useLazyGetAllReaderProgressQuery,
  useLazyGetAllReaderSlideInfoQuery,
  useLazyGetAllReaderDetailsQuery,
  useGetAllPermissionQuery,
} from "../../../state/api/medicalApi";
import Toastify from "toastify-js";
import "toastify-js/src/toastify.css";
import io from "socket.io-client";
import { IoArrowBackCircleSharp } from "react-icons/io5";
import { useAuth0 } from "@auth0/auth0-react";
import { BiPencil } from "react-icons/bi";
import { AiOutlineCaretDown } from "react-icons/ai";
import Header from "../../Header";
import OverViewScreen from "./StudyTabsScreen/OverViewScreen";
import SlideTabScreen from "./StudyTabsScreen/SlideTabScreen";
import Loading from "../../Loading/loading";
import NotReportAvailableScreen from "./StudyTabsScreen/ReportTabScreenSubComponent/NotReportAvailableScreen";
import ReaderReport from "../../Report/ReaderReport";
import Environment from "../../../environment";

const ReaderStudyDetailScreen = () => {
  const socket = io(Environment.USER_URL);
  const toast = useToast();
  const [socketData, setSocketData] = useState("");
  const { data: userInfo, isLoading } = useGetUserInfoQuery();
  const { data: permission } = useGetAllPermissionQuery();
  const { data: readerDetailsInfo, isLoading: isLoading1 } =
    useGetAllReaderDetailsQuery({ skip: !userInfo });
  const { data: readerProgressInfo } = useGetAllReaderProgressQuery();
  const { data: readerTimelineInfo } = useGetAllReaderTimelineQuery();
  const [slideType, setSlideType] = useState("HAndE");
  const { data: readerSlideInfo } = useGetAllReaderSlideInfoQuery(
    {
      case_id: readerDetailsInfo?.data.caseInfoparams?.case_id,
      slide_type: slideType,
    },
    { skip: !readerDetailsInfo }
  );
  console.log(readerDetailsInfo);
  const [getSlideInfo] = useLazyGetAllReaderSlideInfoQuery();
  const [getTimelineInfo] = useLazyGetAllReaderTimelineQuery();
  const [getProgressInfo] = useLazyGetAllReaderProgressQuery();
  const [getDetailInfo] = useLazyGetAllReaderDetailsQuery();
  useEffect(() => {
    if (userInfo) {
      socket.emit("join", userInfo?.data?.[0]?.organizationId);
    }
    socket.on("serverEvent", (data) => {
      setSocketData(data);
    });

    if (socketData?.message === "A new slide Assigned") {
      getSlideInfo({
        case_id: readerDetailsInfo?.data.caseInfoparams?.case_id,
        slide_type: slideType,
      });
      getProgressInfo();
      getTimelineInfo();
      Toastify({
        text: "A new slide Assigned",
        duration: 3000,
        gravity: "bottom",
        position: "center",
        style: {
          background: "green",
        },
        onClick: function () {},
      }).showToast();
    }
    return () => {
      socket.disconnect();
    };
  }, [userInfo, socketData]);

  useEffect(() => {
    getSlideInfo({
      case_id: readerDetailsInfo?.data.caseInfoparams?.case_id,
      slide_type: slideType,
    });
    getProgressInfo();
    getTimelineInfo();
    getDetailInfo();
  }, [slideType]);
  const state = useLocation();
  const { user } = useAuth0();
  const [tabIndex, setTabIndex] = useState(0);
  const handleTabChange = (index) => {
    setTabIndex(index);
    sessionStorage.setItem("tabIndex", index);
  };
  useEffect(() => {
    const storedIndex = sessionStorage.getItem("tabIndex");
    if (storedIndex) {
      setTabIndex(Number(storedIndex));
    }
  }, []);
  console.log("socketData", socketData);
  const blankSpace = "\u00A0";
  const navigate = useNavigate();
  const [ifWidthLessthan1920] = useMediaQuery("(max-width:1920px)");
  console.log("readerPermission", permission);
  return isLoading1 ? (
    <Loading />
  ) : (
    <Flex w="100%" h="100%" direction="column">
      <Header userInfo={userInfo?.data[0]} />
      <HStack w="100%" h="10%" justifyContent="space-between">
        <Flex alignItems="center" ml="5px">
          <Text
            fontSize={ifWidthLessthan1920 ? "22px" : "33px"}
            ml="20px"
            fontWeight={ifWidthLessthan1920 ? "500" : "600"}
          >
            {readerDetailsInfo?.data.caseInfoparams?.study_title}
          </Text>
        </Flex>
        {readerDetailsInfo && (
          <Flex>
            <Menu>
              <MenuButton
                as={Button}
                fontSize={ifWidthLessthan1920 ? "13px" : "0.52916vw"}
                fontWeight="500"
                border="1px solid #E8E8EB"
                borderRadius="5px"
                bgColor="inherit"
                mr="12px"
                w="300px"
                mt="6px"
              >
                <Box>
                  <Flex justifyContent="space-between">
                    <Flex>
                      <Text fontWeight="bold">
                        COM : Dr.{" "}
                        {`${
                          readerDetailsInfo?.data.caseInfoparams?.first_name
                        }${" "}${
                          readerDetailsInfo?.data.caseInfoparams?.last_name
                        }`}{" "}
                      </Text>
                    </Flex>
                  </Flex>
                </Box>
              </MenuButton>
            </Menu>
          </Flex>
        )}
      </HStack>
      <Flex
        w="100%"
        h="100%"
        overflowY="auto"
        css={{
          "&::-webkit-scrollbar": {
            width: "0px",
          },
          "&::-webkit-scrollbar-track": {
            width: "0px",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "#C4C4C4",
          },
        }}
      >
        {readerDetailsInfo && (
          <Tabs w="100%" onChange={handleTabChange} index={tabIndex} isLazy>
            <TabList
              h={ifWidthLessthan1920 ? "40px" : "60px"}
              css={{ position: "fixed" }}
              gap="20px"
              bgColor="light.100"
              zIndex="3"
              w="100%"
            >
              <Tab
                _selected={{
                  color: "#1B75BC",
                  borderBottom: "2px solid #1B75BC",
                }}
                w="6%"
                fontSize={ifWidthLessthan1920 ? "14px" : "20px"}
                fontWeight={ifWidthLessthan1920 ? "500" : "600"}
                ml="15px"
              >
                Details
              </Tab>
              <Tab
                _selected={{
                  color: "#1B75BC",
                  borderBottom: "2px solid #1B75BC",
                }}
                w="6%"
                fontSize={ifWidthLessthan1920 ? "14px" : "20px"}
                fontWeight={ifWidthLessthan1920 ? "500" : "600"}
              >
                Slides
              </Tab>
              <Tab
                _selected={{
                  color: "#1B75BC",
                  borderBottom: "2px solid #1B75BC",
                }}
                w="6%"
                fontSize={ifWidthLessthan1920 ? "14px" : "20px"}
                fontWeight={ifWidthLessthan1920 ? "500" : "600"}
              >
                Report
              </Tab>
            </TabList>

            <TabPanels zIndex="0" pt="40px">
              <TabPanel p={0} h="78vh">
                <OverViewScreen
                  readerDetailsInfo={readerDetailsInfo?.data.caseInfoparams}
                  readerProgressInfo={readerProgressInfo}
                  readerTimelineInfo={readerTimelineInfo}
                  readerSlideInfo={readerSlideInfo}
                  userInfo={userInfo}
                  setSlideType={setSlideType}
                  setTabIndex={setTabIndex}
                  caseId={readerDetailsInfo?.data.caseInfoparams?.case_id}
                />
              </TabPanel>
              <TabPanel p={0} h="78vh">
                <SlideTabScreen
                  readerSlideInfo={readerSlideInfo}
                  userInfo={userInfo}
                  setSlideType={setSlideType}
                  caseId={readerDetailsInfo?.data.caseInfoparams?.case_id}
                  permission={permission}
                />
              </TabPanel>
              <TabPanel p={0} h="78vh">
                {permission?.data?.[0]?.permissions?.includes("viewReport") ? (
                  <ReaderReport
                    caseId={readerDetailsInfo?.data.caseInfoparams?.case_id}
                    userInfo={userInfo?.data[0]}
                    studyName={
                      readerDetailsInfo?.data?.caseInfoparams?.study_title
                    }
                  />
                ) : (
                  <NotReportAvailableScreen />
                )}
              </TabPanel>
            </TabPanels>
          </Tabs>
        )}
        {!readerDetailsInfo && (
          <Flex bgColor="rgb(244,244,244)" h="80.8vh" w="100%" mt="4vh">
            <Flex
              h="76vh"
              mx="14vw"
              bgColor="white"
              w="100%"
              mt="3vh"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
            >
              <Image src={NoCaseIcon} w="120px" h="120px" />
              <Text fontSize="14px" fontWeight="bold">
                No study alotted to you.
              </Text>
            </Flex>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};
export default ReaderStudyDetailScreen;

import React, { useEffect } from "react";
import { Flex, useToast } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  split,
  HttpLink,
} from "@apollo/client";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import { getMainDefinition } from "@apollo/client/utilities";
import { MedViewer } from "med-viewer";
import Loading from "../Loading/loading";
// import Header from "../Header";
import {
  useGetStudyInfoForPIQuery,
  useLazyGetQuestionnaireresponseQuery,
  useLazyGetQuestionsQuery,
  usePostQuestionnaieResponsesMutation,
  useSaveAnnotationMutation,
  useLazyLoadAnnotationsQuery,
  useUpdateAnnotationMutation,
  useDeleteAnnotationMutation,
  useVhutAnalysisMutation,
  useLazyGetVhutAnalysisQuery,
  useVhutViewportAnalysisMutation,
  useGetQuestionsQuery,
  useGetReportResponseQuery,
  usePostAdditionalQuestionnaieResponsesMutation,
  useGetAllPermissionQuery,
  useGetAllReaderSlideInfoQuery,
} from "../../state/api/medicalApi";
import { onMessageListener } from "../../Firebase/init";
import Environment from "../../environment";

const OSDViewer = () => {
  const location = useLocation();
  const toast = useToast();
  const { userInfo, slide, caseId } = location.state;
  const { data: studyInfo, isLoading } = useGetStudyInfoForPIQuery({
    caseId,
  });
  const clinicalStudy = true;
  // const [questions] = useLazyGetQuestionsQuery();
  const [questionnaireResponse, { isError }] =
    useLazyGetQuestionnaireresponseQuery();
  const [submitResponse, { isSuccess }] =
    usePostQuestionnaieResponsesMutation();
  const [submitAdditionalResponse] =
    usePostAdditionalQuestionnaieResponsesMutation();
  const [saveAnnotation] = useSaveAnnotationMutation();
  const [loadAnnotations] = useLazyLoadAnnotationsQuery();
  const [updateAnnotation] = useUpdateAnnotationMutation();
  const [deleteAnnotation] = useDeleteAnnotationMutation();
  const [vhutAnalysis] = useVhutAnalysisMutation();
  const [getVhutAnalysis] = useLazyGetVhutAnalysisQuery();
  const [vhutViewportAnalysis] = useVhutViewportAnalysisMutation();
  const { data: permission } = useGetAllPermissionQuery();
  // console.log("error", isError, "suc", isSuccess);
  const { data: questions } = useGetQuestionsQuery({ studyId: caseId });
  const { data: All_Reader_Responses } = useGetReportResponseQuery({
    case_id: caseId,
    sort: "participants",
    type: slide?.slideType,
  });

  const { data: readerSlideInfo } = useGetAllReaderSlideInfoQuery({
    case_id: caseId,
    slide_type: slide?.slideType,
  });

  // console.log(questions);
  // console.log({ slide });
  //toast on response submit
  useEffect(() => {
    if (isSuccess) {
      toast({
        title: "Action completed",
        status: "success",
        isClosable: false,
        duration: 2000,
      });
    }
  }, [isSuccess]);
  const httpLink = new HttpLink({
    uri: Environment.VIEWER_URL,
    // uri: 'http://localhost:8080',
  });

  const wsLink = new GraphQLWsLink(
    createClient({
      url: Environment.VIEWER_SOCKET_URL,
      // url: 'ws://localhost:8080',
    })
  );

  // The split function takes three parameters:
  //
  // * A function that's called for each operation to execute
  // * The Link to use for an operation if the function returns a "truthy" value
  // * The Link to use for an operation if the function returns a "falsy" value
  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    wsLink,
    httpLink
  );

  const apolloClient = new ApolloClient({
    link: splitLink,
    cache: new InMemoryCache(),
  });
  const caseInfo = studyInfo?.data?.caseInfo;
  // console.log("CaseInfo", caseInfo)

  return !studyInfo ? (
    <Loading />
  ) : (
    <Flex direction="column" h="100vh">
      <ApolloProvider client={apolloClient}>
        <MedViewer
          report
          annotations
          enableAI
          enableFilters
          application="clinical"
          userInfo={userInfo}
          caseInfo={studyInfo?.data?.caseInfo}
          tile={slide?.awsImageBucketUrl}
          viewerIds={[slide]}
         slides={userInfo.data[0]?.role === "PI" ? caseInfo?.slides : readerSlideInfo?.data }
          slide={slide}
          clinicalStudy={clinicalStudy}
          questions={questions}
          responseHandler={submitResponse}
          questionnaireResponse={questionnaireResponse}
          onMessageListener={onMessageListener}
          onSaveAnnotation={saveAnnotation}
          onUpdateAnnotation={updateAnnotation}
          onDeleteAnnotation={deleteAnnotation}
          onLoadAnnotations={loadAnnotations}
          onVhutAnalysis={vhutAnalysis}
          onVhutViewportAnalysis={vhutViewportAnalysis}
          onGetVhutAnalysis={getVhutAnalysis}
          Environment={Environment}
          All_Reader_Responses={All_Reader_Responses}
          submitAdditionalResponse={submitAdditionalResponse}
          permission={permission}
          readerSlideInfo={readerSlideInfo}
        />
      </ApolloProvider>
    </Flex>
  );
};

export default OSDViewer;

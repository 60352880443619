import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Text,
  VStack,
  HStack,
  Icon,
  useMediaQuery,
  Flex,
  Box,
  Image,
  Input,
  Radio,
  RadioGroup,
  Button,
} from "@chakra-ui/react";
import Toastify from "toastify-js";
import "toastify-js/src/toastify.css";
import { FaLock } from "react-icons/fa";
import { getSlideUrl } from "../../../../../hooks/utility";
import { useUpdateSlideDetailsMutation } from "../../../../../state/api/medicalApi";

const SlideEditModal = ({ isOpen, onClose, slide }) => {
  const [ifSmallScreen] = useMediaQuery("(max-width:1920px)");
  const [slideData, setSlideData] = useState();
  const [slideUpdate] = useUpdateSlideDetailsMutation();
  console.log(slideData);
  const handleDetails = (input) => (e) => {
    const { value } = e.target;
    setSlideData((prevState) => ({
      ...prevState,
      [input]: value,
    }));
  };
  const updateSlide = async (slideId) => {
    const resp = await slideUpdate({
      slideId: slide?._id,
      changes: slideData,
    });
    if (resp?.data?.success) {
      onClose();
      setSlideData();
      Toastify({
        text: "Slide Details Updated Successfully",
        duration: 1000,
        gravity: "bottom", // `top` or `bottom`
        position: "center", // `left`, `center` or `right`
        style: {
          background: "green",
        },
        onClick: function () {}, // Callback after click
      }).showToast();
    }
  };
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader
          fontSize={ifSmallScreen ? "16px" : "18.5px"}
          font-weight="500"
          pl={ifSmallScreen ? "20px" : "27px"}
          pb={ifSmallScreen ? "10px" : "15px"}
          pt={ifSmallScreen ? "10px" : "19px"}
          bg="#F0F2FF"
          borderStyle="solid"
          borderWidth="0px 0px 2px 0px"
          borderColor="#DEDEDE"
        >
          Edit Slide
        </ModalHeader>
        <ModalCloseButton
          pb={ifSmallScreen ? "20px" : "15px"}
          pt={ifSmallScreen ? "10px" : "21px"}
          mr={ifSmallScreen ? "10px" : "10.5"}
          _hover={{ opacity: 1 }}
        />
        <ModalBody px="30px">
          <VStack>
            <Flex
              border="1.5px dashed blue"
              padding="3"
              minW="98%"
              minH="10vh"
              justifyContent="center"
              borderRadius="5px"
            >
              <Image
                src={getSlideUrl(slide?.awsImageBucketUrl)}
                alt="Your Image"
                objectFit="cover"
                // width="80%"
                // height="60%"
              />
            </Flex>
          </VStack>
          <VStack alignItems="flex-start" pt="1vw" pl="0.5vw">
            <Text fontSize="14px" fontWeight="bold">
              Case Details
            </Text>
          </VStack>
          <VStack alignItems="flex-start" pt="0.5vw" pl="0.5vw">
            <HStack justifyContent="space-between">
              <Text fontSize="14px" fontWeight="400">
                Organ:
              </Text>
              <Text fontSize="14px" fontWeight="400" pl="2vw">
                {slide?.organ}
              </Text>
            </HStack>
          </VStack>
          <VStack alignItems="flex-start" mb="2vh" pt="0.5vw" pl="0.5vw">
            <Input
              w="100%"
              borderRadius={0}
              _focus={{ outline: "none" }}
              placeholder="Accession ID"
              defaultValue={slide?.accessionId}
              onChange={handleDetails("accession_id")}
              fontSize="14px"
            />
            <Input
              w="100%"
              borderRadius={0}
              _focus={{ outline: "none" }}
              placeholder="Description"
              onChange={handleDetails("description")}
              defaultValue={slide?.description}
              // value={slideName}
              fontSize="14px"
            />
            {/* <HStack>
              <RadioGroup defaultValue={slide?.slide_type}>
                <HStack spacing={10} direction="row">
                  <Radio colorScheme="green" value="H&E" size="md">
                    <Text fontSize="14px">H&E</Text>
                  </Radio>
                  <Radio colorScheme="green" value="Trichrome" size="md">
                    <Text fontSize="14px">Trichrome</Text>
                  </Radio>
                </HStack>
              </RadioGroup>
            </HStack> */}
          </VStack>
        </ModalBody>
        <ModalFooter>
          <HStack spacing="1vw" justifyContent="flex-end" mb="1vh">
            <Button
              alignItems="center"
              bgColor="#fff"
              border="1px solid rgba(207, 226, 241, 1)"
              _focus={{ outline: "none" }}
              borderRadius="5px"
              fontWeight="400"
              fontSize="14px"
              px="30px"
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              alignItems="center"
              bgColor="rgba(196, 218, 236, 1)"
              _focus={{ outline: "none" }}
              borderRadius="5px"
              fontWeight="400"
              fontSize="14px"
              color="rgba(33, 34, 36, 1)"
              px="30px"
              onClick={() => updateSlide()}
            >
              Update
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default SlideEditModal;

import React, { useState, useEffect } from "react";
import {
  Flex,
  HStack,
  Text,
  useMediaQuery,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Image,
  Spinner,
} from "@chakra-ui/react";
import io from "socket.io-client";
import NoCaseIcon from "../../../static/Images/NoCaseIcon.svg";
import {
  useGetPIStudiesQuery,
  useGetUserInfoQuery,
  useLazyGetSlidesInfoQuery,
  useLazyGetStudyProgressQuery,
  useLazyGetStudyTimelineQuery,
  useGetStudyInfoForPIQuery,
  useGetAllPermissionQuery,
} from "../../../state/api/medicalApi";
import Toastify from "toastify-js";
import "toastify-js/src/toastify.css";
import OverViewScreen from "./StudyTabsScreen/OverViewScreen";
import SlideTabScreen from "./StudyTabsScreen/SlideTabScreen";
import Environment from "../../../environment";
import Questionnaire from "../Questionnaire";
import NotReportAvailableScreen from "./StudyTabsScreen/ReportTabScreenSubComponent/NotReportAvailableScreen";
const ClinicalOperationManagerStudyDetailScreen = () => {
  const socket = io(Environment.USER_URL);
  const [socketData, setSocketData] = useState("");
  const [studyId, setStudyId] = useState("");
  const [tabIndex, setTabIndex] = useState(0);
  const { data: userInfo } = useGetUserInfoQuery();
  const { data: PIStudies, isLoading, isFetching } = useGetPIStudiesQuery();
  const [ifWidthLessthan1920] = useMediaQuery("(max-width:1920px)");
  const [getSlidesInfo] = useLazyGetSlidesInfoQuery();
  const [getProgressInfo] = useLazyGetStudyProgressQuery();
  const [getTimelineInfo] = useLazyGetStudyTimelineQuery();

  const { data: studyInfo } = useGetStudyInfoForPIQuery(
    {
      caseId: studyId,
    },
    { skip: !studyId }
  );
  const { data: permission } = useGetAllPermissionQuery();

  // pi data fetching
  useEffect(() => {
    if (PIStudies?.data?.studyDesiredInfo?.length > 0) {
      setStudyId(PIStudies?.data?.studyDesiredInfo?.[0].caseId);
    }
  }, [PIStudies]);

  //tab changes
  useEffect(() => {
    const storedIndex = sessionStorage.getItem("tabIndex");
    if (storedIndex) {
      setTabIndex(Number(storedIndex));
    }
  }, []);

  //socket implementation
  useEffect(() => {
    if (userInfo) {
      socket.emit("join", userInfo?.data?.[0]?.organizationId);
    }
    socket.on("serverEvent", (data) => {
      setSocketData(data);
    });

    if (socketData?.message2 === "ResponseSaved") {
      getSlidesInfo({ studyId, slideType: "HAndE" });
      getSlidesInfo({ studyId, slideType: "Trichrome" });
      getProgressInfo();
      getTimelineInfo();
      Toastify({
        text: socketData?.message,
        duration: 3000,
        gravity: "bottom",
        position: "center",
        style: {
          background: "green",
        },
        onClick: function () {},
      }).showToast();
    }
    return () => {
      socket.disconnect();
    };
  }, [userInfo, socketData]);

  const handleTabChange = (index) => {
    setTabIndex(index);
    sessionStorage.setItem("tabIndex", index);
  };

  return (
    <Flex w="100%" h="100%" direction="column">
      {isLoading || isFetching ? (
        <Flex w="100%" h="90vh" align="center" justifyContent="center">
          <Spinner />
        </Flex>
      ) : (
        <>
          <HStack
            w="100%"
            h="10%"
            justifyContent="space-between"
            bg="light.100"
          >
            <Flex alignItems="center" ml="5px">
              <Text
                fontSize={ifWidthLessthan1920 ? "22px" : "33px"}
                ml="20px"
                pb="0.52916vw"
                pt="0.52916vw"
                fontWeight={ifWidthLessthan1920 ? "500" : "600"}
              >
                {studyInfo?.data?.caseInfo?.name}
              </Text>
            </Flex>
          </HStack>
          <Flex
            w="100%"
            h="100%"
            overflowY="auto"
            css={{
              "&::-webkit-scrollbar": {
                width: "0px",
              },
              "&::-webkit-scrollbar-track": {
                width: "0px",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "#C4C4C4",
              },
            }}
          >
            {true && (
              <Tabs w="100%" onChange={handleTabChange} index={tabIndex} isLazy>
                <TabList
                  h={ifWidthLessthan1920 ? "40px" : "60px"}
                  css={{ position: "fixed" }}
                  gap="20px"
                  bgColor="light.100"
                  // zIndex="3"
                  w="100%"
                >
                  <Tab
                    _selected={{
                      color: "#1B75BC",
                      borderBottom: "2px solid #1B75BC",
                    }}
                    w="6%"
                    fontSize={ifWidthLessthan1920 ? "14px" : "20px"}
                    fontWeight={ifWidthLessthan1920 ? "500" : "600"}
                    ml="15px"
                  >
                    <Text>Details</Text>
                  </Tab>
                  <Tab
                    _selected={{
                      color: "#1B75BC",
                      borderBottom: "2px solid #1B75BC",
                    }}
                    w="6%"
                    fontSize={ifWidthLessthan1920 ? "14px" : "20px"}
                    fontWeight={ifWidthLessthan1920 ? "500" : "600"}
                  >
                    <Text>Slides</Text>
                  </Tab>
                  <Tab
                    _selected={{
                      color: "#1B75BC",
                      borderBottom: "2px solid #1B75BC",
                    }}
                    fontSize={ifWidthLessthan1920 ? "14px" : "20px"}
                    fontWeight={ifWidthLessthan1920 ? "500" : "600"}
                  >
                    <Text>Questionnaire</Text>
                  </Tab>
                </TabList>

                <TabPanels zIndex="0" pt="40px">
                  <TabPanel p={0} h="78vh">
                    <OverViewScreen
                      studyId={studyId}
                      userInfo={userInfo}
                      socket={socket}
                      studyData={PIStudies?.data?.studyDesiredInfo[0]}
                      studyInfo={studyInfo}
                      permission={permission}
                    />
                  </TabPanel>
                  <TabPanel p={0} h="78vh">
                    <SlideTabScreen
                      studyId={studyId}
                      userInfo={userInfo}
                      studyInfo={studyInfo}
                      permission={permission}
                    />
                  </TabPanel>
                  <TabPanel p={0} h="78vh">
                    {/* <NotReportAvailableScreen /> */}
                    {permission?.data?.[0]?.permissions.includes(
                      "addQuestions"
                    ) ? (
                      <Questionnaire caseId={studyId} />
                    ) : (
                      <NotReportAvailableScreen />
                    )}
                  </TabPanel>
                </TabPanels>
              </Tabs>
            )}
            {false && (
              <Flex bgColor="rgb(244,244,244)" h="80.8vh" w="100%" mt="4vh">
                <Flex
                  h="76vh"
                  mx="14vw"
                  bgColor="white"
                  w="100%"
                  mt="3vh"
                  alignItems="center"
                  justifyContent="center"
                  flexDirection="column"
                >
                  <Image src={NoCaseIcon} w="120px" h="120px" />
                  <Text fontSize="14px" fontWeight="bold">
                    No study alotted to you.
                  </Text>
                </Flex>
              </Flex>
            )}
          </Flex>
        </>
      )}
    </Flex>
  );
};
export default ClinicalOperationManagerStudyDetailScreen;

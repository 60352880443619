import {
  Avatar,
  Flex,
  HStack,
  Spinner,
  Text,
  useMediaQuery,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { useGetReportAnalysisQuery } from "../../state/api/medicalApi";

function StudyParticipants({ addParticipants, userInfo, caseId }) {
  const {
    data: report,
    isFetching: isReportsFetching,
    isLoading,
  } = useGetReportAnalysisQuery(
    {
      case_id: caseId,
    },
    { skip: !caseId }
  );
  const [ifWidthLessthan1920] = useMediaQuery("(max-width:1920px)");

  return isLoading ? (
    <VStack
      fontSize={ifWidthLessthan1920 ? "14px" : "0.729vw"}
      py="1.25vh"
      px="0.937vw"
      alignItems="center"
      justifyContent="center"
      background="light.100"
      my="1vh"
      h="100%"
      boxShadow={
        addParticipants
          ? "1px 1px 2px rgba(176, 200, 214, 0.15)"
          : "0px 4px 4px rgba(0, 0, 0, 0.25)"
      }
    >
      <Spinner color="#3965C5" size="xl" thickness="4px" speed="0.65s" />
    </VStack>
  ) : (
    <VStack
      fontSize={ifWidthLessthan1920 ? "14px" : "0.729vw"}
      py="1.25vh"
      px="0.937vw"
      background="light.100"
      my="1vh"
      h="100%"
      alignItems="flex-start"
      boxShadow={
        addParticipants
          ? "1px 1px 2px rgba(176, 200, 214, 0.15)"
          : "0px 4px 4px rgba(0, 0, 0, 0.25)"
      }
    >
      <Flex justifyContent="space-between" alignItems="center" w="100%">
        <Text fontWeight={700} fontSize="14px">
          {addParticipants
            ? "Study Participants"
            : "Participants of the Case and their details"}
        </Text>
        {/* {addParticipants && <MdOutlinePersonAddAlt1 color="green" size={22} />} */}
      </Flex>
      {!addParticipants ? (
        <Flex w="100%" mt="1rem" justifyContent="space-between">
          <Text fontWeight={700} fontSize="12px">
            Participants
          </Text>
          <Text fontWeight={700} fontSize="12px">
            Report Status
          </Text>
        </Flex>
      ) : null}
      {!report?.data?.reportAnalysis?.length && (
        <Flex
          h="15vh"
          w="100%"
          alignItems="center"
          // alignContent="center"
          justifyContent="center"
        >
          <Text fontFamily="inter" fontSize="20px">
            No participants added
          </Text>
        </Flex>
      )}

      <Flex w="100%" flexDirection="column">
        {report?.data?.reportAnalysis?.map((user) => {
          return (
            <HStack
              key={user?.user_id}
              w="100%"
              borderBottom="1px solid rgba(0,0,0,0.2)"
              minH="2.0812vh"
              px="0.5208vw"
              pb="0.5.740vh"
              pt="1.11vh"
              justifyContent="space-between"
              alignItems="center"
            >
              <Flex alignItems="center">
                <Avatar w={10} h={10} mb="1vh" />
                <Text ml="1.5rem">
                  {`${user?.first_name}
              ${user?.last_name}`}
                </Text>
              </Flex>
              {!addParticipants && (
                <Text
                  isTruncated
                  textTransform="capitalize"
                  key={user?.user_id}
                >
                  {user?.reportAnalysis}
                </Text>
              )}
            </HStack>
          );
        })}
      </Flex>
    </VStack>
  );
}

export default StudyParticipants;

import {
  Button,
  Flex,
  HStack,
  Image,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";
import CaseCreatedIcon from "../../../static/Images/CaseCreatedIcon.svg";

const UserCreatedConfirmation = ({ setIsUserCreated, userData }) => {
  const [ifSmallScreen] = useMediaQuery("(max-width:1600px)");
  const navigate = useNavigate();
  const buttonStyle = {
    borderRadius: "10px",
    color: "#1B75BC",
    border: "1px solid rgba(27, 117, 188, 0.25)",
    background: "inherit",
  };

  return (
    <Flex w="100%" h="100%" direction="column">
      <HStack w="100%" justifyContent="space-between">
        <Button
          style={buttonStyle}
          w="198px"
          h="37px"
          onClick={() => {
            // setCaseCreatedPage(false);
            window.location.reload();
          }}
        >
          Add another User
        </Button>
        <Button
          style={buttonStyle}
          w="198px"
          h="37px"
          onClick={() => {
            setIsUserCreated(false);
            navigate(`/dashboard/admin`);
          }}
        >
          Close
        </Button>
      </HStack>
      <Flex
        w="100%"
        h="90%"
        alignItems="center"
        justifyContent="center"
        direction="column"
      >
        <Image src={CaseCreatedIcon} w="120px" h="120px" />
        <Text fontSize={ifSmallScreen ? "14px" : "20px"} color="light.1006">
          User added successfully!
        </Text>
        <Flex
          direction="column"
          alignItems="center"
          mb="6vh"
          w="50%"
          fontSize="14px"
        >
          <Text fontSize={ifSmallScreen ? "14px" : "20px"} mb="1vh">
            User Details
          </Text>
          <HStack w={ifSmallScreen ? "80%" : "50%"} mb="1vh">
            <Text minW="30%">User Name</Text>
            <Text minW="70%">{`: ${userData?.firstName} ${userData?.lastName}`}</Text>
          </HStack>
          <HStack w={ifSmallScreen ? "80%" : "50%"} mb="1vh">
            <Text minW="30%">Role</Text>
            <Text minW="70%">{`: ${localStorage.getItem("role")}`}</Text>
          </HStack>
          <HStack w={ifSmallScreen ? "80%" : "50%"} mb="1vh">
            <Text minW="30%">Hospital</Text>
            <Text minW="70%">{`: ${userData?.hospital}`}</Text>
          </HStack>
        </Flex>

        <Button
          borderRadius="10px"
          width="188px"
          height="37px"
          bgColor="rgba(27, 117, 188, 0.25)"
          color="light.1006"
          onClick={() => {
            sessionStorage.clear();
            navigate("/dashboard/admin");
            setIsUserCreated(false);
          }}
        >
          View User
        </Button>
      </Flex>
    </Flex>
  );
};

export default UserCreatedConfirmation;

import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { Auth0Provider } from "@auth0/auth0-react";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./state/store";
import Environment from "./environment";
import { registerServiceWorker } from "./Firebase/serviceWorker";

ReactDOM.render(
  <Auth0Provider
    domain={Environment.AUTH0_DOMAIN}
    clientId={Environment.AUTH0_CLIENT_ID}
    connection={Environment.AUTH0_CONNECTION}
    redirectUri={`${window.location.origin}/`}
    audience={Environment.AUTH0_AUDIENCE}
    cacheLocation="localstorage"
    scope="openid profile email offline_access"
  >
    <Provider store={store}>
      <App />
    </Provider>
  </Auth0Provider>,
  document.getElementById("root")
);

registerServiceWorker();

import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Text,
  VStack,
  HStack,
  Icon,
  useMediaQuery,
} from "@chakra-ui/react";
import SlideUpload from "./SlideUpload";

function SlideUploadModel({ setOpenUpload, isOpenUpload, studyId, userInfo }) {
  const [ifSmallScreen] = useMediaQuery("(max-width:1920px)");
  const headerStyle = {
    fontSize: ifSmallScreen ? "14px" : "16px",
    opacity: "0.6",
    fontWeight: "600",
  };
  return (
    <Modal
      isOpen={isOpenUpload}
      onClose={() => setOpenUpload(false)}
      size="xl"
      isCentered
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent background="light.200">
        <ModalHeader
          fontSize={ifSmallScreen ? "16px" : "18.5px"}
          font-weight="500"
          pl={ifSmallScreen ? "20px" : "27px"}
          pb={ifSmallScreen ? "10px" : "15px"}
          pt={ifSmallScreen ? "10px" : "19px"}
          bg="#F0F2FF"
          borderStyle="solid"
          borderWidth="0px 0px 2px 0px"
          borderColor="#DEDEDE"
        >
          Upload a Slide
        </ModalHeader>
        <ModalCloseButton
          pb={ifSmallScreen ? "20px" : "15px"}
          pt={ifSmallScreen ? "10px" : "21px"}
          mr={ifSmallScreen ? "10px" : "10.5"}
          _hover={{ opacity: 1 }}
        />
        <ModalBody>
          <SlideUpload
            setOpenUpload={setOpenUpload}
            studyId={studyId}
            userInfo={userInfo}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default SlideUploadModel;

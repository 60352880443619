import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  Flex,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import {
  linkQuestion,
  resetQuestionLink,
} from "../../state/reducers/newStudyReducer";
function LinkQuestionModal({
  isOpen,
  onClose,
  questionData,
  setQuestionData,
  activeButton,
}) {
  const dispatch = useDispatch();
  const { questions } = useSelector((state) => state.newStudyState);
  console.log(questions);
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody p={9}>
          <Text>
            The selected question has already linked to another option. Do you
            want add more linked options ?
          </Text>
          <Flex justifyContent="flex-end">
            <Button
              mr={3}
              size="sm"
              onClick={() => {
                dispatch(
                  resetQuestionLink({
                    type: activeButton,
                    index:
                      questions[activeButton][questionData?.index]
                        ?.conditionQuestion[0]?.index,
                  })
                );
                dispatch(
                  linkQuestion({
                    index: questionData?.index,
                    type: activeButton,
                    questionData: {
                      index: questionData?.index,
                      question_text: questionData?.questionText,
                      choice: questionData?.choice,
                      questionId: questionData?.question_id,
                      linkToIndex: questionData?.linkToIndex,
                    },
                  })
                );
                onClose();
              }}
              fontWeight="400"
              fontFamily="inter"
            >
              Replace
            </Button>
            <Button
              mr={3}
              size="sm"
              onClick={() => {
                dispatch(
                  linkQuestion({
                    index: questionData?.index,
                    type: activeButton,
                    questionData: {
                      index: questionData?.index,
                      question_text: questionData?.questionText,
                      choice: questionData?.choice,
                      questionId: questionData?.question_id,
                      linkToIndex: questionData?.linkToIndex,
                    },
                  })
                );
                onClose();
              }}
              fontWeight="400"
              fontFamily="inter"
              bg="#C6DDEE"
            >
              Add
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default LinkQuestionModal;

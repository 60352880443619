import { Flex, Input, Text, HStack } from "@chakra-ui/react";
import React from "react";

function StudyDetails({ studyData, handleDetails, handleConfidentialStatus }) {
  return (
    <Flex flexDirection="column" w="100%">
      <Text>Study Details</Text>
      <Flex
        m=" 3.148vh 0 2.592vh 0"
        justifyContent="space-between"
        flexWrap="wrap"
        alignItems="flex-start"
      >
        <Flex w="100%" justifyContent="space-between">
          <Flex align="left" direction="column" w="45%">
            <Text color="#000">Title*</Text>
            <Input
              type="text"
              onChange={handleDetails("studyTitle")}
              defaultValue={studyData.studyTitle}
              w="100%"
              h="3.055vh"
              minH="33px"
              bg="light.100"
              borderColor="light.900"
              placeholder="Enter text here"
            />
          </Flex>
          <Flex direction="column" w="45%">
            <Text color="#000">Participants to be recruited*</Text>
            <Input
              type="number"
              onChange={handleDetails("particpantsTobeRecruited")}
              defaultValue={studyData.particpantsTobeRecruited}
              w="100%"
              h="3.055vh"
              minH="33px"
              bg="light.100"
              borderColor="light.900"
              placeholder="Enter text here"
              maxLength="300"
            />
          </Flex>
        </Flex>
        <Flex w="100%" direction="column" mt="3.51vh">
          <Text color="#000">Aim*</Text>
          <Input
            type="text"
            onChange={handleDetails("aim")}
            defaultValue={studyData.aim}
            w="100%"
            h="3.055vh"
            minH="33px"
            bg="light.100"
            borderColor="light.900"
            placeholder="Enter text here"
            maxLength="300"
          />
          {/* <HStack pt="0.5vw">
            <input type="checkbox" onChange={handleAim} />
            <Text fontSize={ifWidthLessthan1920 ? "12px" : " 0.72916vw"}>
              Mark if this information is confidential
            </Text>
          </HStack> */}
        </Flex>
        <Flex direction="column" w="100%" mt="3.51vh">
          <Text color="#000">Objective*</Text>
          <Input
            type="text"
            onChange={handleDetails("objective")}
            defaultValue={studyData.objective}
            w="100%"
            h="3.055vh"
            minH="33px"
            bg="light.100"
            borderColor="light.900"
            placeholder="Enter text here"
            maxLength="300"
          />
          {/* <HStack pt="0.5vw">
            <input type="checkbox" onChange={handleObjective} />
            <Text fontSize={ifWidthLessthan1920 ? "12px" : " 0.72916vw"}>
              Mark if this information is confidential
            </Text>
          </HStack> */}
        </Flex>
        <Flex direction="column" w="100%" mt="3.51vh">
          <Text color="#000">Additional Details</Text>
          <Input
            type="text"
            onChange={handleDetails("studyDetails")}
            defaultValue={studyData.studyDetails}
            w="100%"
            h="3.055vh"
            minH="33px"
            bg="light.100"
            borderColor="light.900"
            placeholder="Enter text here"
            maxLength="300"
          />
          <HStack pt="3.51vh">
            <input type="checkbox" onChange={handleConfidentialStatus} />
            <Text>Mark if this information is confidential</Text>
          </HStack>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default StudyDetails;

import React from "react";
import { Bar, Pie } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import Loading from "../../components/Loading/loading";
import { Flex, HStack, Spinner, Text, VStack } from "@chakra-ui/react";
import {
  useGetDepartmentCasesCountQuery,
  useGetPhaseWiseCountQuery,
} from "../../state/api/medicalApi";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  ArcElement,
  Legend
);
function TrialsChart({ userInfo }) {
  // const depts = [12, 15, 17];
  // const phases = [25, 13, 45, 78];

  const { data: departmentCasesCount } = useGetDepartmentCasesCountQuery({
    ownerId: userInfo?.userId,
  });

  const { data: phaseCount } = useGetPhaseWiseCountQuery();
  const depts = [
    departmentCasesCount?.data?.total_case_histopathology,
    departmentCasesCount?.data?.total_case_cytopathology,
    departmentCasesCount?.data?.total_case_hematopathology,
  ];

  const phases = [
    phaseCount?.data?.phaseOne,
    phaseCount?.data?.phaseTwo,
    phaseCount?.data?.phaseThree,
    phaseCount?.data?.phaseFour,
  ];

  const options = {
    responsive: false,
    maintainAspectRatio: false,
    cutoutPercentage: 20, //Here for innerRadius. It's already exists
    outerRadius: 100, //Here for outerRadius
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  const getRandomColor = () => {
    const randomColor = Math.floor(Math.random() * 16777215).toString(16);
    return "#" + randomColor;
  };

  const pieDataDepts = {
    labels: ["Histopatholgy", "Cytopathology", "Hematopathology"],
    datasets: [
      {
        data: depts,
        backgroundColor: ["#3B5D7C", "#91A4B6", "#437CAF"],
        borderColor: ["#3B5D7C", "#91A4B6", "#437CAF"],
        borderWidth: 1,
      },
    ],
  };
  const pieDataPhases = {
    labels: ["Phase-1", "Phase-2", "Phase-3", "Phase-4"],
    datasets: [
      {
        data: phases,
        backgroundColor: ["#3B5D7C", "#91A4B6", "#437CAF", "#abe5f5"],
        borderColor: ["#3B5D7C", "#91A4B6", "#437CAF", "#abe5f5"],
        borderWidth: 1,
      },
    ],
  };

  return (
    <Flex
      h="36vh"
      maxHeight="36vh"
      background="#fff"
      boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
      width="100%"
      direction="column"
      p="1vw"
    >
      <Text fontSize="14px" fontFamily="inter" fontWeight={600}>
        Overall statistics
      </Text>
      <HStack h="30vh" w="100%" py="1rem" fontSize="12px">
        <VStack minW="50%" maxW="50%" align="center">
          <Pie data={pieDataDepts} options={options} />
          <Text mt="1rem">Study from different departments</Text>
        </VStack>
        <VStack align="center" minW="50%" maxW="50%">
          <Pie data={pieDataPhases} options={options} />
          <Text mt="1rem">Study from different phases</Text>
        </VStack>
      </HStack>
    </Flex>
  );
}

export default TrialsChart;

// 3 study:

// study-1: phase-1, phase-2, phase-3
// study-2: phase-1, phase-2, phase-3
// study-3: phase-1, phase-2, phase-3

import { BrowserRouter as Router } from "react-router-dom";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import RouterConfig from "./navigation/RouterConfig";
import "./App.css";
import { useState, useEffect } from "react";
import { Context } from "./state/Context";

const theme = extendTheme({
  colors: {
    light: {
      100: "#FFF",
      200: "#FAFAFA",
      300: "#ECECEC",
      400: "#00153F",
      500: "#F5F5F5",
      600: "#B00020",
      700: "#1B75BC",
      800: "#F6F6F6",
      900: "#DEDEDE",
      1000: "#FCFCFC",
      1001: "#E5E5E5",
      1002: "#AEAEAE",
    },
  },
  fonts: {
    body: "Inter, arial",
  },
  styles: {
    global: {
      body: {
        bg: "light",
        color: "black",
      },
      button: {
        fontFamily: "sans-serif",
      },
      header: {
        fontFamily: "Roboto",
      },
      nav: {
        fontFamily: "Roboto",
      },
    },
  },
});

const App = () => {
  const [contextStore, setContext] = useState(Context);

  // useEffect(()=> {
  //   setContext({...contextStore, "socketResponse": socket})
  // })

  console.log(contextStore);

  return (
    <Router>
      <Context.Provider value={{ contextStore, setContext }}>
        <ChakraProvider theme={theme}>
          <RouterConfig />
        </ChakraProvider>
      </Context.Provider>
    </Router>
  );
};

export default App;

import {
  Flex,
  Text,
  HStack,
  VStack,
  Button,
  useMediaQuery,
  useToast,
  Box,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import RoleConfiguration from "./RoleConfiguration";
import { useLazyCreateClinicalRoleQuery } from "../../../state/api/medicalApi";

const AddRoles = () => {
  const { user } = useAuth0();
  const toast = useToast();
  const navigate = useNavigate();
  // const { data: userInfo, isLoading } = useGetUserInfoQuery({
  //   subClaim: user?.sub,
  // });
  const [createNewRole] = useLazyCreateClinicalRoleQuery();
  const [ifWidthLessthan1920] = useMediaQuery("(max-width:1920px)");
  const [rolesData, setRolesData] = useState({
    roleTitle: "",
    role: "pi",
    colorIconIndex: 0,
    studyCreate: false,
    studyEdit: false,
    studyDelete: false,
    slideUpload: false,
    slideEdit: false,
    slideDelete: false,
    slideAssign: false,
    viewReport: false,
    addQuestions: false,
    reportDownload: false,
    reportSubmit: false,
    reportEdit: false,
    downloadAbility: false,
    slideAnnotation: false,
  });

  const roles = Object.entries(rolesData);
  const trueVals = [];
  for (let i = 0; i < roles.length; i++) {
    if (roles[i][1] === true) {
      trueVals.push(roles[i][0]);
    }
  }

  const handleSubmit = async () => {
    try {
      await createNewRole({
        name: rolesData.role,
        title: rolesData.roleTitle,
        permissions: trueVals,
        colorIconIndex: rolesData.colorIconIndex,
      }).unwrap();
      setRolesData({
        roleTitle: "",
        studyCreate: false,
        studyEdit: false,
        studyDelete: false,
        slideUpload: false,
        slideEdit: false,
        slideDelete: false,
        slideAssign: false,
        viewReport: false,
        addQuestions: false,
        reportDownload: false,
        reportSubmit: false,
        reportEdit: false,
        downloadAbility: false,
        slideAnnotation: false,
      });

      toast({
        title: "Status",
        description: "Role created successfully",
        status: "success",
        duration: 2000,
        isClosable: true,
      });

      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } catch (err) {
      toast({
        title: "Error",
        description: `Failed to add ${rolesData.roleTitle}, try again after some time!`,
        status: "error",
        duration: 6000,
        isClosable: true,
      });
    }
  };

  return (
    <Box>
      <Box w="100%">
        <Box
          display="flex"
          background="light.800"
          zIndex="100"
          alignItems="flex-end"
          justifyContent="center"
        >
          <Flex
            mt="10px"
            mb="15px"
            w="75%"
            bgColor="#fff"
            p="3% 6% 0 6%"
            direction="column"
            overflowY="scroll"
            css={{
              "&::-webkit-scrollbar": {
                width: "5px",
              },
              "&::-webkit-scrollbar-track": {
                width: "5px",
                marginTop: "58px",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "#C4C4C4",
              },
            }}
            h="90vh"
          >
            <Flex w="100%" justifyContent="space-between">
              <VStack alignItems="flex-start">
                <Text
                  fontSize={ifWidthLessthan1920 ? "16px" : "20px"}
                  color="light.1006"
                  fontWeight={600}
                >
                  Create Role
                </Text>
                <Text fontSize={ifWidthLessthan1920 ? "12px" : "14px"}>
                  Customise permissions to create new profiles in your
                  organisation
                </Text>
              </VStack>
              <HStack color="light.1006">
                <Button
                  w="150px"
                  bgColor="inherit"
                  border="1px solid rgba(27, 117, 188, 0.25)"
                  borderRadius="10px"
                  h="37px"
                  onClick={() => navigate("/dashboard/admin")}
                >
                  Cancel
                </Button>

                <Button
                  w="150px"
                  borderRadius="10px"
                  h="37px"
                  bgColor="rgba(27, 117, 188, 0.25)"
                  onClick={handleSubmit}
                  disabled={rolesData.roleTitle.length === 0}
                >
                  Save
                </Button>
              </HStack>
            </Flex>
            <RoleConfiguration
              rolesData={rolesData}
              setRolesData={setRolesData}
            />
          </Flex>
        </Box>
      </Box>
    </Box>
  );
};

export default AddRoles;

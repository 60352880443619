import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import datalabels from "chartjs-plugin-datalabels";
import { Text, HStack, useMediaQuery, Box } from "@chakra-ui/react";
import DoctorCard from "./DoctorCard";
import { useGetStudyProgressQuery } from "../../../../../state/api/medicalApi";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  datalabels
);

function ClinicalOperationManagerSlideChartScreen({
  socket,
  userInfo,
  particpantsTobeRecruited,
}) {
  let labelNo = "";
  const [ifWidthLessthan1920] = useMediaQuery("(max-width:1920px)");
  const { data: studyProgress, refetch } = useGetStudyProgressQuery();
  const slidesCount = studyProgress?.metadata?.map(
    (slides) => slides?.NumberOfSlides
  );
  const totalSlidesCount = slidesCount?.reduce((accumulator, currentValue) => {
    return +accumulator + +currentValue;
  }, 0);
  socket.on("serverEvent", (data) => {
    if (
      data === "Response saved by a reader" &&
      userInfo?.data?.[0]?.role === "PI"
    )
      refetch();
  });
  // const floatingLabels = {
  //   id: "floatingLabels",
  //   afterDatasetsDraw(chart, args, options) {
  //     const {
  //       ctx,
  //       chartArea: { top },
  //       scales: { x, y },
  //     } = chart;
  //     ctx.save();
  //     ctx.fillText("% of slides reported ", 10, 10);
  //   },
  // };
  const resultForHAndE = studyProgress?.data?.resultForHAndE;
  const HAndELabels = resultForHAndE?.map((user) => user?.first_name);
  const HAndEData = resultForHAndE?.map((user) => user?.slidesReported);
  const HAndEUploadedSlides = resultForHAndE?.map(
    (user) => user?.slidesAssigned
  );
  const HAndEFinalSlidesData = HAndEUploadedSlides?.map(
    (e, index) => e - HAndEData[index]
  );
  const slideAssigned =
    HAndEUploadedSlides && Array.from(HAndEUploadedSlides).fill(6);

  // const HAndEUploadedSlides =
  //   HAndEData &&
  //   HAndEData?.map((e) => studyProgress?.metadata?.[0].NumberOfSlides - e);

  const resultForTrichrome = studyProgress?.data?.resultForTrichrome;
  const TrichromeLabels = resultForTrichrome?.map((user) => user?.first_name);
  const TrichromeData = resultForTrichrome?.map((user) => user?.slidesReported);
  const TrichromeUploadedSlides = resultForTrichrome?.map(
    (user) => user?.slidesAssigned
  );
  const TrichromeFinalSlidesData = TrichromeUploadedSlides?.map(
    (e, index) => e - TrichromeData[index]
  );
  const TrichromeSlideAssigned =
    TrichromeUploadedSlides && Array.from(TrichromeUploadedSlides).fill(7);

  const optionForHandE = {
    indexAxis: "x",
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        title: {
          display: true,
          text: "No of cases",
          font: {
            size: 12,
          },
          beginAtZero: true,
        },
        grid: {
          display: false,
        },
        ticks: {
          font: {
            size: 12,
          },
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        displayColors: false,
        callbacks: {
          title: function (context) {
            return ``;
          },
          labelTextColor: () => {
            return "#000";
          },
          label: function (context) {
            let label = context.dataset.label || "";
            const index = context.dataIndex;
            if (label === "No. of slides reported") {
              label = `${(
                (context.parsed.y / HAndEUploadedSlides[index]) *
                100
              ).toFixed(0)}%`;
              labelNo = "";
            } else {
              label = "";
              labelNo = "notAlloted";
            }
            return label;
          },
        },
        backgroundColor: function (tooltipItem) {
          return labelNo === "notAlloted"
            ? "rgba(0, 0, 0, 0)"
            : ["rgba(196, 230, 196, 1)"];
        },
      },
      legend: {
        position: "bottom",
        align: "center",
        reverse: "true",

        labels: {
          usePointStyle: true,
          pointStyle: "rect",

          filter: function (legendItem, chartData) {
            return (
              legendItem.text !== "H & E" &&
              legendItem.text !== "No. of slides alotted"
              // legendItem.text !== "% of slides reported "
            );
          },
        },
      },
      title: {
        display: true,
        text: `H & E Slides - ${
          studyProgress?.metadata?.[0]?.NumberOfSlides
            ? studyProgress?.metadata?.[0]?.NumberOfSlides
            : ""
        } Slides`,
        align: "center",
        color: "rgba(80, 170, 80, 0.69)",
        padding: {
          bottom: 15,
        },
        font: {
          weight: "bold",
          family: "Inter",
          size: 16,
        },
      },
      subtitle: {
        display: true,
        text: "",
      },
      datalabels: {
        color: function (context) {
          let label = context.dataset.label || "";
          const index = context.dataIndex;
          return label === "No. of slides alotted"
            ? "rgba(242, 145, 17, 1)"
            : "#333";
        },
        font: {
          weight: "bold",
          size: 10,
        },
        backgroundColor: function (context) {
          let label = context.dataset.label || "";
          const index = context.dataIndex;
          return label === "No. of slides alotted"
            ? "rgba(242, 145, 17, 0.12)"
            : "rgba(0,0,0,0)";
        },
        borderRadius: function (context) {
          let label = context.dataset.label || "";
          const index = context.dataIndex;
          return label === "No. of slides alotted" ? 0 : "";
        },
        borderWidth: function (context) {
          let label = context.dataset.label || "";
          const index = context.dataIndex;
          return label === "No. of slides alotted" ? 4 : "";
        },
        formatter: function (value, context) {
          const datasetLabel = context.dataset.label;
          const index = context.dataIndex;
          const datasetValue = dataForHandE.datasets.find(
            (d) => d.label === datasetLabel
          )?.data[index];
          return datasetLabel === "No. of slides pending"
            ? `${datasetValue === 0 ? "" : datasetValue}`
            : datasetLabel === "No. of slides reported"
            ? `${datasetValue}`
            : datasetLabel === "No. of slides alotted"
            ? `${HAndEUploadedSlides[index]}`
            : "";
        },

        offset: function (context) {
          let label = context.dataset.label || "";
          const index = context.dataIndex;
          return HAndEFinalSlidesData?.[index] > 1 ? "-9.0" : "-1.0";
        },
        anchor: function (context) {
          let label = context.dataset.label || "";
          const index = context.dataIndex;
          return HAndEFinalSlidesData?.[index] > 1 ? "center" : "center";
        },
        align: function (context) {
          let label = context.dataset.label || "";
          const index = context.dataIndex;
          return label === "No. of slides alotted" ? "end" : "end";
        },
      },
    },
  };

  const optionForTrichrome = {
    indexAxis: "x",
    scales: {
      x: {
        // title: {
        //   display: true,
        //   text: "No. of Slides",
        //   font: {
        //     size: 12,
        //   },
        // },
        grid: {
          display: false,
        },
      },
      y: {
        title: {
          display: true,
          text: "No of cases  ",
          font: {
            size: 12,
          },
        },
        grid: {
          display: false,
        },
        ticks: {
          font: {
            size: 12, // Adjust the size of the y-axis ticks
          },
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "bottom",
        align: "center",
        reverse: true,

        labels: {
          usePointStyle: true,
          pointStyle: "rect",
          filter: function (legendItem, chartData) {
            return (
              legendItem.text !== "H & E" &&
              legendItem.text !== "No. of slides alotted"
              // legendItem.text !== "no of slides alotted" &&
              // legendItem.text !== "no of slides reported" &&
              // legendItem.text !== "% of slides reported "
            );
          },
        },
      },

      tooltip: {
        displayColors: false,
        callbacks: {
          title: function (context) {
            return ``;
          },
          labelTextColor: () => {
            return "#000";
          },
          label: function (context) {
            let label = context.dataset.label || "";
            const index = context.dataIndex;
            if (label === "No. of slides reported") {
              label = `${(
                (context.parsed.y / TrichromeUploadedSlides[index]) *
                100
              ).toFixed(0)}%`;
              labelNo = "";
            } else {
              label = "";
              labelNo = "notAlloted";
            }
            return label;
          },
        },
        backgroundColor: function (tooltipItem) {
          return labelNo === "notAlloted"
            ? "rgba(0, 0, 0, 0)"
            : ["rgba(67, 124, 175, 0.47)"];
        },
      },
      title: {
        display: true,
        text: `Trichrome slides - ${
          studyProgress?.metadata?.[1]?.NumberOfSlides
            ? studyProgress?.metadata?.[1]?.NumberOfSlides
            : ""
        } Slides`,
        align: "center",
        color: "rgba(177, 190, 203, 1)",
        padding: {
          bottom: 15,
        },
        font: {
          weight: "bold",
          family: "Inter",
          size: 16,
        },
      },
      subtitle: {
        display: true,
        text: "",
      },
      datalabels: {
        color: function (context) {
          let label = context.dataset.label || "";
          const index = context.dataIndex;
          return label === "No. of slides alotted"
            ? "rgba(242, 145, 17, 1)"
            : "#333";
        },
        font: {
          weight: "bold",
          size: 10,
        },
        backgroundColor: function (context) {
          let label = context.dataset.label || "";
          const index = context.dataIndex;
          return label === "No. of slides alotted"
            ? "rgba(242, 145, 17, 0.12)"
            : "rgba(0,0,0,0)";
        },
        borderRadius: function (context) {
          let label = context.dataset.label || "";
          const index = context.dataIndex;
          return label === "No. of slides alotted" ? 0 : "";
        },
        borderWidth: function (context) {
          let label = context.dataset.label || "";
          const index = context.dataIndex;
          return label === "No. of slides alotted" ? 4 : "";
        },
        formatter: function (value, context) {
          const datasetLabel = context.dataset.label;
          const index = context.dataIndex;
          const datasetValue = dataForTrichrome.datasets.find(
            (d) => d.label === datasetLabel
          )?.data[index];
          return datasetLabel === "No. of slides pending"
            ? `${datasetValue === 0 ? "" : datasetValue}`
            : datasetLabel === "No. of slides reported"
            ? `${datasetValue}`
            : datasetLabel === "No. of slides alotted"
            ? `${TrichromeUploadedSlides[index]}`
            : "";
          // return datasetLabel === "no of slides alotted"
          // ? `${(
          //     (studyProgress?.metadata?.[1]?.NumberOfSlides /
          //       particpantsTobeRecruited) *
          //     100
          //   ).toFixed(0)}%`
          //   : datasetLabel === "no of slides reported"
          //   ? `${((datasetValue / particpantsTobeRecruited) * 100).toFixed(0)}%`
          //   : "";
        },
        offset: function (context) {
          const index = context.dataIndex;
          return TrichromeFinalSlidesData?.[index] > 1 ? "-9.0" : "-8.0";
        },
        anchor: function (context) {
          const index = context.dataIndex;
          return TrichromeFinalSlidesData?.[index] > 1 ? "center" : "center";
        },
        align: function (context) {
          let label = context.dataset.label || "";
          const index = context.dataIndex;
          return label === "No. of slides alotted" ? "center" : "end";
        },
      },
    },
  };

  const tobeRecruited =
    HAndEUploadedSlides && Array.from([0, 1, 2]).fill(particpantsTobeRecruited);

  const dataForHandE = {
    labels: HAndELabels,
    datasets: [
      {
        label: "No. of slides reported",
        data: HAndEData ? HAndEData : [0],
        backgroundColor: ["rgba(80, 170, 80, 0.69)"],
        barThickness: 20,
        stack: "background",
      },

      {
        label: "No. of slides pending",
        data: HAndEFinalSlidesData,
        backgroundColor: ["#DEDEDE"],
        barThickness: 20,
        stack: "background",
      },
      {
        label: "No. of slides alotted",
        data: slideAssigned,
        backgroundColor: ["rgba(0, 0, 0, 0)"],
        barThickness: 20,
        stack: "background",
      },
      {
        label: "No. of slides alotted ",
        type: "line",
        data: slideAssigned,
        backgroundColor: ["rgba(242, 145, 17, 1)"],
        barThickness: 0.1,
        borderColor: "rgba(0,0,0,0)",
        borderDash: [5, 5],
        pointRadius: 0,
        borderWidth: 1,
      },
      // {
      //   label: "Buffer",
      //   data: [100],
      //   backgroundColor: ["rgba(0,0,0,0)"],
      //   barThickness: 1,
      // },
    ],
  };
  const dataForTrichrome = {
    labels: TrichromeLabels,
    datasets: [
      {
        label: "No. of slides reported",
        data: TrichromeData ? TrichromeData : [0],
        backgroundColor: ["rgba(67, 124, 175, 1)"],
        barThickness: 20,
        stack: "background",
      },
      // {
      //   label: "Buffer",
      //   data: [100],
      //   backgroundColor: ["rgba(0,0,0,0)"],
      //   barThickness: 1,
      // },
      {
        label: "No. of slides pending",
        data: TrichromeFinalSlidesData,
        backgroundColor: ["rgba(222, 222, 222, 1)"],
        barThickness: 20,
        stack: "background",
      },
      {
        label: "No. of slides alotted",
        data: TrichromeSlideAssigned,
        backgroundColor: ["rgba(0, 0, 0, 0)"],
        barThickness: 20,
        stack: "background",
      },
      {
        label: "No. of slides alotted ",
        type: "line",
        data: TrichromeSlideAssigned,
        backgroundColor: ["rgba(242, 145, 17, 1)"],
        barThickness: 0.1,
        borderColor: "rgba(0,0,0,0)",
        borderDash: [5, 5],
        pointRadius: 0,
        borderWidth: 1,
      },
    ],
  };

  return (
    <div
      style={{
        height: "41%",
        width: "96.2%",
        position: "relative",
        marginBottom: "1.5%",
        // padding: "1%",
        paddingRight: "3%",
        paddingLeft: "1%",
        background: "#fff",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <HStack w="100%" justifyContent="space-between" ml="10px" mb="5px">
        <Text
          color="#1B75BC"
          fontSize={ifWidthLessthan1920 ? "15px" : "18px"}
          fontWeight="bold"
        >
          Study Progress
        </Text>
      </HStack>
      <Text
        fontFamily="inter"
        fontSize="13px"
        fontWeight="600"
        ml="10px"
        mb="2vw"
      >
        Slides created and answered by Readers
        {/* <span
          style={{
            marginLeft: "18%",
          }}
        >
          Total Slides: {totalSlidesCount}
        </span> */}
      </Text>
      <Bar data={dataForHandE} options={optionForHandE} />
      <Box pt="2.2vh" />
      <Bar data={dataForTrichrome} options={optionForTrichrome} />
    </div>
  );
}

export default ClinicalOperationManagerSlideChartScreen;

import { Image, Flex, Text, VStack, Center } from "@chakra-ui/react";
import React from "react";
import ServerDownIcon from "../../images/error-pages/server-down.svg";

const ServerDown = () => {
  return (
    <Center w="100%" h="100vh">
      <Flex
        justify="center"
        align="center"
        maxW={{
          base: "450px",
          md: "550px",
          lg: "650px",
          xl: "750px",
          "2xl": "850px",
        }}
        h="auto"
        flexDir="column"
        gap="35px"
      >
        <Image src={ServerDownIcon} w="100%" h="auto" />
        <VStack spacing={3}>
          <Text
            fontWeight="600"
            fontSize={{
              base: "18px",
              md: "28px",
              lg: "32px",
              xl: "38px",
              "2xl": "50px",
            }}
            lineHeight={{
              base: "22px",
              md: "32px",
              lg: "36px",
              xl: "42px",
              "2xl": "60px",
            }}
          >
            Your Server is Feeling a Little Down
          </Text>
          <Text
            fontWeight="600"
            fontSize={{
              base: "14px",
              md: "18px",
              lg: "22px",
              xl: "30px",
              "2xl": "36px",
            }}
            lineHeight={{
              base: "17px",
              md: "20px",
              lg: "26px",
              xl: "36px",
              "2xl": "42px",
            }}
          >
            Will be Back Soon!!!
          </Text>
        </VStack>
      </Flex>
    </Center>
  );
};

export default ServerDown;

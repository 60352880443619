import React, { useState } from "react";
import {
  Box,
  Button,
  Flex,
  HStack,
  Spinner,
  Text,
  useToast,
} from "@chakra-ui/react";
import {
  useGetUserInfoQuery,
  useCreateStudyMutation,
  useAddReadersToStudyMutation,
} from "../../state/api/medicalApi";
import { useNavigate } from "react-router-dom";
import StudyDetails from "./StudyDetails";
import { useSelector, useDispatch } from "react-redux";
import { resetQuestions } from "../../state/reducers/newStudyReducer";
import ParticipantsQuestionnaire from "./ParticipantsQuestionnaire";
import { BsFillCircleFill } from "react-icons/bs";
import StudyConfirmation from "./StudyConfirmation";

function NewStudy() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { questions } = useSelector((state) => state.newStudyState);
  const [pageIndex, setPageIndex] = useState(0);
  const { data: userInfo } = useGetUserInfoQuery();
  const [createStudy] = useCreateStudyMutation();
  const [addReader] = useAddReadersToStudyMutation();
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [studyCreated, setStudyCreated] = useState(false);

  const [blurState, setBlurState] = useState(false);
  const [participants, setParticipants] = useState([]);
  const [studyData, setStudyData] = useState({
    studyTitle: "",
    aim: "",
    objective: "",
    studyDetails: "",
    duration: "",
    department: "",
    site: "",
  });
  const [detailsConfidential, setDetailsConfidential] = useState(false);
  const handleConfidentialStatus = () => {
    setDetailsConfidential(!detailsConfidential);
  };

  //input data handler
  const handleDetails = (input) => (e) => {
    const { value } = e.target;
    setStudyData((prevState) => ({
      ...prevState,
      [input]: value,
    }));
  };

  const userIds = selectedOptions?.map((options) => {
    return options.value;
  });

  //create case and add reader handler
  const id = "test-toast";
  const toast = useToast();
  const toastMethod = (status, message) => {
    if (!toast.isActive(id)) {
      toast({
        id,
        title: "Error",
        description: `${message}`,
        status: `${status}`,
        duration: 6000,
        isClosable: true,
      });
    }
    return false;
  };
  const validDuration = (duration) => {
    if (duration.length > 3) {
      toastMethod("error", "Length of duration should not exceed 3 digits");
      return false;
    } else return true;
  };
  const validation = (data) => {
    return validDuration(data.duration);
  };
  const handleCreateStudy = async () => {
    if (pageIndex === 0) setPageIndex(1);
    else {
      if (!validation(studyData)) return;
      setBlurState(true);
      const resp = await createStudy({
        ownerId: userInfo?.data[0]?.userId,
        organizationEmail: userInfo?.data[0]?.emailAddress,
        Aim: studyData?.aim,
        name: studyData?.studyTitle,
        Objectives: studyData?.objective,
        Studydetails: studyData?.studyDetails,
        particpantsTobeRecruited: parseInt(studyData?.particpantsTobeRecruited),
        isStudyDetailsConfidential: detailsConfidential,
        participants: [selectedOptions.length],
        questions,
      });
      // await addReader({
      //   caseId: resp?.data?.data?.caseCreation[0]?.case_id,
      //   userIds: userIds,
      // });
      setStudyCreated(true);
      dispatch(resetQuestions());
      sessionStorage.setItem("tabIndex", 0);
      navigate("/dashboard/PI");
    }
  };
  const handleClose = () => {
    dispatch(resetQuestions());
    navigate("/dashboard/PI");
  };
  return (
    <>
      <Flex flex="1" justifyContent="center">
        <Flex
          w="75%"
          mt="2.7777vh"
          flexDirection="column"
          background="light.100"
          align="center"
          p="3.24vh 6.71875vw"
          h="100vh"
        >
          {studyCreated ? (
            <StudyConfirmation />
          ) : (
            <>
              <Flex justifyContent="space-between" w="100%">
                <Flex flexDir="column">
                  <Text color="light.700">Create new study</Text>
                  <Text>Fill the details below to start a new study.</Text>
                </Flex>
                <Flex pt="2.703vh">
                  <Button
                    mr="1.30vw"
                    bg="none"
                    _hover={{ bg: "none" }}
                    _focus={{ bg: "none" }}
                    border="1px solid rgba(27, 117, 188, 0.25)"
                    color="light.700"
                    fontWeight="400"
                    h="2.96vh"
                    minH="30px"
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    _hover={{ bg: "none" }}
                    border="1px solid rgba(27, 117, 188, 0.25)"
                    color="light.700"
                    bg="rgba(27, 117, 188, 0.25)"
                    fontWeight="400"
                    h="2.96vh"
                    minH="30px"
                    _focus={{ outline: "none" }}
                    onClick={handleCreateStudy}
                    disabled={
                      !studyData?.aim ||
                      !studyData?.studyTitle ||
                      !studyData?.particpantsTobeRecruited ||
                      !studyData?.objective
                    }
                  >
                    {pageIndex === 0 ? "Next" : "Save"}
                  </Button>
                </Flex>
              </Flex>
              <Flex w="100%" justifyContent="center" mb="10px">
                <HStack spacing="26px">
                  <BsFillCircleFill
                    color={
                      pageIndex === 0 ? "rgba(27, 117, 188, 0.43)" : "#D9D9D9"
                    }
                    style={{ cursor: "pointer" }}
                    onClick={() => setPageIndex(0)}
                  />
                  <BsFillCircleFill
                    color={
                      pageIndex === 1 ? "rgba(27, 117, 188, 0.43)" : "#D9D9D9"
                    }
                    style={{ cursor: "pointer" }}
                    onClick={() => setPageIndex(1)}
                  />
                </HStack>
              </Flex>
              <Flex flexDirection="column" w="100%" justify="flex-start">
                {pageIndex === 0 ? (
                  <StudyDetails
                    studyData={studyData}
                    handleDetails={handleDetails}
                    handleConfidentialStatus={handleConfidentialStatus}
                  />
                ) : (
                  <ParticipantsQuestionnaire
                    userInfo={userInfo}
                    selectedOptions={selectedOptions}
                    setSelectedOptions={setSelectedOptions}
                  />
                )}
              </Flex>
            </>
          )}
        </Flex>
      </Flex>
      <Box
        background="rgba(0,0,0,0.1)"
        zIndex="100"
        display={blurState ? "flex" : "none"}
        alignItems="center"
        justifyContent="center"
        h="100vh"
        w="100vw"
        position="absolute"
        left="0"
        top="0"
      >
        <Spinner color="#3B5D7C" size="xl" />
      </Box>
    </>
  );
}

export default NewStudy;

import React, { useState, useEffect } from "react";
import {
  Button,
  Flex,
  HStack,
  Text,
  useMediaQuery,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Box,
  useToast,
} from "@chakra-ui/react";
import { useNavigate, useLocation } from "react-router-dom";
import { IoArrowBackCircleSharp } from "react-icons/io5";
import { useAuth0 } from "@auth0/auth0-react";
import { BiPencil } from "react-icons/bi";
import { AiOutlineCaretDown } from "react-icons/ai";
import OverViewScreen from "./StudyTabsScreen/OverViewScreen";

const StudyDetailScreen = () => {
  const toast = useToast();
  const state = useLocation();
  const { user } = useAuth0();
  const [tabIndex, setTabIndex] = useState(0);
  const handleTabChange = (index) => {
    setTabIndex(index);
    sessionStorage.setItem("tabIndex", index);
  };
  useEffect(() => {
    const storedIndex = sessionStorage.getItem("tabIndex");
    if (storedIndex) {
      setTabIndex(Number(storedIndex));
    }
  }, []);
  const blankSpace = "\u00A0";
  const navigate = useNavigate();
  const [ifWidthLessthan1920] = useMediaQuery("(max-width:1920px)");
  return (
    <Flex w="100%" h="100%" direction="column">
      <HStack w="100%" h="10%" justifyContent="space-between">
        <Flex alignItems="center" ml="5px">
          <Button
            border="1px solid #E8E8EB"
            borderRadius="5px"
            bgColor="inherit"
            fontSize="13px"
            fontWeight={500}
          >
            <HStack>
              <IoArrowBackCircleSharp size={20} />
              <Text>Back</Text>
            </HStack>
          </Button>
          <Text
            fontSize={ifWidthLessthan1920 ? "22px" : "33px"}
            ml="20px"
            fontWeight={ifWidthLessthan1920 ? "500" : "600"}
          >
            Case Name
          </Text>
        </Flex>
        <Flex></Flex>
      </HStack>
      <Flex
        w="100%"
        h="100%"
        overflowY="auto"
        css={{
          "&::-webkit-scrollbar": {
            width: "0px",
          },
          "&::-webkit-scrollbar-track": {
            width: "0px",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "#C4C4C4",
          },
        }}
      >
        <Tabs w="100%" onChange={handleTabChange} index={tabIndex} isLazy>
          <TabList
            h={ifWidthLessthan1920 ? "40px" : "60px"}
            css={{ position: "fixed" }}
            gap="20px"
            bgColor="light.100"
            zIndex="3"
            w="100%"
          >
            <Tab
              _selected={{
                color: "#1B75BC",
                borderBottom: "2px solid #1B75BC",
              }}
              w="10%"
              fontSize={ifWidthLessthan1920 ? "14px" : "20px"}
              fontWeight={ifWidthLessthan1920 ? "500" : "600"}
            >
              Overview
            </Tab>
            <Tab
              _selected={{
                color: "#1B75BC",
                borderBottom: "2px solid #1B75BC",
              }}
              w="10%"
              fontSize={ifWidthLessthan1920 ? "14px" : "20px"}
              fontWeight={ifWidthLessthan1920 ? "500" : "600"}
            >
              Slides
            </Tab>
            <Tab
              _selected={{
                color: "#1B75BC",
                borderBottom: "2px solid #1B75BC",
              }}
              w="12%"
              fontSize={ifWidthLessthan1920 ? "14px" : "20px"}
              fontWeight={ifWidthLessthan1920 ? "500" : "600"}
            >
              Clinical History
            </Tab>
            <Tab
              _selected={{
                color: "#1B75BC",
                borderBottom: "2px solid #1B75BC",
              }}
              w="12%"
              fontSize={ifWidthLessthan1920 ? "14px" : "20px"}
              fontWeight={ifWidthLessthan1920 ? "500" : "600"}
            >
              Gross Images
            </Tab>

            <Tab
              _selected={{
                color: "#1B75BC",
                borderBottom: "2px solid #1B75BC",
              }}
              w="10%"
              fontSize={ifWidthLessthan1920 ? "14px" : "20px"}
              fontWeight={ifWidthLessthan1920 ? "500" : "600"}
            >
              Report
            </Tab>
            <Tab
              _selected={{
                color: "#1B75BC",
                borderBottom: "2px solid #1B75BC",
              }}
              w="10%"
              fontSize={ifWidthLessthan1920 ? "14px" : "20px"}
              fontWeight={ifWidthLessthan1920 ? "500" : "600"}
            >
              Timeline
            </Tab>
          </TabList>

          <TabPanels zIndex="0" pt="40px">
            <TabPanel p={0}>
              <OverViewScreen />
            </TabPanel>
            <TabPanel p={0}>
              hii
              {/* <SlidesTab
                setNotifyOpenSlide={setNotifyOpenSlide}
                caseId={state.state.caseId}
                caseInfo={caseInfo}
                isLoading={isLoading}
                permissions={state.state.permissions}
              /> */}
            </TabPanel>
            <TabPanel p="0">
              hii
              {/* <ClinicalHistory
                caseInfo={caseInfo}
                permissions={state.state.permissions}
              /> */}
            </TabPanel>
            <TabPanel p="0">
              hii
              {/* <GrossImages
                caseInfo={caseInfo}
                permissions={state.state.permissions}
              /> */}
            </TabPanel>
            <TabPanel p="0">
              hii
              {/* <ReportTab
                caseInfo={caseInfo}
                slideInfo={slideInfo}
                userInfo={userInfo}
                report={report}
                permissions={state.state.permissions}
              /> */}
            </TabPanel>
            <TabPanel>
              hii
              {/* <TimelineExtended caseInfo={caseInfo} setTabIndex={setTabIndex} /> */}
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Flex>
    </Flex>
  );
};
export default StudyDetailScreen;

import {
  Flex,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  Button,
  Menu,
  MenuItem,
  MenuList,
  MenuButton,
} from "@chakra-ui/react";
import { TiArrowSortedDown } from "react-icons/ti";
import { IoMdMore } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { useGetStudiesQuery } from "../../state/api/medicalApi";
import moment from "moment";

const StudiesList = () => {
  const { data: studies } = useGetStudiesQuery();
  const navigate = useNavigate();
  const rowHeadStyle = {
    fontFamily: "Inter",
    fontWeight: "600",
    color: "#000",
    textTransform: "none",
  };
  const tableBodyStyles = {
    fontFamily: "Inter",
    fontWeight: "500",
    fontSize: "0.9rem",
    color: "#000",
    textTransform: "none",
    paddingTop: "0.3125rem",
    paddingBottom: "0.3125rem",
  };
  const tableStyle = {
    maxHeight: "30vh",
    overflow: "hidden",
  };
  return (
    <Flex direction="column" bg="light.1004" h="93vh">
      <Flex
        h="7.0370vh"
        minH="40px"
        bg="light.100"
        minW="100vw"
        alignItems="center"
      >
        <Flex
          ml="2.7604vw"
          h="100%"
          borderBottom="4px solid #1B75BC"
          alignItems="center"
          px="1rem"
          boxShadow="0px 1px 2px 0px rgba(176, 200, 214, 0.10)"
        >
          <Text color="light.700">My Institute</Text>
        </Flex>
      </Flex>
      <Flex
        mt="6.5704vh"
        justifyContent="center"
        bg="light.1004"
        minH="75vh"
        maxH="75vh"
      >
        <Flex
          w="65.3645vw"
          minW="300px"
          bg="light.100"
          borderRadius="10px"
          direction="column"
          overflow="auto"
        >
          <Flex justifyContent="flex-end" m="1rem">
            <Button
              size="sm"
              rightIcon={<TiArrowSortedDown />}
              border=" 1px solid #E8E8EB"
              bg="light.100"
              fontWeight="500"
            >
              Sort by
            </Button>
          </Flex>
          <Table style={tableStyle} size="md">
            <Thead bgColor="#E8E8EB">
              <Tr>
                <Th style={rowHeadStyle}>Study No.</Th>
                <Th style={rowHeadStyle}>Title</Th>
                <Th style={rowHeadStyle}>COM</Th>
                <Th style={rowHeadStyle}>Created on</Th>
                <Th style={rowHeadStyle}></Th>
              </Tr>
            </Thead>
            <Tbody>
              {studies?.data?.map((study, i) => {
                return (
                  <Tr key={i}>
                    <Th style={tableBodyStyles}>{i + 1}</Th>
                    <Th style={tableBodyStyles}>{study?.study_title}</Th>
                    <Th style={tableBodyStyles}>
                      {`${study?.first_name}
                       ${study?.last_name}`}
                    </Th>
                    <Th style={tableBodyStyles}>
                      {moment(study?.created_at).format("DD.MM.YY")}
                    </Th>
                    <Th style={tableBodyStyles}>
                      <Menu placement="right">
                        <MenuButton as={Button} bg="light.100">
                          <IoMdMore size={18} />
                        </MenuButton>
                        <MenuList p="0">
                          <MenuItem
                            py="0.6rem"
                            fontFamily="inter"
                            onClick={() =>
                              navigate("/admin/study-report", {
                                state: {
                                  study,
                                },
                              })
                            }
                          >
                            View Report
                          </MenuItem>
                        </MenuList>
                      </Menu>
                    </Th>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </Flex>
      </Flex>
    </Flex>
  );
};
export default StudiesList;

import React, { useState } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Avatar,
  Text,
  Button,
} from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import { getSlideUrl } from "../../../../../hooks/utility";
import ReportModal from "../../../../Report/ReportModal";
import moment from "moment";

const SlideTableView = ({ slides, userInfo, caseId, permission }) => {
  const [slideId, setSlideId] = useState();
  const [isReportModalOpen, setIsReportModalOpen] = useState(false);
  const [slideData, setSlideData] = useState();
  const [readerId, setReaderId] = useState();
  const handleReportCloseModal = () => {
    setIsReportModalOpen(false);
  };
  console.log("slides", slides);
  const navigate = useNavigate();
  return (
    <Table
      variant="simple"
      bgColor="white"
      borderWidth="1px"
      borderRadius="md"
      mx="14vw"
    >
      <Thead>
        <Tr position="sticky" top="0" bgColor="white" zIndex="1">
          <Th />
          <Th>Accession Id</Th>
          <Th>Status</Th>
          <Th>Upload Date</Th>
          <Th>Save Date</Th>
          <Th>Report</Th>
        </Tr>
      </Thead>
      <Tbody>
        {slides?.map((item, index) => (
          <Tr
            key={index}
            borderBottom="1px" // Add border to each row
            borderColor="gray.200" // Set border color
            fontSize="14px"
            onClick={() => {
              navigate("/viewer", {
                state: {
                  caseId: caseId,
                  slide: item,
                  userInfo,
                },
              });
            }}
          >
            <Td borderBottom="none" display="flex" alignItems="center">
              <Avatar
                size="sm"
                name={item.accessionId}
                src={getSlideUrl(item?.awsImageBucketUrl)}
                color="#000"
                bg="rgba(27, 117, 188, 0.25)"
              />
            </Td>
            <Td>{item.accessionId}</Td>
            <Td>
              {item?.status ? (
                <Text
                  color="rgba(50, 89, 38, 1)"
                  fontSize="12px"
                  fontWeight="bold"
                >
                  SAVED
                </Text>
              ) : (
                <Text
                  color="rgba(27, 117, 188, 1)"
                  fontSize="12px"
                  fontWeight="bold"
                >
                  NEW
                </Text>
              )}
            </Td>
            <Td>{moment(item?.created_at).format("DD/MM/YYYY")}</Td>
            <Td>
              {item?.status !== false
                ? moment(item?.responses?.saved_at).format("DD/MM/YYYY")
                : ""}
            </Td>{" "}
            <Td>
              {permission?.data?.[0]?.permissions.includes("viewReport") &&
                (item?.status !== false ? (
                  <Button
                    justifyContent="center"
                    bg="rgba(27, 117, 188, 0.25)"
                    _hover={{ bg: "rgba(27, 117, 188, 0.25)" }}
                    color="light.700"
                    borderRadius="10px"
                    fontSize="14px"
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsReportModalOpen(true);
                      setSlideData(item);
                      setReaderId(userInfo?.data?.[0]?.userId);
                    }}
                  >
                    View Report
                  </Button>
                ) : (
                  ""
                ))}
            </Td>
            {slideData?._id === item?._id && (
              <ReportModal
                isOpen={isReportModalOpen}
                onClose={handleReportCloseModal}
                slide={item}
                studyId={caseId}
                readerId={readerId}
              />
            )}
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
};

export default SlideTableView;

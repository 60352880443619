import React, { useState } from "react";
import { RiDeleteBin6Line, RiLayoutTopLine } from "react-icons/ri";
import { BsLink45Deg } from "react-icons/bs";
import { Box, Button, Flex, useToast } from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { addQuestions } from "../../state/reducers/newStudyReducer";
import { AiOutlinePlusCircle } from "react-icons/ai";
import QuestionTemplate from "./QuestionTemplate";
import SectionQuestions from "./SectionQuestions";
import { v4 as uuid } from "uuid";
function CustomQuestionnaire({
  questionState,
  setQuestionState,
  setLinkState,
  linkState,
  activeButton,
}) {
  const toast = useToast();
  const dispatch = useDispatch();
  const [questionText, setQuestionText] = useState("");
  const [choices, setChoices] = useState([""]);
  const [isSection, setIsSection] = useState(false);
  const [sectionHeading, setSectionHeading] = useState("");

  const initialSectionData = {
    isSection: true,
    question_text: "",
    sectionHeading: "",
    question_type: "",
    choices: [],
    sectionQuestions: [],
    conditionQuestion: [],
    questionId: uuid(),
  };
  const [sectionData, setSectionData] = useState(initialSectionData);

  const Questions = {
    question_text: questionText,
    question_type: questionState,
    choices: [...choices],
    isSection,
    sectionQuestions: [],
    sectionHeading: "",
    conditionQuestion: [],
    questionId: uuid(),
  };
  const removeQuestionData = () => {
    if (isSection) {
      const section = document.getElementsByName("sectionQuestionData")[0];
      section.reset();
      setQuestionText("");
      setSectionData(initialSectionData);
      setSectionHeading("");
    } else {
      var options = document.getElementsByName("questionData")[0];
      options?.reset();
      setChoices([""]);
      setQuestionText("");
    }
  };

  const addQuestion = (e) => {
    e.preventDefault();
    if (isSection) {
      if (sectionHeading && sectionData?.sectionQuestions?.length) {
        dispatch(addQuestions({ data: sectionData, type: activeButton }));
        setSectionData(initialSectionData);
        setSectionHeading("");
      } else {
        toast({
          title: "Please add section heading and questions!!",
          status: "error",
          duration: 1500,
          isClosable: false,
        });
      }
    } else {
      dispatch(addQuestions({ data: Questions, type: activeButton }));
      removeQuestionData();
    }
  };
  return (
    <Box flex="1" bg="#fcfcfc" overflow="auto">
      <>
        {isSection ? (
          <>
            <Box
              boxShadow=" 1px 1px 6px rgba(176, 200, 214, 0.25)"
              px="0.8333vw"
            >
              <SectionQuestions
                sectionData={sectionData}
                sectionHeading={sectionHeading}
                setQuestionText={setQuestionText}
                questionText={questionText}
                setSectionHeading={setSectionHeading}
                activeButton={activeButton}
              />
            </Box>

            <Flex m="1vh" justify="flex-end">
              <Button
                background="none"
                onClick={(e) => addQuestion(e)}
                size="sm"
                _focus={{ outline: "none" }}
              >
                <AiOutlinePlusCircle size={17} />
              </Button>
              <Button
                background="none"
                size="sm"
                onClick={() => setIsSection(!isSection)}
                _focus={{ outline: "none" }}
              >
                <RiLayoutTopLine
                  size={17}
                  color={isSection ? "blue" : ""}
                  bg={isSection ? "blue" : ""}
                />
              </Button>
              <Button
                background="none"
                size="sm"
                _focus={{ outline: "none" }}
                onClick={() => {
                  setLinkState(!linkState);
                }}
              >
                <BsLink45Deg
                  size={18}
                  color={linkState ? "blue" : ""}
                  bg={linkState ? "blue" : ""}
                />
              </Button>

              <Button
                background="none"
                onClick={removeQuestionData}
                size="sm"
                _focus={{ outline: "none" }}
              >
                <RiDeleteBin6Line />
              </Button>
            </Flex>
          </>
        ) : (
          <form name="questionData" onSubmit={addQuestion}>
            <Box boxShadow=" 1px 1px 6px rgba(176, 200, 214, 0.25)">
              <QuestionTemplate
                setQuestionState={setQuestionState}
                questionState={questionState}
                questionText={questionText}
                setQuestionText={setQuestionText}
                choices={choices}
                setChoices={setChoices}
              />
            </Box>
            <Flex m="1vh" justify="flex-end" bg="#fcfcfc">
              <Button
                background="none"
                type="submit"
                size="sm"
                _focus={{ outline: "none" }}
              >
                <AiOutlinePlusCircle size={17} />
              </Button>
              <Button
                background="none"
                size="sm"
                onClick={() => setIsSection(!isSection)}
                _focus={{ outline: "none" }}
              >
                <RiLayoutTopLine
                  size={17}
                  color={isSection ? "blue" : ""}
                  bg={isSection ? "blue" : ""}
                />
              </Button>
              <Button
                background="none"
                size="sm"
                _focus={{ outline: "none" }}
                onClick={() => {
                  setLinkState(!linkState);
                }}
              >
                <BsLink45Deg
                  size={18}
                  color={linkState ? "blue" : ""}
                  bg={linkState ? "blue" : ""}
                />
              </Button>

              <Button
                background="none"
                onClick={removeQuestionData}
                size="sm"
                _focus={{ outline: "none" }}
              >
                <RiDeleteBin6Line />
              </Button>
            </Flex>
          </form>
        )}
      </>
    </Box>
  );
}
export default CustomQuestionnaire;

import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Text } from "@chakra-ui/react";
import { useGetSlidesStatusForCaseQuery } from "../../state/api/medicalApi";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function Chart({ caseId }) {
  const { data: slidesStatus } = useGetSlidesStatusForCaseQuery({
    case_id: caseId,
  });
  const options = {
    scaleShowLabels: false,
    indexAxis: "y",
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          display: false,
        },
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
        align: "start",
        labels: {
          usePointStyle: true,
          pointStyle: "rect",
        },
      },
      title: {
        display: false,
        text: "Plan vs Actual",
        align: "start",
        padding: {
          bottom: 20,
        },
        font: {
          family: "inter",
          fontWeight: "bold",
        },
      },
    },
  };
  var labels = [""];
  const data = {
    labels,
    datasets: [
      {
        label: "Actual",
        data: [slidesStatus?.data?.completedSlidesCount[0]?.completedSlides],
        backgroundColor: "rgba(14, 208, 33, 0.35)",
        barThickness: 40,
      },
      {
        label: "Plan",
        data: [slidesStatus?.data?.TotalSlidesCount[0]?.TotalSlides],
        backgroundColor: "rgba(59, 93, 124, 0.5) ",
        barThickness: 40,
      },
    ],
  };
  return (
    <div
      style={{
        height: "100%",
        maxHeight: "100%",
        maxWidth: "600px",
        minWidth: "400px",
        width: "100%",
        position: "relative",
        marginBottom: "1%",
        padding: "1%",
        background: "#fff",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Text fontFamily="inter" fontSize="14px" fontWeight="600" ml="14px">
        Plan vs Actual
      </Text>
      <Bar data={data} options={options} />
    </div>
  );
}

export default Chart;

import React, { useState, useEffect } from "react";
import { Progress, Text, Box, HStack } from "@chakra-ui/react";

const ProgressBar = ({ percentage }) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    // Update progress when the percentage prop changes
    setProgress(percentage);
  }, [percentage]);

  return (
    <Box>
      <Progress value={progress} size="sm" colorScheme="green" />
    </Box>
  );
};

export default ProgressBar;

import { Button, Flex, HStack, Image, Text } from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";
import CaseCreatedIcon from "../../static/CaseCreatedIcon.svg";

const StudyConfirmation = () => {
  const navigate = useNavigate();
  const buttonStyle = {
    borderRadius: "10px",
    color: "#1B75BC",
    border: "1px solid rgba(27, 117, 188, 0.25)",
    background: "inherit",
  };

  return (
    <Flex w="100%" h="100%" direction="column">
      <HStack w="100%" justifyContent="space-between">
        <Button
          style={buttonStyle}
          w="198px"
          h="37px"
          onClick={() => {
            window.location.reload();
          }}
          fontWeight="500"
        >
          Create Another Case
        </Button>
        <Button
          style={buttonStyle}
          w="198px"
          h="37px"
          onClick={() => {
            navigate("/studies");
          }}
          fontWeight="500"
        >
          Close
        </Button>
      </HStack>
      <Flex
        w="100%"
        h="90%"
        alignItems="center"
        justifyContent="center"
        direction="column"
      >
        <Image src={CaseCreatedIcon} w="120px" h="120px" />
        <Text color="light.700" fontweight="600">
          Study Created Successfully!
        </Text>
        <Text mb="4vh">
          You will be notified when the pathologist will review it.
        </Text>

        <Button
          borderRadius="10px"
          width="188px"
          height="37px"
          bgColor="rgba(27, 117, 188, 0.25);"
          color="light.700"
          onClick={() => {
            sessionStorage.clear();
            navigate("/studies");
          }}
          fontWeight="500"
        >
          Open Study
        </Button>
      </Flex>
    </Flex>
  );
};

export default StudyConfirmation;

import React, { useState, useEffect } from "react";
import {
  Button,
  Flex,
  HStack,
  VStack,
  Text,
  useMediaQuery,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Box,
  useToast,
  Link,
  Image,
  Badge,
} from "@chakra-ui/react";
import { AiOutlineCaretDown, AiOutlineCloudUpload } from "react-icons/ai";
import Toastify from "toastify-js";
import "toastify-js/src/toastify.css";
import { IoGrid } from "react-icons/io5";
import { BiLinkExternal } from "react-icons/bi";
import { Link as RouteLink } from "react-router-dom";
import { getSlideUrl } from "../../../../hooks/utility";
import { CheckCircleIcon } from "@chakra-ui/icons";
import { MdOutlineTableRows } from "react-icons/md";
import ProgressBar from "./SlideTabScreenSubComponent/ProgressBar";
import SlideTableView from "./SlideTabScreenSubComponent/SlideTableView";
import SlideGridView from "./SlideTabScreenSubComponent/SlideGridView";
import SlideUploadModel from "./SlideTabScreenSubComponent/SlideUploadModel";
import SlideReaderSelectionModal from "./SlideTabScreenSubComponent/SlideReaderSelectionModal";
import {
  useGetSlidesInfoQuery,
  useLazyGetSlidesInfoQuery,
} from "../../../../state/api/medicalApi";
import { onMessageListener } from "../../../../Firebase/init";
import SlideConfirmMessage from "./SlideTabScreenSubComponent/SlideConfirmMessage";

function SlideTabScreen({ studyId, userInfo, studyInfo, permission }) {
  const toast = useToast();

  const [ifWidthLessthan1920] = useMediaQuery("(max-width:1920px)");
  const [getSlidesInfo] = useLazyGetSlidesInfoQuery();

  const initialActiveButton = localStorage.getItem("activeButton") || "HAndE";
  const [activeButton, setActiveButton] = useState(initialActiveButton);
  useEffect(() => {
    setActiveButton(
      localStorage.getItem("activeButton") === "HAndE" ? "HAndE" : "Trichrome"
    );
  }, []);
  useEffect(() => {
    localStorage.setItem("activeButton", activeButton);
  }, [activeButton]);
  const [select, setSelect] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isSlideModalOpen, setIsSlideModalOpen] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState("");
  const handleSlideCloseModal = () => {
    setIsSlideModalOpen(false);
  };
  const { data: slidesInfo } = useGetSlidesInfoQuery({
    studyId,
    slideType: activeButton,
  });
  onMessageListener()
    .then((payload) => {
      if (payload.notification) {
        const { title, body } = payload.notification;
        // toast({
        //   title: title || "Notification",
        //   description: body || "",
        //   status: "success",
        //   duration: 2000,
        //   isClosable: true,
        // });
        Toastify({
          text: body,
          duration: 3000,
          gravity: "bottom", // `top` or `bottom`
          position: "center", // `left`, `center` or `right`
          style: {
            background: "green",
          },
          onClick: function () {}, // Callback after click
        }).showToast();
      }
      if (payload.data && payload.data.caseId) {
        getSlidesInfo({ studyId, slideType: activeButton }).unwrap();
      }
    })
    .catch((err) => {
      // toast({
      //   title: "Server Error",
      //   description: err.message,
      //   status: "error",
      //   duration: 2000,
      //   isClosable: true,
      // });
      Toastify({
        text: "Server Error",
        duration: 3000,
        gravity: "bottom", // `top` or `bottom`
        position: "center", // `left`, `center` or `right`
        style: {
          background: "red",
        },
        onClick: function () {}, // Callback after click
      }).showToast();
      console.error(err);
    });
  const [layoutSelect, setLayoutSelect] = useState("Table");
  const [isOpenUpload, setOpenUpload] = useState(false);
  const [isOpenReaderSelection, setOpenReaderSelection] = useState(false);

  return (
    <Flex w="100%" h="100%" bgColor="rgb(244,244,244)" flexDirection="column">
      <HStack
        justifyContent="space-between"
        w="100%"
        alignItems="flex-start"
        pt="15px"
      >
        <Flex
          border="1px solid rgba(64, 123, 255, 1)"
          borderRadius="8px"
          p="5px"
          width="300px"
          justifyContent="space-between"
          ml="12px"
          bgColor="rgba(255, 255, 255, 1)"
        >
          <Button
            onClick={() => setActiveButton("HAndE")}
            borderColor={activeButton === 1 ? "blue" : "grey"}
            color={
              activeButton === "HAndE"
                ? "rgba(27, 117, 188, 1)"
                : "rgba(0, 0, 0, 1)"
            }
            backgroundColor={
              activeButton === "HAndE"
                ? "rgba(198, 221, 238, 1)"
                : "rgba(255, 255, 255, 1)"
            }
            width="150px"
            fontSize="14px"
            fontFamily="inter"
          >
            H&E Slides
          </Button>
          <Button
            onClick={() => {
              setActiveButton("Trichrome");
            }}
            borderColor={activeButton === "Trichrome" ? "blue" : "grey"}
            color={
              activeButton === "Trichrome"
                ? "rgba(27, 117, 188, 1)"
                : "rgba(0, 0, 0, 1)"
            }
            backgroundColor={
              activeButton === "Trichrome"
                ? "rgba(198, 221, 238, 1)"
                : "rgba(255, 255, 255, 1)"
            }
            marginLeft="2" // Adding margin between the buttons
            width="150px"
            fontSize="14px"
            fontFamily="inter"
          >
            Trichrome Slides
          </Button>
        </Flex>
        <HStack pt="15px">
          <Box pr="15px">
            <Text fontSize="14px" fontWeight="bold">
              {`Total Slides - ${
                slidesInfo?.metadata?.totalSlideCount !== undefined
                  ? slidesInfo?.metadata?.totalSlideCount
                  : 0
              }`}
            </Text>
          </Box>
          {/* <Box width="300px">
            <ProgressBar
              percentage={
                slidesInfo?.metadata?.percentageCompleted !== undefined
                  ? slidesInfo?.metadata?.percentageCompleted
                  : 0
              }
            />
          </Box>
          <Box pl="15px">
            <Text fontSize="14px" fontWeight="bold">
              {`${
                slidesInfo?.metadata?.percentageCompleted !== undefined
                  ? (slidesInfo?.metadata?.percentageCompleted).toFixed(2)
                  : 0
              }% Completed`}
            </Text>
          </Box> */}
        </HStack>
        <Flex>
          <Flex>
            {/* <Menu>
              <MenuButton
                as={Button}
                fontSize={ifWidthLessthan1920 ? "13px" : "0.52916vw"}
                fontWeight="500"
                border="1px solid #E8E8EB"
                borderRadius="5px"
                bgColor="rgba(255, 255, 255, 1)"
                mr="5px"
                w="150px"
                mt="3px"
                mb="3px"
              >
                <Box>
                  <Flex justifyContent="space-between">
                    <Flex>
                      <Text fontWeight="bold">Status: All </Text>
                    </Flex>
                    <Flex ml="32px">
                      <AiOutlineCaretDown size="14px" />
                    </Flex>
                  </Flex>
                </Box>
              </MenuButton>
            </Menu> */}
          </Flex>
          {layoutSelect === "Table" &&
            permission?.data?.[0]?.permissions.includes("slideAssign") &&
            !select && (
              <Button
                bgColor="#FFFFFF"
                _focus={{ outline: "none" }}
                border="1px solid #E8E8EB"
                borderRadius="5px"
                fontSize="13px"
                mr="5px"
                mt="3px"
                fontWeight="bold"
                onClick={() => {
                  setSelect(true);
                }}
              >
                <Text>Select</Text>
              </Button>
            )}
          {select &&
            (selectedRows.length > 0 ? (
              <Button
                bgColor="#FFFFFF"
                _focus={{ outline: "none" }}
                onClick={() => {
                  setSelect(false);
                  setOpenReaderSelection(true);
                }}
                border="1px solid #E8E8EB"
                borderRadius="5px"
                fontSize="13px"
                mr="5px"
                mt="3px"
                fontWeight="bold"
              >
                <Text>Assign to</Text>
              </Button>
            ) : (
              <Button
                bgColor="#FFFFFF"
                _focus={{ outline: "none" }}
                onClick={() => {
                  setSelect(false);
                }}
                border="1px solid #E8E8EB"
                borderRadius="5px"
                fontSize="13px"
                mr="5px"
                mt="3px"
                fontWeight="bold"
              >
                <Text>Deselect</Text>
              </Button>
            ))}
          <Flex>
            <Menu>
              <MenuButton
                as={Button}
                fontSize={ifWidthLessthan1920 ? "13px" : "0.52916vw"}
                fontWeight="500"
                border="1px solid #E8E8EB"
                borderRadius="5px"
                bgColor="rgba(255, 255, 255, 1)"
                mr="5px"
                w="175px"
                mt="3px"
                mb="3px"
              >
                <Box>
                  <Flex justifyContent="space-between">
                    <Flex mr="5px">
                      {layoutSelect === "Grid" ? (
                        <IoGrid size="14px" />
                      ) : (
                        <MdOutlineTableRows size="16px" bgColor="black" />
                      )}
                    </Flex>
                    <Flex>
                      <Text fontWeight="bold">{`Layout: ${layoutSelect}`}</Text>
                    </Flex>
                    <Flex ml="18px">
                      <AiOutlineCaretDown size="14px" />
                    </Flex>
                  </Flex>
                </Box>
              </MenuButton>
              <MenuList p="0" fontSize="14px">
                <MenuItem
                  default
                  onClick={() => {
                    setLayoutSelect("Grid");
                  }}
                >
                  Grid
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setLayoutSelect("Table");
                  }}
                >
                  Table
                </MenuItem>
              </MenuList>
            </Menu>
          </Flex>
          {permission?.data?.[0]?.permissions.includes("slideUpload") && (
            <Button
              bgColor="#FFFFFF"
              _focus={{ outline: "none" }}
              onClick={() => setOpenUpload(true)}
              border="1px solid #E8E8EB"
              borderRadius="5px"
              fontSize="13px"
              mr="5px"
              mt="3px"
              fontWeight="bold"
              isDisabled={
                Number(
                  slidesInfo?.metadata?.totalSlideCount !== undefined
                    ? slidesInfo?.metadata?.totalSlideCount
                    : 0
                ) < Number(studyInfo?.data?.caseInfo?.participantsTobeRecruited)
                  ? ""
                  : "true"
              }
            >
              <HStack>
                <Box>
                  <AiOutlineCloudUpload size={20} />
                </Box>
                <Text>Upload New Slide</Text>
              </HStack>
            </Button>
          )}
        </Flex>
      </HStack>
      {layoutSelect === "Table" ? (
        <Flex
          maxH="63vh"
          overflowY="auto"
          justifyContent="center"
          w="100%"
          mt="20px"
          zIndex="0"
        >
          <SlideTableView
            slides={slidesInfo?.data}
            userInfo={userInfo}
            studyId={studyId}
            select={select}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            permission={permission}
          />
        </Flex>
      ) : (
        <SlideGridView
          slides={slidesInfo?.data?.mergedData}
          permission={permission}
        />
      )}
      <SlideUploadModel
        isOpenUpload={isOpenUpload}
        setOpenUpload={setOpenUpload}
        studyId={studyId}
        userInfo={userInfo}
      />{" "}
      <SlideReaderSelectionModal
        isOpenReaderSelection={isOpenReaderSelection}
        setOpenReaderSelection={setOpenReaderSelection}
        selectedRows={selectedRows}
        userInfo={userInfo}
        setIsSlideModalOpen={setIsSlideModalOpen}
        setConfirmMessage={setConfirmMessage}
      />
      <SlideConfirmMessage
        isOpen={isSlideModalOpen}
        onClose={handleSlideCloseModal}
        confirmMessage={confirmMessage}
      />
    </Flex>
  );
}

export default SlideTabScreen;

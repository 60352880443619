import React, { useState } from "react";
import {
  Flex,
  HStack,
  VStack,
  Box,
  Text,
  useMediaQuery,
  Button,
  Wrap,
} from "@chakra-ui/react";
import { BiLinkExternal } from "react-icons/bi";
import StudyDetailsModal from "./StudyDetailsModal";
import {
  useGetStudyInfoForPIQuery,
  useGetAllReaderInfoQuery,
} from "../../../../../state/api/medicalApi";
const ScrollBar = ({ children }) => {
  return (
    <Flex
      w="100%"
      h="100%"
      flexDir="column"
      overflowY="auto"
      overflowX="hidden"
      css={{
        "&::-webkit-scrollbar": {
          width: "4px",
        },
        "&::-webkit-scrollbar-track": {
          width: "6px",
        },
        "&::-webkit-scrollbar-thumb": {
          background: "#C4C4C4",
        },
      }}
    >
      {children}
    </Flex>
  );
};

const StudyDetailComponent = ({ studyId, userInfo, studyInfo, permission }) => {
  console.log(studyInfo);
  const [ifWidthLessthan1920] = useMediaQuery("(max-width:1920px)");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    // Fetch study details or set them as needed
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const headerStyle = {
    fontSize: ifWidthLessthan1920 ? "12px" : "15px",
    opacity: "0.7",
    fontFamily: "Inter",
  };
  const infoStyle = {
    fontSize: ifWidthLessthan1920 ? "13px" : "16px",
    fontFamily: "Inter",
    fontWeight: "bold",
  };
  return (
    <Flex w="100%" pl="10px">
      <VStack w="100%">
        <HStack w="100%" justifyContent="space-between">
          <Text
            color="#1B75BC"
            fontSize={ifWidthLessthan1920 ? "15px" : "18px"}
            fontWeight="bold"
          >
            Study Details
          </Text>
          <Button
            bgColor="inherit"
            _hover={{ color: "#1B75BC", cursor: "pointer" }}
            _active={{ color: "#1B75BC", cursor: "pointer" }}
          >
            <BiLinkExternal
              size={18}
              style={{ color: "#1B75BC", cursor: "pointer" }}
              onClick={handleOpenModal}
            />
          </Button>
        </HStack>
        <ScrollBar>
          <HStack w="100%">
            <VStack
              w="50%"
              wordBreak="break-all"
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={ifWidthLessthan1920 ? "2px" : "4px"}
              h="100%"
            >
              <VStack alignItems="left" spacing={0} w="100%">
                <Text style={headerStyle}>Title</Text>
                <Text style={infoStyle} wordBreak="break-word">
                  {studyInfo?.data?.caseInfo?.name}
                </Text>
              </VStack>
              <Box p="2px" />
              <HStack>
                <VStack alignItems="left" spacing={0} w="100%">
                  <Text style={headerStyle}>Aim</Text>
                  <Text style={infoStyle} wordBreak="break-word">
                    {studyInfo?.data?.caseInfo?.Aim}
                  </Text>
                </VStack>
              </HStack>
              <Box p="2px" />
              <HStack>
                <VStack alignItems="left" spacing={0} w="100%">
                  <Text style={headerStyle}>Objective</Text>
                  <Text style={infoStyle}>
                    {studyInfo?.data?.caseInfo?.Objectives}
                  </Text>
                </VStack>
              </HStack>{" "}
              <Box p="2px" />
              <HStack>
                <VStack alignItems="left" spacing={0} w="100%">
                  <Text style={headerStyle}>Total no. of cases</Text>
                  {/* <Text style={infoStyle}>
                  {`${studyInfo?.data?.caseInfo?.participantsTobeRecruited} ${
                    Number(
                      studyInfo?.data?.caseInfo?.participantsTobeRecruited
                    ) -
                      Number(studyInfo?.data?.caseInfo?.participants) !==
                    0
                      ? `(${
                          Number(
                            studyInfo?.data?.caseInfo?.participantsTobeRecruited
                          ) - Number(studyInfo?.data?.caseInfo?.participants)
                        } recruitment pending)`
                      : ""
                  }`}
                </Text> */}
                  <Text style={infoStyle} wordBreak="break-word">
                    {`${studyInfo?.data?.caseInfo?.participantsTobeRecruited}`}
                  </Text>
                </VStack>
              </HStack>
            </VStack>
            <VStack
              w="50%"
              wordBreak="break-all"
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={ifWidthLessthan1920 ? "2px" : "4px"}
              h="100%"
            >
              <VStack alignItems="left" spacing={0} w="90%">
                <Text style={headerStyle}>Institute</Text>
                <Text style={infoStyle} wordBreak="break-word">
                  {studyInfo?.data?.caseInfo?.institute || "-"}
                </Text>
              </VStack>
              <Box p="2px" />
              <HStack>
                <VStack alignItems="right" spacing={0} w="100%">
                  <Text style={headerStyle}>Organ</Text>
                  <Text style={infoStyle} wordBreak="break-word">
                    {studyInfo?.data?.caseInfo?.slides?.[0]?.organ || "Liver"}
                  </Text>
                </VStack>
              </HStack>
              <Box p="2px" />
              <HStack>
                <VStack alignItems="right" spacing={0} w="100%">
                  <Text style={headerStyle}>Details</Text>

                  <Text style={infoStyle} wordBreak="break-word">
                    {studyInfo?.data?.caseInfo?.Studydetails || "-"}
                  </Text>
                </VStack>
              </HStack>
            </VStack>
          </HStack>
        </ScrollBar>
      </VStack>
      <StudyDetailsModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        studyDetails={studyInfo?.data}
        studyId={studyId}
        userInfo={userInfo}
        permission={permission}
      />
    </Flex>
  );
};

export default StudyDetailComponent;

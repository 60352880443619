import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

// Web App's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyC7G9so4P-e-GqsoU2a2x0aoldGWYZ7ZEI",
  authDomain: "softsensor-hospital.firebaseapp.com",
  projectId: "softsensor-hospital",
  storageBucket: "softsensor-hospital.appspot.com",
  messagingSenderId: "820660844238",
  appId: "1:820660844238:web:6f14f4667092101945029f",
  measurementId: "G-8MQ7QJ4SWY",
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

// Initialize Firebase Messaging Service
const messaging = getMessaging(firebaseApp);

// Get Token
const fetchToken = async () => {
  try {
    const currentToken = await getToken(messaging, {
      vapidKey:
        "BDSrh_UlVIRujIdElhFkD9pZV-szk8JvvX2xYsEXvC0qaG3y0-aiubVWk-vgiIpVCT3jHBCEUGb_j5RnFSGfNyY",
    });
    if (currentToken) {
      return currentToken;
    }
  } catch (err) {
    console.error("An error occurred while retrieving token. ", err);
  }
  return null;
};

// Request the browser's permission to send notifications
export const requestFirebaseNotificationPermission = async ({
  updateFCMToken,
}) => {
  try {
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      const token = await fetchToken();
      localStorage.setItem(`firebase_token`, token);
      updateFCMToken({ token });
      return;
    }
    console.warn("Unable to get permission to notify.");
  } catch (error) {
    console.error(error);
  }
};

const onMessageListener = () => {
  return new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
};

export { firebaseApp, messaging, fetchToken, onMessageListener };

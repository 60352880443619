import { Flex, Text, Button } from "@chakra-ui/react";
import { Select, useChakraSelectProps } from "chakra-react-select";
import React, { useEffect, useState } from "react";
import { useGetAllMembersbasedonRolesQuery } from "../../state/api/medicalApi";
import CreateQuestionnaire from "../Qna/createQuestionnaire";
import { useDispatch } from "react-redux";

import LibraryBtn from "../Qna/LibraryBtn";
function ParticipantsQuestionnaire({
  userInfo,
  selectedOptions,
  setSelectedOptions,
}) {
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [activeButton, setActiveButton] = useState("HAndE");

  // get readers
  const { data: membersInfo } = useGetAllMembersbasedonRolesQuery({
    organizationId: userInfo?.organizationId,
    roleName: "Pathologist",
  });
  const options = membersInfo?.data?.desiredRoleInfo?.map((option) => {
    return {
      label: `${option.firstName} ${option.lastName}`,
      value: option.userId,
    };
  });
  const selectProps = useChakraSelectProps({
    isMulti: true,
    value: selectedOptions,
    onChange: setSelectedOptions,
  });

  //user selection styling
  const style = {
    control: (base, state) => ({
      ...base,
      border: "1.5px solid #f5f5f5",
      borderColor: "#f5f5f5",
      background: "#fff",
      width: "61vw",
    }),
  };

  useEffect(() => {
    setSelectedQuestions([]);
  }, [activeButton]);
  return (
    <Flex flexDirection="column" w="100%" h="72vh" overflowY="auto">
      {/* <Text>Participants</Text>
      <Flex
        m=" 3.148vh 0 2.592vh 0"
        justifyContent="space-between"
        flexWrap="wrap"
        alignItems="flex-start"
      >
        <Text pb="2vh" w="100%">
          Add pathologists (Reader) for this study
        </Text>
        <Select
          isMulti={false}
          options={options}
          placeholder="Select readers from your organization"
          closeMenuOnSelect={false}
          {...selectProps}
          selectedOptionColor="light.500"
          useBasicStyles
          chakraStyles={style}
          noOptionsMessage={({ inputValue }) =>
            !inputValue ? "No readers available" : "Reader doesn't exist"
          }
          isOptionDisabled={() => selectedOptions.length >= 3}
        />
      </Flex> */}
      <Text>Questionnaire</Text>
      <Flex justifyContent="space-between" m="2.1296vh 0 3.333vh 0.9895vw">
        <Flex
          border="1px solid rgba(64, 123, 255, 1)"
          borderRadius="5px"
          width="23.333vw"
          h="5.1851vh"
          justifyContent="space-between"
          alignItems="center"
          px="0.4166vw"
          minW="300px"
          flexWrap="wrap"
          bgColor="rgba(255, 255, 255, 1)"
        >
          <Button
            onClick={() => setActiveButton("HAndE")}
            borderColor={activeButton === "HAndE" ? "blue" : "grey"}
            color={
              activeButton === "HAndE"
                ? "rgba(27, 117, 188, 1)"
                : "rgba(0, 0, 0, 1)"
            }
            backgroundColor={
              activeButton === "HAndE"
                ? "rgba(198, 221, 238, 1)"
                : "rgba(255, 255, 255, 1)"
            }
            _hover={{ bgColor: "none" }}
            h="3.7037vh"
            _focus={{ outline: "none" }}
            fontWeight="400"
          >
            H&E Slides
          </Button>
          <Button
            onClick={() => setActiveButton("Trichrome")}
            borderColor={activeButton === "Trichrome" ? "blue" : "grey"}
            color={
              activeButton === "Trichrome"
                ? "rgba(27, 117, 188, 1)"
                : "rgba(0, 0, 0, 1)"
            }
            backgroundColor={
              activeButton === "Trichrome"
                ? "rgba(198, 221, 238, 1)"
                : "rgba(255, 255, 255, 1)"
            }
            marginLeft="2"
            h="3.7037vh"
            _focus={{ outline: "none" }}
            _hover={{ bgColor: "none" }}
            fontWeight="400"
          >
            Trichrome Slides
          </Button>
        </Flex>
        <Flex>
          <LibraryBtn
            activeButton={activeButton}
            selectedQuestions={selectedQuestions}
            setSelectedQuestions={setSelectedQuestions}
          />
        </Flex>
      </Flex>
      <CreateQuestionnaire activeButton={activeButton} />
    </Flex>
  );
}

export default ParticipantsQuestionnaire;

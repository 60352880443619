import React from "react";
import {
  Flex,
  HStack,
  VStack,
  Box,
  Text,
  useMediaQuery,
  Button,
  Avatar,
} from "@chakra-ui/react";
import { BiLinkExternal } from "react-icons/bi";

const ScrollBar = ({ children }) => {
  return (
    <Flex
      w="100%"
      h="100%"
      flexDir="column"
      overflowY="auto"
      overflowX="hidden"
      css={{
        "&::-webkit-scrollbar": {
          width: "4px",
        },
        "&::-webkit-scrollbar-track": {
          width: "6px",
        },
        "&::-webkit-scrollbar-thumb": {
          background: "#C4C4C4",
        },
      }}
    >
      {children}
    </Flex>
  );
};
const ReadersComponent = () => {
  const [ifWidthLessthan1920] = useMediaQuery("(max-width:1920px)");
  const headerStyle = {
    fontSize: ifWidthLessthan1920 ? "12px" : "15px",
    opacity: "0.7",
    fontFamily: "Inter",
  };
  const infoStyle = {
    fontSize: ifWidthLessthan1920 ? "13px" : "16px",
    fontFamily: "Inter",
    fontWeight: "bold",
  };
  return (
    <Flex w="100%">
      <VStack w="100%" pl="10px">
        <HStack w="100%" justifyContent="space-between">
          <Text
            color="#1B75BC"
            fontSize={ifWidthLessthan1920 ? "15px" : "18px"}
            fontWeight="bold"
          >
            Readers
          </Text>
          <Button
            bgColor="inherit"
            _hover={{ color: "#1B75BC", cursor: "pointer" }}
            _active={{ color: "#1B75BC", cursor: "pointer" }}
          >
            <BiLinkExternal
              size={18}
              style={{ color: "#1B75BC", cursor: "pointer" }}
            />
          </Button>
        </HStack>
        <ScrollBar>
          <Flex
            w="96%"
            h="100%"
            direction="column"
            borderBottom="2px solid #DEDEDE"
            pb="7px"
          >
            <HStack w="100%" h="100%">
              <Avatar size="sm" name="siddhesh kale" />
              <Text fontSize="16px">Dr. Siddhesh Kale</Text>
            </HStack>
          </Flex>
        </ScrollBar>
      </VStack>
    </Flex>
  );
};

export default ReadersComponent;

import {
  Button,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useGetReportForReaderQuery } from "../../state/api/medicalApi";

function ReaderReport({ caseId, userInfo, studyName }) {
  const [activeButton, setActiveButton] = useState("HAndE");

  const { data: report, isFetching } = useGetReportForReaderQuery(
    {
      case_id: caseId,
      slide_type: activeButton,
    },
    { skip: !caseId }
  );
  return (
    <Flex bgColor="rgb(244,244,244)" h="100%" w="100%" flexWrap="wrap">
      <Flex
        h="74vh"
        overflowY="auto"
        mx="14vw"
        bgColor="white"
        w="100%"
        mt="10px"
        alignItems="flex-start"
        flexDirection="column"
        p="4.537vh 9.4375vw"
      >
        <Flex
          border="1px solid rgba(64, 123, 255, 1)"
          borderRadius="5px"
          width="23.333vw"
          h="5.1851vh"
          justifyContent="space-between"
          alignItems="center"
          px="0.4166vw"
          minW="300px"
          flexWrap="wrap"
          bgColor="rgba(255, 255, 255, 1)"
          py="0.29rem"
        >
          <Button
            onClick={() => setActiveButton("HAndE")}
            borderColor={activeButton === "HAndE" ? "blue" : "grey"}
            color={
              activeButton === "HAndE"
                ? "rgba(27, 117, 188, 1)"
                : "rgba(0, 0, 0, 1)"
            }
            backgroundColor={
              activeButton === "HAndE"
                ? "rgba(198, 221, 238, 1)"
                : "rgba(255, 255, 255, 1)"
            }
            _hover={{ bgColor: "none" }}
            h="3.7037vh"
            _focus={{ outline: "none" }}
            fontWeight="400"
            fontFamily="inter"
          >
            H&E Slides
          </Button>
          <Button
            onClick={() => setActiveButton("Trichrome")}
            borderColor={activeButton === "Trichrome" ? "blue" : "grey"}
            color={
              activeButton === "Trichrome"
                ? "rgba(27, 117, 188, 1)"
                : "rgba(0, 0, 0, 1)"
            }
            backgroundColor={
              activeButton === "Trichrome"
                ? "rgba(198, 221, 238, 1)"
                : "rgba(255, 255, 255, 1)"
            }
            marginLeft="2"
            h="3.7037vh"
            _focus={{ outline: "none" }}
            _hover={{ bgColor: "none" }}
            fontWeight="400"
            fontFamily="inter"
          >
            Trichrome Slides
          </Button>
        </Flex>
        <Flex maxW="52vw" direction="column" overflowX="hidden" pr="0.8rem">
          {report?.data?.map((slide, i) => {
            return (
              <Flex direction="column" mt="1.3rem" key={i} maxW="100%">
                <Text>{`${i + 1}. ${slide?.accession_id}`}</Text>
                <Flex mt="0.7rem" overflowX="auto">
                  <Table variant="unstyled" border="1px solid #000">
                    <Thead border="1px solid #000">
                      <Tr p="0.2rem 0.5rem">
                        <Th
                          border="1px solid #000"
                          fontFamily="inter"
                          fontWeight="500"
                          p="0.2rem 0.5rem"
                        >
                          S.No
                        </Th>
                        <Th
                          border="1px solid #000"
                          minW="12vw"
                          fontFamily="inter"
                          fontWeight="500"
                          p="0.2rem 0.5rem"
                        >
                          ACCESSION ID
                        </Th>
                        {slide?.questions_responses?.map((question, i) => {
                          return (
                            <Th
                              // maxW="20vw"
                              // minW="20vw"
                              p="0.2rem 0.5rem"
                              border="1px solid #000"
                              key={i}
                              fontFamily="inter"
                              fontWeight="500"
                              textTransform="Uppercase"
                            >
                              {question?.question_text
                                ? question?.question_text
                                : question?.section_heading}
                            </Th>
                          );
                        })}
                      </Tr>
                    </Thead>
                    <Tbody>
                      <Tr
                        border="1px solid #000"
                        key={i}
                        verticalAlign="top"
                        p="0.2rem 0.5rem"
                      >
                        <Th
                          border="1px solid #000"
                          fontFamily="inter"
                          fontWeight="500"
                          p="0.2rem 0.5rem"
                        >
                          {i + 1}
                        </Th>
                        <Th
                          border="1px solid #000"
                          color="light.700"
                          maxW="20vw"
                          fontFamily="inter"
                          fontWeight="500"
                          p="0.2rem 0.5rem"
                        >
                          {slide?.accession_id}
                        </Th>
                        {slide?.questions_responses?.map((question, index) => {
                          return question?.section_questions?.length ? (
                            <Th
                              key={index}
                              p="0 !important"
                              border="1px solid #000"
                            >
                              {question?.section_questions?.map(
                                (sectionQuestion, i) => {
                                  return (
                                    <Tr
                                      key={i}
                                      p="0.2rem 0.5rem"
                                      borderBottom="1px solid #000"
                                      // _last={{ borderBottom: "none" }}
                                      flex="1"
                                    >
                                      <Td
                                        fontFamily="inter"
                                        fontWeight="500"
                                        textTransform="none"
                                        p="0.2rem 0.5rem"
                                        isTruncated
                                      >{`Q${i + 1}. ${
                                        sectionQuestion?.question_text
                                      }`}</Td>
                                      <Td
                                        borderLeft="1px solid #000"
                                        minW="10vw"
                                        fontFamily="inter"
                                        fontWeight="500"
                                        textTransform="none"
                                        p="0.2rem 0.5rem"
                                        isTruncated
                                      >
                                        {sectionQuestion?.response
                                          ?.replace(/{"/g, "")
                                          ?.replace(/"}/g, "")
                                          ?.replace(/"/g, "") || "-"}
                                      </Td>
                                    </Tr>
                                  );
                                }
                              )}
                            </Th>
                          ) : (
                            <Th
                              border="1px solid #000"
                              // maxW="20vw"
                              minW="15vw"
                              key={index}
                              fontFamily="inter"
                              fontWeight="500"
                              textTransform="none"
                              p="0.2rem 0.5rem"
                              isTruncated
                            >
                              {question?.response
                                ?.replace(/{"/g, "")
                                ?.replace(/"}/g, "")
                                ?.replace(/"/g, "") || "-"}
                            </Th>
                          );
                        })}
                      </Tr>
                    </Tbody>
                  </Table>
                </Flex>
              </Flex>
            );
          })}
        </Flex>
      </Flex>
      {!report?.data?.length && !isFetching && (
        <Text>No report available.!!</Text>
      )}
    </Flex>
  );
}

export default ReaderReport;

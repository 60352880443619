import React, { useState, useEffect } from "react";
import { Flex, Button, Text } from "@chakra-ui/react";
import "../../styles/dashboard.css";
import SlidesReport from "./SlidesReport";
import {
  useGetQuestionsQuery,
  useGetReportResponseQuery,
} from "../../state/api/medicalApi";
import xlsx from "json-as-xlsx";
import { xlsxRowsData } from "../../hooks/utility";
import { AiFillPrinter } from "react-icons/ai";
function Report({ studyInfo, userInfo, studyName, caseId }) {
  const [activeButton, setActiveButton] = useState("HAndE");

  const [filter, setFilter] = useState("");

  const { data: questions } = useGetQuestionsQuery({ studyId: caseId });
  const {
    data: report,
    isFetching,
    isLoading,
  } = useGetReportResponseQuery(
    {
      case_id: caseId,
      type: "HAndE",
    },
    { skip: !caseId }
  );
  const { data: trichromeReport } = useGetReportResponseQuery(
    {
      case_id: caseId,
      type: "Trichrome",
    },
    { skip: !caseId }
  );
  useEffect(() => {
    setFilter("");
  }, [caseId]);

  const currentReport = activeButton === "HAndE" ? report : trichromeReport;
  //xlsx header data
  const staticHeader = [
    // { label: "S.No", value: "sno" },
    { label: "AccessionID", value: "accessionId" },
    {
      label: "Reader Name",
      value: "readerName",
    },
    {
      label: "Upload Date",
      value: "uploadDate",
    },
    {
      label: "Report Date",
      value: "reportDate",
    },
  ];
  const HAndEColumns = [...staticHeader];
  questions?.data?.HAndE?.map((e) => {
    return HAndEColumns.push({
      label: e?.is_section ? `${e?.section_heading}` : e?.question_text,
      value: e?.is_section ? `${e?.section_heading}` : e?.question_text,
    });
  });
  HAndEColumns.push({
    label: "Additional Questions",
    value: "additionalQuestions",
  });
  const TrichromeColumns = [...staticHeader];
  questions?.data?.Trichrome?.map((e) => {
    return TrichromeColumns.push({
      label: e?.is_section ? `${e?.section_heading}` : e?.question_text,
      value: e?.is_section ? `${e?.section_heading}` : e?.question_text,
    });
  });
  TrichromeColumns.push({
    label: "Additional Questions",
    value: "additionalQuestions",
  });

  let data = [
    {
      sheet: "H&E",
      columns: HAndEColumns || [],
      content: xlsxRowsData(report) || [],
    },
    {
      sheet: "Trichrome",
      columns: TrichromeColumns || [],
      content: xlsxRowsData(trichromeReport) || [],
    },
  ];

  let settings = {
    fileName: `${studyName}`,
    extraLength: 3,
    writeMode: "writeFile",
    writeOptions: {},
  };
  const downloadReport = () => {
    xlsx(data, settings);
  };
  return (
    <>
      <Flex
        bgColor="rgb(244,244,244)"
        w="100%"
        flexWrap="wrap"
        flex="1"
        h="79vh"
      >
        <Flex
          mx="6.5625vw"
          bgColor="white"
          w="100%"
          mt="3.2407vh"
          alignItems="flex-start"
          flexDirection="column"
          p="2.8703vh 6.6572vw"
        >
          <Flex w="100%" mb="1.5vh" justifyContent="space-between">
            <Flex
              border="1px solid rgba(64, 123, 255, 1)"
              borderRadius="5px"
              width="23.333vw"
              h="5.1851vh"
              justifyContent="space-between"
              alignItems="center"
              px="0.4166vw"
              minW="300px"
              flexWrap="wrap"
              bgColor="rgba(255, 255, 255, 1)"
            >
              <Button
                onClick={() => setActiveButton("HAndE")}
                borderColor={activeButton === "HAndE" ? "blue" : "grey"}
                color={
                  activeButton === "HAndE"
                    ? "rgba(27, 117, 188, 1)"
                    : "rgba(0, 0, 0, 1)"
                }
                backgroundColor={
                  activeButton === "HAndE"
                    ? "rgba(198, 221, 238, 1)"
                    : "rgba(255, 255, 255, 1)"
                }
                _hover={{ bgColor: "none" }}
                h="3.7037vh"
                _focus={{ outline: "none" }}
                fontWeight="400"
                fontFamily="inter"
                minW="40%"
              >
                H&E Slides
              </Button>
              <Button
                onClick={() => setActiveButton("Trichrome")}
                borderColor={activeButton === "Trichrome" ? "blue" : "grey"}
                color={
                  activeButton === "Trichrome"
                    ? "rgba(27, 117, 188, 1)"
                    : "rgba(0, 0, 0, 1)"
                }
                backgroundColor={
                  activeButton === "Trichrome"
                    ? "rgba(198, 221, 238, 1)"
                    : "rgba(255, 255, 255, 1)"
                }
                marginLeft="2"
                h="3.7037vh"
                _focus={{ outline: "none" }}
                _hover={{ bgColor: "none" }}
                fontWeight="400"
                fontFamily="inter"
                minW="40%"
              >
                Trichrome Slides
              </Button>
            </Flex>
            {!!report?.data?.finalResponseArray?.length && (
              <Flex>
                <Button
                  onClick={downloadReport}
                  leftIcon={<AiFillPrinter />}
                  border=" 1px solid #1B75BC"
                  bg="rgba(176, 200, 214, 0.10)"
                  fontSize="0.9rem"
                  fontFamily="inter"
                >
                  Download
                </Button>
              </Flex>
            )}
          </Flex>
          {!!report?.data?.finalResponseArray?.length && (
            <SlidesReport
              caseId={caseId}
              report={currentReport}
              questions={questions?.data}
              activeButton={activeButton}
            />
          )}
          {!isLoading &&
            !isFetching &&
            report?.data?.finalResponseArray?.length === 0 && (
              <Text>No reports from readers..!</Text>
            )}
        </Flex>
      </Flex>
    </>
  );
}

export default Report;

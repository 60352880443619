import { Flex, Text, useMediaQuery, Image } from "@chakra-ui/react";
import React from "react";
import moment from "moment";

const InfoCard = ({
  background,
  headingColor,
  statColor,
  heading,
  number,
  setCaseStatus,
}) => {
  const [ifWidthLessthan1600] = useMediaQuery("(max-width:1600px)");
  return (
    <Flex
      w="34%"
      h="100%"
      bgColor={background}
      p={ifWidthLessthan1600 ? "10px" : "14px"}
      borderRadius="15px"
      mt="3vmin"
      mr="1%"
      ml="1%"
      onClick={() =>
        setCaseStatus(
          heading === "Clinical Operation Manager"
            ? "pi"
            : heading === "Readers"
            ? "reader"
            : null
        )
      }
      cursor="pointer"
    >
      <Flex direction="column" w="80%" h="100%">
        <Text
          fontSize={ifWidthLessthan1600 ? "15px" : "20px"}
          color={headingColor}
          h="25%"
        >
          {heading}
        </Text>
        <Flex>
          <Text
            h="75%"
            fontSize="8vh"
            fontWeight="600"
            color={statColor}
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
          >
            {number}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};
const InfoCardComponent = ({ data, setCaseStatus }) => {
  return (
    <Flex w="100%" height="20%" direction="column">
      <Flex w="100%" h="100%" justifyContent="space-between">
        <InfoCard
          background="#F8F2F0"
          headingColor="rgba(208, 103, 104, 0.85)"
          statColor="#D06768"
          heading="Clinical Operation Manager"
          number={
            data !== undefined
              ? data[0]?.role_name === "pi"
                ? data[0].userCount
                : data[1]?.userCount === undefined
                ? 0
                : data[1].userCount
              : 0
          }
          setCaseStatus={setCaseStatus}
        />

        <InfoCard
          background="#F6F4FE"
          headingColor="#1B75BC"
          statColor="#1B75BC"
          heading="Readers"
          number={
            data !== undefined
              ? data[0]?.role_name === "reader"
                ? data[0].userCount
                : data[1]?.userCount === undefined
                ? 0
                : data[1].userCount
              : 0
          }
          setCaseStatus={setCaseStatus}
        />
        {/* <InfoCard
          background="#FFF6EA"
          headingColor="#FF9900"
          statColor="#FF9900"
          heading="Technologists"
          number={0}
          // setCaseStatus={setCaseStatus}
        /> */}

        <InfoCard
          background="#FEF4FE"
          headingColor="#B451B4"
          statColor="#B451B4"
          heading="Institutes"
          number={1}
          setCaseStatus={setCaseStatus}
        />
        {/* <InfoCard
          background="#F4F6FE"
          headingColor="rgba(63, 98, 203, 0.85)"
          statColor="#3F62CB"
          heading="Total Studies"
          number={0}
          // number="12"
        //   setCaseStatus={setCaseStatus}
        /> */}
      </Flex>
    </Flex>
  );
};

export default InfoCardComponent;

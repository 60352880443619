import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  Flex,
  useToast,
  useMediaQuery,
  HStack,
} from "@chakra-ui/react";
import axios from "axios";
import { useDeleteSlideMutation } from "../../../../../state/api/medicalApi";

function SlideConfirmMessage({ isOpen, onClose, confirmMessage }) {
  const [ifSmallScreen] = useMediaQuery("(max-width:1920px)");
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader
          fontSize={ifSmallScreen ? "16px" : "15.5px"}
          font-weight="500"
          pl={ifSmallScreen ? "20px" : "27px"}
          pb={ifSmallScreen ? "10px" : "15px"}
          pt={ifSmallScreen ? "10px" : "19px"}
          bg="#F0F2FF"
          borderStyle="solid"
          borderWidth="0px 0px 2px 0px"
          borderColor="#DEDEDE"
        >
          Assigned Successfully
        </ModalHeader>
        <ModalCloseButton
          pb={ifSmallScreen ? "20px" : "15px"}
          pt={ifSmallScreen ? "10px" : "21px"}
          mr={ifSmallScreen ? "10px" : "10.5"}
          _hover={{ opacity: 1 }}
          onClick={() => {
            onClose();
            window.location.reload();
          }}
        />
        <ModalBody>
          <Text fontSize="14px">{confirmMessage}</Text>
        </ModalBody>
        <ModalFooter>
          <HStack spacing="1vw" justifyContent="flex-end" mb="1vh">
            <Button
              alignItems="center"
              bgColor="rgba(196, 218, 236, 1)"
              _focus={{ outline: "none" }}
              borderRadius="5px"
              fontWeight="400"
              fontSize="14px"
              color="rgba(33, 34, 36, 1)"
              px="30px"
              onClick={() => {
                onClose();
                window.location.reload();
              }}
            >
              Okay
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default SlideConfirmMessage;

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import Environment from "../../environment";

const medicalApiSlice = createApi({
  reducerPath: "medicalApi",
  baseQuery: fetchBaseQuery({
    baseUrl: Environment.USER_URL,
    prepareHeaders: (headers) => {
      const { body } = JSON.parse(
        localStorage.getItem(Environment.AUTH0_TOKEN)
      );

      if (body && typeof body === "object") {
        const { access_token: accessToken } = body;
        if (accessToken) headers.set("authorization", `Bearer ${accessToken}`);
      }

      return headers;
    },
  }),
  tagTypes: [
    "studies",
    "response",
    "addCase",
    "register",
    "slides",
    "addMember",
    "archive",
    "studyDetails",
    "slideDetails",
    "questions",
  ],
  endpoints: (builder) => ({
    getUserInfo: builder.query({
      query: (body) => ({
        url: "users/v1/profile",
        method: "GET",
      }),
      providesTags: ["register"],
    }),

    getStudyParticipants: builder.query({
      query: (body) => {
        const { case_id } = body;
        return {
          url: `/cases/v1/${case_id}/participants`,
          method: "GET",
        };
      },
    }),

    createStudy: builder.mutation({
      query: (body) => ({
        url: "/cases/v1/case",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["studies"],
    }),
    addReadersToStudy: builder.mutation({
      query: (body) => {
        const { caseId } = body;
        return {
          url: `/cases/v1/${caseId}/readers`,
          method: "POST",
          body: body,
        };
      },
    }),

    searchPathologistByName: builder.mutation({
      query: (body) => {
        const { name } = body;
        return {
          url: `/users/v1/pathologists`,
          method: "GET",
          params: { name },
        };
      },
    }),
    getPIStudies: builder.query({
      query: (body) => ({
        url: "/cases/v1",
        method: "GET",
      }),
      providesTags: ["studies", "archive"],
    }),

    addSlidesToStudy: builder.mutation({
      query: (body) => ({
        url: `/cases/v1/slides`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["slides"],
    }),
    addPathologist: builder.mutation({
      query: (body) => ({
        url: "/users/v1/user/pathologist",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["addMember"],
    }),
    addPI: builder.mutation({
      query: (body) => ({
        url: "/users/v1/user/pi",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["addMember"],
    }),
    getStudies: builder.query({
      query: () => ({
        url: "/admin_study",
        method: "GET",
      }),
    }),
    // addAdmin: builder.mutation({
    //   query: (body) => ({
    //     url: "/users/v1/pathologist",
    //     method: "POST",
    //     body: body,
    //   }),
    //   invalidatesTags: ["addMember"],
    // }),
    createUserAndOrg: builder.mutation({
      query: (body) => ({
        url: "/users/v1/create-organisation",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["register"],
    }),
    getUserBasedCount: builder.query({
      query: (body) => {
        const { organizationId } = body;
        return {
          url: "/users/v1/users-roles/count",
          method: "GET",
          params: { organizationId },
        };
      },
      providesTags: ["addMember"],
    }),
    getAllMembersbasedonRoles: builder.query({
      query: (body) => {
        const { organizationId, roleName } = body;
        return {
          url: "/users/v1/users-roles",
          method: "GET",
          params: { organizationId, roleName },
        };
      },
      providesTags: ["addMember"],
    }),
    getStudyInfoForPI: builder.query({
      query: (body) => {
        const { caseId } = body;
        return {
          url: `/cases/v1/${caseId}`,
          method: "GET",
          params: { caseId },
        };
      },
      providesTags: ["slides", "studyDetails"],
    }),
    getSlidesInfo: builder.query({
      query: (body) => {
        const { studyId, slideType } = body;
        return {
          url: `cases/v1/${studyId}/slides`,
          method: "GET",
          params: { slide_type: slideType },
        };
      },
      providesTags: ["slideDetails"],
    }),
    getTotalParticipantsCount: builder.query({
      query: (body) => {
        return {
          url: "/cases/v1/cases/participants/count",
          method: "GET",
        };
      },
    }),
    getDepartmentCasesCount: builder.query({
      query: (body) => {
        return {
          url: "/cases/v1/cases/departments/count",
          method: "GET",
        };
      },
    }),

    getOverallReaders: builder.query({
      query: (body) => {
        const { studyId } = body;
        return {
          url: `/cases/v1/${studyId}/participants`,
          method: "GET",
        };
      },
    }),
    addQuestions: builder.mutation({
      query: (body) => {
        const { studyId, questions } = body;
        return {
          url: `/cases/v1/${studyId}/questions_addition`,
          body: questions,
          method: "POST",
        };
      },
      invalidatesTags: ["questions"],
    }),
    getQuestions: builder.query({
      query: (body) => {
        const { studyId } = body;
        return {
          url: `/cases/v1/${studyId}/questions`,
          method: "GET",
        };
      },
      providesTags: ["questions"],
    }),
    getReportResponse: builder.query({
      query: (body) => {
        const { case_id, sort, type } = body;
        return {
          url: `/cases/v1/${case_id}/case-report?sort=${sort}&questionnaire_type=${type}`,
          method: "GET",
        };
      },
    }),
    postQuestionnaieResponses: builder.mutation({
      query: (body) => {
        const { case_id } = body;
        return {
          url: `/cases/v1/${case_id}/questionnaire-responses`,
          method: "POST",
          body: body,
        };
      },
      invalidatesTags: ["response"],
    }),
    getQuestionnaireresponse: builder.query({
      query: (body) => {
        const { case_id, slide_id } = body;
        return {
          url: `/cases/v1/${case_id}/${slide_id}/slide-responses`,
          method: "GET",
        };
      },
      providesTags: ["response"],
    }),
    getSlidesStatusForCase: builder.query({
      query: (body) => {
        const { case_id } = body;
        return {
          url: `/cases/v1/${case_id}/slides-count`,
          method: "GET",
        };
      },
      providesTags: ["slides"],
    }),
    getAllParticipants: builder.query({
      query: (body) => ({
        url: "/cases/v1/cases/total-participants",
        method: "GET",
      }),
      providesTags: ["addMember"],
    }),
    getStatsForPI: builder.query({
      query: () => ({
        url: "/cases/v1/cases/statistics",
        method: "GET",
      }),
    }),
    getStudiesForReader: builder.query({
      query: (body) => ({
        url: "/cases/v1/cases/reader",
        method: "GET",
      }),
    }),
    updateFCMToken: builder.mutation({
      query: (body) => ({
        url: "/users/v1/user/fcm-token",
        method: "PUT",
        body: body,
      }),
    }),
    updateArchiveForStudy: builder.mutation({
      query: (body) => {
        const { case_id } = body;
        return {
          url: `/cases/v1/${case_id}/archive-status`,
          method: "PUT",
          body: body,
        };
      },
      invalidatesTags: ["archive"],
    }),

    saveAnnotation: builder.mutation({
      query: (body) => ({
        url: `${Environment.VIEWER_URL}/annotation`,
        method: "POST",
        body: { ...body, app: "clinical" },
      }),
    }),
    loadAnnotations: builder.query({
      query: (body) => ({
        url: `${Environment.VIEWER_URL}/load_annotations`,
        method: "POST",
        body,
      }),
    }),
    deleteAnnotation: builder.mutation({
      query: ({ hash, slideId }) => ({
        url: `${Environment.VIEWER_URL}/annotation`,
        method: "DELETE",
        body: { hash, slideId },
      }),
    }),
    updateAnnotation: builder.mutation({
      query: ({ hash, slideId, updateObject }) => ({
        url: `${Environment.VIEWER_URL}/annotation`,
        method: "PATCH",
        body: { hash, slideId, updateObject },
      }),
    }),
    vhutAnalysis: builder.mutation({
      query: (body) => ({
        url: `${Environment.VIEWER_URL}/vhut_analysis`,
        method: "POST",
        body,
      }),
    }),
    vhutViewportAnalysis: builder.mutation({
      query: (body) => ({
        url: `${Environment.VIEWER_URL}/vhut_viewport_analysis`,
        method: "POST",
        body: {
          ...body,
          notifyHook: `${Environment.USER_URL}/notify_viewport_analysis`,
        },
      }),
    }),
    getVhutAnalysis: builder.query({
      query: (body) => ({
        url: `${Environment.VIEWER_URL}/get_vhut_analysis`,
        method: "POST",
        body,
      }),
    }),
    getPhaseWiseCount: builder.query({
      query: (body) => ({
        url: "/cases/v1/phases/count",
        method: "GET",
      }),
    }),
    getPhaseAnalysis: builder.query({
      query: (body) => {
        const { caseId } = body;
        return {
          url: `/cases/v1/${caseId}/phase-analysis`,
          method: "GET",
        };
      },
    }),
    getReportForReader: builder.query({
      query: (body) => {
        const { case_id, slide_type } = body;
        return {
          url: `/cases/v1/${case_id}/case-responses`,
          method: "GET",
          params: { slide_type },
        };
      },
      providesTags: ["response"],
    }),
    getStudyConclusion: builder.query({
      query: (body) => {
        const { case_id } = body;
        return {
          url: `/cases/v1/${case_id}/case-questions-options-responses-count`,
          method: "GET",
        };
      },
    }),

    //Reader Api
    getAllReaderDetails: builder.query({
      query: () => {
        return {
          url: `/cases/v1/cases/reader-details`,
          method: "GET",
        };
      },
    }),
    getAllReaderProgress: builder.query({
      query: () => {
        return {
          url: `/cases/v1/cases/reader-progress`,
          method: "GET",
        };
      },
    }),
    getAllReaderTimeline: builder.query({
      query: () => {
        return {
          url: `/cases/v1/cases/reader-timeline`,
          method: "GET",
        };
      },
    }),
    getAllReaderSlideInfo: builder.query({
      query: (body) => {
        const { case_id, slide_type = "HAndE" } = body;
        return {
          url: `/cases/v1/${case_id}/reader-slides?slide_type=${slide_type}`,
          method: "GET",
        };
      },
    }),
    //Admin Api
    createClinicalRole: builder.query({
      query: (body) => ({
        url: `/rbac/v1/role`,
        method: "POST",
        body,
      }),
    }),
    createClinicalUser: builder.query({
      query: (body) => ({
        url: `/users/v1/user/member`,
        method: "POST",
        body,
      }),
    }),
    getAllClinicalUserInformation: builder.query({
      query: (body) => {
        const { desiredRole } = body;
        return {
          url: `/users/v1/user-data${
            desiredRole !== null ? `?desiredRole=${desiredRole}` : ""
          }`,
          method: "GET",
        };
      },
    }),
    getAllClinicalRoleInformation: builder.query({
      query: (body) => {
        const { organization_id } = body;
        return {
          url: `/rbac/v1/role-data?organization_id=${organization_id}`,
          method: "GET",
        };
      },
    }),
    getReportAnalysis: builder.query({
      query: (body) => {
        const { case_id } = body;
        return {
          url: `/cases/v1/${case_id}/report-analysis`,
          method: "GET",
        };
      },
    }),
    updateStudyDetails: builder.mutation({
      query: (body) => {
        const { studyId, changes } = body;
        return {
          url: `/cases/v1/${studyId}`,
          method: "PATCH",
          body: { changes },
        };
      },
      invalidatesTags: ["studyDetails"],
    }),
    getStudyProgress: builder.query({
      query: (body) => {
        return {
          url: `/cases/v1/cases/pi-progress`,
          method: "GET",
        };
      },
    }),
    getStudyTimeline: builder.query({
      query: (body) => {
        return {
          url: `/cases/v1/cases/pi-timeline`,
          method: "GET",
        };
      },
    }),
    getStudyData: builder.query({
      query: (body) => {
        return {
          url: `/cases/v1/cases/pi-details`,
          method: "GET",
        };
      },
    }),
    updateSlideDetails: builder.mutation({
      query: (body) => {
        const { slideId, changes } = body;
        return {
          url: `/cases/v1/${slideId}/slide`,
          method: "PATCH",
          body: { changes },
        };
      },
      invalidatesTags: ["slideDetails"],
    }),
    deleteSlide: builder.mutation({
      query: (body) => {
        const { slideId } = body;
        return {
          url: `cases/v1/${slideId}/slide`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["slideDetails"],
    }),
    getReaderReportForPI: builder.query({
      query: (body) => {
        const { studyId, slideId, readerId } = body;
        return {
          url: `/cases/v1/${studyId}/${slideId}/slide-responses?reader_id=${readerId}`,
        };
      },
    }),
    //reader add api
    getAllReaderInfo: builder.query({
      query: (body) => {
        return {
          url: "/cases/v1/cases/readers",
          method: "GET",
        };
      },
    }),
    deleteReader: builder.mutation({
      query: (body) => {
        const { studyId, userIds } = body;
        return {
          url: `/cases/v1/${studyId}/readers`,
          method: "DELETE",
          body: { userIds },
        };
      },
    }),

    //reader slide wise reader api add
    getAllSlidesReader: builder.query({
      query: (body) => ({
        url: `/cases/v1/cases/reader_list`,
        method: "POST",
        body,
      }),
    }),
    getAllAssignSlidesReader: builder.query({
      query: (body) => ({
        url: `/cases/v1/cases/reader_assignment`,
        method: "POST",
        body,
      }),
    }),
    postAdditionalQuestionnaieResponses: builder.mutation({
      query: (body) => {
        const { case_id,slide_id,questions } = body;
        return {
          url: `/cases/v1/${case_id}/${slide_id}/addtional-questionnaire-responses`,
          method: "POST",
          body: { questions }
        };
      },
    }),
    getAllPermission: builder.query({
      query: () => ({
        url: `/rbac/v1/role-permissions`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useGetUserInfoQuery,
  useCreateStudyMutation,
  useAddReadersToStudyMutation,
  useLazyGetStudyParticipantsQuery,
  useGetStudyParticipantsQuery,
  useSearchPathologistByNameMutation,
  useGetPIStudiesQuery,
  useAddSlidesToStudyMutation,
  useCreateUserAndOrgMutation,
  useAddPIMutation,
  useAddPathologistMutation,
  // useAddAdminMutation,
  useGetUserBasedCountQuery,
  useLazyGetStudyInfoForPIQuery,
  useGetStudyInfoForPIQuery,
  useGetSlidesInfoQuery,
  useLazyGetSlidesInfoQuery,
  useGetTotalParticipantsCountQuery,
  useGetDepartmentCasesCountQuery,
  useGetOverallReadersQuery,
  useGetQuestionsQuery,
  useLazyGetQuestionsQuery,
  usePostQuestionnaieResponsesMutation,
  usePostAdditionalQuestionnaieResponsesMutation,
  useLazyGetQuestionnaireresponseQuery,
  useGetAllParticipantsQuery,
  useGetAllMembersbasedonRolesQuery,
  useLazyGetAllMembersbasedonRolesQuery,
  useGetStatsForPIQuery,
  useGetStudiesForReaderQuery,
  useGetReportResponseQuery,
  useLazyGetReportResponseQuery,
  useUpdateFCMTokenMutation,
  useGetSlidesStatusForCaseQuery,
  useUpdateArchiveForStudyMutation,
  useDeleteAnnotationMutation,
  useUpdateAnnotationMutation,
  useSaveAnnotationMutation,
  useLazyLoadAnnotationsQuery,
  useVhutAnalysisMutation,
  useVhutViewportAnalysisMutation,
  useLazyGetVhutAnalysisQuery,
  useGetPhaseWiseCountQuery,
  useGetPhaseAnalysisQuery,
  useGetReportForReaderQuery,
  useGetStudyConclusionQuery,
  useGetAllClinicalUserInformationQuery,
  useGetAllClinicalRoleInformationQuery,
  useGetReportAnalysisQuery,
  useLazyCreateClinicalRoleQuery,
  useLazyCreateClinicalUserQuery,
  useGetAllReaderDetailsQuery,
  useGetAllReaderProgressQuery,
  useGetAllReaderTimelineQuery,
  useGetAllReaderSlideInfoQuery,
  useLazyGetAllReaderTimelineQuery,
  useLazyGetAllReaderProgressQuery,
  useLazyGetAllReaderSlideInfoQuery,
  useLazyGetAllReaderDetailsQuery,
  useUpdateStudyDetailsMutation,
  useGetStudyProgressQuery,
  useGetStudyTimelineQuery,
  useLazyGetStudyProgressQuery,
  useLazyGetStudyTimelineQuery,
  useLazyGetReaderReportForPIQuery,
  useGetStudyDataQuery,
  useUpdateSlideDetailsMutation,
  useDeleteSlideMutation,
  useDeleteReaderMutation,
  useGetReaderReportForPIQuery,
  useGetAllReaderInfoQuery,
  useLazyGetAllReaderInfoQuery,
  useGetAllSlidesReaderQuery,
  useLazyGetAllAssignSlidesReaderQuery,
  useGetAllPermissionQuery,
  useGetStudiesQuery,
  useAddQuestionsMutation,
} = medicalApiSlice;

export default medicalApiSlice;

import React, { useEffect, useState } from "react";
import { Flex, Text, useMediaQuery, useToast } from "@chakra-ui/react";
import {
  useGetPIStudiesQuery,
  useGetStudiesForReaderQuery,
  useGetUserInfoQuery,
  useLazyGetStudyInfoForPIQuery,
} from "../../state/api/medicalApi";
import { useAuth0 } from "@auth0/auth0-react";
import Loading from "../Loading/loading";
import useUserAuthentication from "../../hooks/useUserAuthentication";
import Header from "../../components/Header";
import SideBar from "../../components/Sidebar";
import Toolbar from "../../components/Toolbar";
import Navbar from "./Navbar";
import IconsNavbar from "./IconsNavbar";
import StudyInfo from "./StudyInfo";
import Studybar from "./Studybar";
import Study from "./Study";
import { IoWarningOutline } from "react-icons/io5";
import { onMessageListener } from "../../Firebase/init";

const Studies = () => {
  const { data: userInfo, isFetching } = useGetUserInfoQuery();
  const [getStudyInfo] = useLazyGetStudyInfoForPIQuery();
  const isPI = userInfo?.data[0]?.role === "PI";
  const { data: piStudies, isLoading: isPIStudiesFetching } =
    useGetPIStudiesQuery({}, { skip: !isPI });
  const isReader = userInfo?.data[0]?.role === "Pathologist";
  const { data: readerStudies, isLoading: isReaderStudiesFetching } =
    useGetStudiesForReaderQuery(
      {},
      {
        skip: !isReader,
      }
    );
  const studies =
    userInfo?.data[0]?.role === "PI"
      ? piStudies?.data?.studyDesiredInfo
      : readerStudies?.data?.requiredDataInfo;
  const isUserAuthenticated = useUserAuthentication();
  const [study, setStudy] = useState("allStudy");
  const [studyDetailsToggle, setStudyDetailsToggle] = useState(false);
  const [leftPanelToggle, setLeftPanelToggle] = useState(true);
  const [ifWidthLessthan1920] = useMediaQuery("(max-width:1920px)");
  const [searchStudy, setSearchStudy] = useState("");
  const [caseId, setCaseId] = useState();
  const [studyName, setStudyName] = useState();
  useEffect(() => {
    localStorage.setItem("leftPanelToggle", leftPanelToggle);
    localStorage.setItem("study", study);
  }, [leftPanelToggle, study]);
  const toast = useToast();

  const [navbarToggle, setNavbarToggle] = useState(true);
  onMessageListener()
    .then((payload) => {
      if (payload.notification) {
        const { title, body } = payload.notification;
        toast({
          title: title || "Notification",
          description: body || "",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
      }
      if (payload.data && payload.data.caseId) {
        getStudyInfo({ caseId: payload.data.caseId }).unwrap();
      }
    })
    .catch((err) => {
      toast({
        title: "Server Error",
        description: err.message,
        status: "error",
        duration: 2000,
        isClosable: true,
      });
      console.error(err);
    });
  return isFetching ? (
    <Loading />
  ) : (
    <Flex
      w="100%"
      h="100vh"
      flexDirection="column"
      overflow="hidden !important"
      fontFamily="inter !important"
    >
      <Header userInfo={userInfo?.data[0]} setSearchStudy={setSearchStudy} />
      <Flex w="100vw" background="light.100">
        <SideBar />
        <Flex w="100%" flexDirection="column">
          <Toolbar
            setNavbarToggle={setNavbarToggle}
            navbarToggle={navbarToggle}
            userInfo={userInfo?.data[0]}
            caseId={caseId}
            studyName={studyName}
          />
          <Flex w="100vw">
            {navbarToggle ? (
              <Navbar
                study={study}
                setStudy={setStudy}
                navbarToggle={navbarToggle}
                setLeftPanelToggle={setLeftPanelToggle}
              />
            ) : (
              <IconsNavbar
                study={study}
                setStudy={setStudy}
                navbarToggle={navbarToggle}
                setLeftPanelToggle={setLeftPanelToggle}
              />
            )}
            {isPIStudiesFetching || isReaderStudiesFetching ? (
              <Flex
                w="70vw"
                h="70vh"
                alignItems="center"
                justifyContent="center"
              >
                <Loading />
              </Flex>
            ) : (
              <>
                {!studies?.length > 0 ? (
                  <Flex
                    alignItems="center"
                    justifyContent="center"
                    w="100%"
                    direction="column"
                    bg="#fff"
                  >
                    <IoWarningOutline />
                    <Text>
                      {userInfo?.data[0]?.role === "PI"
                        ? "No cases  are created !!"
                        : "No cases assigned to you!!"}
                    </Text>
                  </Flex>
                ) : (
                  <>
                    {leftPanelToggle ? (
                      <Studybar
                        study={study}
                        navbarToggle={navbarToggle}
                        setStudyDetailsToggle={setStudyDetailsToggle}
                        userInfo={userInfo?.data[0]}
                        studies={studies}
                        setCaseId={setCaseId}
                        caseId={caseId}
                        setStudyName={setStudyName}
                        searchStudy={searchStudy}
                      />
                    ) : (
                      <Flex
                        w={ifWidthLessthan1920 ? "290.25px" : "20.46vw"}
                        h={
                          ifWidthLessthan1920
                            ? "calc(100vh - 84px)"
                            : "90.926vh"
                        }
                        background="light.1000"
                        box-shadow="0px 1px 2px rgba(176, 200, 214, 0.15)"
                        alignItems="center"
                        justifyContent="center"
                        px="1vw"
                      >
                        <Text fontSize="1.3vw" opacity="0.6" textAlign="center">
                          Select a category of case from left panel.
                        </Text>
                      </Flex>
                    )}
                  </>
                )}
                {studyDetailsToggle ? (
                  <StudyInfo
                    userInfo={userInfo?.data[0]}
                    navbarToggle={navbarToggle}
                    studyDetailsToggle={studyDetailsToggle}
                    setStudyDetailsToggle={setStudyDetailsToggle}
                    caseId={caseId}
                  />
                ) : (
                  ""
                )}
                <Study
                  caseId={caseId}
                  userInfo={userInfo?.data[0]}
                  studyName={studyName}
                />
              </>
            )}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Studies;

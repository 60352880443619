import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  useMediaQuery,
  Flex,
  Text,
  Image,
  Spinner,
} from "@chakra-ui/react";
import { useGetReaderReportForPIQuery } from "../../state/api/medicalApi";

const ReportModal = ({ isOpen, onClose, slide, studyId, readerId }) => {
  const [ifSmallScreen] = useMediaQuery("(max-width:1920px)");
  const { data: readerReport, isLoading } = useGetReaderReportForPIQuery(
    {
      studyId,
      slideId: slide?._id,
      readerId,
    },
    { skip: !readerId }
  );
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent borderRadius="0">
        <ModalCloseButton
          pb={ifSmallScreen ? "20px" : "15px"}
          pt={ifSmallScreen ? "10px" : "21px"}
          mr={ifSmallScreen ? "10px" : "10.5"}
          _hover={{ opacity: 1 }}
        />
        <ModalBody p="7vh 6vw">
          {isLoading ? (
            <Flex align="center" justify="center">
              <Spinner
                color="#3965C5"
                size="xl"
                thickness="4px"
                speed="0.65s"
              />
            </Flex>
          ) : (
            <Flex direction="column">
              <Flex border="1px solid #000" mb="1.5vh">
                <Text px="0.5vw">{`Accession ID: ${slide?.accessionId}`}</Text>
                <Text
                  borderLeft="1px solid #000"
                  px="0.5vw"
                >{`Reader: ${readerReport?.data?.first_name} ${readerReport?.data?.last_name}`}</Text>
              </Flex>
              {readerReport?.data?.filteredQuestionnaireResponse?.map(
                (question, i) => {
                  return (
                    <Flex key={i} direction="column" mb="3.47vh">
                      <Text>{`Q ${i + 1}: ${
                        question?.question_text || question?.section_heading
                      }`}</Text>
                      {question?.is_section ? (
                        <Flex direction="column" px="1vw">
                          {question?.section_questions?.map(
                            (question, index) => {
                              return (
                                <Flex direction="column" key={index} py="0.5vh">
                                  <Text>{`Q ${index + 1}: ${
                                    question?.question_text
                                  }`}</Text>
                                  <Text>{`A: ${
                                    question?.response
                                      ?.replace(/{"/g, "")
                                      .replace(/"}/g, "")
                                      .replace(/"/g, "") || "-"
                                  }`}</Text>
                                </Flex>
                              );
                            }
                          )}
                        </Flex>
                      ) : (
                        <Text>{`A: ${
                          question?.response
                            ?.replace(/{"/g, "")
                            .replace(/"}/g, "")
                            .replace(/"/g, "") || "-"
                        }`}</Text>
                      )}
                    </Flex>
                  );
                }
              )}
            </Flex>
          )}
          {!isLoading && (
            <>
              <Image
                src={readerReport?.data?.signature_file}
                alt=""
                maxW="6vw"
                minW="70px"
              />
              <Text>{`${readerReport?.data?.first_name} ${readerReport?.data?.last_name}`}</Text>
              <Text>{`${readerReport?.data?.highest_qualification}, ${readerReport?.data?.Institute}`}</Text>
              <Text>{readerReport?.data?.address}</Text>
            </>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ReportModal;

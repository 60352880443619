import React, { useState, useEffect } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Avatar,
  Flex,
  CircularProgress,
  Button,
  MenuList,
  MenuButton,
  Menu,
  MenuItem,
  IconButton,
  Text,
  Checkbox,
  Box,
  CheckIcon,
  CloseIcon,
  ControlBox,
  Icon,
  MdCheck,
} from "@chakra-ui/react";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import SlideEditModal from "./SlideEditModal";
import SlideDeleteConfirmationModal from "./SlideDeleteConfirmationModal";
import { FiMoreVertical } from "react-icons/fi";
import moment from "moment";
import { getSlideUrl } from "../../../../../hooks/utility";
import ReportModal from "../../../../Report/ReportModal";

const SlideTableView = ({
  slides,
  userInfo,
  studyId,
  select,
  selectedRows,
  setSelectedRows,
  permission,
}) => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isReportModalOpen, setIsReportModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [slideId, setSlideId] = useState();
  const [slideData, setSlideData] = useState();
  const [readerId, setReaderId] = useState();
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const handleDeleteCloseModal = () => {
    setIsDeleteModalOpen(false);
  };
  const handleReportCloseModal = () => {
    setIsReportModalOpen(false);
  };

  console.log(selectedRows);

  const handleRowSelection = (slideId) => {
    // Check if the row is already selected
    const isSelected = selectedRows.includes(slideId);

    // Update the selectedRows state based on the current selection
    setSelectedRows(
      (prevSelectedRows) =>
        isSelected
          ? prevSelectedRows.filter((id) => id !== slideId) // Deselect
          : [...prevSelectedRows, slideId] // Select
    );
  };
  // const handleSelectAll = () => {
  //   // If all items are already selected, clear the selection; otherwise, select all
  //   setSelectedRows((prevSelectedRows) =>
  //     prevSelectedRows.length === slides?.mergedData?.length
  //       ? []
  //       : data?.mergedData?.map((slide) => slide?._id) || []
  //   );
  // };
  return (
    <Table
      variant="simple"
      bgColor="white"
      borderWidth="1px"
      borderRadius="md"
      mx="14vw"
      position="relative"
    >
      <Thead>
        <Tr position="sticky" top="0" bgColor="white" zIndex="1">
          <Th />
          <Th>Accession Id</Th>
          <Th>Status</Th>
          <Th>Upload Date</Th>
          {/* Remove the "Save Date" column */}
          <Th>Progress</Th>
          {select && (
            <Th>
              {/* <Checkbox
                isChecked={selectedRows.length === data?.mergedData?.length}
                onChange={() => handleSelectAll()}
              /> */}
              {selectedRows.length}
            </Th>
          )}
        </Tr>
      </Thead>
      <Tbody>
        {slides?.mergedData?.map((slide) => (
          <Tr
            key={slide?.accessionId}
            borderBottom="1px" // Add border to each row
            borderColor="gray.200" // Set border color
            fontSize="14px"
            onClick={() => {
              if (!select) {
                navigate("/viewer", {
                  state: {
                    caseId: slide?.caseId,
                    slide,
                    userInfo,
                  },
                });
              }
            }}
          >
            <Td borderBottom="none" display="flex" alignslides="center">
              <Avatar
                size="sm"
                color="#000"
                bg="rgba(27, 117, 188, 0.25)"
                name={slide?.accessionId}
                src={getSlideUrl(slide?.awsImageBucketUrl)}
              />
            </Td>
            <Td>{slide?.accessionId}</Td>
            <Td>
              {slide?.status ? (
                <Text
                  color="rgba(50, 89, 38, 1)"
                  fontSize="12px"
                  fontWeight="bold"
                >
                  SAVED
                </Text>
              ) : (
                <Text
                  color="rgba(27, 117, 188, 1)"
                  fontSize="12px"
                  fontWeight="bold"
                >
                  NEW
                </Text>
              )}
            </Td>
            <Td>{moment(slide?.created_at).format("DD/MM/YYYY")}</Td>
            {/* Add the "Progress" column with avatars and circular progress bars */}
            <Td>
              <Flex justifyContent="space-between" position="relative">
                <Flex justifyContent="flex-start" position="relative">
                  {slide?.list?.map((user, index) => {
                    console.log("user", user);
                    return (
                      <Flex
                        key={user.user_id}
                        direction="column"
                        // alignslides="center"
                      >
                        <Menu placement="left">
                          <MenuButton
                            as={IconButton}
                            transition="all 0.2s"
                            bgColor="light.1000"
                            _hover={{ bgColor: "none" }}
                            _focus={{ outline: "none", bg: "none" }}
                            _active={{ outline: "none", bg: "none" }}
                            // icon={<FiMoreVertical />}
                            w="1.5vw"
                            h="3.5vh"
                            borderRadius={0}
                            onClick={(e) => e.stopPropagation()}
                          >
                            <Avatar
                              size="sm"
                              bg="rgba(27, 117, 188, 0.25)"
                              name={`${user?.first_name} ${user?.last_name}`}
                              color="black"
                              // src={`${user?.first_name} ${user?.last_name}`}
                              border={
                                slide?.responses
                                  ?.filter(
                                    (userData) =>
                                      userData?.user_id === user?.user_id
                                  )
                                  ?.find((item) => item?.is_saved === true) &&
                                "2px solid rgba(80, 170, 80, 1)"
                              }
                            />
                          </MenuButton>
                          {slide?.responses
                            ?.filter(
                              (userData) => userData?.user_id === user?.user_id
                            )
                            ?.find((item) => item?.is_saved === true) && (
                            <MenuList
                              color="#000"
                              boxShadow="0px 4px 4px 0px rgba(0, 0, 0, 0.25)"
                              px="1.6145vw"
                            >
                              <Text mb="0.6vh">
                                {`Saved on ${moment(
                                  slide?.responses
                                    ?.filter(
                                      (userData) =>
                                        userData?.user_id === user?.user_id
                                    )
                                    ?.find((item) => item?.is_saved === true)
                                    ?.updated_at
                                ).format("DD/MM/YYYY")}`}
                              </Text>

                              {/* <MenuItem
                            justifyContent="center"
                            bg="rgba(27, 117, 188, 0.25)"
                            _hover={{ bg: "rgba(27, 117, 188, 0.25)" }}
                            color="light.700"
                            borderRadius="10px"
                            onClick={(e) => {
                              e.stopPropagation();
                              setIsReportModalOpen(true);
                              setSlideData(slide);
                              setReaderId(user?.user_id);
                            }}
                          >
                            View Report
                          </MenuItem> */}
                            </MenuList>
                          )}
                        </Menu>
                      </Flex>
                    );
                  })}
                </Flex>
                <Menu placement="right">
                  <MenuButton
                    as={Button}
                    size="sm"
                    variant="ghost"
                    onClick={(e) => e.stopPropagation()}
                    pb="10px"
                    _hover="none"
                    _active="none"
                  >
                    <BiDotsVerticalRounded />
                  </MenuButton>
                  <MenuList p="0px" minW="2vw">
                    {permission?.data?.[0]?.permissions.includes(
                      "slideEdit"
                    ) && (
                      <MenuItem
                        onClick={(e) => {
                          e.stopPropagation();
                          setSlideId(slide?._id);
                          setIsModalOpen(true);
                        }}
                      >
                        Edit
                      </MenuItem>
                    )}
                    {permission?.data?.[0]?.permissions.includes(
                      "slideDelete"
                    ) && (
                      <MenuItem
                        onClick={(e) => {
                          e.stopPropagation();
                          setSlideId(slide?._id);
                          setIsDeleteModalOpen(true);
                        }}
                      >
                        Delete
                      </MenuItem>
                    )}
                  </MenuList>
                </Menu>
              </Flex>
            </Td>
            {select && (
              <Td>
                <Box
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <Checkbox
                    isChecked={selectedRows.includes(slide?._id)}
                    onChange={() => handleRowSelection(slide?._id)}
                    sx={{
                      _checked: {
                        bg: "green",
                        // h: "40px",
                        // px: "15px",
                        borderRadius: "99px",
                      },
                      "span[class*='checkbox__control']:not([data-disabled])": {
                        borderColor: "rgba(217, 217, 217, 1)",
                        borderRadius: "99px",
                        _checked: {
                          bg: "green",
                          borderColor: "green",
                        },
                        _focus: {
                          boxShadow: `0 0 0 2px rgba(217, 217, 217, 1)`,
                          _checked: {
                            boxShadow: `0 0 0 2px green`,
                          },
                        },
                        _after: {
                          transitionProperty: "all",
                          transitionDuration: "normal",
                          content: `""`,
                          position: "absolute",
                          width: "0px",
                          height: "0px",
                          bg: `transparent`,
                          borderRadius: "99px",
                          zIndex: -1,
                        },
                      },
                      // _hover: {
                      //   "span[class*='checkbox__control']:not([data-disabled])":
                      //     {
                      //       _after: {
                      //         width: "40px",
                      //         height: "40px",
                      //         bg: "rgba(217, 217, 217, 1)",
                      //         borderColor: "rgba(217, 217, 217, 1)",
                      //       },
                      //     },
                      // },
                      radius: "99px",
                      controlRadius: "99px",
                    }}
                  />
                </Box>
              </Td>
            )}
            {slideId === slide?._id && (
              <>
                <SlideEditModal
                  isOpen={isModalOpen}
                  onClose={handleCloseModal}
                  slide={slide}
                />
                <SlideDeleteConfirmationModal
                  isOpen={isDeleteModalOpen}
                  onClose={handleDeleteCloseModal}
                  slideId={slide?._id}
                />
              </>
            )}
            {slideData?._id === slide?._id && (
              <ReportModal
                isOpen={isReportModalOpen}
                onClose={handleReportCloseModal}
                slide={slide}
                studyId={studyId}
                readerId={readerId}
              />
            )}
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
};

export default SlideTableView;

import { Box, VStack, Button, useMediaQuery, Icon } from "@chakra-ui/react";
import React, { useState } from "react";
import { AiOutlineFileSearch } from "react-icons/ai";
import { BiBox } from "react-icons/bi";
import { FaRegCheckSquare } from "react-icons/fa";
import { GrPowerCycle } from "react-icons/gr";

const IconsNavbar = ({
  study,
  setStudy,
  sidebarToggle,
  setLeftPanelToggle,
}) => {
  const [hoverAllStudy, setHoverAllStudy] = useState(false);
  const [hoverOngoingStudy, setHoverOngoingStudy] = useState(false);
  const [hoverCompletedStudy, setHoverCompletedStudy] = useState(false);
  const [hoverArchiveState, setHoverArchiveState] = useState(false);
  const [ifWidthLessthan1920] = useMediaQuery("(max-width:1920px)");

  return (
    <Box
      h={ifWidthLessthan1920 ? "calc(100vh - 85px)" : "90.926vh"}
      w={ifWidthLessthan1920 ? "48px" : "2.343vw"}
      bgColor="light.800"
      pt="6.4vh"
      // position="absolute"
      // mt="1px"
    >
      <VStack>
        <Button
          _focus={{ outline: "none" }}
          background={study === "allStudy" ? "light.900" : "light.800"}
          boxShadow={
            study === "allStudy" ? "-2px 0px 0px 0px rgba(59, 93, 124, 1)" : ""
          }
          fontWeight="500"
          // color="light.700"
          w="100%"
          borderRadius={0}
          onClick={() => {
            setStudy("allStudy");
            setLeftPanelToggle(true);
          }}
          onMouseEnter={() => setHoverAllStudy(true)}
          onMouseLeave={() => setHoverAllStudy(false)}
        >
          <Icon as={AiOutlineFileSearch} />
        </Button>
        <Button
          _focus={{ outline: "none" }}
          background={study === "ongoingStudies" ? "light.900" : "light.800"}
          boxShadow={
            study === "ongoingStudies"
              ? "-2px 0px 0px 0px rgba(59, 93, 124, 1)"
              : ""
          }
          fontWeight="500"
          // color="light.700"
          w="100%"
          borderRadius={0}
          mt="-0rem !important"
          onClick={() => {
            setStudy("ongoingStudies");
            setLeftPanelToggle(true);
          }}
          onMouseEnter={() => setHoverOngoingStudy(true)}
          onMouseLeave={() => setHoverOngoingStudy(false)}
        >
          <Icon as={GrPowerCycle} />
        </Button>
        <Button
          _focus={{ outline: "none" }}
          background={study === "completedStudies" ? "light.900" : "light.800"}
          boxShadow={
            study === "completedStudies"
              ? "-2px 0px 0px 0px rgba(59, 93, 124, 1)"
              : ""
          }
          fontWeight="500"
          // color="light.700"
          w="100%"
          borderRadius={0}
          onClick={() => {
            setStudy("completedStudies");
            setLeftPanelToggle(true);
          }}
          onMouseEnter={() => setHoverCompletedStudy(true)}
          onMouseLeave={() => setHoverCompletedStudy(false)}
          mt="-0rem !important"
        >
          <Icon as={FaRegCheckSquare} />
        </Button>
        <Button
          _focus={{ outline: "none" }}
          background={study === "archiveState" ? "light.900" : "light.800"}
          boxShadow={
            study === "archiveState"
              ? "-2px 0px 0px 0px rgba(59, 93, 124, 1)"
              : ""
          }
          fontWeight="500"
          // color="light.700"
          w="100%"
          borderRadius={0}
          mt="-0rem !important"
          onClick={() => {
            setStudy("archiveState");
            setLeftPanelToggle(true);
          }}
          onMouseEnter={() => setHoverArchiveState(true)}
          onMouseLeave={() => setHoverArchiveState(false)}
        >
          <Icon as={BiBox} />
        </Button>
      </VStack>
    </Box>
  );
};

export default IconsNavbar;

import { useEffect, useState } from "react";
import { Flex } from "@chakra-ui/react";
import { Outlet } from "react-router-dom";
import Header from "../Header";
import Loading from "../Loading/loading";
import {
  useGetUserInfoQuery,
  useLazyGetAllMembersbasedonRolesQuery,
} from "../../state/api/medicalApi";
import InfoCardComponent from "../NewAdmin/InfoCardComponent";
import TotalMember from "../NewAdmin/TotalMember";

const DashboardLayout = () => {
  const { data: userInfo, isLoading } = useGetUserInfoQuery();
  const [membersData, setMembersData] = useState();
  const [getMembersInfo] = useLazyGetAllMembersbasedonRolesQuery();
  useEffect(() => {
    async function fetchData() {
      const resp = await getMembersInfo({
        organizationId: userInfo?.data[0].organizationId,
        roleName: "",
      });
      setMembersData(resp?.data.data.desiredRoleInfo);
    }
    fetchData();
  }, []);
  console.log(membersData);

  const data = [
    {
      userId: "83e92ac8-4263-4ffa-b920-8947514d6121",
      emailAddress: "siddheshkaleclinical@yopmail.com",
      firstName: "Siddhesh",
      lastName: "Kale",
      phoneNumber: null,
      yearOfRegistration: null,
      yearOfExperience: null,
      role: "Admin",
      organizationId: "7b2c4df6-9186-4934-a6c1-e7316ba82c28",
      created_at: "2023-09-28T08:01:13.000Z",
    },
    {
      userId: "1b6107e1-2bea-4b4b-bab9-0988e7d6567d",
      emailAddress: "siddheshkaleclinicalt1@yopmail.com",
      firstName: "Siddhesh",
      lastName: "Kale",
      phoneNumber: "23223232323232",
      yearOfRegistration: "2323232",
      yearOfExperience: "less than 1 year",
      role: "PI",
      organizationId: "7b2c4df6-9186-4934-a6c1-e7316ba82c28",
      created_at: "2023-09-28T08:01:13.000Z",
    },
    {
      userId: "1f92124f-5ad4-432c-9a07-5397ad0310d2",
      emailAddress: "ryrtyrtyrt@yopmail.com",
      firstName: "ertrtytry",
      lastName: "ryrtyrtyrty",
      phoneNumber: "21312312323",
      yearOfRegistration: "12312312",
      yearOfExperience: "1 year ",
      role: "Pathologist",
      organizationId: "7b2c4df6-9186-4934-a6c1-e7316ba82c28",
      created_at: "2023-09-28T08:01:13.000Z",
    },
  ];

  return isLoading ? (
    <Loading />
  ) : (
    <Flex
      w="100%"
      h="100%"
      flexDirection="column"
      overflow="hidden !important"
      bg="light.800"
    >
      <Header userInfo={userInfo?.data[0]} />
      <Outlet />
    </Flex>
  );
};

export default DashboardLayout;

import React, { useEffect, useState } from "react";
import {
  Flex,
  Box,
  Text,
  Button,
  useMediaQuery,
  Image,
} from "@chakra-ui/react";
import Timeline from "./Timeline";
import {
  useGetStudyInfoForPIQuery,
  useGetReportResponseQuery,
} from "../../state/api/medicalApi";
import NoCaseSelectedIcon from "../../images/noSelectedCaseIcon.svg";
import Report from "../Report/Report";
import SlidesDetail from "./SlidesDetail";
import ReaderReport from "../Report/ReaderReport";
const Study = ({ caseId, userInfo, studyName }) => {
  const { data: studyInfo, isFetching } = useGetStudyInfoForPIQuery(
    {
      caseId: caseId,
    },
    { skip: !caseId }
  );

  const [ifWidthLessthan1920] = useMediaQuery("(max-width:1920px)");
  const [studyState, setStudyState] = useState("slides");

  // console.log("asdsd", report?.data?.caseReportParams)
  return (
    <Flex
      flexDirection="column"
      flex="0.95"
      fontSize="0.72916vw"
      boxShadow="1px 1px 2px rgba(176, 200, 214, 0.15)"
      h="100%"
      bg="light.1000"
      minW="350px"
      ml="1px"
    >
      {!caseId ? (
        <Flex
          h="100%"
          minH="90vh"
          w="100%"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Image src={NoCaseSelectedIcon} />
          <Text fontSize="1.5vw" opacity="0.8">
            Select a case to see the information.
          </Text>
        </Flex>
      ) : (
        <Flex
          h="4.814vh"
          pb="0.35vh"
          pl="0.937vw"
          w="100%"
          justifyContent="space-between"
          alignItems="center"
          boxShadow="1px 1px 2px rgba(176, 200, 214, 0.1)"
          background="light.1000"
          borderBottom="none"
        >
          <Box>
            <Button
              w="7.1vw"
              minW="100px"
              minH="30px"
              h="4.074vh"
              px="0.833vw"
              py="2.08vh"
              mr="0.8vw"
              size="xs"
              fontFamily="inter"
              fontWeight={400}
              borderRadius="0px"
              _focus={{ outline: "none" }}
              _hover={{ bgColor: "light.900" }}
              bgColor={studyState === "slides" ? "light.900" : "light.1000"}
              color={studyState === "slides" ? "light.700" : "#000"}
              borderBottom={studyState === "slides" ? "2px solid #3B5D7C" : ""}
              onClick={() => setStudyState("slides")}
            >
              <Text fontSize={ifWidthLessthan1920 ? "14px" : "0.72916vw"}>
                Slides
              </Text>
            </Button>
            {userInfo?.role === "PI" && (
              <Button
                w="7.1vw"
                minW="100px"
                minH="30px"
                h="4.074vh"
                size="xs"
                px="3.833vw"
                py="2.08vh"
                mr="0.8vw"
                fontFamily="inter"
                fontWeight={400}
                borderRadius="0px"
                bgColor={studyState === "timeline" ? "light.900" : "light.1000"}
                color={studyState === "timeline" ? "light.700" : "#000"}
                borderBottom={
                  studyState === "timeline" ? "2px solid #3B5D7C" : ""
                }
                _focus={{ outline: "none" }}
                _hover={{ bgColor: "light.900" }}
                onClick={() => setStudyState("timeline")}
              >
                <Text fontSize={ifWidthLessthan1920 ? "14px" : "0.72916vw"}>
                  Timeline
                </Text>
              </Button>
            )}
            <Button
              w="4.1vw"
              minW="100px"
              minH="30px"
              h="4.074vh"
              px="0.833vw"
              size="xs"
              py="2.08vh"
              mr="0.8vw"
              fontFamily="inter"
              bgColor={studyState === "report" ? "light.900" : "light.1000"}
              color={studyState === "report" ? "light.700" : "#000"}
              borderBottom={studyState === "report" ? "2px solid #3B5D7C" : ""}
              fontWeight={400}
              borderRadius="0px"
              _focus={{ outline: "none" }}
              _hover={{ bgColor: "light.900" }}
              onClick={() => setStudyState("report")}
            >
              <Text fontSize={ifWidthLessthan1920 ? "14px" : "0.72916vw"}>
                Report
              </Text>
            </Button>
          </Box>
        </Flex>
      )}
      <Box w="100%" h="81vh" overflowY="auto" pr="1.1vw">
        {studyState === "report" ? (
          userInfo?.role === "PI" ? (
            <Report
              studyInfo={studyInfo?.data?.caseInfo}
              studyName={studyName}
              caseId={caseId}
            />
          ) : (
            <ReaderReport
              caseId={caseId}
              userInfo={userInfo}
              studyName={studyName}
            />
          )
        ) : studyState === "timeline" ? (
          <Timeline
            caseId={caseId}
            slides={studyInfo?.data?.caseInfo?.slides}
            studyName={studyName}
            studyInfo={studyInfo}
            isLoading={isFetching}
          />
        ) : studyState === "slides" ? (
          <SlidesDetail
            caseId={caseId}
            userInfo={userInfo}
            studyName={studyName}
            slides={studyInfo?.data?.caseInfo?.slides}
            loading={isFetching}
          />
        ) : (
          ""
        )}
      </Box>
    </Flex>
  );
};

export default Study;

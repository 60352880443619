import {
  Divider,
  Flex,
  HStack,
  Image,
  Spinner,
  Text,
  useMediaQuery,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { useGetStudyInfoForPIQuery } from "../../state/api/medicalApi";
import StudyAndTimeline from "./StudyAndTimeline";

function StudyDepartments({ userInfo, studyId }) {
  const { data: studyInfo, isLoading } = useGetStudyInfoForPIQuery(
    {
      caseId: studyId,
    },
    { skip: !studyId }
  );
  const [ifWidthLessthan1920] = useMediaQuery("(max-width:1920px)");
  return isLoading ? (
    <Flex
      h="88.9vh"
      w="100%"
      minW="250px"
      minH="125px"
      alignItems="center"
      justifyContent="center"
      bg="light.100"
      boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
      mb="3.055vh"
      p="0.989vw"
      pt="0.3vw"
    >
      <Spinner color="#3965C5" size="xl" thickness="4px" speed="0.65s" />
    </Flex>
  ) : (
    <Flex
      bg="light.100"
      h="88.9vh"
      w="100%"
      fontSize={ifWidthLessthan1920 ? "14px" : "0.729vw"}
      p="1vw"
      boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
      minW="250px"
    >
      <VStack alignItems="flex-start" w="100%">
        <StudyAndTimeline studyInfo={studyInfo} userInfo={userInfo} />
      </VStack>
    </Flex>
  );
}

export default StudyDepartments;

import {
  Divider,
  Flex,
  Text,
  VStack,
  useMediaQuery,
  Spinner,
} from "@chakra-ui/react";
import React from "react";

function StudiesCount({
  userInfo,
  completedCasesCount,
  pendingCasesCount,
  totalCasesCount,
  isStatsLoading,
}) {
  const ongoingCasesCount =
    totalCasesCount - pendingCasesCount - completedCasesCount;
  const [ifWidthLessthan1920] = useMediaQuery("(max-width:1920px)");
  return isStatsLoading ? (
    <Flex
      h="11.6vh"
      w="100%"
      minW="300px"
      minH="70px"
      alignItems="center"
      justifyContent="center"
      bg="light.100"
      py="1vw"
      boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
    >
      <Spinner color="#3965C5" size="xl" thickness="4px" speed="0.65s" />
    </Flex>
  ) : (
    <Flex
      bg="light.100"
      h="11.6vh"
      w="100%"
      justifyContent="space-evenly"
      fontSize={ifWidthLessthan1920 ? "14px" : "0.729vw"}
      minH="70px"
      boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
      minW="300px"
      pt={ifWidthLessthan1920 ? "1vw" : "0.5vw"}
    >
      <VStack>
        <Text>Total Studies</Text>
        <Text
          fontSize={ifWidthLessthan1920 ? "30px" : "2.0833vw"}
          mt="-0rem !important"
        >
          {totalCasesCount}
        </Text>
      </VStack>
      <Divider orientation="vertical" color="#999999" />

      <VStack>
        <Text>Ongoing</Text>
        <Text
          fontSize={ifWidthLessthan1920 ? "30px" : "2.0833vw"}
          mt="-0rem !important"
        >
          {pendingCasesCount}
        </Text>
      </VStack>
      <Divider orientation="vertical" color="#999999" />

      <VStack>
        <Text>Pending</Text>
        <Text
          fontSize={ifWidthLessthan1920 ? "30px" : "2.0833vw"}
          mt="-0rem !important"
        >
          {ongoingCasesCount ? ongoingCasesCount : "0"}
        </Text>
      </VStack>
      <Divider orientation="vertical" color="#999999" />

      <VStack>
        <Text>Completed</Text>
        <Text
          fontSize={ifWidthLessthan1920 ? "32px" : "2.0833vw"}
          mt="-0rem !important"
        >
          {completedCasesCount}
        </Text>
      </VStack>
    </Flex>
  );
}

export default StudiesCount;

import {
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  HStack,
  VStack,
  Button,
  Image,
  Text,
  useMediaQuery,
  Flex,
  Icon,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { AiOutlineFileSearch } from "react-icons/ai";
import { GrPowerCycle } from "react-icons/gr";
import { FaRegCheckSquare } from "react-icons/fa";
import { BiBox } from "react-icons/bi";

const Navbar = ({ study, setStudy, sidebarToggle, setLeftPanelToggle }) => {
  const { user } = useAuth0();
  const [hoverAllStudy, setHoverAllStudy] = useState(false);
  const [hoverOngoingStudy, setHoverOngoingStudy] = useState(false);
  const [hoverCompletedStudy, setHoverCompletedStudy] = useState(false);
  const [hoverArchiveStudies, setHoverArchiveStudies] = useState(false);
  const [ifWidthLessthan1920] = useMediaQuery("(max-width:1920px)");
  return (
    <Box
      justifyContent="center"
      w={ifWidthLessthan1920 ? "210px" : "11.927vw"}
      bgColor="light.800"
      left={0}
      h={ifWidthLessthan1920 ? "calc(100vh - 85px)" : "90.926vh"}
      mt="1px"
    >
      <Accordion defaultIndex={[0, 1, 2]} allowMultiple fontSize="paragraph">
        <AccordionItem border="none">
          <AccordionButton _focus={{ outline: "none" }} outline="none">
            <HStack spacing="0.703125vw" fontFamily="inter">
              <AccordionIcon />
              <Box flex="1" fontSize="14px">
                Study
              </Box>
            </HStack>
          </AccordionButton>
          <AccordionPanel pb={4} pt={1} px="0">
            <VStack>
              <Button
                mt="-0rem !important"
                _focus={{ outline: "none" }}
                background={study === "allStudy" ? "light.900" : "light.800"}
                fontSize="paragraph"
                fontWeight="500"
                w="100%"
                borderRadius={0}
                justifyContent="flex-start"
                onClick={() => {
                  setStudy("allStudy");
                  setLeftPanelToggle(true);
                }}
                onMouseEnter={() => setHoverAllStudy(true)}
                onMouseLeave={() => setHoverAllStudy(false)}
                fontFamily="inter"
              >
                <Icon as={AiOutlineFileSearch} mr="0.5vw" />
                <Text isTruncated fontSize="14px">
                  All Study
                </Text>
              </Button>
              <Button
                mt="-0rem !important"
                _focus={{ outline: "none" }}
                background={
                  study === "ongoingStudies" ? "light.900" : "light.800"
                }
                fontSize="paragraph"
                fontWeight="500"
                w="100%"
                borderRadius={0}
                justifyContent="flex-start"
                onClick={() => {
                  setStudy("ongoingStudies");
                  setLeftPanelToggle(true);
                }}
                onMouseEnter={() => setHoverOngoingStudy(true)}
                onMouseLeave={() => setHoverOngoingStudy(false)}
                fontFamily="inter"
              >
                <Icon as={GrPowerCycle} mr="0.5vw" />
                <Text fontSize="14px">On going Study</Text>
              </Button>
              <Button
                mt="-0rem !important"
                _focus={{ outline: "none" }}
                background={
                  study === "completedStudies" ? "light.900" : "light.800"
                }
                fontSize="paragraph"
                fontWeight="500"
                w="100%"
                borderRadius={0}
                justifyContent="flex-start"
                onClick={() => {
                  setStudy("completedStudies");
                  setLeftPanelToggle(true);
                }}
                onMouseEnter={() => setHoverCompletedStudy(true)}
                onMouseLeave={() => setHoverCompletedStudy(false)}
                fontFamily="inter"
              >
                <Icon as={FaRegCheckSquare} mr="0.5vw" />
                <Text fontSize="14px">Completed study</Text>
              </Button>
            </VStack>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem border="none">
          <AccordionButton _focus={{ outline: "none" }} outline="none">
            <HStack spacing="0.703125vw">
              <AccordionIcon />
              <Box flex="1" fontSize="14px" fontFamily="inter">
                Other
              </Box>
            </HStack>
          </AccordionButton>
          <AccordionPanel pb={4}>
            <VStack>
              <Button
                mt="-0rem !important"
                _focus={{ outline: "none" }}
                background={
                  study === "archiveStudies" ? "light.900" : "light.800"
                }
                fontSize="paragraph"
                fontWeight="500"
                w="210px"
                borderRadius={0}
                justifyContent="flex-start"
                onClick={() => {
                  setStudy("archiveStudies");
                  setLeftPanelToggle(true);
                }}
                onMouseEnter={() => setHoverArchiveStudies(true)}
                onMouseLeave={() => setHoverArchiveStudies(false)}
                fontFamily="inter"
              >
                <Icon as={BiBox} mr="0.5vw" />
                <Text isTruncated fontSize="14px">
                  Archive Cases
                </Text>
              </Button>
            </VStack>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Box>
  );
};

export default Navbar;

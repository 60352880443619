import React from "react";
import { Flex, Text, Image } from "@chakra-ui/react";
import notReportAvailable from "../../../../../static/Images/notReportAvailable.svg";
function NotReportAvailableScreen() {
  return (
    <Flex bgColor="rgb(244,244,244)" h="100%" w="100%">
      <Flex
        h="74vh"
        mx="14vw"
        bgColor="white"
        w="100%"
        mt="10px"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        <Image src={notReportAvailable} w="120px" h="120px" />
        <Text fontSize="14px" fontWeight="bold">
          Not Allowed.
        </Text>
      </Flex>
    </Flex>
  );
}

export default NotReportAvailableScreen;

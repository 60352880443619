import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Text,
  VStack,
  HStack,
  Icon,
  useMediaQuery,
  Image,
  Input,
  Checkbox,
  Button,
  Tag,
  TagLabel,
  TagCloseButton,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Select,
  chakra,
  Flex,
} from "@chakra-ui/react";
import Toastify from "toastify-js";
import "toastify-js/src/toastify.css";
import { FaLock } from "react-icons/fa";
import clickEditButton from "../../../../../static/Images/clickEditButton.svg";
import editDetailButton from "../../../../../static/Images/editDetailButton.svg";
import {
  useUpdateStudyDetailsMutation,
  useGetAllMembersbasedonRolesQuery,
  useGetAllReaderInfoQuery,
  useDeleteReaderMutation,
  useLazyGetAllReaderInfoQuery,
  useAddReadersToStudyMutation,
  useLazyGetStudyInfoForPIQuery,
} from "../../../../../state/api/medicalApi";

const StudyDetailsModal = ({
  isOpen,
  onClose,
  studyDetails,
  studyId,
  userInfo,
  permission,
}) => {
  const [ifSmallScreen] = useMediaQuery("(max-width:1920px)");
  const [isEditDetails, setIsEditDetails] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [studyData, setStudyData] = useState();
  const [updateStudyDetails] = useUpdateStudyDetailsMutation();
  const [deleteReader] = useDeleteReaderMutation();
  const [readerDetail] = useLazyGetAllReaderInfoQuery();
  const [allPiInforamtion] = useLazyGetStudyInfoForPIQuery();
  const [addReader] = useAddReadersToStudyMutation();

  const { data: readerInfo } = useGetAllReaderInfoQuery({ skip: !studyId });
  const { data: membersInfo } = useGetAllMembersbasedonRolesQuery({
    organizationId: userInfo?.organizationId,
    roleName: "Pathologist",
  });
  //reader update
  const [organizationUsers, setOrganizationUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const selectedData = readerInfo?.data;
  const options = membersInfo?.data?.desiredRoleInfo?.map((option) => {
    return {
      first_name: `${option.firstName}`,
      last_name: `${option.lastName}`,
      user_id: option.userId,
    };
  });

  useEffect(() => {
    setOrganizationUsers(options);
  }, [isOpen]);
  useEffect(() => {
    setSelectedUsers(selectedData);
  }, [isOpen]);

  const [finalArray, setFinalArray] = useState([]);
  const [finalDeleteArray, setFinalDeleteArray] = useState([]);
  const [addArray, setAddArray] = useState([]);
  const [deleteArray, setDeleteArray] = useState([]);
  useEffect(() => {
    updateOrganizationData();
  }, [selectedUsers, isEditDetails]);

  const updateOrganizationData = () => {
    const updatedOrganizationUsers = organizationUsers.filter(
      (orgUser) =>
        !selectedUsers.some(
          (selectedUser) => selectedUser.user_id === orgUser.user_id
        )
    );
    setOrganizationUsers(updatedOrganizationUsers);
  };

  const handleSelectChange = (selectedOption) => {
    const selectedUser = organizationUsers.find(
      (user) => user.user_id === selectedOption
    );
    if (selectedUser) {
      setOrganizationUsers((prevOrgUsers) =>
        prevOrgUsers.filter(
          (orgUser) => orgUser.user_id !== selectedUser.user_id
        )
      );
      setSelectedUsers((prevSelectedUsers) => [
        ...prevSelectedUsers,
        selectedUser,
      ]);
      setAddArray((prevAddArray) => [...prevAddArray, selectedUser.user_id]);
      if (organizationUsers.length === 1) {
        setOrganizationUsers([]);
      }

      setDeleteArray(
        deleteArray.filter(
          (deleteUser) => deleteUser.user_id !== selectedUser.user_id
        )
      );
    }
  };

  const handleTagClose = (tag) => {
    if (!tag) {
      return;
    }
    setSelectedUsers((prevSelectedUsers) =>
      prevSelectedUsers.filter((user) => user.user_id !== tag.user_id)
    );
    const isUserNotInOrganization = !organizationUsers.some(
      (user) => user.user_id === tag.user_id
    );
    if (isUserNotInOrganization) {
      setOrganizationUsers((prevOrgUsers) => [...prevOrgUsers, tag]);
    }
    setAddArray(addArray.filter((addUser) => addUser !== tag.user_id));
  };

  //reader end

  const headerStyle = {
    fontSize: ifSmallScreen ? "14px" : "16px",
    opacity: "0.6",
    fontWeight: "600",
  };
  //input data handler
  const handleDetails = (input) => (e) => {
    const { value } = e.target;
    const checkedStatus = e.target.checked;
    setStudyData((prevState) => ({
      ...prevState,
      [input]: value || checkedStatus,
    }));
  };
  const updateDetails = async () => {
    if (studyData) {
      const data = await updateStudyDetails({ changes: studyData, studyId });
      if (data?.data?.success) {
        Toastify({
          text: "Study Details Updated Successfully",
          duration: 1000,
          gravity: "bottom",
          position: "center",
          style: {
            background: "green",
          },
          onClick: function () {},
        }).showToast();
      }
    }
    if (addArray) {
      setFinalArray(async (prevData) => {
        const updatedData = addArray.filter(
          (item) => !selectedData.map((item) => item.user_id).includes(item)
        );
        const data = await addReader({
          caseId: studyId,
          userIds: updatedData,
        });
        if (data?.data?.success) {
          Toastify({
            text: "Reader Add Successfully",
            duration: 1000,
            gravity: "bottom",
            position: "center",
            style: {
              background: "green",
            },
            onClick: function () {},
          }).showToast();
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }
        return updatedData;
      });
    }
    if (deleteArray) {
      setFinalDeleteArray(async (prevData) => {
        const updatedData = deleteArray.filter((item) =>
          selectedData.map((item) => item.user_id).includes(item.user_id)
        );
        const data = await deleteReader({
          studyId,
          userIds: updatedData.map((data) => data.user_id),
        });
        if (data?.data?.success) {
          Toastify({
            text: "Reader Delete Successfully",
            duration: 1000,
            gravity: "bottom",
            position: "center",
            style: {
              background: "green",
            },
            onClick: function () {},
          }).showToast();
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }
        return updatedData;
      });
    }

    onClose();
    setIsEditDetails(false);
    allPiInforamtion({
      caseId: studyId,
    });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader
          fontSize={ifSmallScreen ? "16px" : "18.5px"}
          font-weight="500"
          pl={ifSmallScreen ? "20px" : "27px"}
          pb={ifSmallScreen ? "10px" : "15px"}
          pt={ifSmallScreen ? "10px" : "19px"}
          bg="#F0F2FF"
          borderStyle="solid"
          borderWidth="0px 0px 2px 0px"
          borderColor="#DEDEDE"
        >
          Study Details
        </ModalHeader>
        <ModalCloseButton
          pb={ifSmallScreen ? "20px" : "15px"}
          pt={ifSmallScreen ? "10px" : "21px"}
          mr={ifSmallScreen ? "10px" : "10.5"}
          _hover={{ opacity: 1 }}
        />
        <ModalBody>
          <HStack justifyContent="flex-end">
            {permission?.data?.[0]?.permissions.includes("studyEdit") &&
              (isEditDetails ? (
                <Image
                  src={clickEditButton}
                  w="28px"
                  h="28px"
                  onClick={() => {
                    setIsEditDetails(false);
                  }}
                />
              ) : (
                <Image
                  src={editDetailButton}
                  w="30px"
                  h="30px"
                  onClick={() => {
                    setIsEditDetails(true);
                  }}
                />
              ))}
          </HStack>
          <VStack spacing={4} align="start">
            <VStack alignItems="left" spacing={0} w="100%">
              <Text style={headerStyle}>Title</Text>
              {isEditDetails ? (
                <Input
                  w="100%"
                  variant="flushed"
                  fontWeight="600"
                  h="20px"
                  fontSize="14px"
                  defaultValue={studyDetails?.caseInfo?.name}
                  // name="Title"
                  onChange={handleDetails("study_title")}
                  // style={{ textTransform: "capitalize" }}
                />
              ) : (
                <Text fontSize="14px" fontWeight="600">
                  {studyDetails?.caseInfo?.name}
                </Text>
              )}
            </VStack>
            <VStack alignItems="left" spacing={0} w="100%">
              <Text style={headerStyle}>Aim</Text>
              {isEditDetails ? (
                <Input
                  w="100%"
                  variant="flushed"
                  fontWeight="600"
                  h="20px"
                  fontSize="14px"
                  defaultValue={studyDetails?.caseInfo?.Aim}
                  // name="Title"
                  onChange={handleDetails("aim")}
                  // style={{ textTransform: "capitalize" }}
                />
              ) : (
                <Text fontSize="14px" fontWeight="600">
                  {studyDetails?.caseInfo?.Aim}
                </Text>
              )}
            </VStack>
            <VStack alignItems="left" spacing={0} w="100%">
              <Text style={headerStyle}>Objective</Text>
              {isEditDetails ? (
                <Input
                  w="100%"
                  variant="flushed"
                  fontWeight="600"
                  h="20px"
                  fontSize="14px"
                  defaultValue={studyDetails?.caseInfo?.Objectives}
                  // name="Title"
                  onChange={handleDetails("objectives")}
                  // style={{ textTransform: "capitalize" }}
                />
              ) : (
                <Text fontSize="14px" fontWeight="600">
                  {studyDetails?.caseInfo?.Objectives}
                </Text>
              )}
            </VStack>

            <VStack alignItems="left" spacing={0} w="100%">
              <Text style={headerStyle}>Details</Text>
              {/* {isEditDetails ? (
                <Checkbox
                  onChange={handleDetails("is_study_detail_confidential")}
                  pt="5px"
                  defaultChecked={
                    studyDetails?.caseInfo?.isStudyDetailsConfidential
                  }
                >
                  <Text ml={2} fontSize="14px">
                    Mark if Confidential
                  </Text>
                </Checkbox>
              ) : (
                <Text fontSize="14px" fontWeight="600">
                  {studyDetails?.caseInfo?.studyDetails
                    ? studyDetails?.caseInfo?.studyDetails
                    : "-"}
                </Text>
              )} */}
              {isEditDetails ? (
                <Input
                  w="100%"
                  variant="flushed"
                  fontWeight="600"
                  h="20px"
                  fontSize="14px"
                  defaultValue={studyDetails?.caseInfo?.Studydetails}
                  // name="Title"
                  onChange={handleDetails("study_details")}
                  // style={{ textTransform: "capitalize" }}
                />
              ) : (
                <Text fontSize="14px" fontWeight="600">
                  {studyDetails?.caseInfo?.Studydetails}
                </Text>
              )}
            </VStack>
            {/* <VStack alignItems="left" spacing={0} w="60%">
              <Text style={headerStyle}>Duration</Text>
              <Text fontSize="14px" fontWeight="600">
                {duration}
              </Text>
            </VStack> */}

            {/* <VStack w="100%" alignItems="left" spacing={0}>
              <Text style={headerStyle}>Add Readers</Text>
              {!isEditDetails ? (
                <Flex
                  width="100%"
                  variant="flushed"
                  fontWeight="600"
                  fontSize="14px"
                >
                  <HStack spacing={2}>
                    {selectedUsers?.length > 0
                      ? selectedUsers?.map((user) => (
                          <Tag
                            key={user?.user_id}
                            size="md"
                            variant="solid"
                            fontWeight="600"
                            fontSize="14px"
                            color="white"
                            bg="rgb(45,120,188)"
                          >
                            <TagLabel>{`${user?.first_name} ${user?.last_name}`}</TagLabel>
                          </Tag>
                        ))
                      : "No User Found"}
                  </HStack>
                  {selectedUsers?.length == 0 && "No User Found"}
                </Flex>
              ) : (
                <Popover w="100%" placement="bottom-start">
                  <PopoverTrigger>
                    <Flex
                      width="100%"
                      variant="flushed"
                      fontWeight="600"
                      fontSize="14px"
                      borderBottom="1px solid #DEDEDE"
                    >
                      <HStack spacing={2}>
                        {selectedUsers?.length > 0
                          ? selectedUsers?.map((user) => (
                              <Tag
                                key={user.user_id}
                                size="md"
                                variant="solid"
                                fontWeight="600"
                                fontSize="14px"
                                color="white"
                                bg="rgb(45,120,188)"
                              >
                                <TagLabel>{`${user?.first_name} ${user?.last_name}`}</TagLabel>
                                <TagCloseButton
                                  onClick={() => {
                                    handleTagClose(user);
                                    setDeleteArray((prevDeleteArray) => [
                                      ...prevDeleteArray,
                                      user,
                                    ]);
                                  }}
                                />
                              </Tag>
                            ))
                          : "No User Found"}
                      </HStack>
                      {selectedUsers?.length == 0 && "No User Found"}
                    </Flex>
                  </PopoverTrigger>
                  <PopoverContent>
                    <PopoverBody>
                      {organizationUsers?.length > 0 ? (
                        <Select
                          onChange={(e) => handleSelectChange(e.target.value)}
                        >
                          <option selected disable hidden>
                            Select Option
                          </option>
                          {organizationUsers?.map((user) => (
                            <option key={user.user_id} value={user.user_id}>
                              {user.user_id !== ""
                                ? `${user.first_name} ${user.last_name}`
                                : ""}
                            </option>
                          ))}
                        </Select>
                      ) : (
                        <Text>No user found</Text>
                      )}
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
              )}
            </VStack> */}
          </VStack>
        </ModalBody>
        <ModalFooter>
          {isEditDetails && (
            <HStack spacing="1vw" justifyContent="flex-end" mb="1vh">
              <Button
                alignItems="center"
                bgColor="#fff"
                border="1px solid rgba(207, 226, 241, 1)"
                _focus={{ outline: "none" }}
                borderRadius="5px"
                fontWeight="400"
                fontSize="14px"
                px="30px"
                onClick={() => {
                  setOrganizationUsers([]);
                  setIsEditDetails(false);
                  onClose();
                }}
              >
                Close
              </Button>
              <Button
                alignItems="center"
                bgColor="rgba(196, 218, 236, 1)"
                _focus={{ outline: "none" }}
                borderRadius="5px"
                fontWeight="400"
                fontSize="14px"
                color="rgba(33, 34, 36, 1)"
                px="30px"
                onClick={() => {
                  updateDetails();
                }}
              >
                Save
              </Button>
            </HStack>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default StudyDetailsModal;

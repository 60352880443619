import { Button, HStack, Input, Select, Text, VStack } from "@chakra-ui/react";
import React from "react";

function QuestionTemplate({
  setQuestionState,
  questionState,
  questionText,
  setQuestionText,
  choices,
  setChoices,
}) {
  const handleFormChange = (index, event) => {
    let data = [...choices];
    data[index] = event.target.value;
    setChoices(data);
  };
  const addOption = () => {
    setChoices([...choices, ""]);
  };
  return (
    <VStack
      alignItems="flex-start"
      p="0.8333vw"
      boxShadow="2px 2px 4px 0px rgba(176, 200, 214, 0.25), -2px -2px 4px 0px rgba(176, 200, 214, 0.25)"
      bg="light.100"
    >
      <HStack mt="1.418vh" justifyContent="space-between">
        <Input
          name="question"
          value={questionText}
          fontFamily="inter"
          required
          onChange={(event) => setQuestionText(event.target.value)}
          placeholder="Question"
          bgColor="#F8F8F5"
          width="38.1386vw"
          size="md"
          borderRadius="0px"
          border="none"
          h="4.074vh"
          minH="35px"
          borderBottom="1px solid #3B5D7C"
          _focus={{ outline: "none" }}
        />
        <HStack justifyContent="space-between">
          <Select
            id="select"
            fontFamily="inter"
            bgColor="#fff"
            focusBorderColor="none"
            borderColor="#3B5D7C"
            size="sm"
            width="150px"
            value={questionState}
            onChange={(e) => setQuestionState(e.target.value)}
          >
            <option
              value="Multiple Choice"
              style={{ backgroundColor: "#ECECEC", height: "30px" }}
            >
              Multiple Choice
            </option>
            <option
              value="Checkbox"
              style={{ backgroundColor: "#ECECEC", height: "30px" }}
            >
              Checkbox
            </option>
          </Select>
        </HStack>
      </HStack>
      {choices.map((choice, index) => {
        return (
          <div key={index}>
            <VStack
              alignItems="flex-start"
              justifyContent="center"
              height="3.88vh"
              minH="42px"
              mt="0.5vh"
            >
              <HStack h="100%" key={index} alignItems="center">
                <Text>{`${index + 1}. `}</Text>
                <Input
                  name="choiceText"
                  placeholder="option"
                  value={choice.choiceText}
                  onInput={(event) => handleFormChange(index, event)}
                  borderRadius="0px"
                  border="none"
                  outline="none"
                  _focus={{ border: "none" }}
                  fontFamily="inter"
                  required
                  w="39vw"
                  ml="-0.5rem !important"
                />
              </HStack>
            </VStack>
          </div>
        );
      })}
      <HStack mt="0.740vh" ml="0" pb="0.740vh">
        <Button
          onClick={() => addOption()}
          mr="3vw"
          borderRadius="0"
          color="light.700"
          bg="none"
          fontWeight="400"
          h="2.96vh"
          minH="30px"
          fontFamily="inter"
          _focus={{ bg: "none" }}
          _hover={{ bg: "none" }}
        >
          Add option
        </Button>
      </HStack>
    </VStack>
  );
}

export default QuestionTemplate;

let _Environments = {
  production: {
    env: "production",
    USER_URL: "https://production-api.medai.prr.ai",
    VIEWER_URL: "https://production-api.viewer.prr.ai",
    LOGS: false,
    AUTH0_DOMAIN: "softsensor.us.auth0.com",
    AUTH0_CLIENT_ID: "7wna8JPPgSu08QBt8FzxOiGwCHNXTN7u",
    AUTH0_REDIRECT_URI: "redirect",
    AUTH0_AUDIENCE: "https://softsensor.us.auth0.com/api/v2/",
    AUTH0_CONNECTION: "Username-Password-Authentication",
    AUTH0_TOKEN:
      "@@auth0spajs@@::7wna8JPPgSu08QBt8FzxOiGwCHNXTN7u::https://softsensor.us.auth0.com/api/v2/::openid profile email offline_access",
  },
  staging: {
    env: "staging",
    USER_URL: "https://staging-api.medai.prr.ai",
    VIEWER_URL: "https://staging-api.viewer.prr.ai",
    LOGS: true,
    AUTH0_DOMAIN: "softsensor-staging.us.auth0.com",
    AUTH0_CLIENT_ID: "H1agnhWnVYcmZELVOuA4WfuQ8oOhUidk",
    AUTH0_REDIRECT_URI: "redirect",
    AUTH0_AUDIENCE: "https://softsensor-staging.us.auth0.com/api/v2/",
    AUTH0_CONNECTION: "Username-Password-Authentication",
    AUTH0_TOKEN:
      "@@auth0spajs@@::H1agnhWnVYcmZELVOuA4WfuQ8oOhUidk::https://softsensor-staging.us.auth0.com/api/v2/::openid profile email offline_access",
  },
  development: {
    env: "development",
    USER_URL: "https://development-api.medai.prr.ai",
    VIEWER_URL: "https://development-api.viewer.prr.ai",
    LOGS: true,
    AUTH0_DOMAIN: "softsensor-development.us.auth0.com",
    AUTH0_CLIENT_ID: "ryjED0MCy3NwyWZ1NSafPVqcCABG6Vl5",
    AUTH0_REDIRECT_URI: "redirect",
    AUTH0_AUDIENCE: "https://softsensor-development.us.auth0.com/api/v2/",
    AUTH0_CONNECTION: "Username-Password-Authentication",
    VIEWER_SOCKET_URL: "wss://development-api.viewer.prr.ai",
    AUTH0_TOKEN:
      "@@auth0spajs@@::ryjED0MCy3NwyWZ1NSafPVqcCABG6Vl5::https://softsensor-development.us.auth0.com/api/v2/::openid profile email offline_access",
  },
  local: {
    env: "local",
    USER_URL:
      process.env.REACT_APP_URL || "https://development-api.medai.prr.ai",
    VIEWER_URL: "https://development-api.viewer.prr.ai",
    LOGS: true,
    AUTH0_DOMAIN: "softsensor-development.us.auth0.com",
    AUTH0_CLIENT_ID: "ryjED0MCy3NwyWZ1NSafPVqcCABG6Vl5",
    AUTH0_REDIRECT_URI: "redirect",
    AUTH0_AUDIENCE: "https://softsensor-development.us.auth0.com/api/v2/",
    AUTH0_CONNECTION: "Username-Password-Authentication",
    VIEWER_SOCKET_URL: "wss://development-api.viewer.prr.ai",
    AUTH0_TOKEN:
      "@@auth0spajs@@::ryjED0MCy3NwyWZ1NSafPVqcCABG6Vl5::https://softsensor-development.us.auth0.com/api/v2/::openid profile email offline_access",
  },
};

function getEnvironment() {
  var env = "development";
  if (window.location.href.includes("localhost")) {
    env = "local";
  } else if (window.location.href.includes("development")) {
    env = "development";
  } else if (window.location.href.includes("staging")) {
    env = "staging";
  } else {
    env = "production";
  }
  return _Environments[env];
}

var Environment = getEnvironment();
module.exports = Environment;

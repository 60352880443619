import {
  Flex,
  Text,
  useMediaQuery,
  Image,
  HStack,
  VStack,
  Link,
  IconButton,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { BiUpload } from "react-icons/bi";
import { Link as RouteLink } from "react-router-dom";
import { getSlideUrl } from "../../hooks/utility";
import { IoIosInformationCircleOutline } from "react-icons/io";
import Loading from "../Loading/loading";
import SlideUpload from "./SlideUpload";

function SlidesDetail({ caseId, userInfo, studyName, slides, loading }) {
  const [ifWidthLessthan1920] = useMediaQuery("(max-width:1920px)");
  const [isOpenUpload, setOpenUpload] = useState(false);

  return loading ? (
    <Loading />
  ) : (
    <Flex
      flexDirection="column"
      h="100%"
      flex="1"
      fontSize={ifWidthLessthan1920 ? "14px" : "0.729vw"}
      maxH="80vh"
      overflow="auto"
      css={{
        "&::-webkit-scrollbar": {
          width: "4px",
        },
        "&::-webkit-scrollbar-track": {
          width: "6px",
        },
        "&::-webkit-scrollbar-thumb": {
          background: "#C4C4C4",
        },
      }}
    >
      {userInfo?.role === "PI" && (
        <Flex justifyContent="flex-end" m="3vh 0vw 0vw 0vw" pr="3vw">
          {slides?.length > 0 && (
            <Button
              w="3vw"
              borderRadius="0px"
              fontWeight="600"
              fontFamily="inter"
              fontSize="14px"
              border="1px solid #3B5D7C"
              py="1vh"
              pl="1.7625vw"
              color="#3B5D7C"
              _focus={{ outline: "none" }}
              leftIcon={<BiUpload color="#3B5D7C" />}
              onClick={() => setOpenUpload(true)}
            ></Button>
          )}
        </Flex>
      )}
      {!slides?.length > 0 ? (
        <Flex
          h="100%"
          mt="1vh"
          flexDirection="column"
          p="1.5vw 1vh"
          alignItems="center"
          justifyContent="center"
        >
          <Text py="1vh" px="1.5625vw">
            There are no slides available.
          </Text>
          {userInfo?.role === "PI" && (
            <Button
              w="13vw"
              borderRadius="0px"
              fontWeight="600"
              fontFamily="inter"
              fontSize="14px"
              border="1px solid #3B5D7C"
              py="1vh"
              px="1.5625vw"
              color="#3B5D7C"
              _focus={{ outline: "none" }}
              leftIcon={<BiUpload color="#3B5D7C" />}
              onClick={() => setOpenUpload(true)}
            >
              Upload Slides
            </Button>
          )}
        </Flex>
      ) : (
        <Flex
          flexWrap="wrap"
          alignItems="flex-start"
          justifyContent="flex-start"
          flex="1"
          h="100%"
          mt="1.11vh"
          ml="2.5vw"
        >
          {slides?.map((slide, index) => {
            return slide?.awsImageBucketUrl !== "" ? (
              <Link
                key={index}
                as={RouteLink}
                to={`/viewer`}
                state={{ caseId, slide, userInfo }}
                style={{ textDecoration: "none" }}
                _hover={{ bgColor: "light.900" }}
                _focus={{ outline: "none" }}
                bgColor="light.100"
                w="12.9vw"
                m="1.1vh 1.1vw 1.1vw 0vw"
                pb="0.833vw"
              >
                <VStack pt="1.1vh" key={index}>
                  <Image
                    src={getSlideUrl(slide?.awsImageBucketUrl)}
                    w="12vw"
                    h="20.571vh"
                    alt={slide.accessionId}
                    pt="1vh"
                  />
                  <HStack w="100%" justifyContent="space-between" px="1.1vw">
                    <Text isTruncated>
                      {slide?.slideName || `${studyName}-${index + 1}`}
                    </Text>
                    {/* <IconButton
                      bgColor="#fff"
                      _focus={{ outline: "none" }}
                      w="2vw"
                      h="3vh"
                      as={IoIosInformationCircleOutline}
                    /> */}
                  </HStack>
                </VStack>
              </Link>
            ) : (
              ""
            );
          })}
        </Flex>
      )}
      <Modal
        isOpen={isOpenUpload}
        onClose={() => setOpenUpload(false)}
        size="6xl"
        isCentered
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent background="light.200" w="fit-content">
          <ModalCloseButton />
          <ModalBody>
            <SlideUpload
              caseId={caseId}
              userInfo={userInfo}
              setOpenUpload={setOpenUpload}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Flex>
  );
}

export default SlidesDetail;

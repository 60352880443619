import React, { useEffect, useRef, useState } from "react";
import {
  Text,
  Box,
  Button,
  HStack,
  Input,
  VStack,
  useToast,
  Flex,
  Progress,
  RadioGroup,
  Radio,
} from "@chakra-ui/react";
import { MdFileUpload } from "react-icons/md";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import Environment from "../../../../../environment";

const SlideUpload = ({ setOpenUpload, studyId, userInfo }) => {
  const toast = useToast();
  const { user } = useAuth0();
  const [slideType, setSlideType] = useState("HAndE");
  const [selectedFile, setSelectedFile] = useState(null);
  const [accessionId, setAccessionId] = useState("");
  const [description, setDescription] = useState("");
  const [isUploading, setIsUploading] = useState(false);

  const [progress, setProgress] = useState(0);
  const uploadInputRef = useRef(null);

  const [abortController, setAbortController] = useState(null);

  useEffect(() => {
    const generateRandomNumber = () => Math.floor(Math.random() * 1000);
    const getCurrentDate = () => {
      const now = new Date();
      const year = now.getFullYear().toString().slice(-2);
      const month = (now.getMonth() + 1).toString().padStart(2, "0");
      const day = now.getDate().toString().padStart(2, "0");
      return `${day}${month}${year}`;
    };
    const randomL = generateRandomNumber();
    const currentDate = getCurrentDate();
    const finalPattern = `CT/${currentDate}/L${randomL}/${
      slideType === "HAndE" ? "HE" : "TRI"
    }`;
    setAccessionId(finalPattern);
  }, [slideType]);

  const handleDragFile = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    setSelectedFile(file);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "copy";
  };

  const handleDragEnter = (event) => {
    event.preventDefault();
  };

  const cancelFileSelection = () => {
    if (abortController) abortController.abort();
    setSelectedFile(null);
    setIsUploading(false);
    setProgress(0);
    toast({
      title: "Uploading cancelled",
      status: "info",
      duration: 1500,
      isClosable: true,
    });
    setOpenUpload(false);
  };

  const handleFileInputClick = () => {
    if (!uploadInputRef.current) return;
    uploadInputRef.current.click();
  };

  const handleInputChange = (event) => {
    event.preventDefault();
    if (event.target.files && event.target.files[0]) {
      setSelectedFile(event.target.files[0]);
    }
  };

  // useEffect(() => {
  //   if (selectedFile) {
  //     if (accessionId.length === 0) setAccessionId(selectedFile?.name);
  //   }
  // }, [selectedFile]);

  const uploadFiles = async () => {
    const form = new FormData();
    const controller = new AbortController();
    setIsUploading(true);
    setAbortController(controller);
    try {
      form.append("subClaim", user?.sub);
      form.append("slideName", `${accessionId}_${slideType}`);
      form.append("caseId", studyId);
      form.append("uploadedBy", userInfo?.data[0].userId);
      form.append("slideType", slideType);
      form.append("accessionId", accessionId);
      form.append("organ", "Liver");
      form.append("description", description);
      form.append("slides", selectedFile);

      const { body } = JSON.parse(
        localStorage.getItem(Environment.AUTH0_TOKEN)
      );
      const { access_token: accessToken } = body;
      const config = {
        signal: controller.signal,
        onUploadProgress: (curProgress) => {
          const percentCompleted = Math.round(
            (curProgress.loaded * 100) / curProgress.total
          );

          setProgress(percentCompleted);
        },
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      };

      const resp = await axios.post(
        `${Environment.USER_URL}/cases/v1/slides`,
        form,
        config
      );
      if (resp.status === 200) {
        toast({
          title: "Slide successfully uploaded",
          description: "We will notify you once it's processed",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (err) {
      if (err.code !== "ERR_CANCELED")
        toast({
          title: "Slide Uploading Failed",
          description: "Something went wrong, try uploading again!",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
    }
    setIsUploading(false);
    setProgress(0);
    setSelectedFile(null);
    setAbortController(null);
    setAccessionId("");
    setOpenUpload(false);
  };

  return (
    <Flex
      direction="column"
      p="1vw 2vw"
      w="100%"
      h={isUploading ? "25vh" : "70vh"}
      position="relative"
    >
      <Text fontWeight="600" fontSize="14px" mb="1vh">
        {isUploading ? "Uploading" : "Slide Details"}
      </Text>
      {isUploading && (
        <Text fontFamily="inter" fontSize="14px" fontWeight="600">
          {`${progress}% uploaded`}
        </Text>
      )}
      {!isUploading ? (
        <>
          <HStack
            justifyContent="space-between"
            width="50%"
            fontSize="14px"
            fontWeight="bold"
            mb="1vh"
          >
            <Text>Organ:</Text>
            <Text>Liver</Text>
          </HStack>
          <VStack alignItems="flex-start" mb="2vh">
            <Input
              w="100%"
              borderRadius={0}
              _focus={{ outline: "none" }}
              placeholder="Accession ID"
              onChange={(e) => setAccessionId(e.target.value)}
              value={accessionId}
              fontSize="14px"
            />
            <Input
              w="100%"
              borderRadius={0}
              _focus={{ outline: "none" }}
              placeholder="Description"
              onChange={(e) => setDescription(e.target.value)}
              value={description}
              fontSize="14px"
            />
            <HStack>
              <RadioGroup defaultValue="HAndE" onChange={setSlideType}>
                <HStack spacing={5} direction="row">
                  <Radio colorScheme="green" value="HAndE" size="md">
                    <Text fontSize="14px">H&E</Text>
                  </Radio>
                  <Radio colorScheme="green" value="Trichrome" size="md">
                    <Text fontSize="14px">Trichrome</Text>
                  </Radio>
                </HStack>
              </RadioGroup>
            </HStack>
          </VStack>
          <Box
            w="100%"
            h="100%"
            alignSelf="center"
            border="1.5px dashed rgba(27, 117, 188, 1)"
            background="rgba(252, 252, 252, 1)"
            borderRadius="5px"
            onClick={handleFileInputClick}
            onDragEnter={(e) => handleDragEnter(e)}
            onDragOver={(e) => handleDragOver(e)}
            onDrop={(e) => handleDragFile(e)}
            mb="1vh"
            position="relative"
          >
            <Input
              type="file"
              ref={uploadInputRef}
              style={{ display: "none" }}
              onChange={handleInputChange}
              accept=".tiff,.svs,.tif"
            />
            <Flex
              w="100%"
              h="100%"
              margin="auto"
              alignItems="center"
              justifyContent="center"
              direction="column"
              position="absolute"
            >
              <MdFileUpload
                style={{
                  width: "100%",
                  height: "50%",
                  color: "rgba(27, 117, 188, 0.49)",
                }}
              />
              <Text fontSize="1vw" opacity="0.7" textAlign="center">
                {selectedFile ? (
                  `${selectedFile.name} - ${selectedFile.size / 1000000} MB`
                ) : (
                  <Text fontSize="13px" fontWeight="bold">
                    Drop your slide here, or{" "}
                    <span
                      style={{
                        color: "rgba(27, 117, 188, 1)",
                      }}
                    >
                      browse
                    </span>
                  </Text>
                )}
              </Text>
            </Flex>
          </Box>
        </>
      ) : (
        <Progress value={progress} min={0} max={100} />
      )}

      <HStack marginTop="2vh" spacing="1vw" justifyContent="flex-end">
        <Button
          alignItems="center"
          bgColor="#fff"
          border="1px solid rgba(207, 226, 241, 1)"
          _focus={{ outline: "none" }}
          borderRadius="5px"
          fontWeight="400"
          fontSize="14px"
          px="30px"
          onClick={cancelFileSelection}
          // disabled={!isUploading}
        >
          Cancel
        </Button>
        <Button
          alignItems="center"
          bgColor="rgba(196, 218, 236, 1)"
          _focus={{ outline: "none" }}
          borderRadius="5px"
          fontWeight="400"
          fontSize="14px"
          color="rgba(33, 34, 36, 1)"
          px="30px"
          disabled={selectedFile === null || isUploading}
          onClick={uploadFiles}
        >
          Upload
        </Button>
      </HStack>
    </Flex>
  );
};

export default SlideUpload;

import {
  Divider,
  Flex,
  Text,
  useMediaQuery,
  VStack,
  Spinner,
  styled,
} from "@chakra-ui/react";
import React from "react";
import {
  Chart as ChartJS,
  PointElement,
  LineElement,
  ChartConfiguration,
  LineController,
  LinearScale,
  Title,
  CategoryScale,
  Tooltip,
  Legend,
  TimeScale,
  ChartOptions,
  registerables as registerablesJS,
} from "chart.js";
import { Chart } from "react-chartjs-2";
import {
  useGetPhaseAnalysisQuery,
  useGetReportResponseQuery,
} from "../../state/api/medicalApi";
ChartJS.register(...registerablesJS);
ChartJS.register(
  PointElement,
  LineElement,
  LineController,
  PointElement,
  LinearScale,
  CategoryScale,
  PointElement,
  LineElement,
  TimeScale,
  Title,
  Tooltip,
  Legend
);

function ParticipantsCount({ studyId }) {
  const { data: phaseWiseData, isLoading } = useGetPhaseAnalysisQuery({
    caseId: studyId,
  });

  const {
    data: report,
    error,
    isFetching: isReportsFetching,
  } = useGetReportResponseQuery(
    {
      case_id: studyId,
    },
    { skip: !studyId }
  );

  const slidesCount = phaseWiseData?.data?.caseQuery?.[0].phases?.map(
    (phase) => {
      return phaseWiseData?.data?.caseSlidesCount?.[0]?.caseSlides;
    }
  );

  const reportedSlides = phaseWiseData?.data?.caseQuery?.[0].phases?.map(
    (phase) => {
      return report?.data?.caseReportParams?.length > 0
        ? phaseWiseData?.data?.caseSlidesCount?.[0]?.caseSlides
        : "0";
    }
  );

  const phases = phaseWiseData?.data?.caseQuery?.[0].phases;
  const participants = phaseWiseData?.data?.caseQuery?.[0].participants;

  const options = {
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          display: false,
        },
        title: {
          display: true,
          text: "(Phases)",
        },
      },

      y: {
        grid: {
          display: false,
        },
        title: {
          display: true,
          text: "(Patients)",
        },
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: "top",
        align: "end",
        labels: {
          font: {
            size: 10,
          },
        },
      },
    },
  };

  const graphData = {
    labels: phases,
    datasets: [
      {
        label: "Reported",
        data: reportedSlides,
        backgroundColor: "#3B5D7C",
        barThickness: 23,
      },
      {
        label: "Recruited",
        data: slidesCount,
        backgroundColor: "#D9D9D9",
        barThickness: 16,
      },
      {
        label: "To be recruited",
        data: participants,
        borderColor: "rgb(0, 0, 0)",
        type: "line",
        borderDash: [2, 2],
      },
    ],
  };

  const [ifWidthLessthan1920] = useMediaQuery("(max-width:1920px)");

  return isLoading ? (
    <Flex
      h="20vh"
      w="100%"
      minW="300px"
      minH="200px"
      alignItems="center"
      justifyContent="center"
      bg="light.100"
      mb="2.055vh"
      py="1vw"
      boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
    >
      <Spinner color="#3965C5" size="xl" thickness="4px" speed="0.65s" />
    </Flex>
  ) : (
    <Flex
      bg="light.100"
      maxH="30vh"
      w="100%"
      justifyContent="space-evenly"
      fontSize={ifWidthLessthan1920 ? "14px" : "0.729vw"}
      minH="230px"
      boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
      minW="260px"
      mb="2.055vh"
      py="1vw"
      direction="column"
    >
      <Text
        ml="1.2vw"
        fontSize={ifWidthLessthan1920 ? "12px" : "0.729vw"}
        fontWeight={600}
      >
        Patient recruited and Reported per phase
      </Text>
      <Chart type="bar" options={options} data={graphData} />
    </Flex>
  );
}

export default ParticipantsCount;

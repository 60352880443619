import React, { useState } from "react";
import { Flex, HStack, VStack, Box, useMediaQuery } from "@chakra-ui/react";
import SlideComponent from "./OverViewScreenSubComponent/SlideComponent";
import StudyTimelineComponent from "./OverViewScreenSubComponent/StudyTimelineComponent";
import ReaderSlideChartScreen from "./OverViewScreenSubComponent/ReaderSlideChartScreen";

const OverViewScreen = ({
  readerDetailsInfo,
  readerProgressInfo,
  readerTimelineInfo,
  readerSlideInfo,
  userInfo,
  setSlideType,
  setTabIndex,
  caseId,
}) => {
  const [ifWidthLargethanthan1800] = useMediaQuery("(min-width:1800px)");
  return (
    <Flex
      h="100%"
      w="100%"
      pt="10px"
      alignItems="center"
      bgColor="rgb(244,244,244)"
    >
      <HStack h="100%" w="100%" justifyContent="center">
        <VStack w="56%" h="100%">
          <Flex
            h="43vh"
            w="56vw"
            bgColor="rgba(255, 255, 255, 1)"
            borderRadius="5px"
          >
            <ReaderSlideChartScreen
              chartInfo={readerProgressInfo}
              readerDetailsInfo={readerDetailsInfo}
            />
          </Flex>
          <Box
            h="31vh"
            w="56vw"
            bgColor="rgba(255, 255, 255, 1)"
            borderRadius="5px"
          >
            <StudyTimelineComponent timeLineData={readerTimelineInfo} />
          </Box>
        </VStack>
        <VStack w="38%" h="100%">
          <Flex
            borderRadius="5px"
            h="75vh"
            w="37.5vw"
            bgColor="rgba(255, 255, 255, 1)"
          >
            <SlideComponent
              readerSlideInfo={readerSlideInfo}
              userInfo={userInfo}
              setSlideType={setSlideType}
              setTabIndex={setTabIndex}
              caseId={caseId}
            />
          </Flex>
        </VStack>
      </HStack>
    </Flex>
  );
};

export default OverViewScreen;

import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  Flex,
  useToast,
  useMediaQuery,
  HStack,
} from "@chakra-ui/react";
import axios from "axios";
import { useDeleteSlideMutation } from "../../../../../state/api/medicalApi";

const SlideDeleteConfirmationModal = ({ isOpen, onClose, slideId }) => {
  const [ifSmallScreen] = useMediaQuery("(max-width:1920px)");
  const toast = useToast();
  //   const handleDelete = async () => {
  //     const resp = await axios.post(`${Environment.USER_URL}/delete_case`, {
  //       caseId: selectedId,
  //       subClaim: user?.sub,
  //     });
  //     if (resp.status === 200) {
  //       toast({
  //         title: "Case Delete Successfully",
  //         status: "success",
  //         duration: 3000,
  //         isClosable: true,
  //       });
  //       window.location.reload();
  //     } else {
  //       toast({
  //         title: "Some error occured",
  //         description: "Please try again after some time",
  //         status: "error",
  //         duration: 3000,
  //         isClosable: true,
  //       });
  //     }
  //   };
  const [slideDelete] = useDeleteSlideMutation();
  const deleteSlide = () => {
    slideDelete({ slideId });
    onClose();
  };
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader
          fontSize={ifSmallScreen ? "16px" : "15.5px"}
          font-weight="500"
          pl={ifSmallScreen ? "20px" : "27px"}
          pb={ifSmallScreen ? "10px" : "15px"}
          pt={ifSmallScreen ? "10px" : "19px"}
          bg="#F0F2FF"
          borderStyle="solid"
          borderWidth="0px 0px 2px 0px"
          borderColor="#DEDEDE"
        >
          Confirmation
        </ModalHeader>
        <ModalCloseButton
          pb={ifSmallScreen ? "20px" : "15px"}
          pt={ifSmallScreen ? "10px" : "21px"}
          mr={ifSmallScreen ? "10px" : "10.5"}
          _hover={{ opacity: 1 }}
        />
        <ModalBody>
          <Text fontSize="14px">
            This slide will be deleted permanently. Are you sure you want to
            delete this slide?
          </Text>
        </ModalBody>
        <ModalFooter>
          <HStack spacing="1vw" justifyContent="flex-end" mb="1vh">
            <Button
              alignItems="center"
              bgColor="#fff"
              border="1px solid rgba(207, 226, 241, 1)"
              _focus={{ outline: "none" }}
              borderRadius="5px"
              fontWeight="400"
              fontSize="14px"
              px="30px"
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              alignItems="center"
              bgColor="rgba(196, 218, 236, 1)"
              _focus={{ outline: "none" }}
              borderRadius="5px"
              fontWeight="400"
              fontSize="14px"
              color="rgba(33, 34, 36, 1)"
              px="30px"
              onClick={() => deleteSlide()}
            >
              Delete
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default SlideDeleteConfirmationModal;

import React, { useRef, useState } from "react";
import {
  Text,
  Box,
  Button,
  HStack,
  Input,
  VStack,
  useToast,
  Flex,
  Progress,
} from "@chakra-ui/react";
import { MdFileUpload } from "react-icons/md";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import Environment from "../../environment";

const SlideUpload = ({ caseId, userInfo, setOpenUpload }) => {
  const toast = useToast();
  const { user } = useAuth0();

  const [selectedFile, setSelectedFile] = useState(null);
  const [slideName, setSlideName] = useState("");
  const [isUploading, setIsUploading] = useState(false);

  const [progress, setProgress] = useState(0);
  const uploadInputRef = useRef(null);

  const [abortController, setAbortController] = useState(null);

  const handleDragFile = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    setSelectedFile(file);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "copy";
  };

  const handleDragEnter = (event) => {
    event.preventDefault();
  };

  const cancelFileSelection = () => {
    if (abortController) abortController.abort();
    setSelectedFile(null);
    setIsUploading(false);
    setProgress(0);
    toast({
      title: "Uploading cancelled",
      status: "info",
      duration: 1500,
      isClosable: true,
    });
  };

  const handleFileInputClick = () => {
    if (!uploadInputRef.current) return;
    uploadInputRef.current.click();
  };

  const handleInputChange = (event) => {
    event.preventDefault();
    if (event.target.files && event.target.files[0]) {
      setSelectedFile(event.target.files[0]);
    }
  };

  // useEffect(() => {
  //   if (selectedFile) {
  //     if (slideName.length === 0) setSlideName(selectedFile?.name);
  //   }
  // }, [selectedFile]);

  const uploadFiles = async () => {
    const form = new FormData();
    const controller = new AbortController();
    setIsUploading(true);
    setAbortController(controller);
    try {
      form.append("subClaim", user.sub);
      form.append("caseId", caseId);
      form.append("uploadedBy", userInfo?.userId);
      form.append("slideName", slideName);
      form.append("slides", selectedFile);
      const { body } = JSON.parse(
        localStorage.getItem(Environment.AUTH0_TOKEN)
      );
      const { access_token: accessToken } = body;
      const config = {
        signal: controller.signal,
        onUploadProgress: (curProgress) => {
          const percentCompleted = Math.round(
            (curProgress.loaded * 100) / curProgress.total
          );

          setProgress(percentCompleted);
        },
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      };

      const resp = await axios.post(
        `${Environment.USER_URL}/cases/v1/slides`,
        form,
        config
      );

      if (resp.status === 200) {
        toast({
          title: "Slide successfully uploaded",
          description: "We will notify you once it's processed",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (err) {
      if (err.code !== "ERR_CANCELED")
        toast({
          title: "Slide Uploading Failed",
          description: "Something went wrong, try uploading again!",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
    }
    setIsUploading(false);
    setProgress(0);
    setSelectedFile(null);
    setAbortController(null);
    setSlideName("");
    setOpenUpload(false);
  };

  return (
    <Flex
      direction="column"
      p="1vw 2vw"
      w="100%"
      h={isUploading ? "25vh" : "60vh"}
    >
      <Text fontWeight="600" fontSize="16px" mb="2vh">
        {isUploading ? "Uploading" : "Upload a slide"}
      </Text>
      {isUploading && (
        <Text fontFamily="inter" fontSize="14px" fontWeight="600">
          {`${progress}% uploaded`}
        </Text>
      )}
      {!isUploading ? (
        <>
          <VStack alignItems="flex-start" mb="2vh">
            <Text>Slide title*</Text>
            <Input
              w="100%"
              borderRadius={0}
              _focus={{ outline: "none" }}
              placeholder="Enter slide title"
              onChange={(e) => setSlideName(e.target.value)}
              value={slideName}
            />
          </VStack>
          <Text>Upload file</Text>
          <Box
            w="100%"
            h="30vh"
            alignSelf="center"
            border="1px black"
            background="light.300"
            onClick={handleFileInputClick}
            onDragEnter={(e) => handleDragEnter(e)}
            onDragOver={(e) => handleDragOver(e)}
            onDrop={(e) => handleDragFile(e)}
          >
            <Input
              type="file"
              ref={uploadInputRef}
              style={{ display: "none" }}
              onChange={handleInputChange}
              accept=".tiff,.svs,.tif"
            />
            <Flex
              w="30vw"
              h="23vh"
              margin="auto"
              alignItems="center"
              justifyContent="center"
              direction="column"
            >
              <MdFileUpload
                style={{
                  width: "100%",
                  height: "100%",
                  color: "#3B5D7C",
                }}
              />
              <Text fontSize="1vw" opacity="0.7" textAlign="center">
                {selectedFile
                  ? `${selectedFile.name} - ${selectedFile.size / 1000000} MB`
                  : "Drop file here or Click to upload"}
              </Text>
            </Flex>
          </Box>
        </>
      ) : (
        <Progress value={progress} min={0} max={100} />
      )}

      <HStack marginTop="3vh" spacing="2vw" marginStart="30vw">
        <Button
          alignItems="center"
          bgColor="#fff"
          color="light.700"
          border="1px solid #3B5D7C"
          size="sm"
          _focus={{ outline: "none" }}
          borderRadius="0px"
          fontWeight="400"
          width="7vw"
          height="4vh"
          onClick={cancelFileSelection}
          disabled={!isUploading}
        >
          Cancel
        </Button>
        <Button
          alignItems="center"
          bgColor="light.700"
          color="light.100"
          size="sm"
          _focus={{ outline: "none" }}
          borderRadius="0px"
          fontWeight="400"
          width="7vw"
          height="4vh"
          disabled={selectedFile === null || isUploading || !slideName?.length}
          onClick={uploadFiles}
        >
          Upload
        </Button>
      </HStack>
    </Flex>
  );
};

export default SlideUpload;

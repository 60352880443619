import {
  VStack,
  Text,
  Box,
  HStack,
  Menu,
  MenuButton,
  MenuList,
  MenuOptionGroup,
  MenuItemOption,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useState } from "react";
import _ from "lodash";
import { useDispatch } from "react-redux";
import { linkQuestion } from "../../state/reducers/newStudyReducer";
import LinkQuestionModal from "./LinkQuestionModal";

const Choice = ({
  question,
  questions,
  linkState,
  activeButton,
  questionId,
}) => {
  const dispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [questionData, setQuestionData] = useState();
  const filteredQuestions = questions[activeButton]?.findIndex(
    (questionElement) => questionElement?.questionId === questionId
  );
  const linkQuestions = ({
    choice,
    questionText,
    linkToIndex,
    index,
    question_id,
  }) => {
    dispatch(
      linkQuestion({
        index,
        type: activeButton,
        questionData: {
          index,
          question_text: questionText,
          choice,
          questionId: question_id,
          linkToIndex,
        },
      })
    );
  };
  const handleQuestionLink = ({ e, choice, questionText, question_id }) => {
    // const parsedData = JSON.parse(e);
    const linkToIndex = questions[activeButton]?.findIndex(
      (e) => e?.questionId === question_id
    );
    const index = questions[activeButton]?.findIndex(
      (el) => el?.questionId === e?.questionId
    );
    if (questions[activeButton]?.[index]?.conditionQuestion?.length) {
      onOpen();
      setQuestionData({
        choice,
        questionText,
        linkToIndex,
        question_id,
        index,
      });
    } else {
      linkQuestions({ choice, questionText, linkToIndex, question_id, index });
    }
  };
  return (
    <>
      <VStack alignItems="flex-start" w="100%" overflowX="hidden">
        {question?.choices?.map((choice, index) => {
          return (
            <HStack
              key={index}
              w="100%"
              minW="100%"
              justifyContent="space-between"
            >
              <Text maxW="60%">
                {`(${index + 1})
        ${choice}`}
              </Text>
              {linkState && (
                <Box maxW="50%">
                  <Menu>
                    <MenuButton
                      mr="1rem"
                      border="1px solid rgba(217, 217, 217, 1)"
                      borderRadius="5px"
                      p="0.3rem 1rem"
                      size="sm"
                    >
                      Link To Question
                    </MenuButton>
                    <MenuList p={0}>
                      <MenuOptionGroup type="radio">
                        {questions[activeButton]
                          ?.slice(filteredQuestions + 1)
                          ?.filter(
                            (question) =>
                              !question?.conditionQuestion?.questionId
                          )
                          ?.map((filteredQuestion, i) => {
                            return (
                              <MenuItemOption
                                value={filteredQuestion}
                                key={i}
                                onClick={() => {
                                  handleQuestionLink({
                                    e: filteredQuestion,
                                    choice,
                                    questionText: question?.question_text,
                                    question_id: question?.questionId,
                                  });
                                }}
                              >{`Link to  ${
                                filteredQuestion?.isSection
                                  ? "Section"
                                  : "Question"
                              } - ${
                                filteredQuestion?.question_text
                                  ? filteredQuestion?.question_text
                                  : filteredQuestion?.sectionHeading
                              }`}</MenuItemOption>
                            );
                          })}
                      </MenuOptionGroup>
                    </MenuList>
                  </Menu>
                </Box>
              )}
            </HStack>
          );
        })}
      </VStack>
      <LinkQuestionModal
        isOpen={isOpen}
        questionData={questionData}
        setQuestionData={setQuestionData}
        onClose={onClose}
        activeButton={activeButton}
      />
    </>
  );
};

export default Choice;

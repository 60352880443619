import {
  Flex,
  Menu,
  HStack,
  MenuButton,
  Text,
  Box,
  Button,
} from "@chakra-ui/react";
import Report from "../Report/Report";
import { useLocation } from "react-router-dom";

const ReportDetails = () => {
  const location = useLocation();
  const study = location?.state?.study;
  return (
    <>
      <Flex
        w="100%"
        direction="column"
        h="14.16666vh"
        minH="80px"
        bg="light.100"
        justifyContent="space-between"
      >
        <HStack w="100%" justifyContent="space-between" mt="0.6rem">
          <Flex alignItems="center" ml="5px">
            <Text ml="20px" fontSize="1.3rem" fontWeight="bold">
              {study?.study_title}
            </Text>
          </Flex>
          <Flex>
            <Menu>
              <MenuButton
                as={Button}
                fontWeight="500"
                border="1px solid #E8E8EB"
                borderRadius="5px"
                bgColor="inherit"
                mr="12px"
                w="300px"
                mt="6px"
              >
                <Box>
                  <Flex justifyContent="space-between">
                    <Flex>
                      <Text fontWeight="bold" fontSize="0.85rem">
                        COM :{" "}
                        {`${study?.first_name}
                       ${study?.last_name}`}
                      </Text>
                    </Flex>
                  </Flex>
                </Box>
              </MenuButton>
            </Menu>
          </Flex>
        </HStack>
        <Flex alignItems="flex-end">
          <Text ml="3.177vw" pb="2.6vh">
            Report
          </Text>
        </Flex>
      </Flex>
      <Flex bg="light.1004" w="100%" flex="1">
        <Report caseId={study?.case_id} studyName={study?.study_title} />
      </Flex>
    </>
  );
};
export default ReportDetails;

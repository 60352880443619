import React, { useState, useEffect } from "react";
import {
  Button,
  Flex,
  HStack,
  VStack,
  Text,
  useMediaQuery,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Box,
  useToast,
  Link,
  Image,
  Badge,
} from "@chakra-ui/react";
import { AiOutlineCaretDown } from "react-icons/ai";
import { IoGrid } from "react-icons/io5";
import { BiLinkExternal } from "react-icons/bi";
import { Link as RouteLink } from "react-router-dom";
import { getSlideUrl } from "../../../../../hooks/utility";
import { CheckCircleIcon } from "@chakra-ui/icons";
import { BiDotsVerticalRounded } from "react-icons/bi";
import SlideEditModal from "./SlideEditModal";
import SlideDeleteConfirmationModal from "./SlideDeleteConfirmationModal";
import { useGetUserInfoQuery } from "../../../../../state/api/medicalApi";

function SlideGridView({ slides, permission }) {
  const { data: userInfo, isLoading } = useGetUserInfoQuery();
  const [slideId, setSlideId] = useState();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const handleDeleteCloseModal = () => {
    setIsDeleteModalOpen(false);
  };
  return (
    <Flex
      flexDirection="column"
      h="100%"
      flex="1"
      maxH="80vh"
      overflow="auto"
      css={{
        "&::-webkit-scrollbar": {
          width: "4px",
        },
        "&::-webkit-scrollbar-track": {
          width: "6px",
        },
        "&::-webkit-scrollbar-thumb": {
          background: "#C4C4C4",
        },
      }}
      position="relative"
    >
      <Flex
        flexWrap="wrap"
        alignItems="flex-start"
        justifyContent="flex-start"
        flex="1"
        h="100%"
        mt="1.11vh"
        ml="7.8vw"
        mb="1.11vh"
      >
        {slides?.map((slide, index) => {
          return slide?.awsImageBucketUrl !== "" ? (
            <Flex
              key={index}
              // state={{ caseId, slide, userInfo }}
              style={{ textDecoration: "none" }}
              _hover={{ outline: "2px solid rgba(27, 117, 188, 1)" }}
              _focus={{ outline: "none" }}
              bgColor="light.100"
              w="12.9vw"
              m="1.1vh 1.1vw 1.1vw 0vw"
              p="0.833vw"
              borderRadius="5px"
              position="relative"
              direction="column"
            >
              <Link
                key={index}
                as={RouteLink}
                to={`/viewer`}
                state={{ caseId: slide?.caseId, slide, userInfo }}
                style={{ textDecoration: "none" }}
                // _hover={{ bgColor: "light.900" }}
                _focus={{ outline: "none" }}
                bgColor="light.100"
                // pb="0.833vw"
              >
                <VStack position="relative">
                  <Box
                    border="1px solid gray"
                    display="inline-block"
                    _before={
                      slide?.status && {
                        content: '""',
                        position: "absolute",
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0,
                        background: "rgba(217, 217, 217, 0.8)", // Adjust the opacity as needed
                      }
                    }
                  >
                    <Image
                      src={getSlideUrl(slide?.awsImageBucketUrl)}
                      w="12vw"
                      h="20.571vh"
                      alt={slide.accessionId}
                      p="1vh"
                    />
                  </Box>
                  {slide?.status && (
                    <Badge
                      colorScheme="green"
                      position="absolute"
                      // bottom="14vh"
                      // width="40%"
                      // height="10%"
                      // fontSize="10px"
                      // pt="0.6vh"
                      // display="none"
                      top="50%"
                      left="50%"
                      transform="translate(-50%, -50%)"
                    >
                      <Text>
                        Saved
                        <CheckCircleIcon ml="1vw" mb="3px" />
                      </Text>
                    </Badge>
                  )}
                </VStack>
                <HStack
                  w="100%"
                  justifyContent="space-between"
                  px="1.1vw"
                  pt="1.5vh"
                  fontSize="14px"
                >
                  <Text isTruncated>{slide?.accessionId}</Text>
                  {/* <IconButton
              bgColor="#fff"
              _focus={{ outline: "none" }}
              w="2vw"
              h="3vh"
              as={IoIosInformationCircleOutline}
            /> */}
                </HStack>
              </Link>
              <Box
                position="absolute"
                top="1.3vmax"
                right="0.5vmax"
                onClick={(e) => e.stopPropagation()}
              >
                <Menu>
                  <MenuButton
                    as={Button}
                    size="sm"
                    variant="ghost"
                    _hover="none"
                    _active="none"
                  >
                    <BiDotsVerticalRounded />
                  </MenuButton>
                  <MenuList p="0px" minW="2vw">
                    {permission?.data?.[0]?.permissions.includes(
                      "slideEdit"
                    ) && (
                      <MenuItem
                        onClick={() => {
                          setSlideId(slide?._id);
                          setIsModalOpen(true);
                        }}
                      >
                        Edit
                      </MenuItem>
                    )}
                    {permission?.data?.[0]?.permissions.includes(
                      "slideDelete"
                    ) && (
                      <MenuItem
                        onClick={() => {
                          setSlideId(slide?._id);
                          setIsDeleteModalOpen(true);
                        }}
                      >
                        Delete
                      </MenuItem>
                    )}
                  </MenuList>
                </Menu>
              </Box>
              {slideId === slide?._id && (
                <>
                  <SlideEditModal
                    isOpen={isModalOpen}
                    onClose={handleCloseModal}
                    slide={slide}
                  />
                  <SlideDeleteConfirmationModal
                    isOpen={isDeleteModalOpen}
                    onClose={handleDeleteCloseModal}
                    slideId={slide?._id}
                  />
                </>
              )}
            </Flex>
          ) : (
            ""
          );
        })}
      </Flex>
    </Flex>
  );
}

export default SlideGridView;

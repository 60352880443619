import React from "react";
import {
  Flex,
  HStack,
  VStack,
  Box,
  Text,
  useMediaQuery,
  Button,
} from "@chakra-ui/react";
import { BiLinkExternal } from "react-icons/bi";
const StudyDetailComponent = () => {
  const [ifWidthLessthan1920] = useMediaQuery("(max-width:1920px)");
  const headerStyle = {
    fontSize: ifWidthLessthan1920 ? "12px" : "15px",
    opacity: "0.7",
    fontFamily: "Inter",
  };
  const infoStyle = {
    fontSize: ifWidthLessthan1920 ? "13px" : "16px",
    fontFamily: "Inter",
    fontWeight: "bold",
  };
  return (
    <Flex w="100%" pl="10px">
      <VStack w="100%">
        <HStack w="100%" justifyContent="space-between">
          <Text
            color="#1B75BC"
            fontSize={ifWidthLessthan1920 ? "15px" : "18px"}
            fontWeight="bold"
          >
            Study Details
          </Text>
          <Button
            bgColor="inherit"
            _hover={{ color: "#1B75BC", cursor: "pointer" }}
            _active={{ color: "#1B75BC", cursor: "pointer" }}
          >
            <BiLinkExternal
              size={18}
              style={{ color: "#1B75BC", cursor: "pointer" }}
            />
          </Button>
        </HStack>
        <VStack
          w="100%"
          wordBreak="break-all"
          direction="column"
          justifyContent="flex-start"
          spacing={ifWidthLessthan1920 ? "2px" : "4px"}
          mt="5px"
        >
          <HStack w="100%" alignItems="flex-start">
            <VStack alignItems="left" spacing={0} w="50%">
              <Text style={headerStyle}>Title</Text>
              <Text style={infoStyle}>Study title - 7292022</Text>
            </VStack>
            <VStack alignItems="left" spacing={0} w="50%">
              <Text style={headerStyle}>Objective</Text>
              <Text style={infoStyle}>
                Duis autem vel eum iriure dolor in hendreit in vulputate velit
                esse molestie consequat, vel illum
              </Text>
            </VStack>
          </HStack>
          <HStack w="100%" alignItems="flex-start">
            <VStack alignItems="left" spacing={0} w="50%">
              <Text style={headerStyle}>Aim</Text>
              <Text style={infoStyle}>
                Lorem ipsum dolor consectetuer adipiscing ellit
              </Text>
            </VStack>
            <VStack alignItems="left" spacing={0} w="50%">
              <Text style={headerStyle}>Details</Text>
              <Text style={infoStyle}> Confidential</Text>
            </VStack>
          </HStack>
          <HStack w="100%" alignItems="flex-end">
            <VStack alignItems="left" spacing={0} w="50%" />
            <VStack alignItems="left" spacing={0} w="50%">
              <Text style={headerStyle}>Duration</Text>
              <Text style={infoStyle}>6 Months</Text>
            </VStack>
          </HStack>
        </VStack>
      </VStack>
    </Flex>
  );
};

export default StudyDetailComponent;
